import React from 'react'
import { PageGridItem } from '../types'

type Props = {
  item: PageGridItem
  children: React.ReactNode
}

export const hasGridId = (
  item: PageGridItem
): item is PageGridItem & { grid_id: number } => {
  return 'grid_id' in item
}

export const WordPressWrapper = ({ item, children }: Props) => {
  return hasGridId(item) ? (
    <React.Fragment key={`item-${item.grid_id}`}>{children}</React.Fragment>
  ) : (
    <>{children}</>
  )
}
