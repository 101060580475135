import { IMatrix2x4 } from '../../types'
import { parseMatrixLayout } from '../../utils'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { MatrixPost } from '../Post/MatrixPost'
import {
  Container,
  MobileTitleWrapper,
  PostsContainer,
  SubMatrixContainer,
  SubMatrixesContainer,
  TitlesWrapper,
} from './Matrix2x4.styles'

type Props = {
  matrix: IMatrix2x4
}

export const Matrix2x4 = ({ matrix }: Props) => {
  const { is_split_categories, matrix_elements, layout } = matrix

  const postsInLayout = parseMatrixLayout({
    matrixElements: matrix_elements,
    is_split_categories: is_split_categories,
    layout: layout,
    layoutType: '2x4',
  })

  const componentTitles = [
    matrix_elements[0].matrix_title,
    matrix_elements[1].matrix_title,
  ]

  if (!postsInLayout[0] && !postsInLayout[1]) {
    return null
  }

  return (
    <Container>
      {/* displays from medium screens and higher */}
      <TitlesWrapper isSplitCategories={!!is_split_categories}>
        {is_split_categories ? (
          <>
            <ComponentTitle componentTitle={componentTitles[0]} />
            <ComponentTitle componentTitle={componentTitles[1]} />
          </>
        ) : (
          // If is_split_categories is 0, the title spans over both columns
          <ComponentTitle componentTitle={componentTitles[0]} columnSpan={2} />
        )}
      </TitlesWrapper>
      <SubMatrixesContainer isSplitCategories={!!is_split_categories}>
        <SubMatrixContainer>
          <MobileTitleWrapper>
            <ComponentTitle
              componentTitle={componentTitles[0]}
              columnSpan={2}
            />
          </MobileTitleWrapper>
          <PostsContainer isSplitCategories={!!is_split_categories}>
            {postsInLayout[0]?.map(
              ({ post, layout: { rowSpan, columnSpan } }) =>
                post && (
                  <MatrixPost
                    key={post.id}
                    post={post}
                    rowSpan={rowSpan}
                    columnSpan={columnSpan}
                  />
                )
            )}
          </PostsContainer>
        </SubMatrixContainer>
        <SubMatrixContainer>
          <MobileTitleWrapper>
            {!!is_split_categories && (
              <ComponentTitle
                componentTitle={componentTitles[1]}
                columnSpan={2}
              />
            )}
          </MobileTitleWrapper>
          <PostsContainer isSplitCategories={!!is_split_categories}>
            {postsInLayout[1]?.map(
              ({ post, layout: { rowSpan, columnSpan } }) =>
                post && (
                  <MatrixPost
                    key={post.id}
                    post={post}
                    rowSpan={rowSpan}
                    columnSpan={columnSpan}
                  />
                )
            )}
          </PostsContainer>
        </SubMatrixContainer>
      </SubMatrixesContainer>
    </Container>
  )
}
