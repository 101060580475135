import styled from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const StyledLink = styled(Link)`
  &:last-child {
    ${themeClassName.vod} &, ${themeClassName.vodMain} & {
      color: ${colors.red};
    }
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;
  flex-wrap: wrap;

  ${device.medium} {
    margin-bottom: 24px;
  }

  > svg > path {
    fill: ${props =>
      props.theme.isDarkTheme ? colors.steelBlue : colors.black};
  }
`

export const StyledTitle = styled.div<{
  isLast: boolean
  fontWeight: string
  hoverColor: string
}>`
  font-size: 14px;
  line-height: 14px;
  color: ${props =>
    props.isLast && props.theme.isDarkTheme
      ? '#FFFFFF'
      : props.isLast
      ? '#000000'
      : props.theme.isDarkTheme
      ? '#9592A5'
      : '#5B5B5B'};
  font-weight: ${props => props.fontWeight};

  &:hover {
    color: ${props => props.hoverColor};
  }

  ${device.medium} {
    font-size: 18px;
    line-height: 20px;
  }
`
