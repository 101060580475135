import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

import ArrowLeftDesktop from '/public/assets/images/svg/arrow-left-gallery-desktop.svg'
import ArrowRight from '/public/assets/images/svg/right-arrow-white-big.svg'

export const CloseIcon = styled.span`
  display: flex;
  padding: 20px;
  position: absolute;
  top: 32px;
  right: 0;
  cursor: pointer;

  ${device.medium} {
    display: none;
  }
`

export const CloseIconDesktop = styled.span`
  display: none;

  ${device.medium} {
    display: flex;
    position: absolute;
    cursor: pointer;
    pointer-events: auto;
    top: 0;
    right: calc(50% - 976px / 2 - 1px);

    > svg {
      font-size: 17px;
    }
  }
`

export const WrapperImages = styled.div`
  margin-top: auto;
  position: relative;

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  ${device.medium} {
    margin: auto 0;

    .swiper {
      padding-bottom: 40px;
      padding-top: 29px;
    }

    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        justify-content: center;
        position: relative;
      }
    }
  }

  .swiper-pagination {
    display: none;
    ${device.medium} {
      direction: ltr;
      display: flex;
      justify-content: center;
      white-space: pre;
      color: ${colors.white};
      font-size: 20px;
      font-weight: 600;
      bottom: 0;
    }
  }
`

export const NavigationOverlay = styled.div<{
  position: 'left' | 'right'
}>`
  top: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  pointer-events: none;
  ${props => (props.position === 'left' ? 'left: 0;' : 'right: 0;')}

  ${device.medium} {
    transition: position 300ms ease-in;
    ${props =>
      props.position === 'left'
        ? // this accounts for the max width of the image,
          // the distance between the image and the arrow,
          // the arrow's padding and the arrow's width
          'left: calc(50% - 976px / 2 - 35px - 20px - 29px / 2);'
        : 'right:calc(50% - 976px / 2 - 35px - 20px - 29px / 2);'}
  }
`

export const TouchArea = styled.div<{ position: 'left' | 'right' }>`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: 20px;

  ${device.medium} {
    font-size: 29px;
  }
`

export const ArrowRightIcon = styled(ArrowRight)`
  pointer-events: auto;
  filter: drop-shadow(0px 4px 4px #00000096);

  ${device.medium} {
    display: none;
  }
`

export const ArrowLeftIcon = styled(ArrowRightIcon)`
  /* mirror horizontally */
  transform: scaleX(-1);
`

export const ArrowLeftIconDesktop = styled(ArrowLeftDesktop)`
  display: none;

  ${device.medium} {
    display: inline;
    pointer-events: auto;
  }
`
export const ArrowRightIconDesktop = styled(ArrowLeftIconDesktop)`
  /* mirror horizontally */
  transform: scaleX(-1);
`

export const Caption = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.white};
`

export const ImageAndCaption = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  pointer-events: auto;

  ${device.medium} {
    width: 976px;
    max-width: 976px;
    height: 549px;
    max-height: 549px;
    background-color: #030303;

    > img {
      flex: 1;
      object-fit: scale-down;
    }
  }
`

export const CaptionDesktop = styled.div`
  display: none;
  ${device.medium} {
    direction: rtl;
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.white};

    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 4px 5px 2px 0;
    background-color: rgba(19, 19, 19, 0.82);
  }
`

export const WrapperText = styled.div`
  position: relative;
  margin-top: auto;
  padding: 0 20px;

  .swiper-wrapper {
    padding-bottom: 80px;
  }

  .swiper-pagination {
    direction: ltr;
    display: flex;
    justify-content: center;
    white-space: pre;
    color: ${colors.white};
    bottom: 35px;
    font-size: 17px;
    font-weight: 500;
  }

  .swiper-slide {
    direction: rtl;
  }

  ${device.medium} {
    display: none;
  }
`

export const Container = styled.div<{ onlyOnePicture: boolean }>`
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${NavigationOverlay}, .swiper-pagination {
    ${({ onlyOnePicture }) => onlyOnePicture && 'display: none;'}
  }
`
