import styled from 'styled-components'
import { device, colors } from '../../styles/GlobalVariables'
import ArrowDown from '/public/assets/images/svg/down-arrow-white.svg'

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 34px;
  overflow: hidden;
  color: #000;
  font-size: 18px;

  &:before {
    content: 'סנן:';
    margin-left: 8px;
  }

  div.timeLineTag {
    padding: 5px 12px;
    border: solid 1px #e5e5e5;
    cursor: pointer;
    margin-right: 10px;
  }

  div.all {
    background-color: ${props =>
      props.theme.activeTag === 'All' ? props.theme.color : '#fff'};
    color: ${props => (props.theme.activeTag === 'All' ? '#fff' : '#000')};
  }

  div.active {
    background-color: ${props => props.theme.color};
    color: #fff;
  }

  margin-bottom: 20px;
  ${device.small} {
    margin-bottom: unset;
  }
`

export const MainItemWrapper = styled.div`
  position: relative;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  [id^='timeline_box'] {
    min-width: 100%;
    text-align: center;
  }

  ${device.small} {
    flex-direction: row;
  }
`

export const TimeLineItem = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 10px;
  background-color: #fff;
  padding: 16px 22px;
  margin-bottom: 42px;
  font-size: 20px;
  position: relative;
  width: 100%;
  border: solid 1px ${colors.lightBlue};
  border-radius: 4px;

  ${props =>
    props.theme !== 'last'
      ? `
        :after {
            position: absolute;
            right: 40px;
            bottom: -13px;
            content: "";
            background-color: ${colors.lightBlue};
            height: 13px;
            width: 1px;
        }
`
      : ''}

  ${device.small} {
    margin-bottom: 24px;
    border-radius: 0;
    border: solid 1px ${colors.menuOptionGray};
    &:not(.last):after {
      content: unset;
    }
  }
`

export const ItemTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: right;
  color: #000;
  margin-top: 8px;

  ${device.small} {
    margin-top: unset;
  }

  a.itemTitleGrid {
    font-size: 22px;
    color: black;
    font-weight: 400;
    &:visited {
      text-decoration: none;
    }
  }
`

export const ItemContent = styled.div`
  overflow-y: hidden;
  display: inline;

  figcaption {
    margin-bottom: 40px;
    color: #939393;
    font-weight: 600;
    font-size: 20px;
  }

  p {
    position: relative;
    margin-top: 20px;
  }

  [class^='Playerstyles__WrapStyledPlayerComp'] {
    width: 100%;
  }
`

export const ShareButtonsWrapper = styled.div`
  display: inline-flex;
  button {
    margin-left: 12px;
  }
`

export const Anchor = styled.div<{ headerHeight: number }>`
  position: absolute;
  top: -${props => props.headerHeight}px;
  display: block;
`

export const WrapperFlex = styled.div`
  flex-grow: 0;
  display: flex;

  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column-reverse;
  padding: 0;
  margin-top: 14px;

  ${device.small} {
    flex-direction: row;
    align-items: center;
    margin-bottom: 28px;
  }
`

export const SelectContainer = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.darkBlue};
  min-width: 13.75%;
  margin-bottom: 30px;

  ${device.small} {
    margin-bottom: unset;
  }
`

export const Select = styled.div`
  color: #000;
  cursor: pointer;
  position: relative;
`

export const SelectValue = styled.div`
  display: inline-flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  color: #000;

  div {
    white-space: nowrap;
    overflow: hidden;
  }
`

export const SelectDropBox = styled.div`
  border: 1px solid;
  padding: 2px 3px;
  position: absolute;
  top: 23px;
  display: ${props => (props.theme.showDrop ? 'block' : 'none')};
  background-color: #fff;
  z-index: 100;
`

export const Option = styled.div`
  padding: 2px 4px;
  font-size: 16px;
`

export const ArrowRightIcon = styled(ArrowDown)`
  font-size: 11px;
  z-index: 1;
  pointer-events: auto;
  margin-top: -3px;
  color: #000;
  path {
    stroke: ${colors.darkBlue};
  }
  &.up {
    transform: rotate(180deg);
  }
`

export const TimeWrapper = styled.div`
  display: flex;
  align-self: stretch;

  ${device.small} {
    max-width: 13.75%;
    width: 13.75%;
    padding-right: 30px;

    &.first {
      padding-top: 12px;
    }
  }
`

export const InnerTimeWrapper = styled.div`
  &.last {
    border: none;
  }

  ${props =>
    props.theme.isLast
      ? ''
      : `
            ${device.small}{
                &:before {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    background-color: ${props.theme.innerTimeWrapperColor};
                    top: 12px;
                }
            }
    `}
`

const TimeH = 36
export const Time = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  width: 64px;
  height: ${TimeH}px;
  line-height: 0.9;
  flex-grow: 0;
  border-radius: 4px;
  z-index: 1;
  top: -${TimeH / 2}px;
  right: 9px;
  background-color: ${props => props.theme.color};

  &:not(.first) {
    :before {
      position: absolute;
      right: 50%;
      top: -12px;
      content: '';
      background-color: ${props => props.theme.color}
      height: 12px;
      width: 1px;
    }
  }

  ${device.small} {
    top: 12px;
    right: 0;

    :after {
      left: -55px;
      position: absolute;
      content: '';
      background-color: ${props => props.theme.color};
      width: 55px;
      height: 1px;
      bottom: 18px;
    }

    &:not(.first):before {
      left: -55px;
      position: absolute;
      content: '';
      background-color: ${props => props.theme.color};
      width: 55px;
      height: 1px;
      bottom: 18px;
    }

    &:not(.first) {
      :before {
        content: none;
      }
    }
  }

  ${props =>
    !props.theme.isToday
      ? `
        span:first-child{
            font-size: 12px;
            font-weight: 400;
        }
    `
      : ''}
`

export const StyledAdWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  margin-bottom: 30px;
  position: relative;

  .line {
    display: none;
  }

  ${device.small} {
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: space-between;
    border-right: ${props => props.theme.color} .line {
      display: inline-flex;
      content: '';
      height: 298px;
      background: ${props => props.theme.color};
      width: 1px;
      margin-right: 30px;
      margin-bottom: -48px;
    }

    [id^='timeline_box'] {
      text-align: center;
      width: 100%;
    }
  }
`

export const StyledColumn = styled.div``
export const StyledRow = styled.div`
  display: flex;
`
