import React, { useContext, useEffect, useRef, useState } from 'react'
import { TimeLineContext } from '../../contexts'
import { IPage, IPageSEO, ITimeLine, ITimeLineElement } from '../../types'
import { SortByFL } from './SortByFL'
import {
  StyledColumn,
  StyledRow,
  CalendarContainer,
  WrapperFlex,
} from './TimeLineFL.styles'
import { TimeLineSubItemFL } from './TimeLineSubItemFL'
import { TimeLineTagsFL } from './TimeLineTagsFL'
import { Calendar } from './Calendar/Calendar'

type TimeLineProps = {
  data: IPage
}

export const TimeLineFL: React.FC<TimeLineProps> = props => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const dta = props.data.Content.Item?.timeLines || []
  const data_key = Object.keys(dta)[0] as string
  const timeLineArr = (dta[
    data_key as keyof typeof dta
  ] as unknown as ITimeLine) || { elements: [] }

  const [filterElements, setFilterElements] = useState<Array<ITimeLineElement>>(
    timeLineArr.elements
  )
  const [activeTag, setActiveTag] = useState('All')
  const refElements = useRef<Array<ITimeLineElement>>([])
  const timeLineCtx = useContext(TimeLineContext)
  const calendarContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    timeLineCtx.setElements(refElements.current)
  }, [props, timeLineCtx])

  const renderItem = (SEO: IPageSEO) => {
    const elements =
      order === 'desc' ? filterElements.reverse() : filterElements
    if (refElements.current) {
      return (
        filterElements.length > 0 &&
        Object.entries(elements).map((el, index, elements) => {
          const isLast = index + 1 === elements.length
          const props = {
            key: el[0],
            id: el[1].id + 'Item',
            data: el[1],
          }
          const adIndex = index + 1
          const bulletColor = dta[
            Object.keys(dta)[0] as string as keyof typeof dta
          ] as unknown as ITimeLine
          const orderClassName =
            index === 0
              ? 'first'
              : index + 1 === elements.length
              ? 'last'
              : 'middle'
          return (
            <TimeLineSubItemFL
              key={'TimeLineSubItem' + index}
              el={el}
              index={index}
              adIndex={adIndex}
              orderClassName={orderClassName}
              isLast={isLast}
              SEO={SEO}
              data={props}
              color={bulletColor.header.favcolor}
            />
          )
        })
      )
    }
    return null
  }

  const tagFilterHandler = (id: string) => {
    console.log('renderItem tagFilterHandler', { id })
    let filteredElements = []
    if (id === 'All') {
      filteredElements = refElements.current
    } else {
      filteredElements = refElements.current.filter(el => {
        return el.tags.find(tag => {
          return tag.term_id === id
        })
      })
    }
    setActiveTag(id)
    setFilterElements(filteredElements)
    timeLineCtx.setElements(filteredElements)
  }

  const sortElementsByDateHandler = (sotBy: 'asc' | 'desc') => {
    setOrder(sotBy)
  }

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (calendarContainerRef.current) {
        if (window.scrollY >= 300) {
          calendarContainerRef.current.classList.add('sticky')
        } else {
          calendarContainerRef.current.classList.remove('sticky')
        }
      }
    })
  }, [])

  //get data
  const timelineItem = props?.data.Content?.Item
  const data = timelineItem?.timeLines

  if (!data) {
    return null
  }

  const { SEO } = props.data
  const key = Object.keys(data)[0] as string
  const timeLineData = data[key as keyof typeof data] as unknown as ITimeLine
  if (timeLineData) {
    refElements.current = timeLineData.elements
    const calender = timeLineData.calender

    return (
      <StyledRow key={'tl_wrapper_' + key}>
        <StyledColumn>
          {calender && (
            <CalendarContainer ref={calendarContainerRef}>
              <Calendar calender={calender} timelineItem={timelineItem} />
            </CalendarContainer>
          )}
          <WrapperFlex>
            <TimeLineTagsFL
              timeline={timeLineData}
              tagFilterHandler={tagFilterHandler}
              activeTag={activeTag}
            />
            <SortByFL sortElementsByDate={sortElementsByDateHandler} />
          </WrapperFlex>
          {renderItem(SEO)}
        </StyledColumn>
      </StyledRow>
    )
  }
  return null
}
