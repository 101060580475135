import styled from 'styled-components'
import {
  device,
  maxWidth,
  maxWidthFl,
  WarOffset,
} from '../../styles/GlobalVariables'

export const Container = styled.div`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 0 auto;
  // this is to counter the effects of the line above which shrinks the flex container
  width: 100%;
  padding: 0 20px;

  ${device.medium} {
    padding: 0;
    max-width: ${props =>
      (props.theme.isFaceLift ? maxWidthFl : maxWidth).medium};
  }

  ${device.large} {
    max-width: ${props =>
      (props.theme.isFaceLift ? maxWidthFl : maxWidth).large};
  }

  ${props => WarOffset(props.theme.haseWarComp)}
`

export const TaboolaWrapper = styled.div`
  margin-bottom: 15px;
`
