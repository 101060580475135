import { IPost } from '../../types'
import { addAnalyticsToUrl } from '../../utils'
import { Image } from '../Image/Image'
import { PostContainer, PostsContainer, PostTitle } from './Advertisers.styles'

type Props = {
  posts: IPost[]
  pid?: string
  gid: string
}

export const Desktop = ({ posts, gid, pid }: Props) => {
  return (
    <PostsContainer>
      {posts.slice(0, 4).map(post => (
        <PostContainer href={addAnalyticsToUrl(post.link, pid || '#', gid)}>
          <Image image={post.imageObj} alt={post.title} />
          <PostTitle>{post.title}</PostTitle>
        </PostContainer>
      ))}
    </PostsContainer>
  )
}
