import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'
import ArrowLeft from '/public/assets/images/svg/arrow-left-grey.svg'
import ArrowRight from '/public/assets/images/svg/arrow-right-grey.svg'

export const Container = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  z-index: 1;

  ${device.medium} {
    margin: 0 0 30px;
  }
`

export const ProgramContainer = styled.div`
  display: flex;
  min-height: 284px;
  max-height: 284px;

  ${device.medium} {
    flex: 1 0 234px;
    min-height: 416px;
    max-height: 416px;
  }
`

export const Thumbnail = styled(Link)`
  display: flex;

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }
`

export const Carousel = styled.div`
  position: relative;

  ${device.medium} {
    display: none;
  }

  .swiper {
    overflow-x: hidden;
    overflow-y: auto;
  }
`

export const ProgramsGrid = styled.div`
  display: none;

  ${device.medium} {
    display: grid;
    grid-template-columns: repeat(5, 234px);
    gap: 20px 22.5px;
  }

  ${device.large} {
    gap: 20px 30px;
  }
`

export const ArrowLeftIcon = styled(ArrowLeft)`
  font-size: 22px;
  left: 30px;
  top: 85px;
  z-index: 1;
`

export const ArrowRightIcon = styled(ArrowRight)`
  font-size: 22px;
  right: 0;
  top: 85px;
  z-index: 1;
  pointer-events: auto;
`

export const TouchArea = styled.div<{ position: 'left' | 'right' }>`
  display: flex;
  position: absolute;
  pointer-events: auto;
  cursor: pointer;
  padding: 20px 20px;
  ${props => (props.position === 'left' ? 'left: 0;' : 'right: 0;')}
  align-self: ${({ position }) =>
    position === 'left' ? 'flex-end' : 'flex-start'};
  top: calc(50% - 31px);
  user-select: none;
`
