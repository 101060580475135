import styled, { css } from 'styled-components'
import { Theme } from '../../contexts'
import { colors, themeClassName } from '../../styles/GlobalVariables'
import Facebook from '/public/assets/images/svg/facebook.svg'
import FacebookFl from '/public/assets/images/svg/facebook-black.svg'
import FacebookDarkThemeFl from '/public/assets/images/svg/facebook_dark_theme_fl.svg'
import Email from '/public/assets/images/svg/mail.svg'
import EmailFl from '/public/assets/images/svg/emailIcon_fl.svg'
import EmailDarkThemeFl from '/public/assets/images/svg/emailIcon_dark_theme_fl.svg'
import Twitter from '/public/assets/images/svg/twitter.svg'
import TwitterFl from '/public/assets/images/svg/x-twitter.svg'
import TwitterDarkThemeFl from '/public/assets/images/svg/x-twitter_dark_theme.svg'
import Whatsapp from '/public/assets/images/svg/whatsapp.svg'
import WhatsappFl from '/public/assets/images/svg/whatsapp_fl.svg'
import WhatsappDarkThemeFl from '/public/assets/images/svg/whatsapp_dark_theme_fl.svg'

export const Container = styled.div`
  display: flex;
  gap: 15px;
`

const defaultStyle = css<{ theme: Theme; size?: string }>`
  font-size: ${props => (props.size ? props.size : '25px')};

  ${themeClassName.vod} & path {
    fill: ${colors.white};
  }
`

export const FacebookIcon = styled(Facebook)`
  ${defaultStyle}
`
export const FacebookFlIcon = styled(FacebookFl)`
  ${defaultStyle}
`
export const FacebookDarkThemeFlIcon = styled(FacebookDarkThemeFl)`
  ${defaultStyle}
`

export const TwitterIcon = styled(Twitter)`
  ${defaultStyle}
`
export const TwitterFlIcon = styled(TwitterFl)`
  ${defaultStyle}
`
export const TwitterDarkThemeFlIcon = styled(TwitterDarkThemeFl)`
  ${defaultStyle}
`

export const WhatsappIcon = styled(Whatsapp)`
  ${defaultStyle}
`
export const WhatsappFlIcon = styled(WhatsappFl)`
  ${defaultStyle}
`
export const WhatsappDarkThemeFlIcon = styled(WhatsappDarkThemeFl)`
  ${defaultStyle}
`
export const EmailIcon = styled(Email)`
  ${defaultStyle}

  ${themeClassName.vod} & {
    circle {
      fill: ${colors.white};
    }
    path {
      fill: ${colors.tvGuideDark};
    }
  }
`
export const EmailFlIcon = styled(EmailFl)`
  ${defaultStyle}
`
export const EmailDarkThemeFlIcon = styled(EmailDarkThemeFl)`
  ${defaultStyle}
`
