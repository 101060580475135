import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  background-repeat: no-repeat;
  background-image: url('/assets/images/svg/404-mobile.svg');
  background-size: 100%;

  ${device.medium} {
    background-size: 110%;
    height: 631px;
  }
`
export const RedirectSection = styled.div`
  ${device.medium} {
    display: flex;
    flex-direction: row-reverse;
    max-width: 1260px;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    padding-top: 235px;
  }
  ${device.large} {
    max-width: 1290px;
  }
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 68px;
  justify-content: right;
  align-items: center;

  ${device.medium} {
    margin-top: unset;
    align-items: start;
  }
`

export const Title = styled.div`
  max-width: 232px;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.blueBlack};

  ${device.medium} {
    text-align: initial;
    max-width: 494px;
    font-size: 44.8px;
  }
`

export const Description = styled.div`
  max-width: 236px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.whiteGray};

  ${device.medium} {
    text-align: initial;
    max-width: 446px;
    font-size: 20px;
  }
`

export const StyledLink = styled(Link)`
  flex-shrink: 0;
  width: 102px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 4px 12px;
  border-radius: 4px;
  background-color: #00104a;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
  cursor: pointer;

  ${device.medium} {
    width: 158px;
    height: 44px;
    font-size: 20px;

    margin-left: unset;
    margin-right: unset;

    max-width: 446px;
    font-size: 20px;
  }
`

export const Logo = styled.img<{
  mobile: string | undefined
  desktop: string | undefined
}>`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12%;
  width: 67px;
  height: 72px;
  content: url(${props => props.mobile});

  ${device.medium} {
    flex-grow: 0;
    margin-top: unset;
    object-fit: contain;
    margin-left: unset;
    margin-right: unset;
    content: url(${props => props.desktop});
    width: 155px;
    height: 170px;
  }
`
