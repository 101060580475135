import React, { useContext, useEffect, useRef, useState } from 'react'
import { TimeLineContext } from '../../contexts'
import { IPage, IPageSEO, ITimeLine, ITimeLineElement } from '../../types'
import { SortByDropDown } from './SortByDropDown'
import { StyledColumn, StyledRow, WrapperFlex } from './TimeLine.styles'
import { TimeLineSubItem } from './TimeLineSubItem'
import { TimeLineTags } from './TimeLineTags'

type TimeLineProps = {
  data: IPage
}

export const TimeLine: React.FC<TimeLineProps> = props => {
  const dta = props.data.Content.Item?.timeLines || []
  const data_key = Object.keys(dta)[0] as string
  const timeLineArr = (dta[
    data_key as keyof typeof dta
  ] as unknown as ITimeLine) || { elements: [] }

  const [filterElements, setFilterElements] = useState<Array<ITimeLineElement>>(
    timeLineArr.elements
  )
  const [activeTag, setActiveTag] = useState('All')
  const refElements = useRef<Array<ITimeLineElement>>([])
  const timeLineCtx = useContext(TimeLineContext)

  useEffect(() => {
    timeLineCtx.setElements(refElements.current)
  }, [props, timeLineCtx])

  const renderItem = (SEO: IPageSEO) => {
    if (refElements.current) {
      return (
        filterElements.length > 0 &&
        Object.entries(filterElements).map((el, index, elements) => {
          const isLast = index + 1 === elements.length
          const props = {
            key: el[0],
            id: el[1].id + 'Item',
            data: el[1],
          }
          const adIndex = index + 1
          const bulletColor = dta[
            Object.keys(dta)[0] as string as keyof typeof dta
          ] as unknown as ITimeLine
          const orderClassName =
            index === 0
              ? 'first'
              : index + 1 === filterElements.length
              ? 'last'
              : 'middle'
          return (
            <TimeLineSubItem
              key={'TimeLineSubItem' + index}
              el={el}
              index={index}
              adIndex={adIndex}
              orderClassName={orderClassName}
              isLast={isLast}
              SEO={SEO}
              data={props}
              color={bulletColor.header.favcolor}
            />
          )
        })
      )
    }
    return null
  }

  const tagFilterHandler = (id: string) => {
    let filteredElements = []
    if (id === 'All') {
      filteredElements = refElements.current
    } else {
      filteredElements = refElements.current.filter(el => {
        return el.tags.find(tag => {
          return tag.term_id === id
        })
      })
    }
    setActiveTag(id)
    setFilterElements(filteredElements)
    timeLineCtx.setElements(filteredElements)
  }

  const sortElementsByDateHandler = (sotBy: string) => {
    const sortedElements =
      sotBy === 'newFirst' ? filterElements : [...filterElements.reverse()]
    setFilterElements(sortedElements)
    timeLineCtx.setElements(sortedElements)
  }

  //get data
  const data = props?.data.Content?.Item?.timeLines
  if (!data) {
    return null
  }
  const { SEO } = props.data
  const key = Object.keys(data)[0] as string
  const timeLineData = data[key as keyof typeof data] as unknown as ITimeLine
  if (timeLineData) {
    refElements.current = timeLineData.elements
    return (
      <StyledRow key={'tl_wrapper_' + key}>
        <StyledColumn>
          <WrapperFlex>
            <SortByDropDown sortElementsBysoDate={sortElementsByDateHandler} />
            <TimeLineTags
              timeline={timeLineData}
              tagFilterHandler={tagFilterHandler}
              activeTag={activeTag}
            />
          </WrapperFlex>
          {renderItem(SEO)}
        </StyledColumn>
      </StyledRow>
    )
  }
  return null
}
