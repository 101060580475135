import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${device.medium} {
    gap: 16px;
  }
`

export const Thumbnail = styled(Link)`
  flex: 1 1 50%;
  display: flex;
  position: relative;
  min-height: 210.9px;
  max-height: 210.9px;
  border-radius: 1px;

  img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }

  ${device.medium} {
    min-height: 355px;
    max-height: 417px;
  }
`

export const TextContainer = styled.div<{ lifestyle?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.lifestyle ? '4px' : '8px')};
  padding: 0 20px 0;

  ${device.medium} {
    padding: 0;
  }
`

export const Title = styled.h3<{ lifestyle?: boolean }>`
  font-size: ${props => (props.lifestyle ? '18px' : '28px')};
  font-weight: ${props => (props.lifestyle ? '600' : 'bold')};
  line-height: ${props => (props.lifestyle ? '1.2' : '1.13')};
  color: ${colors.black};

  ${device.medium} {
    font-size: 37px;
    line-height: 1.2;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: ${props =>
      props.lifestyle ? 'calc(1.2 * 3 * 37px)' : 'calc(1.2 * 3 * 37px)'};
  }
`

export const SecondaryTitle = styled(Link)<{ lifestyle?: boolean }>`
  font-size: ${props => (props.lifestyle ? '16px' : '18px')};
  font-weight: normal;
  line-height: 1.2;
  color: ${colors.black};

  ${device.medium} {
    font-size: 18px;
    line-height: 1;
    max-height: calc(1 * 2 * 18px);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
`
