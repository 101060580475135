import styled from 'styled-components'
import {
  colors,
  device,
  fonts,
  themeClassName,
} from '../../styles/GlobalVariables'

export const Container = styled.div<{
  columnSpan?: number
  containerWidth?: number | null
  leftTextWidth?: number | null
}>`
  grid-column: span ${props => props.columnSpan || 1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px;
  position: relative;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  max-height: 40px;
  width: 100%;

  ${device.medium} {
    margin: 0 0 32px 0;
  }

  ${themeClassName.vodMain} & {
    border: unset;
  }
`

export const Title = styled.h2<{ fontColor: string }>`
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  text-align: right;
  font-family: ${fonts.leon};
  color: ${props => `${props.fontColor};`};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${device.medium} {
    width: 100%;
    -webkit-line-clamp: 1;
    font-size: 32px;
    line-height: 38px;
    max-height: 50px;
  }

  ${themeClassName.vodMain} & {
    color: ${colors.white};
    font-size: 18px;
    font-weight: 500;
    line-height: normal;

    ${device.medium} {
      font-size: 24px;
      line-height: 1.25;
    }
  }
`

export const LeftText = styled.a<{ lineColor: string; fontColor: string }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  margin-right: 16px;
  position: relative;
  height: 100%;
  border: 1px solid
    ${props => (props.theme.isDarkTheme ? '#01DEFF' : colors.steelBlue)};
  gap: 8px;
  max-height: 40px;

  margin-bottom: -0.25px;

  > a {
    z-index: 1;
  }

  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    max-width: 95px; // 150px - gap - arrow width - padding

    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;
    color: ${props => `${props.fontColor};`};

    ${device.medium} {
      font-size: 18px;
      line-height: 1.1;
    }
  }

  > svg {
    z-index: 1;
    font-size: 13.67px;

    path {
      fill: ${props => `${props.fontColor};`};
    }
  }

  ${themeClassName.vodMain} & {
    background: none;
    padding: 2px 0;

    :after {
      display: none;
    }
  }
`
