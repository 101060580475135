import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { ITimeLineElement } from '../../types'
import { TagsWrapper } from './TimeLine.styles'

interface TimeLineTagsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeline: any
  tagFilterHandler: (id: string) => void
  activeTag: string
}

type Tag = {
  counter: string
  id: string
  name: String
}

export const TimeLineTags: React.FC<TimeLineTagsProps> = props => {
  const [tags, setTags] = useState<Array<Tag>>([])
  const { timeline, tagFilterHandler, activeTag } = props

  useEffect(() => {
    const elements: ITimeLineElement[] = timeline.elements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let tagsArray: any[] = [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tagsSortArray: any[] = []

    if (timeline && elements) {
      tagsSortArray = []
      tagsArray = []
      const posts_ids: (number | string)[] = []
      elements.forEach(el => {
        posts_ids.push(el.id)
        tagsSortArray = tagsSortArray.concat(el.tags)
      })

      //sort count of tags in use
      tagsSortArray.forEach(el => {
        const key = el.term_id
        const count = tagsArray[key]
          ? tagsArray[key].counter
            ? tagsArray[key].counter
            : 0
          : 0
        tagsArray[key] = {
          name: el.name,
          counter: count + 1,
          id: el.term_id,
        }
      })
      tagsArray.sort((a, b) => (a.counter < b.counter ? 1 : -1))
      setTags(tagsArray)
    }
  }, [timeline])

  return (
    <TagsWrapper
      theme={{
        color: timeline.header.favcolor,
        activeTag: activeTag,
      }}
    >
      <div
        className={'timeLineTag all'}
        onClick={() => tagFilterHandler('All')}
        children={'הכל'}
      />
      {tags.map((el, index) => {
        return (
          <div
            key={index}
            id={el.id}
            className={cx([
              'timeLineTag',
              activeTag === el.id ? 'active' : '',
              el.id,
            ])}
            onClick={() => tagFilterHandler(el.id)}
            children={el.name}
          />
        )
      })}
    </TagsWrapper>
  )
}
