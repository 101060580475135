import styled from 'styled-components'
import {
  colors,
  device,
  headerZIndex,
  hideScrollbar,
  themeClassName,
} from '../../styles/GlobalVariables'

export const Container = styled.div`
  grid-column: span 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 29px;
  position: relative;
  z-index: ${headerZIndex};

  ${themeClassName.vodMain} &, ${themeClassName.vod} & {
    background: transparent;
    box-shadow: none;
  }

  ${device.medium} {
    grid-column: 2 / span 2;
  }

  :before {
    position: absolute;
    width: 400vh;
    z-index: -1;
    content: '';
    bottom: 0;
    left: -100vh;
    top: 0;

    ${themeClassName.portal} & {
      background: ${colors.white};
    }

    ${themeClassName.vodMain} &, ${themeClassName.vod} & {
      background: transparent;
    }
  }
`

export const SubMenuItemDot = styled.div`
  height: 4px;
  width: 4px;
  background: ${colors.headerBlue};
  border-radius: 50%;
  transform: translate(0%, 180%);
  position: relative;
  margin: 0 12px;

  ${themeClassName.vodMain} &, ${themeClassName.vod} & {
    background: ${colors.white};
  }
`

export const SubMenuItem = styled.div<{ active?: boolean; color: string }>`
  font-size: 14px;
  line-height: 19px;
  min-width: unset;

  ${({ active }) =>
    active && {
      fontWeight: 'bold',
    }}
  a {
    color: ${props => props.color};
    display: flex;
  }

  gap: 8px;
  background: ${colors.white};

  ${themeClassName.portal} & p {
    border-bottom: none !important;
  }

  ${themeClassName.vodMain} &, ${themeClassName.vod} & {
    background: transparent;
    box-shadow: none;

    a {
      color: ${colors.white};
    }
  }

  ${device.medium} {
    font-size: 16px;
  }
`

export const SubMenuItems = styled.div<{ menuOverlay: boolean }>`
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 97%;
  overflow-x: scroll;

  ${device.medium} {
    overflow-x: hidden;
  }

  ${SubMenuItem}:last-child {
    ${SubMenuItemDot} {
      display: none;
    }
  }

  ${hideScrollbar}
`

export const ArrowWrapper = styled.div<{ direction: 'left' | 'right' }>`
  display: none;

  ${device.medium} {
    display: flex;
    align-items: center;
    min-width: 16px;
    ${props =>
      props.direction === 'left' ? 'margin-right: 3.5px' : 'margin-left: 3.5px'}
  }

  :hover {
    cursor: pointer;
  }

  ${themeClassName.vodMain} &, ${themeClassName.vod} & {
    svg {
      path {
        stroke: white;
      }
    }
  }
`
