import styled from 'styled-components'
import { device, maxWidth } from '../../styles/GlobalVariables'

export const Container = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 30px;

  ${device.medium} {
    max-width: ${maxWidth.medium};
    margin-bottom: 28px;
  }

  ${device.large} {
    max-width: ${maxWidth.large};
  }
`

export const TitlesWrapper = styled.div<{ isSplitCategories: boolean }>`
  display: none;

  ${device.medium} {
    grid-column: span 2;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: ${props => (props.isSplitCategories ? '30px' : '20px')};
  }

  ${device.large} {
    gap: ${props => (props.isSplitCategories ? '50px' : '30px')};
  }
`

export const MobileTitleWrapper = styled.div`
  ${device.medium} {
    display: none;
  }
`

export const SubMatrixContainer = styled.div`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  display: grid;
`

export const SubMatrixesContainer = styled.div<{ isSplitCategories: boolean }>`
  display: grid;
  row-gap: 20px;

  ${device.medium} {
    grid-template-columns: 1fr 1fr;
    gap: ${props => (props.isSplitCategories ? '30px' : '20px')};
  }

  ${device.large} {
    gap: ${props => (props.isSplitCategories ? '50px' : '30px')};
  }
`

export const PostsContainer = styled.div<{ isSplitCategories: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(2, 166px); */
  gap: 19px 15px;

  ${device.medium} {
    grid-template-rows: repeat(2, 271px);
    gap: 30px ${props => (props.isSplitCategories ? '15px' : '20px')};
  }

  ${device.large} {
    gap: 30px ${props => (props.isSplitCategories ? '20px' : '30px')};
  }
`
