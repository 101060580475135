import styled from 'styled-components'
import { LayoutMobile } from '../../../types'
import { device } from '../../../styles/GlobalVariables'
import { TextContainer as PostTextContainer } from '../../Post/Post.styles'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const PostsContainer = styled.div<{
  layoutMobile: LayoutMobile
  isDarkTheme: boolean
}>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 19px;

  ${PostTextContainer} {
    margin-top: 11px;
  }

  ${device.medium} {
    ${PostTextContainer} {
      margin-top: 18px;
    }

    grid-template-columns: repeat(4, 300px);
    grid-gap: 20px;
  }

  ${device.large} {
    grid-gap: 30px;
  }
`
