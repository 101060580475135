import { useState } from 'react'
import { useAdminCookie, usePage } from '../../contexts'
import { IImage } from '../../types'
import { CopyImage, CopyLink } from './Copy'
import {
  ControlsWrap,
  DesktopImage,
  MobileImage,
  RelativeDiv,
} from './Image.styles'

type Props = {
  image: IImage
  alt?: string | undefined
  refDesktop?: React.Ref<HTMLImageElement>
  link?: URL | string
  disableControls?: boolean
  forAdmin?: boolean
  eager?: boolean
}
const defaultImageSrc =
    'https://media.reshet.tv/image/upload/f_auto,q_auto:good/v1633341900/assets/2021/default_image_tv.webp',
  defaultImage: IImage = {
    d: defaultImageSrc,
    alt_d: 'רשת 13',
    m: defaultImageSrc,
    alt_m: 'רשת 13',
  }

export const Image = ({
  image,
  alt,
  refDesktop,
  link,
  disableControls,
  forAdmin = true,
  eager = false,
}: Props) => {
  const page = usePage()
  const admin_cookie = useAdminCookie()
  const webpOn = page?.SiteMeta.config?.features?.webp === 'on' || false

  image = image || defaultImage

  const [desktop, setDesktop] = useState({
    src: webpOn
      ? (image.d || '').replace(/png|jpg|jpeg/g, 'webp')
      : image.d || '',
    alt: image.alt_d,
    fallback: image.d || '',
  })

  const [mobile, setMobile] = useState({
    src: webpOn
      ? (image.m || '').replace(/png|jpg|jpeg/g, 'webp')
      : image.m || '',
    alt: image.alt_m,
    fallback: image.m || '',
  })

  if (!image) {
    return null
  }

  const main = (
    <>
      {desktop.src && (
        <DesktopImage
          loading={eager ? 'eager' : 'lazy'}
          src={desktop.src}
          alt={alt || desktop.alt}
          ref={refDesktop}
          onError={() =>
            webpOn &&
            setDesktop({
              ...desktop,
              src: desktop.fallback,
            })
          }
        />
      )}
      {mobile.src && (
        <MobileImage
          loading={eager ? 'eager' : 'lazy'}
          src={mobile.src}
          alt={alt || mobile.alt}
          onError={() => {
            webpOn &&
              setMobile({
                ...mobile,
                src: mobile.fallback,
              })
          }}
        />
      )}
    </>
  )

  if (admin_cookie && forAdmin) {
    return (
      <RelativeDiv theme={{ isAdmin: admin_cookie && !disableControls }}>
        {main}
        {!disableControls && admin_cookie && (
          <ControlsWrap>
            {desktop.fallback && <CopyImage src={desktop.fallback} />}
            {link && <CopyLink url={link} />}
          </ControlsWrap>
        )}
      </RelativeDiv>
    )
  }

  return main
}
