import { debounce } from 'lodash'
import React, { useEffect, useRef } from 'react'
import shave from 'shave'
import { isDesktop } from '../../client/getAdDeviceType'
import { useWindow } from '../../contexts'

type Props = {
  children: React.ReactNode
  maxLines: number
  character?: string
}

export const Shave = ({ children, maxLines, character = '…' }: Props) => {
  const child = (Array.isArray(children) ? children[0] : children) || null
  if (Array.isArray(child)) {
    console.warn('Shave: children should be a single element')
  }
  const ref = useRef() as React.MutableRefObject<HTMLElement>
  const win = useWindow()

  useEffect(() => {
    const debounceMs = 20
    const onResize = debounce(() => {
      if (!ref.current) {
        return
      }
      const offsetError = 1
      const style = window.getComputedStyle(ref.current)

      // style.lineHeight is computed by browser in px
      // no need to add fontSize to maxHeight calculation
      const lineHeight = parseFloat(style.lineHeight) || 0
      const maxHeight = lineHeight * maxLines + offsetError

      // TODO: find a better way to fix the type error here
      shave(ref.current, maxHeight, {
        character: character,
      })
    }, debounceMs)

    // Do not run polyfill on desktop
    if (isDesktop()) {
      return
    }

    onResize()
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [character, maxLines])

  if (win && isDesktop()) {
    return child
  }

  return React.cloneElement(child, { ref: ref })
}
