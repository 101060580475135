import { ReactElement } from 'react'
import { ICategoryOpener } from '../../types'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import {
  Container,
  FourGrid,
  LeftColumn,
  RightColumn,
  SubContainer,
} from './CategoryOpener.styles'
import { MainPost } from './MainPost'
import { SecondaryPost } from './SecondaryPost'

type Props = {
  categoryOpener: ICategoryOpener
  children?: ReactElement[]
  lifestyle?: boolean
}

export const CategoryOpener = ({
  categoryOpener,
  children,
  lifestyle,
}: Props) => {
  const firstPost = categoryOpener.posts[0]

  return (
    <Container>
      <ComponentTitle componentTitle={categoryOpener.grid_title} />
      <SubContainer lifestyle={lifestyle}>
        <RightColumn>
          <MainPost post={firstPost} lifestyle={lifestyle} />
        </RightColumn>
        <LeftColumn>
          {children || (
            <FourGrid>
              {categoryOpener.posts.slice(1, 5).map((post, index) => (
                <SecondaryPost key={index} post={post} />
              ))}
            </FourGrid>
          )}
        </LeftColumn>
      </SubContainer>
    </Container>
  )
}
