import { useEffect, useState } from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { IPageSEO } from '../../types'
import {
  Container,
  EmailDarkThemeFlIcon,
  EmailFlIcon,
  EmailIcon,
  FacebookDarkThemeFlIcon,
  FacebookFlIcon,
  FacebookIcon,
  TwitterDarkThemeFlIcon,
  TwitterFlIcon,
  TwitterIcon,
  WhatsappDarkThemeFlIcon,
  WhatsappFlIcon,
  WhatsappIcon,
} from './ShareButtons.styles'
import { useFL } from '../../hooks/useFL'

type Props = {
  SEO: IPageSEO
  title?: string
  size?: string
  anchor?: string
  isFaceLift?: boolean
  containerRef?: React.Ref<HTMLDivElement>
}

type IStateData = {
  url: string
  postTitle: string
}
export const ShareButtons = ({
  SEO,
  title,
  size,
  anchor,
  containerRef,
  isFaceLift,
}: Props) => {
  const [data, setData] = useState<IStateData>()
  const { isDarkTheme } = useFL()

  useEffect(() => {
    let url = document.URL
    url = url.split('#')[0]

    if (anchor) {
      url += anchor
    }
    setData({
      url: url,
      postTitle: title || SEO.og.title,
    })
  }, [anchor, title, SEO.og.title])

  if (!data) {
    return null
  }
  const { postTitle, url } = data
  return (
    <Container ref={containerRef}>
      <FacebookShareButton url={url} title={postTitle}>
        {isFaceLift ? (
          isDarkTheme ? (
            <FacebookDarkThemeFlIcon size={size} />
          ) : (
            <FacebookFlIcon size={size} />
          )
        ) : (
          <FacebookIcon size={size} />
        )}
      </FacebookShareButton>
      <TwitterShareButton url={url} title={postTitle}>
        {isFaceLift ? (
          isDarkTheme ? (
            <TwitterDarkThemeFlIcon size={size} />
          ) : (
            <TwitterFlIcon size={size} />
          )
        ) : (
          <TwitterIcon size={size} />
        )}
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={postTitle}>
        {isFaceLift ? (
          isDarkTheme ? (
            <WhatsappDarkThemeFlIcon size={size} />
          ) : (
            <WhatsappFlIcon size={size} />
          )
        ) : (
          <WhatsappIcon size={size} />
        )}
      </WhatsappShareButton>
      <EmailShareButton url={url} body={postTitle}>
        {isFaceLift ? (
          isDarkTheme ? (
            <EmailDarkThemeFlIcon size={size} />
          ) : (
            <EmailFlIcon size={size} />
          )
        ) : (
          <EmailIcon size={size} />
        )}
      </EmailShareButton>
    </Container>
  )
}
