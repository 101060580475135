import { useState } from 'react'
import XCircle from '../../../public/assets/images/svg/xCircle.svg'
import { useHeader } from '../../contexts'
import { IVodLeft } from '../../types'
import { Image } from '../Image/Image'
import { Post } from '../Post/Post'
import {
  BottomGradient,
  Close,
  Container,
  ImageContainer,
  LeftColumn,
  Open,
  PostsContainer,
  RightColumn,
  Title,
  TitleContainer,
  Toggle,
} from './VodLeft.styles'

type Props = {
  vodLeft: IVodLeft
}

export const VodLeft = ({ vodLeft }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { headerHeight } = useHeader()

  if (!vodLeft) {
    return null
  }

  const { title, posts, logoImageObj, leftImageObj } = vodLeft
  return (
    <Container headerHeight={headerHeight} isOpen={isOpen}>
      {posts && (
        <LeftColumn isOpen={isOpen}>
          {title && (
            <TitleContainer>
              <Title>{title}</Title>
            </TitleContainer>
          )}
          <PostsContainer>
            {posts?.map((post, index) => (
              <Post
                key={index}
                post={post}
                layoutMobile='first_small'
                hideSecondaryTitle
              />
            ))}
          </PostsContainer>
        </LeftColumn>
      )}

      {logoImageObj && leftImageObj && (
        <RightColumn>
          <ImageContainer isOpen={isOpen} mask={logoImageObj.d}>
            <Image image={logoImageObj} forAdmin={false} />
            <Toggle>
              <Open onClick={() => setIsOpen(true)}>
                <Image image={leftImageObj} forAdmin={false} />
              </Open>
              <Close onClick={() => setIsOpen(false)}>
                <XCircle />
              </Close>
            </Toggle>
          </ImageContainer>
        </RightColumn>
      )}

      <BottomGradient />
    </Container>
  )
}
