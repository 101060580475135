import { useRouter } from 'next/router'
import React from 'react'
import { IBreadcrumb } from '../../types'
import { urlsEqual } from '../../utils'
import { Container, StyledLink } from './Breadcrumbs.styles'

type Props = {
  breadcrumbs: IBreadcrumb[]
}

export const Breadcrumbs = ({ breadcrumbs }: Props) => {
  const { asPath } = useRouter()

  return (
    <Container>
      {breadcrumbs?.map(({ link, title }, index) => (
        <StyledLink
          key={'crumb' + index}
          href={!urlsEqual(link, asPath) ? link : undefined}
        >
          {title}
        </StyledLink>
      ))}
    </Container>
  )
}
