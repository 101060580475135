/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios'
import fs from 'fs/promises'
import { GetStaticPaths, GetStaticProps, GetStaticPropsResult } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { isIosApp } from '../client'
import { Maavaron } from '../components/Ads'
import { Overlay } from '../components/Overlay/Overlay'
import { PageLayout } from '../components/Page/PageLayout'
import { playerConfig } from '../components/Player/Helpers'
import { Providers } from '../components/Providers'
import { Scripts } from '../components/Scripts'
import { config } from '../lib'
import { api } from '../lib/api'
import { useRootStore } from '../store'
import {
  IContent,
  IFooter,
  IHeader,
  IPage,
  IPageMeta,
  IPageProps,
  IPageSEO,
  ISiteMeta,
} from '../types'
import {
  generateHeadLevelsString,
  liveSourcesDefault,
  pageHasVideo,
  sendGA4,
  setClientConfig,
} from '../utils'
import { RedAlert } from '../components/RedAlert'
import BigBrotherBubbleHandler from '../components/BigBrotherBubble/BigBrotherBubbleHandler'
import { clientConfig } from '../client/clientConfig'
import { useGooglePal } from '../contexts/hooks/useGooglePal'

const Home = ({
  page,
  notFound = false,
  // enable_push_notification,
  ...props
}: IPageProps) => {
  setClientConfig({
    page,
    notFound,
    ...props,
  })
  useGooglePal()
  const { asPath } = useRouter()
  const [isPlayerFullscreen, playerScript] = useRootStore(state => [
    state.isPlayerFullscreen,
    state.playerScript,
  ])
  // TODO: happens on invalid urls
  if (
    typeof page.Content === 'undefined' ||
    (page.Content.PageGrid as unknown) === ''
  ) {
    return null
  }

  useEffect(() => {
    window.dmp?.run() // used for IDX need to run on every page change
    window._heyDayInterface?.methods.activateSearchBox() // used for heyday need to run on every page change

    sendGA4({
      eventName: playerConfig.inWebView ? 'App_page_load' : 'Reshet_page_load',
      pageData: page,
      page_has_video: pageHasVideo(page),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, page])

  const headLevels = useMemo(
    () =>
      generateHeadLevelsString(
        page?.PageMeta?.levels,
        page.PageMeta.pageType == 'item'
      ),
    [page.PageMeta?.levels, page.PageMeta.pageType]
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const blockZooming = (event: any) => {
      if (!isIosApp()) {
        return
      }

      if (event.scale !== 1) {
        event.preventDefault()
        event.stopImmediatePropagation()
      }
    }

    window.addEventListener('touchmove', blockZooming, { passive: false })
    return () => {
      window.removeEventListener('touchmove', blockZooming)
    }
  }, [])

  // useEffect(() => {
  //   if (enable_push_notification === 'enabled') {
  //     runOneSignal()
  //   }
  // }, [enable_push_notification])

  useEffect(() => {
    if (isIosApp()) {
      setDisableScrollStyles(document.body, isPlayerFullscreen)
    }
  }, [isPlayerFullscreen])

  const setDisableScrollStyles = (element: HTMLElement, disable: boolean) => {
    if (!element) {
      return
    }

    const scrollY = window.scrollY

    if (disable) {
      element.style.overflowY = 'hidden'
      element.style.position = 'fixed'
      element.style.top = `${-scrollY}px`
    }

    if (!disable) {
      const top = Number(element.style.top.replace('px', ''))
      element.style.overflowY = ''
      element.style.position = ''
      element.style.top = ''
      window.scrollTo(0, (top || 0) * -1)
    }
  }

  return (
    <>
      <Head key={asPath}>
        <title>{page.SEO.title}</title>
        <meta name='page-levels' content={headLevels} />
      </Head>
      <Scripts playerScript={playerScript} page={page} />
      <Providers page={page}>
        <Overlay />
        <RedAlert key={'RedAlert'} />
        <Maavaron key={'Maavaron'} />
        <PageLayout
          adTargetEnv={clientConfig.adTargetEnv || 'prod'}
          notFound={notFound}
          kalturaReady={playerScript.kalturaReady}
          kantarReady={playerScript.kantarReady}
          key={`PageLayout ${asPath}`}
        />
        <BigBrotherBubbleHandler key={'BigBrotherBubbleHandler'} />
      </Providers>
    </>
  )
}

export default Home

export const errorPage: IPage = {
  PageMeta: {} as IPageMeta,
  SiteMeta: {} as ISiteMeta,
  Header: {} as IHeader,
  Content: {} as IContent,
  Footer: {} as IFooter,
  SEO: {} as IPageSEO,
  isPreview: false,
  isDarkTheme: false,
  isPlayerEmbed: false,
}

export const getStaticProps: GetStaticProps<IPageProps> = async context => {
  const path = (context.params?.all as string[]) ?? []
  console.log('getStaticProps', path.join('/'), config)
  const dataQueryPath = `/${path.join('/')}/`
  let res: GetStaticPropsResult<IPageProps>
  const props = {
    googleTagManager: config.googleTagManager || 'GTM-W6ZR3W',
    googleAnalytics: config.googleAnalytics || 'UA-985873-3',
    kalturaPlayerId: config.kalturaPlayerId || '50272542',
    live_ima: config.live_ima,
    live_mid_rolls: config.live_mid_rolls,
    baseUrl: config.baseUrl,
    disableMetrics: config.disableMetrics || '0',
    dataQuerySource: config.dataQuerySource,
    geo_plugin_key: config.geo_plugin_key,
    login_page: config.login_page,
    bigBrotherRegistration: config.bigBrotherRegistration,
  }
  try {
    let liveSources = { ...liveSourcesDefault }
    try {
      const sourcesFromFile = await fs.readFile(
        './public/cdn/live.json',
        'utf8'
      )
      if (sourcesFromFile) {
        liveSources = JSON.parse(sourcesFromFile)
      }
    } catch (e) {
      console.log('failed ' + config.baseUrl + '/cdn/live.json' + ' fetch', e)
    }
    const page = await api.fetchPage(dataQueryPath, config.dataQuerySource)
    if (!page.Content) {
      res = {
        props: {
          ...props,
          page,
          notFound: true,
          bigBrotherRegistration: 'on',
          shouldTaboolaWaitForInterstitial: 'off',
        },
        revalidate: config.revalidateInSeconds,
      }
      return res
    }

    res = {
      props: {
        page,
        ...props,
        newsFlashQuerySource: config.newsFlashQuerySource,
        adTargetEnv: config.adTargetEnv || '',
        enable_push_notification: config.enable_push_notification || 'disabled',
        liveSources,
        shouldTaboolaWaitForInterstitial: 'off',
      },
      revalidate: config.revalidateInSeconds,
    }
    return res
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.warn(
      'Error while fetching qdata for page',
      config.dataQuerySource,
      dataQueryPath,
      axios.isAxiosError(err) ? err.toJSON() : err
    )
    res = {
      props: {
        page: err?.response?.data || errorPage,
        ...props,
        shouldTaboolaWaitForInterstitial: 'off',
      },
      notFound: true,
      revalidate: config.revalidateInSeconds,
    }
    return res
  }
}

export const getStaticPaths: GetStaticPaths = async context => {
  console.log('getStaticPaths', context)
  let paths = [] as string[]
  if (config.prebuildPages) {
    const csv = await fs.readFile('./config/test-pages.csv', 'utf8')
    paths = csv
      .split(/\r?\n/g)
      .filter(t => !!t)
      .map(t => new URL(t).pathname)
  }
  return {
    paths,
    fallback: 'blocking',
  }
}

declare global {
  interface Window {
    googletag: typeof googletag
    goog?: {
      pal: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        NonceRequest: any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        NonceLoader: any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ConsentSettings: any
      }
    }
    qdata: IPage | { qdata: 'on' }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SpringStreams?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KalturaPlayer: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _taboola?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _newsroom?: any
    taboolaMobilePubName: string
    dataLayer: Array<Object>
    dmp?: {
      run: () => {}
    }
    _heyDayInterface?: {
      methods: {
        activateSearchBox: () => {}
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    instgrm?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Playbuzz?: any
  }
}
