import { usePage } from '../../contexts'
import { useFL } from '../../hooks/useFL'
import { IPost } from '../../types'
import { ImageFl } from '../ImageFl/ImageFl'
import { utils } from '../Player/Helpers/utils'
import {
  Container,
  DateContainer,
  SecondaryTitle,
  TextContainer,
  Thumbnail,
  Title,
} from './BigPost.styles'

type Props = {
  post: IPost
  gid?: number
}

const formatDate = (dateString: string) => {
  const [datePart] = dateString.split(' ')
  const [year, month, day] = datePart.split('-')
  return `${parseInt(day)}.${parseInt(month)}.${year.slice(-2)}`
}

function BigPost({ post, gid }: Props) {
  const {
    imageObj,
    title,
    publishDate,
    secondaryTitle,
    isBlank,
    writers,
    link: postLink,
  } = post
  const linkTarget = isBlank ? '_blank' : undefined
  const page = usePage()
  const pageId = page?.PageMeta.id
  const { fontColor, isDarkTheme } = useFL()
  const queryParams = utils.addPageQueryParams(postLink, pageId, gid)
  const link = postLink + queryParams

  return (
    <Container>
      <Thumbnail href={link} target={linkTarget}>
        <ImageFl
          image={imageObj}
          alt={imageObj ? title : title}
          playButton={true}
          isBigPost={true}
          playButtonName={'episodes'}
        />
      </Thumbnail>
      <TextContainer>
        <Title fontColor={fontColor}>{title}</Title>
        <DateContainer theme={{ isDarkTheme: isDarkTheme }}>
          {writers[0].name} | {formatDate(publishDate)}
        </DateContainer>
        <SecondaryTitle fontColor={fontColor}>{secondaryTitle}</SecondaryTitle>
      </TextContainer>
    </Container>
  )
}

export default BigPost
