import styled, { css } from 'styled-components'
import {
  colors,
  device,
  fonts,
  headerZIndex,
  themeClassName,
} from '../../styles/GlobalVariables'

const AStyle = css`
  position: absolute;
  z-index: 102;
  background-color: #1d2327;
  padding: 6px;
  transition: all 0.4s ease;
  opacity: 0.4;
  display: inline-flex;
  border-radius: 4px;
  top: 0;
  cursor: pointer;
`
export const EditMeDiv = styled.div`
  position: relative;

  a {
    left: 0;

    ${AStyle};

    &:hover {
      opacity: 1;
    }
  }
`
const ButtonParent = styled.div`
  color: ${colors.white};
  display: inline-flex;

  &:after {
    font-family: ${fonts.reshet};
  }
`
export const EditButton = styled(ButtonParent)`
  &:after {
    content: '\\e922';
  }
`
export const LinkForPush = styled(ButtonParent)`
  ${AStyle};
  left: 40px;

  &:after {
    content: '\\e921';
  }

  &:hover {
    opacity: 1;
  }
`
export const AdminBarDiv = styled.div`
  transition: all 0.4s ease;
  position: fixed;
  top: ${props => (props.theme.show ? 0 : '-57px;')};
  width: 100%;
  min-height: 30px;
  background-color: #1d2327;
  z-index: ${headerZIndex + 1};
  color: ${colors.white};
  font-size: 14px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-weight: 400;
  padding: 10px 20px;

  ${device.medium} {
    top: ${props => (props.theme.show ? 0 : '-37px;')};
  }

  a {
    color: ${colors.white};

    &:visited {
      color: ${colors.white};
    }
  }

  button {
    background: transparent;
    color: ${colors.white};
    border: none;
    font-size: 14px;
    font-family: ${fonts.default};
    font-weight: 400;
  }

  .toggle {
    position: absolute;
    bottom: -25px;
    transform: ${props =>
      props.theme.show ? 'rotate(180deg)' : 'rotate(0deg)'};
    background-color: ${colors.adminBG};
    padding: 5px;
    cursor: pointer;
    left: 5%;
    text-align: center;

    &:after {
      font-family: ${fonts.reshet};
      content: '\\e91f';
      width: 20px;
      height: 20px;
    }
  }

  .has-sub-menu {
    cursor: pointer;

    &:hover {
      ul {
        display: flex;
        z-index: 2;
      }
    }
  }
`

export const SubMenuUl = styled.ul`
  flex-direction: column !important;
  position: absolute;
  background-color: #1d2327;
  padding: 8px;
  display: none;

  li {
    padding: 4px 2px;
    cursor: pointer;

    & + li {
      margin-top: 2px;
    }
  }
`

export const MenuDiv = styled.div`
  > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    gap: 20px;

    li {
      list-style: none;
    }
  }
`

export const ChildrenWrap = styled.div`
  position: relative;
  outline: 1px solid black;

  ${themeClassName.vod} & {
    outline: 1px solid ${colors.white};
  }
`
export const RefreshButton = styled.button`
  ${props =>
    props.theme.disable
      ? 'color: #767676 !important; cursor: progress;'
      : 'cursor: pointer;'}
`
export const HiddenInput = styled.input``
