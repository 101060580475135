import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const ContestantsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 19px 15px;

  ${device.medium} {
    gap: 28px 13px;
  }

  ${device.large} {
    gap: 28px 19px;
  }
`

export const ContestantProfile = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(50% - 15px);

  ${device.medium} {
    height: 256px;
    flex-basis: 198px;
  }
`

export const ImageLink = styled(Link)`
  display: flex;

  img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
    min-height: 160px;
    max-height: 160px;

    ${device.medium} {
      min-height: 198px;
      max-height: 198px;
    }
  }
`

export const ProfileTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: ${colors.black};
  text-align: center;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
  height: 38px;

  ${device.medium} {
    font-size: 20px;
    font-weight: bold;
    margin: 8px 12.5px 0;
    height: 50px;
    width: 173px;
  }
`
