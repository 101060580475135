import styled from 'styled-components'
import Link from '../../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`

export const Time = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
`

export const ContentContainer = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 11px;
  overflow: hidden;
`

export const TimeContainer = styled.div<{ isDarkTheme: boolean }>`
  background-color: ${props => (props.isDarkTheme ? '#01DEFF' : '#F0F0F0')};
  width: 58px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  width: 100%;
  height: 56.25vw;
  object-fit: cover;
`

export const Title = styled.p<{ fontColor?: string }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: ${props => props.fontColor || '#000'};
`

export const SecondaryTitle = styled.p<{ fontColor?: string }>`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: ${props => props.fontColor || '#000'};
`
