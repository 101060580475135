import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import { LayoutMobile } from '../../types'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const EpisodesContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${device.medium} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const FirsPostsContainer = styled.div<{
  layoutMobile: LayoutMobile
}>`
  display: ${props => (props.layoutMobile === 'first_big' ? 'block' : 'none')};

  ${device.medium} {
    display: block;
  }
`

export const PostsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${device.medium} {
    gap: 28px;
  }
`
