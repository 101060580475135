import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import { Container as CreditsContainer } from '../DateAndWriter/Credits.styles'
import {
  SecondaryTitle as PostSecondaryTitle,
  Title as PostTitle,
} from '../Post/Post.styles'

export const Container = styled.div<{ headerHeight?: number; isOpen: boolean }>`
  display: none;
  ${device.medium} {
    display: flex;
    position: fixed;
    flex-direction: row-reverse;
    min-height: 977px;
    max-height: 977px;
    top: ${({ headerHeight }) => (headerHeight ? headerHeight : 64)}px;
    left: ${props => (props.isOpen ? '0' : '-333px')};
    transition: left 300ms ease-in-out;

    z-index: 5000;
    pointer-events: none;

    filter: drop-shadow(4px 0px 5px rgba(0, 0, 0, 0.25));
  }
`

export const BottomGradient = styled.div`
  content: '';
  position: absolute;
  left: 0;
  width: 333px;
  bottom: 0;
  height: 217px;
  background: linear-gradient(
    to top,
    rgba(247, 247, 247, 1),
    rgba(247, 247, 247, 0)
  );
  z-index: 5001;
`

export const PostsContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
  margin-top: 63px;
  display: flex;
  flex-direction: column;
  gap: 28px;

  ${PostTitle}, ${PostSecondaryTitle} {
    color: ${colors.black};
  }

  ${CreditsContainer} {
    color: ${colors.transparentGray};
  }
`

export const LeftColumn = styled.div<{ isOpen: boolean }>`
  /* display: ${props => (props.isOpen ? 'block' : 'none')}; */
  /* position: absolute;
  left: ${props => (props.isOpen ? '0' : '-333px')};
  max-height: 977px; */
  max-width: 333px;
  min-width: 333px;
  overflow-y: scroll;
  transition: all 300ms ease-in-out;

  ::-webkit-scrollbar {
    width: 0;
  }

  background-color: ${colors.alabaster};
  pointer-events: auto;
`

export const Title = styled.div`
  width: 300px;
  margin: 12px auto;
  font-size: 30px;
  height: 36px;
  font-weight: bold;
  color: ${colors.tvGuideDark};
  border-bottom: 3px solid ${colors.darkBlue};
  border-image: linear-gradient(
      170deg,
      #ff1380 0px,
      #ff1380 45px,
      #ffd300 45px,
      #ffd300 52px,
      #00f1c8 52px,
      #00f1c8 59px,
      #00104a 59px,
      #00104a 100%
    )
    1;
`

export const TitleContainer = styled.div`
  position: fixed;
  z-index: 2;
  background: ${colors.alabaster};
  max-width: 333px;
  min-width: 333px;
`

export const RightColumn = styled.div`
  margin-top: 220px;
`

export const Toggle = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const ToggleIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 300ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &,
  > svg,
  > img {
    min-width: 57px;
    min-height: 57px;
  }
`

export const Open = styled(ToggleIcon)``
export const Close = styled(ToggleIcon)``

export const ImageContainer = styled.div<{ isOpen: boolean; mask: string }>`
  width: 80px;
  height: 273.2px;
  max-height: 273.2px;
  max-width: 80px;
  position: relative;

  -webkit-mask-image: url(${({ mask }) => mask});
  mask-image: url(${({ mask }) => mask});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: ${colors.alabaster};

  > img {
    position: absolute;
    opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
    transition: all 300ms ease-in-out;
  }

  ${Open} {
    ${({ isOpen }) => ({
      zIndex: isOpen ? -1 : 1,
      opacity: isOpen ? 0 : 1,
      pointerEvents: isOpen ? 'none' : 'auto',
    })}
  }

  ${Close} {
    ${({ isOpen }) => ({
      zIndex: isOpen ? 1 : -1,
      opacity: isOpen ? 1 : 0,
      pointerEvents: isOpen ? 'auto' : 'none',
    })}
  }

  pointer-events: auto;
`
