import { TimeLineContextProvider } from '../../contexts'
import {
  IPage,
  isArticlePage,
  isArticlePageFl,
  isArticleTimeLinePage,
  isArticleTimeLinePageFL,
  isAuthorCategoryTagPage,
  isAuthorCategoryTagPageFl,
  isGridVodPage,
  isHomepage,
  isLiveVodPage,
  isNewsFlashesPage,
  isRecipePage,
  isTVGuidePage,
  isVodPage,
} from '../../types'
import { Article } from './Article'
import { Recipe } from './ArticleRecipe'
import { ArticleTimeLine } from './ArticleTimeLine'
import { AuthorCategoryTag } from './AuthorCategoryTag'
import { AuthorCategoryTagFl } from './AuthorCategoryTagFl'
import { GridVodPage } from './GridVod'
import { LiveVod } from './LiveVod'
import { NewsFlashesPage } from './NewsFlashesPage'
import { TVGuide } from './TVGuide'
import { VodPage } from './VodPage'
import { ArticleFl } from './ArticleFl'
import { ArticleTimeLineFL } from './ArticleTimeLineFL'

export const renderPage = (page: IPage) => {
  if (!page) {
    console.log(
      '%cPageLayout :161',
      'background-color:yellow;color:black;padding:2px 5px;',
      'PageLayout: unknown page type',
      page
    )
    return
  }

  if (isAuthorCategoryTagPage(page)) {
    return <AuthorCategoryTag page={page} isHomepage={isHomepage(page)} />
  }
  if (isAuthorCategoryTagPageFl(page)) {
    return <AuthorCategoryTagFl page={page} isHomepage={isHomepage(page)} />
  }
  if (isArticlePage(page)) {
    if (isRecipePage(page)) {
      return <Recipe page={page} />
    }
    if (isArticleTimeLinePage(page)) {
      return (
        <TimeLineContextProvider>
          <ArticleTimeLine page={page} />
        </TimeLineContextProvider>
      )
    }
    return <Article page={page} />
  }
  if (isArticlePageFl(page)) {
    if (isRecipePage(page)) {
      return <Recipe page={page} />
    }
    if (isArticleTimeLinePage(page)) {
      return (
        <TimeLineContextProvider>
          <ArticleTimeLine page={page} />
        </TimeLineContextProvider>
      )
    }
    if (isArticleTimeLinePageFL(page)) {
      return (
        <TimeLineContextProvider>
          <ArticleTimeLineFL page={page} />
        </TimeLineContextProvider>
      )
    }
    return <ArticleFl page={page} />
  }
  if (isNewsFlashesPage(page)) {
    return <NewsFlashesPage page={page} />
  }
  if (isTVGuidePage(page)) {
    return <TVGuide page={page} />
  }
  if (isLiveVodPage(page)) {
    return <LiveVod page={page} key={'LiveVod'} />
  }
  if (isVodPage(page)) {
    return <VodPage page={page} />
  }
  if (isGridVodPage(page)) {
    return <GridVodPage page={page} />
  }
  return null
}
