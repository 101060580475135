import React from 'react'
import { Time } from './TimeLineFL.styles'
import { pad } from '../../utils'

type TimeBulletProps = {
  order: string
  date: string
  color: string
}

const today = new Date()

export const TimeBulletFL = ({ order, date, color }: TimeBulletProps) => {
  const arr = date.split(/[- :]/)
  const parsedDate = new Date(
    Number(arr[0]),
    Number(arr[1]) - 1,
    Number(arr[2]),
    Number(arr[3]),
    Number(arr[4]),
    Number(arr[5])
  )
  const theme = {
    isToday:
      parsedDate.getDate() == today.getDate() &&
      parsedDate.getMonth() == today.getMonth() &&
      parsedDate.getFullYear() == today.getFullYear(),
    color: color,
  }

  return (
    <Time theme={theme} className={order} title={date}>
      <span>
        {[pad(parsedDate.getHours(), 2), pad(parsedDate.getMinutes(), 2)].join(
          ':'
        )}
      </span>
    </Time>
  )
}
