import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 16px;

  ${device.medium} {
    margin-bottom: 40px;
  }
`

export const ImageLink = styled(Link)`
  position: relative;
  flex: 1;
  flex-direction: column;
  display: flex;
`
