import { ICredits, ISponsorship } from '../../../types'
import { he } from '../../../locales/he'
import { Sponsorship } from '../../Sponsorship/Sponsorship'
import { ArticleCredits } from './ArticleCredits'
import {
  Container,
  LeftContainer,
  RightContainer,
  Separator,
  VerticalDivider,
} from './ArticleMeta.styles'
import Comments from '/public/assets/images/svg/comments.svg'

export interface Props {
  // numberOfComments: number
  credits: ICredits
  sponsorship: ISponsorship
  commentNumber: number | null
  showComments: boolean
}

export const ArticleMeta = ({
  credits,
  sponsorship,
  commentNumber = 0,
  showComments = true,
}: Props) => {
  const renderSponsorship =
    sponsorship.icon !== null && sponsorship.text !== null

  return (
    <Container>
      <RightContainer>
        <ArticleCredits credits={credits} />
        {renderSponsorship && <Separator>|</Separator>}
        <Sponsorship sponsorship={sponsorship} />
      </RightContainer>
      {showComments && (
        <>
          <VerticalDivider />
          <LeftContainer>
            <Comments />
            <span>{`${commentNumber} ${he.comments}`}</span>
          </LeftContainer>
        </>
      )}
    </Container>
  )
}
