import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { IBroadcastWeek } from '../../types'
import { he } from '../../locales/he'
import {
  Comma,
  Date,
  DatesContainer,
  DatesScrollArea,
  ScrollArrow,
  ScrollArrowWrapper,
  ShowDate,
  Weekday,
} from './Dates.styles'
import TvGuideArrowLeftIcon from '/public/assets/images/svg/tv-guide-arrow-left.svg'
import TvGuideArrowRightIcon from '/public/assets/images/svg/tv-guide-arrow-right.svg'

type Props = {
  broadcastWeek: IBroadcastWeek[]
  selectedDay: number
  setSelectedDay: Dispatch<SetStateAction<number>>
}

export const Dates = ({
  broadcastWeek,
  selectedDay,
  setSelectedDay,
}: Props) => {
  const datesContainerRef = useRef<HTMLDivElement>(null)
  const [currentDatesArrowPos, setCurrentDatesArrowPos] = useState<
    'left' | 'right'
  >('left')
  return (
    <DatesContainer ref={datesContainerRef}>
      <DatesScrollArea>
        {broadcastWeek.map(({ weekday, shortDate }, index) => {
          const day = getDay(shortDate) || weekday
          return (
            <Date
              key={`day-${index}`}
              selected={index === selectedDay}
              onClick={() => setSelectedDay(index)}
            >
              <ShowDate>{shortDate}</ShowDate>
              <Comma>,&nbsp;</Comma>
              <Weekday>{day}</Weekday>
            </Date>
          )
        })}
      </DatesScrollArea>
      <ScrollArrowWrapper position={currentDatesArrowPos}>
        <ScrollArrow
          onClick={() => {
            setCurrentDatesArrowPos(
              currentDatesArrowPos === 'left' ? 'right' : 'left'
            )
            datesContainerRef.current?.scrollTo({
              left:
                currentDatesArrowPos === 'left'
                  ? -datesContainerRef.current?.scrollWidth
                  : 0,
              behavior: 'smooth',
            })
          }}
        >
          {currentDatesArrowPos === 'left' ? (
            <TvGuideArrowLeftIcon />
          ) : (
            <TvGuideArrowRightIcon />
          )}
        </ScrollArrow>
      </ScrollArrowWrapper>
    </DatesContainer>
  )
}

const getDay = (date: string) => {
  const today = DateTime.local({ zone: 'Israel' })

  if (date === today.toFormat('dd.MM')) {
    return he.today
  }

  if (date === today.plus({ days: 1 }).toFormat('dd.MM')) {
    return he.tomorrow
  }

  return undefined
}
