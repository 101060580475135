import { DateTime as LuxonDateTime } from 'luxon'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { clientApi } from '../../client/clientApi'
import { useWindow } from '../../contexts'
import { INewsflash, INewsFlashPage } from '../../types'
import { he } from '../../locales/he'
import { DateTime } from '../../utils'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { PageTitle } from '../PageTitle/PageTitle'
import { Shave } from '../Shave/Shave'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import {
  Container,
  DateContainer,
  MivzakimLeftRail,
  NewsflashContainer,
  NewsFlashItem,
  NewsFlashSpecialWidget,
  PageContent,
  TaboolaContainer,
  TextContainer,
  Timeline,
  TimelineWrapper,
} from './NewsFlahesPage.styles'

type Props = {
  page: INewsFlashPage
}

export const NewsFlashesPage = ({ page }: Props) => {
  const [newsflashes, setNewsflashes] = useState<INewsflash[] | undefined>(
    page.Content.PageGrid[0].newsFlashArr
  )

  const isFromYesterday = (time: string) => {
    return (
      LuxonDateTime.fromFormat(time, 'yyyy-MM-dd HH:mm:ss').day -
        LuxonDateTime.local({ zone: 'Israel' }).day ===
      -1
    )
  }
  const last24h = LuxonDateTime.local({ zone: 'Israel' }).minus({ hours: 24 })

  useEffect(() => {
    if (!newsflashes || newsflashes.length < 1) {
      let unmounted = false
      ;(async () => {
        const flashes = await clientApi.getNewsflashes()
        if (unmounted) {
          return
        }

        const filteredFlashes = flashes.filter(flash => {
          const flashTime = LuxonDateTime.fromFormat(
            flash.time,
            'yyyy-MM-dd HH:mm:ss'
          )
          return last24h.diff(flashTime, 'minutes').minutes < 0 && flash
        })

        setNewsflashes(filteredFlashes)

        return () => {
          unmounted = true
        }
      })()
    }
  }, [last24h, newsflashes])
  const win = useWindow()

  const { PageMeta, SEO, Header } = page
  const disableTaboola = PageMeta.ads.block.taboola
  return (
    <Container>
      <PageTitle SEO={SEO} pageMeta={PageMeta} showCurrentDate />
      <Breadcrumbs breadcrumbs={Header.breadCrumbs} />
      <PageContent>
        <TimelineWrapper>
          <Timeline />
          <NewsflashContainer>
            {newsflashes?.map(({ id, time, link, text }, index) => (
              <>
                <NewsFlashItem key={id}>
                  <DateContainer>
                    {isFromYesterday(time) && <span>{he.yesterday}</span>}
                    <time>{DateTime.parse(time)?.toTime()}</time>
                  </DateContainer>
                  <TextContainer hasLink={!!link.length}>
                    <Shave maxLines={3} character='…   '>
                      {link ? (
                        <Link href={link} prefetch={false}>
                          {text}
                        </Link>
                      ) : (
                        <p>{text}</p>
                      )}
                    </Shave>
                  </TextContainer>
                </NewsFlashItem>
                {win && !disableTaboola && (index + 1) % 7 === 0 && (
                  <NewsFlashSpecialWidget key={index + 1}>
                    <Taboola
                      pageType={PocTaboolaPageType.newsFlash}
                      suffixId={PocTaboolaSuffixId.specialWidget}
                      index={(index + 1) / 7}
                    />
                  </NewsFlashSpecialWidget>
                )}
              </>
            ))}
          </NewsflashContainer>
        </TimelineWrapper>
        <MivzakimLeftRail>
          {!disableTaboola && win && win.innerWidth > 768 && (
            <TaboolaContainer>
              <Taboola
                pageType={PocTaboolaPageType.newsFlash}
                suffixId={PocTaboolaSuffixId.mivzakimLeftRail}
              />
            </TaboolaContainer>
          )}
        </MivzakimLeftRail>
      </PageContent>
      {!disableTaboola && (
        <Taboola
          pageType={PocTaboolaPageType.newsFlash}
          suffixId={PocTaboolaSuffixId.pageFooter}
        />
      )}
    </Container>
  )
}
