import { default as NextLink } from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { usePage } from '../../contexts'
import { useFL } from '../../hooks/useFL'
import { IComponentTitle, isNoTitle, isTextTitle } from '../../types'
import { addAnalyticsToUrl } from '../../utils'
import Link from '../Link/Link'
import { Container, LeftText, Title } from './ComponentTitleFL.styles'
import LeftArrowIcon from '/public/assets/images/svg/arrow-left-fl.svg'

type Props = {
  componentTitle: IComponentTitle
  className?: string
  componentId: number
  columnSpan?: number
}

/**
 * ComponentTitleFL is a functional component that receives a componentTitle object and renders a title with a link and a left text.
 *
 * @see NOTE: Add Analytics to components!!!!
 *
 * @param {IComponentTitle} componentTitle - The componentTitle object that contains the title, link, leftText and color.
 * @param {string} className - The class name for the container.
 * @param {number} columnSpan - The column span for the container.
 * @param {number} componentId - The component id for the analytics.
 */
const ComponentTitleFL = ({
  componentTitle,
  componentId,
  columnSpan,
  className,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const leftTextRef = useRef<HTMLAnchorElement>(null)
  const [containerWidth, setContainerWidth] = useState<number | null>(null)
  const [leftTextWidth, setLeftTextWidth] = useState<number | null>(null)
  const { link, leftText, color } = componentTitle
  const page = usePage()
  const { isDarkTheme, fontColor } = useFL()
  const url =
    link &&
    addAnalyticsToUrl(
      link || '',
      page?.PageMeta.id.toString() || '',
      componentId.toString() || ''
    )

  useEffect(() => {
    if (!containerRef.current || !leftTextRef.current) {
      return
    }
    setContainerWidth(containerRef.current.offsetWidth)
    setLeftTextWidth(leftTextRef.current.offsetWidth)
  }, [])

  if (
    isNoTitle(componentTitle) ||
    (isTextTitle(componentTitle) && !componentTitle.text)
  ) {
    return null
  }

  return (
    <Container
      ref={containerRef}
      className={className}
      containerWidth={containerWidth}
      leftTextWidth={leftTextWidth}
      columnSpan={columnSpan}
    >
      {isTextTitle(componentTitle) &&
        (url ? (
          <Link href={url}>
            <Title fontColor={fontColor}>{componentTitle.text}</Title>
          </Link>
        ) : (
          <Title fontColor={fontColor}>{componentTitle.text}</Title>
        ))}
      {!!url && (
        <NextLink href={url} passHref>
          <LeftText
            theme={{ isDarkTheme: isDarkTheme }}
            lineColor={color}
            ref={leftTextRef}
            fontColor={fontColor}
          >
            <LeftArrowIcon />
            <span>{leftText}</span>
          </LeftText>
        </NextLink>
      )}
    </Container>
  )
}

export default ComponentTitleFL
