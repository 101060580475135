import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useCallback, useRef, useState } from 'react'
import {
  FacebookEmbed,
  InstagramEmbed,
  PlaceholderEmbed,
  TwitterEmbed,
  YouTubeEmbed,
} from 'react-social-media-embed'
import { isDesktop } from '../../client'
import { DesktopAdId, MobileAdId } from '../../contexts'
import { loadScriptOnce } from '../../utils'
import Ad from '../Ads/Ad'
import { isShorteCode } from '../Parser/Parser'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import { ZoomIn } from '../ZoomIn'
import {
  SpecialBlockquote,
  StyledBlockquote,
  StyledEmbed,
  StyledFigure,
  StyledH3,
  StyledIframe,
  StyledIframeWrapper,
  StyledMoreArticles,
  StyledOL,
  StyledP,
  StyledPWithADWrap,
  StyledUL,
} from './ContentParser.style'
import { getNodesFromHtmlText } from './utils'

type IContentProps = {
  iframeEl: HTMLIFrameElement
}
const ContentIframe = ({ iframeEl }: IContentProps) => {
  const [height, setHeight] = useState(300)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const _onLoad = useCallback(() => {
    const url = new URL(iframeEl.src)

    if (iframeRef !== null && url.host === window.location.host) {
      try {
        if (iframeRef && iframeRef.current) {
          setHeight(
            iframeRef?.current?.contentWindow?.document?.body?.offsetHeight ||
              300
          )
        }
      } catch (e) {
        /* empty */
      }
    }
  }, [iframeEl.src])

  const props: {
    height?: number
    width?: number
    css?: string
  } = {}
  let css
  if (iframeEl.height) {
    props.height = parseInt(iframeEl.height)
  }
  if (iframeEl.width) {
    props.width = parseInt(iframeEl.width)
  }
  if (iframeEl.style) {
    css = iframeEl.style.cssText
  }
  return (
    <StyledIframeWrapper theme={{ height: props.height || height, css }}>
      <StyledIframe
        title={iframeEl.title || ''}
        ref={iframeRef}
        src={iframeEl.src}
        frameBorder={iframeEl.frameBorder}
        onLoad={_onLoad}
        {...props}
      />
    </StyledIframeWrapper>
  )
}

const getWidthAndHeightFromEmbedTag = (embedTag: string): Dimension => {
  const widthMatch = embedTag.match(/width="(\d+)"/)
  const heightMatch = embedTag.match(/height="(\d+)"/)

  const dimension: Dimension = {
    width: 640,
    height: 360,
  }

  if (widthMatch) {
    dimension.width = parseInt(widthMatch[1])
  }

  if (heightMatch) {
    dimension.height = parseInt(heightMatch[1])
  }

  return dimension
}

type Dimension = {
  width?: number
  height?: number
}

const SocialEmbed = ({
  url,
  dimension,
}: {
  url: string
  dimension: Dimension
}) => {
  let embedComponent
  const { width, height } = dimension

  if (url.includes('instagram.com')) {
    embedComponent = <InstagramEmbed url={url} width={width} height={height} />
  } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
    embedComponent = <YouTubeEmbed url={url} width={width} height={height} />
  } else if (url.includes('twitter.com')) {
    embedComponent = <TwitterEmbed url={url} width={width} height={height} />
  } else if (url.includes('facebook.com')) {
    embedComponent = <FacebookEmbed url={url} width={width} height={height} />
  } else {
    embedComponent = <PlaceholderEmbed url={url} />
  }

  return <StyledEmbed>{embedComponent}</StyledEmbed>
}

const PWithADWrap = ({
  for_key,
  html,
  cls,
  adId,
  fallBackAdId,
}: {
  for_key: string
  html: string
  cls: string
  adId: string
  fallBackAdId?: string
}) => {
  const [state_id, setAdId] = useState(adId)
  const router = useRouter()
  // const [adWidth, setAdWidth] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRender = (e: any) => {
    // !e.isEmpty && setAdWidth(e.size[0])
    if (e.isEmpty && fallBackAdId) {
      setAdId(fallBackAdId)
    }
  }

  return (
    <StyledPWithADWrap key={`${state_id}-${for_key}-${router.asPath}`}>
      <StyledP dangerouslySetInnerHTML={{ __html: html }} className={cls} />
      <Ad
        desktopAdId={state_id as DesktopAdId}
        mobileAdId={state_id as MobileAdId}
        onRender={handleRender}
      />
    </StyledPWithADWrap>
  )
}

type Props = {
  html: undefined | string
  adMidArticle?: boolean
  keyPrefix?: string
  addArticleBox?: boolean
  isArticleVod?: boolean
  isArticle?: boolean
}

export const parseHTML = ({
  html,
  adMidArticle = true,
  keyPrefix = '',
  addArticleBox = true,
  isArticleVod,
  isArticle,
}: Props) => {
  if (!html) {
    return []
  }
  const counts = { p: 0, char: 0, ad: 0, word: 0 }
  let figureCounter = 2

  let foundFirstImage = false,
    hasMoreArticles = false,
    moreArticlesHead: {} | null | undefined = null

  let childNodes = getNodesFromHtmlText(html).reverse()
  if (childNodes.length > 0) {
    while (
      childNodes[0] &&
      childNodes[0].nodeName === 'P' &&
      (childNodes[0].innerHTML === '' ||
        childNodes[0].innerHTML === ' ' ||
        childNodes[0].innerHTML === '&nbsp;')
    ) {
      childNodes.splice(0, 1)
    }
  }

  childNodes = childNodes.reverse()

  const embedRegex =
    /\[embed(?:\s+width="\d+"\s+height="\d+")?](.*?)\[\/embed\]/g

  const midArticleTaboola = (
    <Taboola
      pageType={
        isArticleVod
          ? PocTaboolaPageType.article_vod
          : PocTaboolaPageType.article
      }
      suffixId={PocTaboolaSuffixId.midArticle}
    />
  )

  const nodesCount = childNodes.length - 1
  return childNodes
    .map((t, index) => {
      const shortcodeElement = isShorteCode(
        t.innerHTML,
        keyPrefix + '_cp_el_' + index
      )
      if (shortcodeElement) {
        return shortcodeElement
      }

      const key = keyPrefix + '_cp_el_' + index

      const script = t.querySelector('script')
      const iframe = t.querySelector('iframe')
      switch (t.nodeName.toLowerCase()) {
        case 'p':
          hasMoreArticles = false
          if (t.innerHTML.match(embedRegex)) {
            const urlMatch = t.innerHTML.match(
              /\[embed(?:\s+width="\d+"\s+height="\d+")?](.*?)\[\/embed\]/
            )
            if (urlMatch) {
              const url = urlMatch[1]
              const dimension = getWidthAndHeightFromEmbedTag(t.innerHTML)
              return <SocialEmbed url={url} dimension={dimension} />
            }
          }
          if (script) {
            try {
              if (script.src) {
                const url = new URL(script.src)
                url && loadScriptOnce({ src: url.href, id: url.href })
                return null
                // return (
                //   <Script
                //     dangerouslySetInnerHTML={{
                //       __html: `
                //           if (window.instgrm) {
                //             try {
                //               window.instgrm.Embeds.process();
                //             } catch (err) {
                //               console.error('Error processing Instagram embed:', err);
                //             }
                //           } else {
                //             setTimeout(() => {
                //               if (window.instgrm) {
                //                 try {
                //                   window.instgrm.Embeds.process();
                //                 } catch (err) {
                //                   console.error('Error processing Instagram embed:', err);
                //                 }
                //               }
                //             }, 1000);
                //           }
                //         `,
                //     }}
                //     key={Date.now()}
                //   />
                // )
              } else {
                return (
                  <Script
                    dangerouslySetInnerHTML={{
                      __html: script.innerText,
                    }}
                    key={Date.now()}
                  />
                )
              }
            } catch (e) {
              console.log(
                '%cContentParser ',
                'background-color:darkred;color:white;padding:2px 5px;',
                e
              )
            }
          }
          if (iframe) {
            return <ContentIframe iframeEl={iframe} key={key} />
          }
          if (
            [' ', '&nbsp;', ''].includes(t.innerText.toLowerCase()) ||
            t.innerHTML === '&nbsp;'
          ) {
            return null
          }
          t.innerText.length > 0 && counts.p++
          counts.char += t.innerText.length
          counts.word += t.innerText.split(' ').length

          if (addArticleBox && t.innerText.length > 0 && nodesCount > index) {
            switch (counts.ad) {
              case 0:
                if (counts.p >= 1 && counts.word >= 60) {
                  counts.ad++
                  counts.p = 0
                  counts.char = 0
                  counts.word = 0
                  return (
                    <PWithADWrap
                      key={key}
                      for_key={key}
                      html={t.innerHTML}
                      cls={t.className}
                      adId={`In_article_box_${counts.ad}`}
                    />
                  )
                }
                break
              case 1:
              case 2:
              case 3:
                if (counts.p >= 2 && counts.word >= 120) {
                  counts.ad++
                  counts.p = 0
                  counts.char = 0
                  counts.word = 0
                  return (
                    <PWithADWrap
                      key={key}
                      for_key={key}
                      html={t.innerHTML}
                      cls={t.className}
                      adId={'In_article_box_' + counts.ad}
                    />
                  )
                }
                break
              case 4:
                if (!isDesktop() && counts.p >= 2 && counts.word >= 120) {
                  counts.ad++
                  counts.p = 0
                  counts.char = 0
                  counts.word = 0
                  return (
                    <PWithADWrap
                      key={key}
                      for_key={key}
                      html={t.innerHTML}
                      cls={t.className}
                      adId={'In_article_box_5'}
                    />
                  )
                }
            }
          }
          return (
            <StyledP
              key={key}
              dangerouslySetInnerHTML={{ __html: t.innerHTML }}
              className={t.className}
            />
          )
        case 'figure':
          hasMoreArticles = false
          if (!foundFirstImage && adMidArticle && index < nodesCount) {
            foundFirstImage = true
            const key = t.querySelector('img')?.src || Date.now()
            return (
              <div key={key} style={{ position: 'relative' }}>
                <StyledFigure
                  dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                  className={`${t.className} article-image`}
                  // TODO: Move style to styles file somehow
                  style={{ marginBottom: '10px' }}
                />
                {isArticle && <ZoomIn index={1} key={key} />}
                {midArticleTaboola}
              </div>
            )
          }
          return (
            <div key={key} style={{ position: 'relative' }}>
              <StyledFigure
                dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                className={`${t.className} article-image`}
              />
              {isArticle && <ZoomIn index={figureCounter++} key={key} />}
            </div>
          )
        case 'blockquote':
          hasMoreArticles = false
          if (
            t.className.includes('instagram-media') ||
            t.className.includes('tiktok-embed') ||
            t.className.includes('reddit-embed-bq') ||
            t.className.includes('facebook') ||
            t.className.includes('twitter-tweet')
          ) {
            return (
              <SpecialBlockquote
                key={key}
                dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                className={t.className}
              />
            )
          }
          return (
            <StyledBlockquote
              key={key}
              dangerouslySetInnerHTML={{ __html: t.innerHTML }}
              className={t.className}
            />
          )
        case 'h3':
          moreArticlesHead = (
            <StyledH3
              dangerouslySetInnerHTML={{ __html: t.innerHTML }}
              key={key}
            />
          )
          hasMoreArticles = false
          if (t.innerText.includes('כתבות נוספות')) {
            hasMoreArticles = true
            return null
          }
          return moreArticlesHead
        case 'ul':
          if (hasMoreArticles) {
            hasMoreArticles = false
            if (!foundFirstImage && adMidArticle && index < nodesCount) {
              foundFirstImage = true
              return (
                <React.Fragment key={key}>
                  <StyledMoreArticles>
                    {moreArticlesHead}
                    <StyledUL
                      dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                    />
                  </StyledMoreArticles>
                  {midArticleTaboola}
                </React.Fragment>
              )
            }
            return (
              <StyledMoreArticles key={key}>
                {moreArticlesHead}
                <StyledUL dangerouslySetInnerHTML={{ __html: t.innerHTML }} />
              </StyledMoreArticles>
            )
          }
          moreArticlesHead = null
          hasMoreArticles = false
          return (
            <StyledUL
              dangerouslySetInnerHTML={{ __html: t.innerHTML }}
              key={key}
            />
          )
        case 'ol':
          return (
            <StyledOL
              key={key}
              dangerouslySetInnerHTML={{ __html: t.innerHTML }}
            />
          )
        default:
          return (
            <div key={key} dangerouslySetInnerHTML={{ __html: t.outerHTML }} />
          )
      }
    })
    .filter(el => el !== null) as JSX.Element[]
}
