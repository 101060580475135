import { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { TimeLineContext, useWindow } from '../../contexts'
import { IArticlePage, ITimeLine, ITimeLineElement } from '../../types'
import { pad } from '../../utils'
import { ArticleMeta, ArticleTitle } from '../Article'
import { ArticleVideo } from '../ArticleVideo/ArticleVideo'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { parseHTML } from '../ContentParser/ContentParser'
import { ImageWithCredits } from '../ImageWithCredits/ImageWithCredits'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import {
  ArticleOnImage,
  Container,
  Content,
  FullWidth,
  HorizontalContainer,
  InShort,
  LR,
  TextContainer,
} from './ArticleTimeLine.styles'
import { cleanHtmlString } from '../ContentParser/utils'
import { utils } from '../Player/Helpers'

type Props = {
  page: IArticlePage
}

const InShortItem = (el: ITimeLineElement, color: string) => {
  const arr = el.createdOn.split(/[- :]/)
  const parsedDate = new Date(
    Number(arr[0]),
    Number(arr[1]) - 1,
    Number(arr[2]),
    Number(arr[3]),
    Number(arr[4]),
    Number(arr[5])
  )

  return (
    <div className={'time-line-wrap'} key={'in_short_' + el.id}>
      <div style={{ color: color }} className={'date'}>
        {[
          parsedDate.getDate(),
          parsedDate.getMonth() + 1,
          parsedDate.getFullYear(),
        ].join('.')}{' '}
        |{' '}
        {[pad(parsedDate.getHours(), 2), pad(parsedDate.getMinutes(), 2)].join(
          ':'
        )}
      </div>
      <div className={'title'}>{el.post_title}</div>
    </div>
  )
}

export const ArticleTimeLine = ({ page }: Props) => {
  const [parsedContent, setParsedContent] = useState<ReactElement[]>()
  const timeLineCtx = useContext(TimeLineContext)
  const container = useRef<HTMLDivElement>(null)
  const article = page.Content.Item
  const postContent = cleanHtmlString(article.postContent || '')
  const [NextItemPagerComp, setNextItemPagerComp] =
    useState<JSX.Element | null>(null)

  useEffect(() => {
    const loadComponent = async () => {
      const { NextItemPager } = await import('../NextItemPager/NextItemPager')
      setNextItemPagerComp(<NextItemPager objectToSlide={container} />)
    }
    if (utils.isMobile() && localStorage.getItem('itemLinks') != '') {
      loadComponent().then(() => null)
    }
  }, [])

  const win = useWindow()
  useEffect(() => {
    setParsedContent(
      parseHTML({
        html: postContent,
        adMidArticle: false,
      })
    )
  }, [postContent])

  if (!article) {
    return null
  }

  const { PageMeta, Header } = page
  const data = page.Content.Item.timeLines
  const timelineItem = data[
    Object.keys(data)[0] as string as keyof typeof data
  ] as unknown as ITimeLine
  const { video, title, secondaryTitle, sponsorship, image } = article
  const articleBreadcrumbs = Header.breadCrumbs.slice(
    0,
    Header.breadCrumbs.length - 1
  )
  const disableTaboola = PageMeta.ads.block.taboola

  const inShortItems =
    timeLineCtx.filterElements
      .slice(0, 6)
      .map(el => InShortItem(el, timelineItem.header.favcolor)) || []

  return (
    <>
      {NextItemPagerComp}
      <div ref={container}>
        <Container>
          <Breadcrumbs breadcrumbs={articleBreadcrumbs} />
        </Container>
        <Container>
          <HorizontalContainer>
            <Content>
              <FullWidth>
                {video && video.kalturaId ? (
                  <>
                    <ArticleVideo
                      pageMeta={PageMeta}
                      hideDetails={true}
                      video={{ ...video, poster: article.image.src }}
                    />
                    <ArticleTitle title={title} subtitle={secondaryTitle} />
                    <ArticleMeta
                      credits={article}
                      sponsorship={sponsorship}
                      showComments={false}
                      commentNumber={0}
                    />
                  </>
                ) : (
                  <>
                    <ImageWithCredits
                      image={image}
                      showCredits={false}
                      isInTimeline
                    />
                    <ArticleOnImage>
                      <ArticleTitle title={title} subtitle={secondaryTitle} />
                      <ArticleMeta
                        credits={article}
                        sponsorship={sponsorship}
                        showComments={false}
                        commentNumber={0}
                      />
                    </ArticleOnImage>
                  </>
                )}
              </FullWidth>
              {/*<InShort*/}
              {/*  theme={{*/}
              {/*    show: 'mobile',*/}
              {/*    backgroundColor: timelineItem.header.favcolor,*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div className={'main-title'}>בקצרה:</div>*/}
              {/*  <div className={'short-timeline-items'}>{inShortItems}</div>*/}
              {/*</InShort>*/}
            </Content>
          </HorizontalContainer>
          <InShort
            theme={{
              show: 'desktop',
              backgroundColor: timelineItem.header.favcolor,
            }}
          >
            <div className={'main-title'}>בקצרה:</div>
            <div className={'short-timeline-items'}>{inShortItems}</div>
          </InShort>
        </Container>
        <Container>
          <HorizontalContainer>
            <Content>
              {article &&
                (win ? (
                  <TextContainer>{parsedContent}</TextContainer>
                ) : (
                  <TextContainer
                    dangerouslySetInnerHTML={{ __html: postContent }}
                  />
                ))}
              {!disableTaboola && (
                <Taboola
                  pageType={PocTaboolaPageType.timeLine}
                  suffixId={PocTaboolaSuffixId.pageFooter}
                />
              )}
            </Content>
          </HorizontalContainer>
          <LR>
            <Taboola
              pageType={PocTaboolaPageType.article_item_left_rail_thumbnails}
              suffixId={PocTaboolaSuffixId.null}
            />
          </LR>
        </Container>
      </div>
    </>
  )
}
