import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'
import { Container as PostContainer, SecondaryTitle } from '../Post/Post.styles'

export const BottomRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 19px 20px;

  ${device.medium} {
    gap: 20px 10px;
    grid-template-columns: 1fr 1fr;
  }

  ${device.large} {
    gap: 30px 30px;
  }

  ${PostContainer} {
    ${SecondaryTitle} {
      display: none;
    }
    ${device.medium} {
      ${SecondaryTitle} {
        display: inline;
      }
    }
  }
`

export const ExtendedImages = styled.div`
  display: block;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImageLink = styled(Link)`
  display: flex;
  flex: 1;

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
    max-height: 107px;
  }
`

export const SliderContainer = styled.div`
  display: block;
  margin: 0 -20px;

  ${device.medium} {
    display: none;
  }

  .swiper-wrapper {
    > :not(.swiper-slide-active) {
      transform: scaleY(0.9);
    }
  }
`

export const SlideContainer = styled.div`
  max-height: 170px;
`

export const GridContainer = styled.div`
  display: none;

  ${device.medium} {
    display: grid;
    gap: 16px 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 107px);
  }

  ${device.large} {
    gap: 16px 30px;
  }
`
