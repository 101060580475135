import styled, { css } from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'

const TitleCss = css`
  position: relative;
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;

  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    line-height: 1.21;
    font-size: 26px;
    font-weight: 700;
  }
`
export const Title = styled.h1`
  ${TitleCss};
`
export const TitleLink = styled.div`
  ${TitleCss};
  cursor: pointer;
  opacity: 50%;
  font-weight: 600;

  ${device.medium} {
    font-weight: 600;
  }
`
export const ActiveLink = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  right: 0;

  ${props =>
    props.theme.color == 'bb'
      ? css`
          background: linear-gradient(90deg, #006fea 0%, #00bdfb 100%);

          ${device.medium} {
            box-shadow: 0 0 5px 1px #00bdfb80;
          }
        `
      : css`
          background: #ff1414;

          ${device.medium} {
            box-shadow: 0 0 5px 1px #ff148080;
          }
        `}
  ${device.medium} {
    bottom: -10px;
  }
`

export const CurrentDate = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.darkBlue};

  ${device.medium} {
    font-size: 16px;
    font-weight: 400;
  }
`

export const RightColumn = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;

  ${device.medium} {
    gap: 8px;
  }
`
export const TabNavNavigation = styled.div`
  display: inline-flex;

  flex-direction: ${props => {
    if (props.theme.direction && props.theme.direction === 'reverse') {
      return 'row-reverse'
    }
    return 'row'
  }};
  width: 100%;
  gap: 24px;

  ${device.medium} {
    gap: 0;

    > div {
      padding: 0 20px;
    }
  }
`

export const LeftColumnMobile = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;

  ${device.medium} {
    display: none;
  }
`

export const LeftColumnDesktop = styled.div`
  display: none;

  ${device.medium} {
    display: flex;
    align-items: center;
    font-size: 25px;
    gap: 12px;

    svg {
      display: block;
      path {
        ${themeClassName.vod} &, ${themeClassName.vodMain} & {
          fill: ${colors.white};
        }
      }
    }
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  margin-bottom: 20px;
  border-bottom: 2px solid ${colors.solidGray};
  z-index: 1;

  ${themeClassName.vod} &, ${themeClassName.vodMain} & {
    border-bottom-color: ${colors.darkGray2};
  }

  ${device.medium} {
    padding-bottom: 8px;
    margin-bottom: 14px;
  }
`
