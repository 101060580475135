import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div<{ lifestyle?: boolean }>`
  display: flex;
  flex-direction: ${({ lifestyle }) => (lifestyle ? 'row' : 'column')};
  gap: ${({ lifestyle }) => (lifestyle ? '19px' : '14px')};

  ${device.medium} {
    flex-direction: column;
    gap: 18px;
  }
`

export const Thumbnail = styled(Link)<{ lifestyle?: boolean }>`
  flex: 0 0 50%;
  display: flex;
  position: relative;
  min-height: ${({ lifestyle }) => (lifestyle ? '106px' : '95px')};
  max-height: ${({ lifestyle }) => (lifestyle ? '106px' : '95px')};
  border-radius: 1px;

  img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }

  ${device.medium} {
    min-height: 170px;
    max-height: 170px;
  }
`

export const TextContainer = styled.div<{ lifestyle?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ lifestyle }) => (lifestyle ? '8px' : '2px')};

  ${device.medium} {
    gap: 0;
  }
`

export const Title = styled.h3<{ lifestyle?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: ${colors.black};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lifestyle }) => (lifestyle ? '2' : '3')};
  -webkit-box-orient: vertical;
  max-height: ${({ lifestyle }) =>
    lifestyle ? 'calc(1.2 * 2 * 16px)' : 'calc(1.2 * 3 * 16px)'};

  ${device.medium} {
    font-size: 20px;
    font-weight: bold;
    -webkit-line-clamp: 2;
    max-height: calc(1.2 * 2 * 20px);
    margin-bottom: 6px;
  }
`

export const SecondaryTitle = styled(Link)<{ lifestyle?: boolean }>`
  display: none;

  ${device.medium} {
    font-size: 16px;
    line-height: 1.13;
    font-weight: normal;
    color: ${colors.black};

    overflow: hidden;
    max-height: calc(1.13 * 2 * 16px);
    display: ${({ lifestyle }) => (lifestyle ? '-webkit-box' : 'none')};
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`
