import { ILayout4 } from '../../../types'
import ComponentTitle from '../../ComponentTitle/ComponentTitle'
import { Post } from '../../Post/Post'
import { Container, PostsContainer } from './Layout4.styles'
import { usePage } from '../../../contexts'

type Props = {
  layout: ILayout4
}

export const Layout4 = ({ layout }: Props) => {
  const { posts, layout_mobile, grid_title } = layout

  const page = usePage()
  const isDarkTheme = page?.isDarkTheme || false
  return posts ? (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <PostsContainer layoutMobile={layout_mobile} isDarkTheme={isDarkTheme}>
        {posts.map(
          post =>
            post.imageObj && (
              <Post
                key={post.id}
                layoutMobile={layout_mobile}
                post={post}
                maxLines={4}
                hideSecondaryTitle
                leftPadded
              />
            )
        )}
      </PostsContainer>
    </Container>
  ) : null
}
