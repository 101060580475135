import { IMatrix1x4 } from '../../types'
import { parseMatrixLayout } from '../../utils'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { MatrixPost } from '../Post/MatrixPost'
import { Container, PostsContainer } from './Matrix1x4.styles'

type Props = {
  matrix: IMatrix1x4
}

export const Matrix1x4 = ({ matrix }: Props) => {
  const { matrix_elements, is_split_categories, layout } = matrix
  const postsInLayout = parseMatrixLayout({
    matrixElements: matrix_elements,
    is_split_categories: is_split_categories,
    layout: layout,
    layoutType: '1x4',
  })

  return (
    <Container>
      <ComponentTitle componentTitle={matrix_elements[0].matrix_title} />
      <PostsContainer>
        {postsInLayout[0].map(
          ({ post, layout: { rowSpan, columnSpan } }) =>
            post && (
              <MatrixPost
                key={post.id}
                post={post}
                rowSpan={rowSpan}
                columnSpan={columnSpan}
              />
            )
        )}
        {postsInLayout[1].map(
          ({ post, layout: { rowSpan, columnSpan } }) =>
            post && (
              <MatrixPost
                key={post.id}
                post={post}
                rowSpan={rowSpan}
                columnSpan={columnSpan}
              />
            )
        )}
      </PostsContainer>
    </Container>
  )
}
