import { usePage } from '../../contexts'
import { IPost } from '../../types'
import { ImageFl } from '../ImageFl/ImageFl'
import { utils } from '../Player/Helpers/utils'
import { ImageContainer } from './ReelsPostFl.styles'
type Props = {
  post: IPost
  grid_id?: number
}

export const ReelsPostsFl = ({ post, grid_id }: Props) => {
  const { imageObj, title, isBlank, link: postLink } = post
  const linkTarget = isBlank ? '_blank' : undefined
  const page = usePage()
  const pageId = page?.PageMeta.id
  const queryParams = utils.addPageQueryParams(postLink, pageId, grid_id)
  const link = postLink + queryParams

  return (
    <ImageContainer href={link} target={linkTarget}>
      <ImageFl
        image={imageObj}
        alt={imageObj ? title : title}
        playButton={true}
        playButtonName={'reels'}
        reelsTitle={title}
      />
    </ImageContainer>
  )
}
