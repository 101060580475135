import dynamic from 'next/dynamic'
import { Fragment, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { utils } from '../Player/Helpers/utils'
import { IPage } from '../../types'

export const CounterHandler = ({ page }: { page: IPage }) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const cookies = new Cookies()
    const close_counter_cookie = cookies.get(
      'counter_cookie_' + (page?.SiteMeta.config?.counter?.id || 'reshet')
    )

    if (
      page &&
      page.SiteMeta.config?.counter &&
      new Date((page.SiteMeta.config.counter?.target || 0) * 1000).getTime() >
        Date.now() &&
      (page.SiteMeta.config.counter?.start || 0) * 1000 < Date.now() &&
      close_counter_cookie != 1
    ) {
      if (page.SiteMeta.config.counter.debug) {
        setShow(utils.getParameterByName('counter') === 'preview')
      } else {
        setShow(true)
      }
    }
  }, [page])
  const close = () => {
    const cookies = new Cookies()
    cookies.set(
      'counter_cookie_' + (page?.SiteMeta.config?.counter?.id || 'reshet'),
      1,
      {
        path: '/',
      }
    )
    setShow(false)
  }
  if (show && page && page.SiteMeta.config?.counter) {
    const Counter = dynamic(() => import('./CounterComp'))
    return (
      <Counter
        key={'counter'}
        {...page.SiteMeta.config.counter}
        close={close}
      />
    )
  }
  return <Fragment />
}
