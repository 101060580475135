import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container, ItemContainer, Slide } from './Marquee.styles'

export type Axis = 'X' | 'Y'

export type Align = 'start' | 'center' | 'end'

export interface MarqueeComponentProps {
  speed: number
  axis?: Axis
  directionReverse?: boolean
  contentReverse?: boolean
  align?: Align
  duration?: number
  height?: string
  pauseOnHover?: boolean
  width?: string
  children: React.ReactNode
  className?: string
}

export const Marquee = ({
  speed,
  axis,
  directionReverse,
  contentReverse,
  align,
  height,
  pauseOnHover,
  width,
  children,
  className,
}: MarqueeComponentProps) => {
  const firstSlideRef = useRef<HTMLDivElement>(null)
  const [animationTime, setAnimationTime] = useState(0)

  const calculateAnimationTime = useCallback(
    (initialSpeed = 0) => {
      if (!firstSlideRef.current) {
        return initialSpeed
      }

      const slideWidth = firstSlideRef.current.clientWidth
      const slideHeight = firstSlideRef.current.clientHeight

      if (axis === 'X') {
        return slideWidth / speed
      }
      return slideHeight / speed
    },
    [axis, speed]
  )

  useEffect(() => {
    const calculateAndSetAnimationTime = () => {
      const newSpeed = calculateAnimationTime(speed)
      setAnimationTime(newSpeed)
    }

    calculateAndSetAnimationTime()
    addEventListener('resize', calculateAndSetAnimationTime)

    return () => {
      removeEventListener('resize', calculateAndSetAnimationTime)
    }
  }, [children, speed, calculateAnimationTime])

  return (
    <Container
      width={width}
      height={height}
      pauseOnHover={pauseOnHover}
      childrenMounted={React.Children.count(children) > 0}
      className={className}
    >
      <Slide
        ref={firstSlideRef}
        offset={-1}
        axis={axis}
        animationTime={animationTime}
        directionReverse={directionReverse}
      >
        <ItemContainer
          axis={axis}
          contentReverse={contentReverse}
          align={align}
        >
          {children}
        </ItemContainer>
      </Slide>
      <Slide
        offset={0}
        axis={axis}
        animationTime={animationTime}
        directionReverse={directionReverse}
      >
        <ItemContainer
          axis={axis}
          contentReverse={contentReverse}
          align={align}
        >
          {children}
        </ItemContainer>
      </Slide>
      <Slide
        offset={1}
        axis={axis}
        animationTime={animationTime}
        directionReverse={directionReverse}
      >
        <ItemContainer
          axis={axis}
          contentReverse={contentReverse}
          align={align}
        >
          {children}
        </ItemContainer>
      </Slide>
    </Container>
  )
}
