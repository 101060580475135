import styled from 'styled-components'
import { device } from '../../../styles/GlobalVariables'
import { SecondaryTitle } from '../../Post/Post.styles'

export const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;

  ${SecondaryTitle} {
    display: none;
  }

  ${device.medium} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px 20px;
  }

  ${device.large} {
    gap: 30px 30px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const Banner = styled.div`
  flex-grow: 0;
  margin: 0 auto;

  ${device.medium} {
    margin: unset;
    display: inline-block;
  }
`
