import styled, { css } from 'styled-components'
import { colors, device, layout } from '../../styles/GlobalVariables'

export const figureStyle = css`
  margin-top: 40px;
  margin-bottom: 40px;

  figcaption {
    color: ${colors.commentsGray};
    font-weight: 600;

    ${device.medium} {
      color: ${colors.gray};
    }
  }
`
export const StyledP = styled.p`
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 40px;

    ${device.small} {
      margin-bottom: 20px;
    }
  `,
  StyledFigure = styled.figure`
    ${figureStyle}
  `,
  StyledBlockquote = styled.blockquote``,
  SpecialBlockquote = styled.blockquote`
    display: none;
  `,
  StyledH3 = styled.h3``,
  StyledUL = styled.ul``,
  StyledOL = styled.ol``,
  StyledIframeWrapper = styled.div`
    height: 490px;
    margin-bottom: 20px;
  `,
  StyledIframe = styled.iframe`
    ${props => props.theme.css};
    width: 100%;
    height: 100%;
  `,
  StyledEmbed = styled.div`
    display: flex;
    justify-content: center;
  `,
  StyledMoreArticles = styled.div`
    ul li {
      font-size: 24px;
      margin-bottom: 5px;
      line-height: 1;

      &:before {
        top: 0;
      }
    }
  `,
  StyledPWithADWrap = styled.div`
    margin-bottom: ${layout.gapMedium};

    [id*='google_ads_iframe'],
    [id*='In_article_box'] {
      text-align: center;
    }
  `
