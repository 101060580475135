import { IContestants } from '../../types'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { FaceLiftBlockSeparator } from '../FaceLiftBlockSeperator/FaceLiftBlockSeparator'
import { Container, ContestantsContainer } from './ContestantsFl.style'
import PostContestant from './PostContestant'

type Props = {
  contestants: IContestants
}

export const ContestantsFl = ({ contestants }: Props) => {
  const { grid_title, posts, grid_id } = contestants

  return (
    <>
      <Container>
        <ComponentTitleFL componentTitle={grid_title} componentId={grid_id} />
        <ContestantsContainer>
          {posts.map((post, i) => (
            <PostContestant key={i} post={post} gid={grid_id} />
          ))}
        </ContestantsContainer>
      </Container>
      <FaceLiftBlockSeparator />
    </>
  )
}
