import styled from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${colors.breadCrumbGray};

  &:last-child {
    font-weight: 700;
    color: ${colors.darkBlue};

    ${themeClassName.vod} &, ${themeClassName.vodMain} & {
      color: ${colors.white};
    }
  }

  & + & {
    &:before {
      width: 11px;
      content: '';
      display: inline-block;
      border-style: solid;
      border-width: 5.5px 8px 5.5px 0;
      border-color: transparent ${colors.pink} transparent transparent;
    }
  }
`

export const Container = styled.div`
  display: none;

  ${device.medium} {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 28px;
  }
`
