import {
  NewsFlashSeparator,
  StyledLinkNewsflashesItem,
} from './Newsflashes.styles'
import { DateTime } from '../../utils'
import { INewsflash } from '../../types'

interface props {
  index: number
  newsflashesPageUrl: string
  newsFlashColor?: string
  item: INewsflash
}

export const NewsFlashListItem = ({
  index,
  newsflashesPageUrl,
  newsFlashColor,
  item,
}: props) => {
  return (
    <div key={`${index}-${item.id}`}>
      <StyledLinkNewsflashesItem
        href={newsflashesPageUrl}
        newsFlashColor={newsFlashColor}
      >
        <time>{DateTime.parse(item.time)?.toTime()}</time>
        {item.text}
      </StyledLinkNewsflashesItem>
      <NewsFlashSeparator />
    </div>
  )
}
