import {
  LiveBlockContainer,
  PlayerContainer,
  PlayerSection,
  VideoSection,
} from './Style'
import { PageTitle } from '../../PageTitle/PageTitle'
import { Player } from '../../Player/Player'
import { Image } from '../../Image/Image'
import { ILiveVodPageGrid, IVideo } from '../../../types'
import { usePage } from '../../../contexts'
import React, { useMemo } from 'react'

export const LiveBlock = ({
  video: videoString,
  player_poster,
  background,
  grid_id,
}: ILiveVodPageGrid) => {
  const page = usePage()
  const video: IVideo = useMemo(
    () => ({
      name: videoString,
      autoPlay: true,
      duration: 0,
      poster: '',
      kalturaId: '',
    }),
    [videoString]
  )
  if (!page) {
    return <></>
  }
  const { PageMeta, SEO } = page
  return (
    <LiveBlockContainer key={'LiveGridBlock' + grid_id}>
      <VideoSection>
        <PageTitle SEO={SEO} pageMeta={PageMeta} />
        <PlayerSection imageObj={background?.imageObj}>
          <PlayerContainer>
            {video ? (
              <Player
                pageMeta={PageMeta}
                video={video}
                img={player_poster?.imageObj.d || video.poster}
              />
            ) : (
              <Image image={player_poster?.imageObj} />
            )}
          </PlayerContainer>
        </PlayerSection>
      </VideoSection>
    </LiveBlockContainer>
  )
}
