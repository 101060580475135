import { useState } from 'react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { IVodEpisodes } from '../../types'
import { VodEpisodePost } from '../Post/VodEpisodePost'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CarouselWrapper,
  Container,
  NavigationOverlay,
  StyledComponentTitle,
  TouchArea,
} from './VodEpisodes.styles'

type Props = {
  item: IVodEpisodes
}

export const VodEpisodes = ({ item }: Props) => {
  const [prevEl, setPrevEl] = useState<HTMLDivElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLDivElement | null>(null)

  const swiperProps: SwiperProps = {
    modules: [Navigation],
    slidesPerView: 'auto' as const,
    dir: 'rtl',
    navigation: {
      prevEl,
      nextEl,
    },
    spaceBetween: 11,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
    breakpoints: {
      1280: {
        slidesPerView: 4,
        spaceBetween: 20,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
      1300: {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
    },
  }

  return (
    <Container>
      <StyledComponentTitle componentTitle={item.grid_title} />
      <CarouselWrapper>
        <Swiper {...swiperProps}>
          {item?.posts.map((post, index) => (
            <SwiperSlide key={index}>
              <VodEpisodePost post={post} />
            </SwiperSlide>
          ))}
        </Swiper>
        <NavigationOverlay position='left' ref={node => setNextEl(node)}>
          <TouchArea position='left'>
            <ArrowLeftIcon />
          </TouchArea>
        </NavigationOverlay>
        <NavigationOverlay position='right' ref={node => setPrevEl(node)}>
          <TouchArea position='right'>
            <ArrowRightIcon />
          </TouchArea>
        </NavigationOverlay>
      </CarouselWrapper>
    </Container>
  )
}
