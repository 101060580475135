import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const ImageContainer = styled(Link)`
  position: relative;
  display: block;
  & img {
    width: 100%;
    transition: filter 0.3s ease;

    ${device.medium} {
      height: 234px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6) 20%,
      rgba(0, 0, 0, 0) 49%
    );
    transition: opacity 0.3s ease;
    z-index: 1;
    opacity: 0.8;
  }

  &:hover::before {
    opacity: 1;
  }
`
