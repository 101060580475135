import styled from 'styled-components'
import { colors } from '../../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  width: '100%';
  flex-direction: column;
`

export const PostsContainer = styled.div`
  display: flex;
`

export const PostsWrapper = styled.div`
  display: flex;
  width: '100%';
  flex-direction: row;
  gap: 16px;
`

export const Post = styled.div`
  display: flex;
  width: 308px;
  flex-direction: column;
  gap: 8px;

  & img {
    width: 308px;
    height: 173px;
    &:hover {
      filter: brightness(80%);
    }
  }
`

export const PostTitle = styled.div`
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: ${props => props.theme.fontColor};
`

export const PostCredit = styled.div`
  color: ${props =>
    props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2};
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.3px;
`
