import React from 'react'
import { Title, SubTitle, Container, Footer } from './ArticleTitle.styles'

export interface Props {
  title: string
  subtitle: string
  footer?: string
}

export const ArticleTitle = ({ title, subtitle, footer }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
      {footer && <Footer>First</Footer>}
    </Container>
  )
}
