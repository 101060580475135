import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { usePage } from '../../../contexts'
import { useFL } from '../../../hooks/useFL'
import { colors } from '../../../styles/GlobalVariables'
import { IArticlesGrid, IPost } from '../../../types'
import { addAnalyticsToUrl } from '../../../utils'
import Credits from '../../DateAndWriter/Credits'
import { Image } from '../../Image/Image'
import { Shave } from '../../Shave/Shave'
import {
  BigPostContainer,
  BigPostContent,
  BigPostTitle,
  ComponentContainer,
  Post,
  PostTitle,
} from './MobileSwiperBigFirst.styles'

type Props = {
  layout: IArticlesGrid
}

export const MobileSwiperBigFirst = ({ layout }: Props) => {
  const { posts } = layout
  const bigPost = posts[0]
  const restPosts = posts.filter((_, index) => index > 0 && index < 12)
  const page = usePage()
  const { fontColor, isDarkTheme } = useFL()

  if (!page || !posts.length) {
    return null
  }
  return (
    <ComponentContainer>
      <Link
        href={addAnalyticsToUrl(
          bigPost.link || '#',
          page.PageMeta.id.toString(),
          layout.grid_id.toString()
        )}
      >
        <BigPostContainer>
          <Image image={bigPost.imageObj} />
          <BigPostContent theme={{ isDarkTheme: isDarkTheme }}>
            <Shave maxLines={2}>
              <BigPostTitle theme={{ fontColor: fontColor }}>
                {bigPost.title}
              </BigPostTitle>
            </Shave>
            <Credits
              credits={bigPost}
              color={isDarkTheme ? colors.steelBlue : colors.darkGray2}
            />
          </BigPostContent>
        </BigPostContainer>
      </Link>

      <Swiper allowTouchMove={true} slidesPerView={2.6} spaceBetween={16}>
        {restPosts.slice(0, 12).map((post: IPost, idx) => (
          <SwiperSlide key={idx}>
            <Link
              href={addAnalyticsToUrl(
                post.link,
                page.PageMeta.id.toString(),
                layout.grid_id.toString()
              )}
            >
              <Post>
                <Image image={post.imageObj} />
                <Shave maxLines={4}>
                  <PostTitle theme={{ fontColor: fontColor }}>
                    {post.title}
                  </PostTitle>
                </Shave>
                <Credits
                  credits={post}
                  color={isDarkTheme ? colors.steelBlue : colors.darkGray2}
                />
              </Post>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </ComponentContainer>
  )
}
