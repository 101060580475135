import { MouseEvent } from 'react'
import styled from 'styled-components'
import { useOverlay } from '../../contexts'

export const Overlay = () => {
  const { isOpen, body, handleClose } = useOverlay()

  const close = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (event.target === event.currentTarget) {
      handleClose()
    }
  }

  return (
    <Container onClick={event => close(event)} isOpen={isOpen}>
      {body}
    </Container>
  )
}

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);

  transition-property: opacity, z-index;
  transition-duration: 200ms;
  transition-timing-function: ease-in;

  ${({ isOpen }) => ({
    opacity: isOpen ? 1 : 0,
    zIndex: isOpen ? 9999 : -9999,
  })}
`
