import styled from 'styled-components'
import { device, layout, maxWidth } from '../../styles/GlobalVariables'
import { IImage } from '../../types'

export const LiveVodContainerBg = styled.div<{ background: IImage }>`
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url('${props => props.background.m}');

  ${device.medium} {
    background-image: url('${props => props.background.d}');
  }
`
export const LiveVodContainer = styled.div`
  max-width: ${maxWidth.large};
  margin: auto;

  > div:not(:first-child):not(:last-child) {
    margin-left: 20px;
    margin-right: 20px;

    ${device.medium} {
      margin: auto;
      margin-bottom: ${layout.gridBlockGap};
    }
  }
`
export const Container = styled.div`
  margin-top: 20px;
  position: relative;
  height: 85vh;

  ${device.medium} {
    height: unset;
    margin-top: 28px;
    margin-bottom: 28px;
  }
`

export const TaboolaWrapper = styled.div`
  margin: auto;
  max-width: 1290px;
  width: 100%;
  padding: 0 ${layout.gapMedium};

  ${device.medium} {
    padding: 0;
  }
`

export const TaboolaWidgetWrapper = styled(TaboolaWrapper)`
  margin: 0 0 ${layout.gapMedium} 0 !important;
`
