import { usePage } from '../../contexts'
import { IVideo } from '../../types'
import { Player } from '../Player/Player'
import { TimeLine } from '../TimeLine/TimeLine'

type Props = {
  type: ShortCodeTypes
  shortcode: string
}

type ShortCodeTypes = 'video' | 'timeline' | 'image'

const regexTypes = {
  video:
    '\\[brightcove_iframe(\\s[\\s\\S]*?)?\\](?:((?!\\s*?(?:\\[brightcove_iframe|\\[/(?!brightcove_iframe)))[\\s\\S]*?)(\\[/brightcove_iframe\\]))?',
  timeline:
    '\\[timeline(\\s[\\s\\S]*?)?\\](?:((?!\\s*?(?:\\[timeline|\\[/(?!timeline)))[\\s\\S]*?)(\\[/timeline\\]))?',
  image:
    '\\[img_figure(\\s[\\s\\S]*?)?\\](?:((?!\\s*?(?:\\[img_figure|\\[/(?!img_figure)))[\\s\\S]*?)(\\[/img_figure\\]))?',
}

//parse
const parseOptions = (stringOptions: string) => {
  const options: { [key: string]: string } = {}
  if (!stringOptions) {
    return
  }
  const set = stringOptions.replace(/(\w+=)/g, '\n$1').split('\n')
  set.shift()
  for (let i = 0; i < set.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const kv: any = set[i].split('=')
    kv[1] = (kv[1] || '').trim()
    options[kv[0]] = kv[1].replace(/(^['"])|(['"]$)/g, '')
  }
  return options
}

const Parser = ({ type, shortcode }: Props) => {
  const pageData = usePage()

  if (!pageData) {
    return null
  }

  const stringOptions = shortcode.match(new RegExp(regexTypes[type]))

  if (!stringOptions) {
    return null
  }

  const options = parseOptions(stringOptions[1])
  if (!options) {
    return null
  }

  const { video_id, credit, desc, poster, kid, duration = '' } = options
  const videoProps = {
    duration: duration ? parseInt(duration.replace(/'/g, '')) : 0,
    poster: poster,
    kalturaId: kid,
    name: video_id,
    credit: credit,
    description: desc,
    autoPlay: false,
  } as IVideo

  switch (type) {
    case 'video':
      return (
        <Player
          pageMeta={pageData.PageMeta}
          video={videoProps}
          img={''}
          fromParser={true}
          key={kid}
        />
      )
    case 'timeline':
      return <TimeLine data={pageData} key={'tl_id_' + options.id} />
    // case "image":
    //     return <ImageFigure image={options} />
    default:
      return null
  }
}

export const isShorteCode = (shortcode: string, key = '') => {
  let shortcodeType = ''
  if (shortcode.match(new RegExp(regexTypes['video']))) {
    shortcodeType = 'video'
  }
  if (shortcode.match(new RegExp(regexTypes['timeline']))) {
    shortcodeType = 'timeline'
  }
  if (shortcode.match(new RegExp(regexTypes['image']))) {
    shortcodeType = 'image'
  }

  if (shortcodeType) {
    return (
      <Parser
        type={shortcodeType as ShortCodeTypes}
        shortcode={shortcode}
        key={'parser' + key}
      />
    )
  } else {
    return null
  }
}
