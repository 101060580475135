import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContestantsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-row: repeat(2, 102px);
  gap: 16px;

  ${device.small} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-row: repeat(2, 200px);
    gap: 16px;
  }

  ${device.regular} {
    grid-template-columns: repeat(5, 1fr);
    grid-template-row: repeat(2, 200px);
  }

  ${device.medium} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-row: repeat(2, 202px);
  }
`

export const ContestantPost = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: filter 0.3s ease;

  > img {
    width: 100%;
    height: auto;

    ${device.medium} {
      width: 202px;
      height: 202px;
    }
  }

  &:hover,
  &:active {
    filter: brightness(80%);
  }

  ${device.medium} {
    gap: 10px;
  }
`

export const ImageText = styled.div<{ fontColor?: string }>`
  text-align: center;
  color: ${({ fontColor }) => fontColor ?? '#000'};
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${device.medium} {
    font-size: 18px;
    line-height: 22px;
  }
`
