import { ICredits } from '../../../types'
import {
  Container,
  DateContainer,
  TimeDateWrapper,
  Writer,
} from './ArticleCreditsFl.styles'

type Props = {
  credits: ICredits
  isMobile?: boolean
}

export const ArticleCreditsFl = ({ credits, isMobile = false }: Props) => {
  const date = credits.publishDate.split(' ')[0]
  const time = credits.publishDate.split(' ')[1].slice(0, 5)
  const dateTime = date + ' | ' + time

  const writers = credits.writers?.filter(w => w.name) || []

  return (
    <Container>
      {!isMobile &&
        (writers.length > 0 ? (
          writers.map(writer => (
            <Writer key={writer.name} href={writer.link}>
              {writer.name}
            </Writer>
          ))
        ) : (
          <span>{credits.writers[0].name}&nbsp;|&nbsp;</span>
        ))}
      <TimeDateWrapper>
        <DateContainer>{dateTime}</DateContainer>
      </TimeDateWrapper>
    </Container>
  )
}
