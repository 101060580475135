import styled from 'styled-components'
import { colors, device, hideScrollbar } from '../../styles/GlobalVariables'

export const DatesContainer = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  background: ${colors.mediumGrayBg};

  ${device.medium} {
    background: transparent;
  }

  ${hideScrollbar}
`

export const DatesScrollArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  height: 60px;
  padding: 0 18.5px;

  ${device.medium} {
    padding: 0;
    height: unset;
  }
`

export const ScrollArrowWrapper = styled.div<{ position: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  height: 60px;
  width: 100px;
  top: 0;
  z-index: 5;

  ${device.extraSmall} {
    display: none;
  }

  ${device.medium} {
    display: flex;
    height: 40px;

    ${({ position }) =>
      position === 'left'
        ? `background: linear-gradient(to left, transparent 0%, ${colors.tvGuideDark} 100%)`
        : `background: linear-gradient(to right, transparent 0%, ${colors.tvGuideDark} 100%)`}
  }

  ${({ position }) =>
    position === 'left' && {
      left: 0,
      background: 'linear-gradient(to left, transparent 0%, #2f2f2f 100%)',
      justifyContent: 'flex-end',
    }}

  ${({ position }) =>
    position === 'right' && {
      right: 0,
      background: 'linear-gradient(to right, transparent 0%, #2f2f2f 100%)',
      justifyContent: 'flex-start',
    }}
`

export const ScrollArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  padding: 20px;
  cursor: pointer;

  > svg {
    font-size: 22px;
  }

  ${device.medium} {
    padding: 9px 10px 9px 0;
  }
`

export const Date = styled.div<{ selected?: boolean }>`
  flex: 0 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    font-weight: ${props => (props.selected ? '500' : '300')};
    color: ${props => (props.selected ? colors.pink : colors.white)};

    ${device.medium} {
      color: ${colors.white};
    }
  }

  cursor: ${props => (props.selected ? 'default' : 'pointer')};

  ${device.medium} {
    flex-direction: row;
    background: ${props => (props.selected ? colors.pink : colors.lightGrayBg)};
    flex-basis: 203px;
    height: 40px;
    border-radius: 4px;
    font-weight: bold;
  }
`

export const ShowDate = styled.span`
  order: -1;
  font-size: 22px;

  ${device.medium} {
    font-size: 18px;
    order: 1;
  }
`

export const Comma = styled.span`
  display: none;

  ${device.medium} {
    display: inline;
  }
`

export const Weekday = styled.span`
  order: 1;
  font-size: 18px;

  ${device.medium} {
    order: -1;
  }
`
