import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { useHeader } from '../../contexts'
import { he } from '../../locales/he'
import { useRootStore } from '../../store'
import { IVodPlaylist } from '../../types'
import {
  displayShowMore,
  Episode,
  getSeason,
  getShowData,
  Season,
} from '../../utils'
import { NowPlaying } from './NowPlaying'
import { PlaylistEpisodes } from './PlaylistEpisodes'
import {
  Container,
  EpisodesHeader,
  LastEpisodes,
  SeasonSelector,
  SeasonsList,
  SeasonTitle,
  SelectedSeason,
  ShowMore,
} from './VodPlaylist.styles'
import ArrowSeasonSelector from '/public/assets/images/svg/arrow-season-selector.svg'
import ArrowVodPlaylist from '/public/assets/images/svg/arrow-vod-playlist.svg'

type Props = {
  vodPlaylist: IVodPlaylist
}

export const VodPlaylist = ({ vodPlaylist }: Props) => {
  const router = useRouter()
  const { headerHeight } = useHeader()
  const { pageTitleRef } = useRootStore()
  // const { videoEndedId } = useContext(PlayerContext)

  const { portalLink } = vodPlaylist

  const { currentEpisode, seasons } = useMemo(
    () => getShowData(vodPlaylist, router.asPath),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vodPlaylist]
  )
  const currentSeason = getSeason(seasons, currentEpisode)

  const [selectorOpen, setSelectorOpen] = useState(false)
  const [nowPlaying, setNowPlaying] = useState(currentEpisode)
  const [selectedSeason, setSelectedSeason] = useState(currentSeason)
  const [episodesGroupCounter, setEpisodesGroupCounter] = useState(0)

  if (!selectedSeason) {
    return null
  }

  const displayShowMoreButton = displayShowMore(
    selectedSeason.episodes,
    episodesGroupCounter
  )

  // const videoEnded = videoEndedId === nowPlaying?.video?.kalturaId

  // const handleVideoEnded = () => {
  //   const nextEpisode = getNextEpisode(seasons, nowPlaying)

  //   if (!nextEpisode) return
  //   const newSeason = getSeason(seasons, nextEpisode)

  //   if (newSeason && newSeason !== currentSeason) {
  //     setEpisodesGroupCounter(0)
  //     setSelectedSeason(newSeason)
  //   }

  //   handleEpisodeChange(nextEpisode)
  // }

  // useEffect(() => {
  //   videoEnded && handleVideoEnded()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [videoEnded])

  const handleSeasonChange = (season: Season) => {
    setSelectedSeason(season)
    setSelectorOpen(false)
    setEpisodesGroupCounter(0)
  }

  const scrollToPlayer = () => {
    if (!pageTitleRef || !pageTitleRef.current) {
      return
    }

    const scrollToPosition =
      document.documentElement.scrollTop +
      pageTitleRef.current.getBoundingClientRect().top -
      headerHeight

    window.scrollTo({
      top: Math.floor(scrollToPosition || 100),
      behavior: 'smooth',
    })
  }

  const handleEpisodeChange = (episode: Episode) => {
    setNowPlaying(episode)
    scrollToPlayer()

    const currentPath = router.asPath.split('/').filter(slug => slug !== '')

    let nextLink: string | string[] = episode.link
      .split('/')
      .filter(slug => slug !== '')

    if (
      nextLink[0] !== currentPath[0] &&
      nextLink[0].includes(currentPath[0])
    ) {
      nextLink.shift()
      nextLink = [currentPath[0], ...nextLink]
    }

    nextLink = nextLink.join('/')
    router.push('/' + nextLink, undefined, { shallow: true })
  }

  return (
    <Container>
      <NowPlaying nowPlaying={nowPlaying} portalLink={portalLink} />
      <EpisodesHeader>
        <LastEpisodes>{he.lastEpisodes}</LastEpisodes>
        <SeasonSelector>
          <SelectedSeason
            onClick={() => setSelectorOpen(!selectorOpen)}
            open={selectorOpen}
          >
            {selectedSeason.name}
            <ArrowSeasonSelector />
          </SelectedSeason>
          <SeasonsList open={selectorOpen}>
            {seasons.map((season, index) => (
              <SeasonTitle
                key={index}
                onClick={() => handleSeasonChange(season)}
                selected={season.id === selectedSeason.id}
              >
                {season.name}
              </SeasonTitle>
            ))}
          </SeasonsList>
        </SeasonSelector>
      </EpisodesHeader>
      <PlaylistEpisodes
        episodes={selectedSeason.episodes}
        nowPlaying={nowPlaying}
        handleEpisodeChange={handleEpisodeChange}
        episodesGroupCounter={episodesGroupCounter}
      />
      {displayShowMoreButton && (
        <ShowMore
          onClick={() => setEpisodesGroupCounter(episodesGroupCounter + 1)}
        >
          {he.showMore}
          <ArrowVodPlaylist />
        </ShowMore>
      )}
    </Container>
  )
}
