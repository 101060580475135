import styled from 'styled-components'
import {
  colors,
  device,
  playButtonStyle,
  postThumbnailHeight,
  themeClassName,
} from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Thumbnail = styled(Link)`
  flex: 1 1 50%;
  display: flex;
  position: relative;
  min-height: ${postThumbnailHeight.mobileMatrix};
  max-height: ${postThumbnailHeight.mobileMatrix};
  border-radius: 1px;

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }

  ${device.medium} {
    min-height: ${postThumbnailHeight.medium};
    max-height: ${postThumbnailHeight.medium};
  }

  ${playButtonStyle()}
`

export const Overlay = styled.div`
  ${device.medium} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 43.71%,
      rgba(0, 0, 0, 0.63) 77.08%,
      #000000 92.19%
    );
  }
`

export const TextContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  direction: rtl;
  gap: 2px;

  ${device.medium} {
    gap: 6px;
    position: absolute;
    width: 100%;
    bottom: 0;
    text-shadow: 0 2px 3px #000;
    padding: 15px 12px;
    margin-left: auto;
  }
`

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  height: 38px;
  color: ${colors.black};

  ${device.medium} {
    font-size: 20px;
    font-weight: bold;
    height: 42px;
    color: ${colors.white};

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: calc(1.2 * 3em);
    -webkit-line-clamp: 2;
  }

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }
`

export const AirDate = styled.span`
  font-size: 14px;
  line-height: 1.2;
  color: ${colors.transparentGray};

  ${device.medium} {
    height: 27px;
    font-size: 15px;
    line-height: 1.77;
    color: ${colors.white};
  }

  ${themeClassName.vodMain} & {
    color: ${colors.transparentWhite};
  }
`

export const Container = styled.div<{
  backgroundColor: false | string
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : null};
  gap: 14px;
  height: 166px;

  ${device.medium} {
    gap: 18px;
    min-height: 263px;

    ${TextContainer} {
      position: unset;
      width: unset;
      text-shadow: unset;
      padding: unset;
      margin-left: inherit;
    }

    ${Overlay} {
      display: none;
    }
  }
`
