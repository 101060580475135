import styled from 'styled-components'

import { Container as PageTitle } from '../../PageTitle/PageTitle.styles'
import { device, layout, maxWidth } from '../../../styles/GlobalVariables'
import { IImage } from '../../../types'

export const LiveBlockContainer = styled.div`
  margin-top: 20px;
  position: relative;

  ${device.medium} {
    margin-top: 28px;
    margin-bottom: 28px;
  }
`
export const VideoSection = styled.div`
  width: 100%;
  z-index: 1;

  ${PageTitle} {
    margin: 0 20px 20px;
  }

  ${device.medium} {
    background-image: unset;
    background-repeat: unset;
    background-size: unset;
    position: sticky;

    ${PageTitle} {
      max-width: ${maxWidth.medium};
      margin: 0 auto;
      padding: 0;
      margin-bottom: 30px;
      font-size: 32px;
      padding-bottom: 8px;
    }
  }

  ${device.large} {
    ${PageTitle} {
      max-width: ${maxWidth.large};
    }
  }
`
export const PlayerSection = styled.div<{
  imageObj: IImage
}>`
  margin: 0 auto 20px;

  ${device.medium} {
    margin-bottom: 40px;
    min-height: 630px;
    max-height: 630px;
  }
`
export const PlayerContainer = styled.div`
  margin: 0 20px;

  ${device.medium} {
    margin: 0 auto;
    min-width: 1120px;
    max-width: 1120px;
  }
`
export const TaboolaWrapper = styled.div`
  margin: auto;
  max-width: ${maxWidth.large};
  width: 100%;
  padding: ${layout.gapMedium};

  ${device.medium} {
    padding: 0;
  }
`
