import styled from 'styled-components'
import { device, maxWidth } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 0 auto;
  // this is to counter the effects of the line above which shrinks the flex container
  width: 100%;
  padding: 0 20px;

  ${device.medium} {
    max-width: ${maxWidth.medium};
    padding: 0;
  }

  ${device.large} {
    max-width: ${maxWidth.large};
  }
`
