import styled, { css } from 'styled-components'
import {
  colors,
  device,
  headerZIndex,
  host,
  themeClassName,
} from '../../styles/GlobalVariables'
import { Header } from '../Header/Header'
import { Container as SubMenuContainer } from '../Header/SubMenu.styles'

export const StyledHeader = styled(Header)``

export const Banners = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -20px;
  z-index: 2;
  width: 100%;

  ${device.medium} {
    margin-top: -28px;
  }

  ${host.nativeApp} & {
    margin-top: 0;
  }
`

export const BackgroundLogo = styled.div`
  position: absolute;
  left: 0;
  top: 100px;
`

export const LogoWrapper = styled.div`
  position: relative;
`

export const FooterSpacer = styled.div<{ height: number }>`
  width: 100vw;
  height: ${props => props.height}px;
`

export const Container = styled.div<{
  subMenu: boolean
  hasHero: boolean
  showingHeader: boolean
  heroHeight?: number
  headerHeight?: number
  isFaceLift?: boolean
  backgroundColor?: string
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  padding-top: ${props => {
    if (!props.showingHeader || props.hasHero) {
      return '0'
    }
    if (props.subMenu) {
      return `${48 + 29 + 20}px`
    }
    return `${48 + 20}px`
  }};
  ${BackgroundLogo} {
    top: ${({ subMenu }) => (subMenu ? 'calc(-48px - 29px - 20px)' : '-68px')};

    ${device.medium} {
      top: ${({ subMenu }) =>
        subMenu ? 'calc(-64px - 29px - 28px)' : 'calc(-64px - 28px)'};
    }
  }

  ${device.medium} {
    ${props => {
      if (props.showingHeader) {
        if (props.subMenu) {
          return 'padding-top: calc(64px + 29px + 28px)'
        }
        return 'padding-top: calc(64px + 28px);'
      }
      return 'padding-top: 28px;'
    }}
  }

  background: ${colors.white};

  ${props =>
    props.isFaceLift
      ? css`
          background: ${props.backgroundColor};
        `
      : ''}

  &${themeClassName.vod} {
    background: ${colors.tvGuideDark};
  }

  &${themeClassName.vodMain} {
    background: ${colors.tvGuideDark};

    ${props =>
      props.hasHero &&
      'background: linear-gradient(180deg, rgba(18,18,18,1) 0, rgba(18,18,18,0) 21vh, rgba(18,18,18,1) 85vh, rgba(18,18,18,1) 100%);'}
    ${device.medium} {
      ${({ hasHero }) => hasHero && `background: ${colors.tvGuideDark};`}
      ${({ hasHero, heroHeight }) =>
        hasHero &&
        heroHeight &&
        `background: linear-gradient(180deg, rgba(18,18,18,1) 0, rgba(18,18,18,0) ${
          heroHeight * 0.23
        }px, rgba(18,18,18,0) ${heroHeight * 0.28}px, rgba(18,18,18,1) ${
          heroHeight * 0.85
        }px, rgba(18,18,18,1) 100%);`}
    }
  }

  ${host.nativeApp} & {
    //padding-top: 49px;

    ${BackgroundLogo} {
      top: -49px;
    }

    .playkit-in-browser-fullscreen-mode {
      :before {
        content: '';
        display: block;
        position: fixed;
        inset: 0;
        background: ${colors.black};
        z-index: 0 !important;
      }
    }
  }

  ${host.nativeApp} & ${StyledHeader} {
    display: none;
  }

  > ${SubMenuContainer} {
    display: none;

    ${host.nativeApp} & {
      display: flex;
      padding-right: 19px;
      z-index: ${headerZIndex};
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

      ${themeClassName.vod} & {
        background: ${colors.darkHeaderBg};
        box-shadow: unset;
      }

      ${themeClassName.vodMain} & {
        background: linear-gradient(
          180deg,
          #121212 0%,
          rgba(18, 18, 18, 0) 100%
        );
        box-shadow: unset;

        &.is-scrolled {
          background: ${colors.darkHeaderBg};
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
`
