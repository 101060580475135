import { Swiper as SwiperClass } from 'swiper/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import React, { useEffect, useRef, useState } from 'react'
import { ICalender, IItem } from '../../../types'
import {
  CalendarSliderWrapper,
  WeekDayContainer,
  WeekDayDate,
  WeekDayName,
  ButtonContainer,
  SwiperButtonWrapper,
} from './Calendar.styles'
import { NavButton } from './NavButton'
import NextLink from 'next/link'

type props = {
  calender: ICalender[]
  timelineItem: IItem
}

export const Calendar = ({ calender, timelineItem }: props) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const ButtonContainerRef = useRef<HTMLDivElement | null>(null)
  const [visiblePrev, setVisiblePrev] = useState<boolean>(true)
  const [visibleNext, setVisibleNext] = useState<boolean>(true)
  const [activeElementId, setActiveElementId] = useState<string | number>(0)

  useEffect(() => {
    setActiveElementId(timelineItem.id)
  }, [timelineItem.id])

  function getItemParentLink(timelineItemLink: string) {
    const link = timelineItemLink.replace('/item', '')
    const splitLink = link.split('/')
    splitLink.forEach((item, index) => {
      if (item == '') {
        splitLink.splice(index, 1)
      }
    })
    splitLink.splice(-1)

    return '/' + splitLink.join('/')
  }

  useEffect(() => {
    setVisiblePrev(true)
    setVisibleNext(false)
    if (ButtonContainerRef.current) {
      ButtonContainerRef.current.style.justifyContent = 'flex-start'
    }
  }, [])

  const handleSlideChange = (swiper: SwiperClass) => {
    if (swiper.isBeginning) {
      setVisiblePrev(true)
      setVisibleNext(false)
      if (ButtonContainerRef.current) {
        ButtonContainerRef.current.style.justifyContent = 'flex-start'
      }
    } else if (swiper.isEnd) {
      setVisiblePrev(false)
      setVisibleNext(true)
      if (ButtonContainerRef.current) {
        ButtonContainerRef.current.style.justifyContent = 'flex-end'
      }
    } else {
      setVisiblePrev(true)
      setVisibleNext(true)
      if (ButtonContainerRef.current) {
        ButtonContainerRef.current.style.justifyContent = 'space-between'
      }
    }
  }

  return (
    <CalendarSliderWrapper className={'calendar-slider-wrapper'}>
      {Object.entries(calender).length >= 7 && (
        <ButtonContainer ref={ButtonContainerRef}>
          {visiblePrev && (
            <SwiperButtonWrapper id={'prev'}>
              <NavButton
                role={'prev'}
                ref={navigationPrevRef}
                swiperRef={swiperRef}
              />
            </SwiperButtonWrapper>
          )}
          {visibleNext && (
            <SwiperButtonWrapper id={'next'}>
              <NavButton
                role={'next'}
                ref={navigationNextRef}
                swiperRef={swiperRef}
              />
            </SwiperButtonWrapper>
          )}
        </ButtonContainer>
      )}
      <Swiper
        allowTouchMove={true}
        direction={'horizontal'}
        slidesPerView={
          Object.entries(calender).length >= 7
            ? 7
            : Object.entries(calender).length + 2
        }
        spaceBetween={Object.entries(calender).length <= 7 ? 90 : 30}
        slidesOffsetAfter={4}
        pagination={{ clickable: true }}
        slidesPerGroup={2}
        onSwiper={setSwiperRef}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onSlideChange={handleSlideChange}
      >
        {Object.values(calender)
          .reverse()
          .map((item, index) => (
            <SwiperSlide key={index}>
              <NextLink
                className={'lnk'}
                href={item.link.replace(/^.*\/\/[^/]+/, '')}
              >
                <WeekDayContainer
                  key={'day-' + item.id}
                  className={activeElementId == item.id ? 'activeDay' : ''}
                >
                  <WeekDayName>{item.date.dayMonth}</WeekDayName>
                  <WeekDayDate>{item.date.weekday}</WeekDayDate>
                </WeekDayContainer>
              </NextLink>
            </SwiperSlide>
          ))}
        <SwiperSlide className={'last-slide-all'} key={'all'}>
          <NextLink href={getItemParentLink(timelineItem.link)}>
            <WeekDayContainer
              key={'day-all'}
              className={activeElementId === 'all' ? 'activeDay' : ''}
            >
              <WeekDayName>לכל</WeekDayName>
              <WeekDayDate>העידכונים</WeekDayDate>
            </WeekDayContainer>
          </NextLink>
        </SwiperSlide>
      </Swiper>
    </CalendarSliderWrapper>
  )
}
