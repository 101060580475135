import { usePage } from '../../contexts'
import { IPost } from '../../types'
import Credits from '../DateAndWriter/Credits'
import { Image } from '../Image/Image'
import Link from '../Link/Link'
import { utils } from '../Player/Helpers/utils'
import {
  Container,
  SecondaryTitle,
  TextContainer,
  Thumbnail,
  Title,
} from './SecondaryPost.styles'

type Props = {
  post: IPost
  lifestyle?: boolean
}

export const SecondaryPost = ({ post, lifestyle }: Props) => {
  const { imageObj, title, secondaryTitle, isBlank, id, link: postLink } = post

  const linkTarget = isBlank ? '_blank' : undefined
  const pageId = usePage()?.PageMeta.id
  const queryParams = utils.addPageQueryParams(postLink, pageId, id)
  const link = postLink + queryParams

  return (
    <Container lifestyle={lifestyle}>
      <Thumbnail href={link} target={linkTarget} lifestyle={lifestyle}>
        <Image image={imageObj} alt={imageObj ? title : title} />
      </Thumbnail>
      <TextContainer lifestyle={lifestyle}>
        <Link href={link} target={linkTarget}>
          <Title lifestyle={lifestyle}>{title}</Title>
        </Link>
        <SecondaryTitle href={link} target={linkTarget} lifestyle={lifestyle}>
          {secondaryTitle}
        </SecondaryTitle>
        <Credits credits={post} />
      </TextContainer>
    </Container>
  )
}
