import { useEffect, useState } from 'react'
import { usePage } from '../../contexts'
import Link from '../Link/Link'
import { utils } from '../Player/Helpers/utils'
import {
  AlertItem,
  Alerts,
  AlertsTitle,
  AlertsWrapper,
  CloseAlerts,
  logoImg,
  PikudLogoWrap,
  RedAlertPreload,
  RedAlertWrapper,
} from './RedAlert.styles'
import { clientConfig } from '../../client/clientConfig'
import { prettyLog } from '../../utils'

type IAlertItem = {
  guid: string
  pubDate: string
  category?: string
  title: string
  link?: string
  description: string
  ttl: number
  polygon: string
  priority: string
}

let fetchTimeout: NodeJS.Timeout, alertsPath: string
export const RedAlert = () => {
  const [info, setInfo] = useState({
    title: '',
    description: '',
    alerts: [] as IAlertItem[],
    guids: [] as string[],
  })
  const [closed, setClosed] = useState(false)
  const page = usePage()

  const close = () => {
    setClosed(true)
  }

  useEffect(() => {
    const path =
      utils.getParameterByName('alerts') === 'test' ? 'ra-test' : 'ra'
    const interval = page?.SiteMeta?.config?.red_alert?.interval || 3000
    alertsPath = `${clientConfig.baseUrl}/${path}.xml`
    const getAlerts = () => {
      fetch(alertsPath)
        .then(response => {
          if (response.ok) {
            return response.text()
          }
          throw new Error(response.statusText)
        })
        .then(str => new window.DOMParser().parseFromString(str, 'text/xml'))
        .then(data => {
          const hasData = data.getElementsByTagName('title').length > 0

          hasData &&
            setInfo(info => {
              const title = data.getElementsByTagName('title')[0]?.innerHTML
              const description =
                data.getElementsByTagName('description')[0]?.innerHTML
              const itemsEl = Array.from(data.getElementsByTagName('item'))
              const newAlerts: IAlertItem[] = []
              const newGuids: string[] = []
              itemsEl.map(item => {
                const guid = item.getElementsByTagName('guid')[0]?.innerHTML
                if (!info.guids.includes(guid)) {
                  newAlerts.push({
                    guid: guid,
                    pubDate: item.getElementsByTagName('pubDate')[0]?.innerHTML,
                    title: item.getElementsByTagName('title')[0]?.innerHTML,
                    description:
                      item.getElementsByTagName('description')[0]?.innerHTML,
                    ttl:
                      parseInt(
                        item.getElementsByTagName('alerts:ttl')[0]?.innerHTML
                      ) | 3,
                    polygon:
                      item.getElementsByTagName('alerts:polygon')[0]?.innerHTML,
                    priority:
                      item.getElementsByTagName('alerts:priority')[0]
                        ?.innerHTML,
                  })
                  newGuids.push(guid)
                }
              })
              return {
                ...info,
                title: title,
                description: description,
                alerts: [...info.alerts, ...newAlerts],
                guids: [...info.guids, ...newGuids],
              }
            })
          fetchTimeout = setTimeout(getAlerts, interval)
        })
        .catch(error => {
          prettyLog('%cRedAlert:', error, 'error')
          fetchTimeout = setTimeout(getAlerts, interval * 10)
        })
    }
    const clearAlerts = () => {
      setInfo(info => {
        const filteredAlerts = info.alerts
          .map(alert => {
            return { ...alert, ttl: alert.ttl - 1 }
          })
          .filter(alert => alert.ttl > 0)

        return {
          ...info,
          alerts: filteredAlerts,
        }
      })
      setTimeout(clearAlerts, 1000)
    }

    if (closed) {
      clearTimeout(fetchTimeout)
      fetchTimeout = setTimeout(() => setClosed(false), interval * 10)
      setInfo({
        title: '',
        description: '',
        alerts: [],
        guids: [],
      })
    } else {
      clearAlerts()
      getAlerts()
    }
  }, [page?.SiteMeta?.config?.red_alert?.interval, closed])

  if (info.alerts.length == 0) {
    return <RedAlertPreload />
  }

  return (
    <RedAlertWrapper>
      <PikudLogoWrap>
        <Link
          href={'https://www.oref.org.il/1096-he/Pakar.aspx'}
          target={'_blank'}
        >
          <img src={logoImg} alt='פיקוד העורף' />
        </Link>
      </PikudLogoWrap>
      <AlertsWrapper>
        <AlertsTitle>{info.title}</AlertsTitle>
        <Alerts>
          {info.alerts.map((alert, index) => {
            return (
              <AlertItem key={index + 'al'} title={alert.description}>
                {alert.title}
              </AlertItem>
            )
          })}
        </Alerts>
      </AlertsWrapper>
      <CloseAlerts onClick={close} title={'סגור'}></CloseAlerts>
    </RedAlertWrapper>
  )
}
export default RedAlert
