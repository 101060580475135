import { usePage } from '../../../contexts'
import { useFL } from '../../../hooks/useFL'
import { colors } from '../../../styles/GlobalVariables'
import { IArticlesGrid, IPost } from '../../../types'
import { addAnalyticsToUrl } from '../../../utils'
import Credits from '../../DateAndWriter/Credits'
import { Image } from '../../Image/Image'
import Link from '../../Link/Link'
import {
  BigPost,
  BigPostContent,
  BigPostSecondaryTitle,
  BigPostTitle,
  BigPostWrapper,
  Container,
  PostsContainer,
  SmallPost,
  SmallPostContent,
  SmallPostTitle,
  SmallPostsWrapper,
} from './DesktopBigFirst.styles'

type Props = {
  layout: IArticlesGrid
}
export const DesktopBigFirst = ({ layout }: Props) => {
  const { posts } = layout
  const bigPost = posts[0]
  const restPosts = posts.filter((_, index) => index > 0 && index < 4)
  const page = usePage()
  const { fontColor, isDarkTheme } = useFL()

  if (!page || !posts.length) {
    return null
  }

  if (posts.length < 2) {
    return null
  }

  return (
    <Container theme={{ isDarkTheme: isDarkTheme }}>
      <PostsContainer>
        <BigPostWrapper>
          <Link
            href={addAnalyticsToUrl(
              bigPost.link,
              page.PageMeta.id.toString(),
              layout.grid_id.toString()
            )}
          >
            <BigPost>
              <Image image={bigPost.imageObj} />
              <BigPostContent>
                <BigPostTitle theme={{ fontColor: fontColor }}>
                  {bigPost.title}
                </BigPostTitle>
                <BigPostSecondaryTitle theme={{ fontColor: fontColor }}>
                  {bigPost.secondaryTitle}
                </BigPostSecondaryTitle>
                <Credits
                  credits={bigPost}
                  color={isDarkTheme ? colors.steelBlue : colors.darkGray2}
                />
              </BigPostContent>
            </BigPost>
          </Link>
        </BigPostWrapper>
        <SmallPostsWrapper>
          {restPosts.length &&
            restPosts.map((post: IPost, id: number) => (
              <Link
                key={id}
                href={addAnalyticsToUrl(
                  post.link,
                  page.PageMeta.id.toString(),
                  layout.grid_id.toString()
                )}
              >
                <SmallPost>
                  <Image image={post.imageObj}></Image>
                  <SmallPostContent>
                    <SmallPostTitle theme={{ fontColor: fontColor }}>
                      {post.title}
                    </SmallPostTitle>
                    <Credits
                      credits={post}
                      color={isDarkTheme ? colors.steelBlue : colors.darkGray2}
                    />
                  </SmallPostContent>
                </SmallPost>
              </Link>
            ))}
        </SmallPostsWrapper>
      </PostsContainer>
    </Container>
  )
}
