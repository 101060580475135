import styled, { css } from 'styled-components'
import { device, layout } from '../../styles/GlobalVariables'

export const Wrapper = styled.div`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
  z-index: 1;
  background-image: url(${props => props.theme.mobile_background});
  background-size: cover;
  background-repeat: round;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${layout.gridBlockGap};
  height: 368px;

  &::before {
    background-color: rgba(0, 0, 0, 0.48);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  ${device.medium} {
    padding-right: 72px;
    background-image: url(${props => props.theme.desktop_background});
    gap: 20px;
    display: flex;
    flex-direction: row;
  }

  ${device.large} {
    background-image: url(${props => props.theme.desktop_background});
    width: 1290px;
    height: 325px;
    gap: 30px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  width: 370px;
  flex-direction: column;
  gap: 10px;
  height: 90px;
  padding: 0 20px 12px;
  justify-content: space-between;
  align-items: center;

  ${device.medium} {
    width: 700px;
    gap: 28px;
    height: 414px;
    padding: 0 20px 12px;
    justify-content: space-around;
    margin-right: 105px;
  }
`
export const TitleSubTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 72px;
  border-bottom: 2px solid #fff;
  padding-bottom: 0;
  border-radius: 0;
  width: 290px;

  ${device.medium} {
    flex-direction: row;
    top: -26px;
    right: 589px;
    border: none;
    width: 100%;
    align-items: baseline;
  }
`

export const SliderWrapper = styled.div`
  flex-grow: 0;
  background-color: transparent;
  background-image: none;
  width: 100%;
  position: relative;

  ${device.medium} {
    padding: 20px;
    padding-left: 90px;
    width: 350px;
    height: 325px;
    background-color: ${props => props.theme.background_color};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;

    &:after {
      content: '';
      line-height: 100px;
      font-size: 0;
      width: 0;
      height: 225px;
      border-top: 50px solid transparent;
      border-bottom: 325px solid ${props => props.theme.background_color};
      border-left: 80px solid transparent;
      border-right: 0 solid ${props => props.theme.background_color};
      position: absolute;
      bottom: 0;
      right: 350px;
    }

    & > div.swiper {
      width: 320px;
      height: 300px;
    }
  }
`

export const SingleSlideWrapper = styled.div`
  margin: 9px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 366px;
  gap: 20px;

  & img {
    width: 192px;
    height: 108px;
    flex-grow: 0;
    margin: 0 15px;
    border-radius: 8px;
  }

  ${device.medium} {
    padding: 1px;
    background-color: transparent;
    margin: 0;
    gap: 5px;
    justify-content: start;
    align-items: flex-start;

    & > a.textLink {
      width: 320px;
      z-index: 99999;
    }
  }
`

export const Title = styled.span`
  width: 86px;
  height: 78px;
  flex-grow: 0;
  font-family: Anton, serif;
  font-size: 65px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  ${device.medium} {
    width: 199px;
    height: 161px;
    margin: 8px 0 48px 17px;
    text-shadow: 0 0 35px #000, 0 0 43px rgba(0, 0, 0, 0.8);
    font-size: 134px;
    color: rgba(255, 255, 255, 0.35);
  }
`
export const SubTitle = styled.span`
  width: 58px;
  height: 29px;
  flex-grow: 0;
  font-family: 'OpenSansCondensed';
  font-size: 24px;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.48px;
  text-align: center;
  color: #fff;
  bottom: 10px;
  position: relative;

  ${device.medium} {
    width: 395px;
    height: 174px;
    flex-grow: 0;
    margin: 0 25px 0 0;
    text-shadow: 0 0 34.9px #000, 0 0 42.9px rgba(0, 0, 0, 0.8);
    font-family: 'OpenSansCondensed';
    font-size: 160px;
    font-weight: 800;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -4.8px;
    text-align: center;
    position: relative;
    top: 0;
    left: 20px;
    color: rgba(255, 255, 255, 0.25);
  }
`

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  direction: ltr;
  height: 12px;
  gap: 30px;
  position: absolute;
  bottom: 47px;

  ${device.medium} {
    gap: 78px;
    align-items: baseline;
    position: relative;
    top: 25px;
  }
`
export const CounterItem = styled.div`
  height: 50px;
  width: 41px;
  display: flex;
  flex-direction: column;
  align-items: normal;
  z-index: 1;

  ${device.medium} {
    display: flex;
    flex-direction: column;
    height: 141px;
    width: 87px;
    align-items: normal;
    z-index: 999;
  }
`

export const CounterItemText = styled.div`
  width: 23px;
  height: 16px;
  flex-grow: 0;
  margin: -21px 10px 0;
  font-family: 'OpenSansCondensed';
  font-weight: 600;
  font-size: 13px;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.34px;
  text-align: center;
  color: #fff;

  ${device.medium} {
    width: 56px;
    height: 36px;
    flex-grow: 0;
    font-family: 'OpenSansCondensed';
    font-size: 30px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
    text-align: center;
    color: #fff;
    position: relative;
    left: 8px;
    bottom: 7px;
  }
`
export const CounterItemDigit = styled.div`
  width: 41px;
  height: 50px;
  flex-grow: 0;
  margin: 0 0 17px;
  font-family: Anton, serif;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.84px;
  text-align: center;
  color: #fff;

  ${device.medium} {
    width: 87px;
    height: 106px;
    flex-grow: 0;
    font-family: Anton, serif;
    font-size: 88px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
`
export const Image = styled.img`
  width: 100vw;
  height: calc(100vw * 9 / 16); //198px
  background: transparent;
  margin-bottom: -12px;

  ${device.medium} {
    width: 335px;
    height: calc(335px * 9 / 16);
    margin: 80px 0 10px;
  }

}

${device.medium} {
  width: 208px;
  height: 117px;
  margin: 0 0 4px 128px;
  border-radius: 8px;
  background-color: #d9d9d9;
}

`
export const Name = styled.div`
  height: 34px;
  font-family: 'OpenSansCondensed';
  font-size: 20px;
  font-weight: 600;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.4px;
  text-align: center;
  color: #fff;
  padding: 5px 15px;
  margin: 0 96px;
  background-color: #810a0b;
  position: relative;
  bottom: 17px;
  width: auto;
  border-radius: 3px;
  left: 0;
  //overflow: hidden;

  ${device.medium} {
    text-align: right;
    font-size: 41px;
    letter-spacing: -1.23px;
    font-family: 'OpenSansCondensed';
    font-weight: 300;
    font-stretch: condensed;
    height: 49px;
    background-color: transparent;
    width: 40px;
    left: 80px;
    bottom: 0;
    text-wrap: nowrap;
    font-style: normal;
    text-indent: -15px;
    border-bottom: 2px solid #55bdff;
    padding-bottom: 70px;
    border-radius: 0;
  }
`

export const Text = styled.div`
  font-family: 'OpenSansCondensed';
  font-weight: 600;
  font-size: 17px;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: -0.34px;
  color: #fff;
  text-align: center;
  padding: 0 15px;
  position: relative;
  bottom: 189px;
  height: 42px;
  overflow: hidden;
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${device.medium} {
    font-family: 'OpenSansCondensed';
    font-size: 19px;
    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.38px;
    text-align: right;
    padding: 0;
    margin-top: 45px;
    bottom: 28px;
    height: 90px;
    right: 15px;
    width: 98%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`

export const ImageNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${device.medium} {
    align-items: start;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 10%;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  width: 95%;

  ${device.medium} {
    top: 50px;
    right: -30px;
    width: 119%;
  }
`
export const SwiperButton = styled.div`
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  background-size: contain;

  ${props => {
    switch (props.theme.role) {
      case 'prev':
        return css`
          background-image: url(https://media.reshet.tv/image/upload/v1699870921/uploads/2023/903801371.png);
          ${device.medium} {
            background-image: url(https://media.reshet.tv/image/upload/v1699870921/uploads/2023/903801371.png);
          }
        `
      case 'next':
        return css`
          background-image: url(https://media.reshet.tv/image/upload/v1699877978/uploads/2023/903801690.png);
          right: 5.5%;
          position: relative;
          ${device.medium} {
            background-image: url(https://media.reshet.tv/image/upload/v1699877978/uploads/2023/903801690.png);
            right: 0;
          }
        `
      default:
        return ''
    }
  }}
`
