import styled from 'styled-components'

export const MobileComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`
export const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Post = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: flex-start;
  align-items: flex-start;

  & img {
    width: 42.67vw;
    height: 100px;
    flex-shrink: 0;
    &:active {
      filter: brightness(80%);
    }
  }
`

export const PostContent = styled.div`
  color: ${props => props.theme.fontColor};
  display: flex;
  width: 100%;
  padding-right: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  > span {
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
  }
`

export const PostTitle = styled.div`
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.fontColor};
  max-height: 60px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
