export interface IGetRailConfig {
  bigPost?: boolean | undefined
}
export interface IGetReelsConfig {
  playButtonName: string | undefined
  bigPost?: boolean | undefined
}

export const getEpisodesPostConfig = ({ bigPost }: IGetRailConfig) => {
  return {
    media: {
      fontSizeMobile: '16',
    },
    desktop: {
      flexDirection: bigPost ? 'column' : 'row',
      marginLeft: bigPost ? '' : '28',
      imageWidth: bigPost ? '100' : '200',
      imageHeight: bigPost ? '416' : '120',
      imageWidthMobile: bigPost ? '100' : '143',
      marginTop: bigPost ? '16' : '',
      marginRight: bigPost ? '' : '16',
      gap: bigPost ? '8' : '',
      titleWidth: bigPost ? '' : '172',
      fontSizeDesktop: bigPost ? '28' : '18',
      fontSizeMobile: bigPost ? '24' : '16',
      fontWeight: bigPost ? '700' : '500',
      lineHeight: bigPost ? '28' : '20',
      DateFontSizeDesktop: '18',
    },
  }
}
export const getPlayButtonConfig = ({
  bigPost,
  playButtonName,
}: IGetReelsConfig) => {
  switch (playButtonName) {
    case 'episodes':
      return {
        media: {
          playTop: bigPost ? '50' : '',
          playLeft: bigPost ? '50' : '',
          playBottom: !bigPost ? '10' : '',
          playRight: !bigPost ? '4' : '',
          playWidth: bigPost ? '40' : '24',
          playHeight: bigPost ? '40' : '24',
          transformFirst: bigPost ? '-50' : '',
          transformSecond: bigPost ? '-50' : '',
        },
        desktop: {
          playTop: bigPost ? '50' : '',
          playLeft: bigPost ? '50' : '',
          playBottom: !bigPost ? '8' : '',
          playRight: !bigPost ? '8' : '',
          playWidth: bigPost ? '80' : '24',
          playHeight: bigPost ? '80' : '24',
          transformFirst: bigPost ? '-50' : '',
          transformSecond: bigPost ? '-50' : '',
        },
      }
    case 'reels':
      return {
        desktop: {
          playWidth: '40',
          playHeight: '40',
        },
      }
    default:
      return null
  }
}
//////
// import styled from 'styled-components'
// import {device} from '../../styles/GlobalVariables'
// import PlayButton from '../../../public/assets/images/svg/play-logo-episodes.svg'
//
// export const RelativeDiv = styled.div`
//   ${props =>
//     props.theme.isAdmin
//         ? `
//   position: relative;
//       &:hover{
//         [class*='StyledBtn']{
//           opacity: .5;
//           &:hover{
//             opacity: 1;
//           }
//         }
//     }
//   `
//         : ''}
// `
//
// export const HiddenInput = styled.input``
//
// export const ControlsWrap = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   padding: 5px;
//   flex-direction: row-reverse;
//   display: inline-flex;
//   flex-wrap: wrap;
//   gap: 5px;
// `
//
// export const StyledBtn = styled.div`
//   width: 30px;
//   height: 30px;
//   background-position: center;
//   background-size: cover;
//
//   background-image: url(${props =>
//     props.theme.icon === 'link'
//         ? 'https://media.reshet.tv/image/upload/v1662367203/assets/2022/CopyLink_Icon.png'
//         : 'https://media.reshet.tv/image/upload/v1662367899/assets/2022/CopyImage_Icon.png'});
//   background-repeat: no-repeat;
//   opacity: 0.1;
//   transition: opacity 0.3s ease;
// `
//
// export const MobileImage = styled.img`
//   ${device.medium} {
//     display: none;
//     min-height: inherit;
//     max-height: inherit;
//   }
// `
//
// export const DesktopImage = styled.img`
//   display: none;
//
//   ${device.medium} {
//     display: inherit;
//     min-height: inherit;
//     max-height: inherit;
//   }
// `
// export const StyledPlayButtonDesktop = styled(PlayButton)<{
//     playWidth: string
//     playHeight: string
//     playTop: string
//     playLeft: string
//     playBottom: string
//     playRight: string
//     transformFirst: string
//     transformSecond: string
// }>`
//   display: none;
//
//   ${device.medium} {
//     position: absolute;
//     display: block;
//     top: ${props => props.playTop}%;
//     right: ${props => props.playRight}px;
//     left: ${props => props.playLeft}%;
//     bottom: ${props => props.playBottom}px;
//     transform: translate(${props => props.transformFirst}%,
//     ${props => props.transformSecond}%);
//     width: ${props => props.playWidth}px;
//     height: ${props => props.playHeight}px;
//   }
// `
// export const StyledPlayButtonMobile = styled(PlayButton)<{
//     playWidth: string
//     playHeight: string
//     playTop: string
//     playLeft: string
//     playBottom: string
//     playRight: string
//     transformFirst: string
//     transformSecond: string
// }>`
//   ${device.medium} {
//     display: none;
//   }
//
//   @media screen and (max-width: 768px) {
//     position: absolute;
//     display: block;
//     top: ${props => props.playTop}%;
//     right: ${props => props.playRight}px;
//     left: ${props => props.playLeft}%;
//     bottom: ${props => props.playBottom}px;
//     transform: translate(${props => props.transformFirst}%,
//     ${props => props.transformSecond}%);
//     width: ${props => props.playWidth}px;
//     height: ${props => props.playHeight}px;
//   }
// `
// export const PlayTextContainer = styled.div`
//   ${device.medium} {
//     height: 40px;
//     width: 336px;
//     color: #FFF;
//     max-height: 2em;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     font-family: "Narkiss Block RESHET";
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 500;
//   }
//
//   @media screen and (max-width: 768px) {
//     /* Add styles for smaller screens if needed */
//   }
// `
// export const PlayContainer = styled.div`
//   ${device.medium} {
//     position: absolute;
//     bottom: 16px;
//     right: 26px;
//     display: flex;
//     flex-direction: row;
//     gap: 8px;
//   }
//
//   @media screen and (max-width: 768px) {
//     /* Add styles for smaller screens if needed */
//   }
// `
//
