import styled from 'styled-components'
import { colors, device, host, maxWidth } from '../../styles/GlobalVariables'
import { RelativeDiv } from '../Image/Image.styles'
import Link from '../Link/Link'

export const Container = styled.div<{
  imageHeight?: number
  headerHeight?: number
}>`
  position: relative;
  height: 100vh;
  width: 100vw;
  margin-left: -20px;
  margin-right: -20px;
  ${({ headerHeight }) =>
    headerHeight && {
      marginTop: `-${headerHeight}px`,
    }}

  ${host.nativeApp} & {
    width: 100vw !important;
  }

  ${device.medium} {
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    margin-bottom: 0;
    ${({ imageHeight }) => imageHeight && `height: ${imageHeight * 0.77}px;`}

    > img {
      /* display: block; */
      opacity: 0;
    }
  }

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${({ imageHeight }) =>
      imageHeight ? `${imageHeight}px` : '100vh'};
    background-color: ${colors.tvGuideDark};
    z-index: -2;
  }
`

export const SwiperImage = styled.section<{
  fadeOut: boolean
  opacity: number
  imageHeight?: number
}>`
  width: inherit;
  height: ${({ imageHeight }) =>
    imageHeight ? `${imageHeight}px` : 'inherit'};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  transition: opacity 0.5s;
  z-index: -1;

  opacity: ${props => (props.fadeOut ? 0 : props.opacity)};

  ${props =>
    props.fadeOut && {
      opacity: 0,
    }}

  .swiper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .swiper-image {
    .swiper-slide-visible.swiper-slide-active {
      z-index: 1;
      background: ${colors.black};
    }

    .swiper-slide {
      z-index: 0;
    }
  }
`

export const SwiperContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  img,
  ${RelativeDiv}, ${RelativeDiv} > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    ${device.medium} {
      width: 100%;
      height: auto;
    }
  }
`

export const HorizontalOverlay = styled.div`
  display: none;
  ${device.small} {
    display: block;
    position: absolute;
    top: 0;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to left,
      #0c0c0c 7%,
      rgba(12, 12, 12, 0) 43%
    );
  }
`

export const SwiperLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .swiper {
    position: relative;
    height: 100%;
    left: 0;
  }

  .swiper-slide {
    position: relative;
    visibility: hidden;
  }

  .swiper-slide-active {
    visibility: visible;
  }

  .swiper-pagination {
    bottom: 51px;

    ${device.medium} {
      bottom: unset;
      top: 91%;
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 8px;
    object-fit: contain;
    opacity: 0.5;
    background-color: #c4c4c4;
  }

  .swiper-pagination-bullet-active {
    background-color: ${colors.white};
    margin: 0 0 0 8px;
    opacity: 1;
  }

  ${SwiperContainer} {
    ${device.medium} {
      margin: 0 auto;
      max-width: ${maxWidth.medium};
    }

    ${device.large} {
      max-width: ${maxWidth.large};
    }
  }
`

export const TextContainer = styled.div`
  position: absolute;
  bottom: 115px;
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  padding-right: 26px;
  color: ${colors.white};

  ${device.medium} {
    padding-right: 0;
    bottom: unset;
    top: 44%;
  }
`

export const ShowName = styled.p`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 4px;

  ${device.medium} {
    font-size: 60px;
    margin-bottom: 5px;
  }
`

export const Title = styled.p`
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  margin-bottom: 12px;
`

export const SubTitle = styled.p`
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;

  ${device.medium} {
    margin-bottom: 32px;
  }
`

export const Button = styled(Link)`
  height: 35px;
  width: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.pink};
  color: ${colors.white};
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;

  ${device.medium} {
    width: 173px;
    height: 49px;
    font-size: 24px;
  }
`

export const StyledLink = styled(Link)`
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  ${device.medium} {
    font-size: 17px;
  }
  font-weight: 600;
  color: ${colors.pink};
`
