import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Genre = styled(Link)<{ backgroundColor: string }>`
  flex-grow: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  object-fit: contain;
  border-radius: 4px;
  ${props =>
    props.backgroundColor && {
      backgroundColor: props.backgroundColor,
    }}
  color: ${colors.white};
  font-size: 22px;
  font-weight: 500;
  line-height: 1;

  ${device.medium} {
    font-weight: 600;
    font-size: 30px;
    line-height: normal;
  }
`

export const Container = styled.div`
  margin-bottom: 30px;
`

export const SwiperSection = styled.div`
  color: ${colors.white};
  position: relative;

  .swiper {
    z-index: auto;
  }

  ${device.medium} {
    .swiper-slide {
      :nth-last-child(2) {
        ${Genre} {
          background-color: ${colors.green};
        }
      }

      :nth-last-child(3) {
        ${Genre} {
          background-color: ${colors.pink};
        }
      }

      :nth-last-child(4) {
        ${Genre} {
          background-color: ${colors.yellow};
        }
      }
    }
  }

  .swiper-slide {
    pointer-events: none;
    width: 243px;

    ${device.medium} {
      width: 296px;
      transform: translate(44px);
    }

    ${Genre} {
      padding: 23.5px 0;
      margin: 10px 4px;
      ${device.medium} {
        width: unset;
        margin: 10px 4px;
        padding: 36.5px 20px;
      }
    }
  }

  .swiper-slide-active {
    pointer-events: all;

    ${Genre} {
      margin: 0 4px;
      padding: 33.5px 20px;

      ${device.medium} {
        width: unset;
        margin: 0 4px;
        padding: 46.5px 20px;
      }
    }

    ${device.medium} {
      width: 349px;
    }
  }

  .swiper-slide-prev {
    ${device.medium} {
      pointer-events: all;
    }
  }

  .swiper-slide-next {
    ${device.medium} {
      pointer-events: all;
    }
  }

  .swiper-button-prev::after {
    visibility: hidden;

    ${device.medium} {
      visibility: visible;
    }

    /* font-size: 1rem; */
    background-image: url('/assets/images/svg/arrow-right-grey.svg');
    height: 22px;
    width: 12px;
    content: ' ';
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }

  .swiper-button-next::after {
    visibility: hidden;

    ${device.medium} {
      visibility: visible;
    }
    /* font-size: 1rem; */
    height: 22px;
    width: 12px;
    content: ' ';
    background-image: url('/assets/images/svg/arrow-left-grey.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
`

export const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NavigationOverlay = styled.div<{ position: 'left' | 'right' }>`
  background-image: ${props =>
    props.position === 'left'
      ? `linear-gradient(89deg, ${colors.tvGuideDark} 3%, rgba(18, 18, 18, 0) 100%);`
      : `linear-gradient(271deg, ${colors.tvGuideDark} 3%, rgba(18, 18, 18, 0) 100%);`};

  width: 17.5%;
  ${device.medium} {
    width: 300px;
  }
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
  ${props => (props.position === 'left' ? 'left: -2px;' : 'right: -2px;')}

  .swiper-button-disabled {
    display: none;
  }
`
