import styled from 'styled-components'
import { device } from '../../../styles/GlobalVariables'
import { Post } from '../../Post/Post'
import { SecondaryTitle, TextContainer, Title } from '../../Post/Post.styles'

export const Container = styled.div`
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const StyledPost = styled(Post)`
  ${SecondaryTitle} {
    display: none;

    ${device.medium} {
      display: -webkit-box;
    }
  }

  &:first-child {
    ${SecondaryTitle} {
      display: none;

      ${device.medium} {
        display: -webkit-box;
      }
    }

    ${device.medium} {
      ${Title} {
        -webkit-line-clamp: 2;
      }
    }
  }

  ${Title} {
    ${device.medium} {
      -webkit-line-clamp: 2;
      margin-bottom: 6px;
      font-size: 20px;
    }
  }

  ${TextContainer} {
    ${device.medium} {
      gap: 0;
    }
  }
`

export const PostContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${device.medium} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  gap: 19px;

  ${device.medium} {
    gap: 30px 20px;
  }

  ${device.large} {
    gap: 30px 30px;
  }
`

export const Poster = styled.div`
  display: none;

  ${device.medium} {
    display: block;
    grid-row: 1 / 3;
    grid-column: -1 / -2;
    width: 300px;
    height: 600px;
  }
`
