import { usePage } from '../../contexts'
import { IPost } from '../../types'
import { Image } from '../Image/Image'
import { utils } from '../Player/Helpers/utils'
import { Container, Thumbnail, Title } from './Post.styles'

type Props = {
  post: IPost
  height?: string
  className?: string
  fontColor?: string
  grid_id?: number
}

export const Post = ({ post, className, fontColor, grid_id }: Props) => {
  const { imageObj, title, isBlank, link: postLink } = post
  const linkTarget = isBlank ? '_blank' : undefined
  const page = usePage()
  const pageId = page?.PageMeta.id
  const queryParams = utils.addPageQueryParams(postLink, pageId, grid_id)
  const link = postLink + queryParams
  return (
    <Container className={className}>
      <Thumbnail href={link} target={linkTarget}>
        <Image image={imageObj} alt={title} />
      </Thumbnail>
      <Title fontColor={fontColor}>{title}</Title>
    </Container>
  )
}
