import styled from 'styled-components'
import { colors } from '../../../styles/GlobalVariables'

export const ComponentContainer = styled.div`
  & img {
    width: 143px;
    height: 81px;
    margin-bottom: 8px;
    &:active {
      filter: brightness(80%);
    }
  }
`

export const Post = styled.div`
  display: flex;
  width: 143px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
`

export const PostTitle = styled.div`
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.32px;
  color: ${props => props.theme.fontColor};
`

export const PostCredit = styled.div`
  color: ${props =>
    props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2};
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`
