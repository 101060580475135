import { usePage } from '../../../contexts'
import { useFL } from '../../../hooks/useFL'
import { IArticlesGrid, IPost } from '../../../types'
import { addAnalyticsToUrl } from '../../../utils'
import Credits from '../../DateAndWriter/Credits'
import { Image } from '../../Image/Image'
import Link from '../../Link/Link'
import {
  MobileComponentWrapper,
  Post,
  PostContent,
  PostTitle,
} from './MobileNoSwiperSmall.styles'

type Props = {
  layout: IArticlesGrid
}
export const MobileNoSwiperSmall = ({ layout }: Props) => {
  const { posts } = layout
  const page = usePage()
  const { fontColor, isDarkTheme } = useFL()

  if (!page || !posts.length) {
    return null
  }

  return (
    <MobileComponentWrapper theme={{ isDarkTheme: isDarkTheme }}>
      {posts.length &&
        posts.slice(0, 4).map((post: IPost, id: number) => (
          <Link
            key={id}
            href={addAnalyticsToUrl(
              post.link,
              page.PageMeta.id.toString(),
              layout.grid_id.toString()
            )}
          >
            <Post>
              <Image image={post.imageObj} />
              <PostContent>
                <PostTitle theme={{ fontColor: fontColor }}>
                  {post.title}
                </PostTitle>
                <Credits
                  credits={post}
                  color={isDarkTheme ? '#9592A5' : '#5B5B5B'}
                />
              </PostContent>
            </Post>
          </Link>
        ))}
    </MobileComponentWrapper>
  )
}
