import { IContestants } from '../../types'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { Image } from '../Image/Image'
import {
  Container,
  ContestantProfile,
  ContestantsContainer,
  ImageLink,
  ProfileTitle,
} from './Contestants.style'

type Props = {
  contestants: IContestants
}

export const Contestants = ({ contestants }: Props) => {
  const { grid_title, posts } = contestants

  return (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <ContestantsContainer>
        {posts.map(post => {
          const { id, imageObj, title, link, isBlank } = post
          if (!imageObj) {
            return null
          }
          return (
            <ContestantProfile key={id}>
              <ImageLink
                href={link || undefined}
                target={isBlank ? '_blank' : '_self'}
              >
                <Image image={imageObj} />
              </ImageLink>
              {title && <ProfileTitle>{title}</ProfileTitle>}
            </ContestantProfile>
          )
        })}
      </ContestantsContainer>
    </Container>
  )
}
