import { useEffect, useState } from 'react'
import { clientApi } from '../../../client/clientApi'
import { he } from '../../../locales/he'
import { IHorizontalNewsFlashItem, INewsflash } from '../../../types'
import { DateTime } from '../../../utils'
import { Marquee } from '../../Marquee/Marquee'
import {
  Container,
  CurrentTime,
  HorizontalMarqueeContainer,
  StyledLinkHorizontalMarqueeHeader,
  StyledLinkNewsflashesItem,
  StyledLinkNewsflashesText,
  Title,
} from './HorizontalNewsFlashes.styles'

type NewsFlashesSpeedProps = {
  newsflashHorizontalSpeed: number
  newsflashVerticalSpeed: number
}

interface Props {
  newsFlashes: IHorizontalNewsFlashItem
}

const HorizontalNewsFlashes = ({ newsFlashes }: Props) => {
  const { newsFlashArr, newsFlashColor } = newsFlashes
  const [newsflashes, setNewsflashes] = useState<INewsflash[] | undefined>(
    newsFlashArr
  )

  const [newsFlashesSpeed, setNewsFlashesSpeed] =
    useState<NewsFlashesSpeedProps>({
      newsflashHorizontalSpeed: 40,
      newsflashVerticalSpeed: 20,
    })

  useEffect(() => {
    const fetchNews = async () => {
      if (unmounted) {
        return
      }
      const newsFlashesSpeed = await clientApi.getNewsFlashesSpeed()
      setNewsFlashesSpeed(newsFlashesSpeed)
      const news = await clientApi.getNewsflashes()
      setNewsflashes(news)
    }
    let unmounted = false

    if (!newsFlashArr || newsFlashArr.length === 0) {
      fetchNews()
      return () => {
        unmounted = true
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const duplicateDate = (newsflashes: INewsflash[] | undefined) => {
    let news = newsflashes

    if (!news || !news.length) {
      return
    }

    while (news.length < 6) {
      news = news.concat(news)
    }

    return news
  }

  const newsflashesPageUrl = '/news/news-flash'

  const renderNewsflashes = () => {
    const news = duplicateDate(newsflashes)
    return (
      news?.slice(0, 8).map((item, index) => (
        <StyledLinkNewsflashesItem
          key={`${index}-${item.id}`}
          href={newsflashesPageUrl}
          newsFlashColor={newsFlashColor}
        >
          <time>{DateTime.parse(item.time)?.toTime()}</time>
          <StyledLinkNewsflashesText>{item.text}</StyledLinkNewsflashesText>
        </StyledLinkNewsflashesItem>
      )) ?? []
    )
  }

  return (
    <Container hideOnMobile={true} width={1300}>
      <HorizontalMarqueeContainer>
        <StyledLinkHorizontalMarqueeHeader
          href={newsflashesPageUrl}
          backgroundColor={'#f6f6f6'}
        >
          <Title color={newsFlashColor}>{he.newsflashes}</Title>
          <CurrentTime>{DateTime.localNowToFormattedDate()}</CurrentTime>
        </StyledLinkHorizontalMarqueeHeader>
        <Marquee
          speed={newsFlashesSpeed.newsflashHorizontalSpeed}
          directionReverse={false}
          axis={'X'}
          pauseOnHover={true}
          height={'100px'}
          width={'1300px'}
          align='center'
        >
          {renderNewsflashes()}
        </Marquee>
      </HorizontalMarqueeContainer>
    </Container>
  )
}

export default HorizontalNewsFlashes
