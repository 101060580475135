import styled from 'styled-components'
import { colors, device, hideScrollbar } from '../../styles/GlobalVariables'
import { SecondaryTitle } from '../Post/Post.styles'
import { VoteType } from './Opinions'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;

  ${device.medium} {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: repeat(2, 289px);
    gap: 20px;
  }

  ${device.large} {
    gap: 30px;
  }
`

export const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;

  > ${SecondaryTitle} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1.2;
    font-size: 16px;
    max-height: calc(1.2 * 2 * 16px);
  }

  ${device.medium} {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: repeat(2, 289px);
    gap: 30px 20px;
  }

  ${device.large} {
    gap: 30px 30px;
  }
`

export const OpinionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 16px;
  background-color: #e7e7e7;
  margin: 0 -20px;
  position: relative;
  overflow: hidden;

  ${device.medium} {
    margin: 0;
    grid-column: 4 / 5;
    grid-row: 1 / 3;
  }

  :before {
    display: none;

    ${device.medium} {
      display: block;
      content: '';
      position: absolute;
      width: 423.48px;
      height: 443.81px;
      left: -0.21px;
      top: 164px;

      background: url('/assets/images/svg/opinions-background.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform: rotate(10.49deg);
      transform-origin: top left;
    }
  }
`

export const Header = styled.div`
  min-height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-image: linear-gradient(98deg, #00104a 0%, #001a7a 100%);

  > svg {
    font-size: 39px;
  }

  ${device.medium} {
    background-image: linear-gradient(98deg, #00104a 0%, #001a7a 100%);
  }
`
export const LogoContainer = styled.div`
  width: 100%;
  height: 52px;
  transform: rotate(360deg);
  background-image: url(https://media.reshet.tv/image/upload/v1685611367/uploads/2023/903546672.png);
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
`

export const Title = styled.h2`
  font-size: 31px;
  font-weight: bold;
  line-height: 1.2;
  color: ${colors.white};
  position: relative;
  left: 7px;
  top: 2px;
`

export const Subtitle = styled.span`
  font-size: 31px;
  font-weight: normal;
  line-height: 1.2;
  color: ${colors.white};
`

export const OwnersContainer = styled.div`
  position: relative;

  .swiper {
    position: relative;

    .swiper-wrapper {
      box-sizing: border-box;
    }

    .swiper-slide {
      width: 68px;
      margin-left: 8px;
      :first-child {
        margin-right: 20px;
      }
      :last-child {
        margin-left: 20px;
      }

      ${device.medium} {
        width: 58.8px;
        :first-child {
          margin-right: 8px;
        }
        :last-child {
          margin-left: 8px;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
      ${device.medium} {
        display: block;
        width: 23px;
        height: 23px;
        background-size: cover;
        border-radius: 50%;
        box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.4);
        position: absolute;
        top: calc(50% - 8px);

        :after {
          content: '';
        }
      }
    }

    .swiper-button-next {
      left: 6px;
      background: url('/assets/images/svg/opinions-arrow-left.svg') center
        no-repeat;
    }

    .swiper-button-prev {
      right: 6px;
      background: url('/assets/images/svg/opinions-arrow-right.svg') center
        no-repeat;
    }

    .swiper-button-disabled {
      display: none;
    }
  }
`

export const Owners = styled.div`
  padding: 0 20px;
  overflow-x: scroll;
  display: flex;
  gap: 8px;

  ${hideScrollbar}
`

export const Owner = styled.span<{ selected: boolean }>`
  display: flex;
  flex-basis: 68px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};

  > img {
    border: solid 2px
      ${({ selected }) => (selected ? colors.blueOpinion : colors.black)};
    border-radius: 50%;

    padding: 3px;
    border-radius: 50%;
    min-width: 68px;
    max-width: 68px;
    min-height: 68px;
    max-height: 68px;
    margin: 0;
    object-fit: cover;

    ${device.medium} {
      flex-basis: 58.8px;
      min-width: 58.8px;
      max-width: 58.8px;
      min-height: 58.8px;
      max-height: 58.8px;
    }
  }

  > h3 {
    color: ${({ selected }) => (selected ? colors.blueOpinion : colors.black)};
    font-size: 12px;
    font-weight: bold;
    max-width: 68px;
    text-align: center;

    ${device.medium} {
      max-width: 58.8px;
    }
  }

  ${device.medium} {
    flex-basis: 58.8px;
  }
`

export const Opinion = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 20px 16px 12px;
  border-radius: 13px;
  margin: 0 20px;
  gap: 8px;

  > h3 {
    align-self: center;
    font-size: 16px;
    font-weight: bold;
  }

  ${device.medium} {
    margin: 0 8px;
    padding: 16px 16px 24px;
    /* This is to keep the background in the background */
    z-index: 1;
  }
`

export const OpinionDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;

  > h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.blueOpinion};

    ${device.medium} {
      font-size: 16px;
    }
  }

  > span {
    font-size: 13px;
    color: #949494;
  }
`

export const Content = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 18px;

  overflow: hidden;

  > figure {
    > img {
      display: none;

      ${device.medium} {
        display: block;
        max-height: 172px;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  > p {
    display: none;

    :first-child {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.2;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${({ hasImage }) => (hasImage ? 5 : 14)};
      -webkit-box-orient: vertical;

      ${device.medium} {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.2;
      }
    }
  }

  ${device.medium} {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 300;
  }
`

export const Yes = styled.span``

export const No = styled.span``

export const Voting = styled.div<{ voted: boolean; vote?: VoteType }>`
  align-self: center;
  display: flex;
  padding: 13px 16px 0;
  gap: 20px;

  > span {
    position: relative;
    height: 47px;
    width: 47px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ voted }) => (voted ? 'default' : 'pointer')};

    > svg {
      font-size: 26px;
    }
  }

  ${Yes} {
    ${({ voted }) => !voted && 'background: #3089dc;'}
    ${({ voted, vote }) => voted && vote === 'like' && 'background: #3089dc;'}
        ${({ voted, vote }) =>
      voted && vote === 'unlike' && 'background: #b7b7b7;'}
  }

  ${No} {
    ${({ voted }) => !voted && 'background: #f6173a;'}
    ${({ voted, vote }) => voted && vote === 'like' && 'background: #b7b7b7;'}
        ${({ voted, vote }) =>
      voted && vote === 'unlike' && 'background: #f6173a;'}
  }
`

export const Percentage = styled.span<{ isThumbsUp?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.black};
  background: #eaeaea;
  border-radius: 50%;

  position: absolute;
  top: -13px;
  ${({ isThumbsUp }) => (isThumbsUp ? 'right: -16px;' : 'left: -16px;')};

  font-size: 14px;
  font-weight: 500;
`
