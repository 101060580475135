import styled from 'styled-components'
import { colors, device } from '../../../styles/GlobalVariables'
import Link from '../../Link/Link'

export const Container = styled.div`
  margin-bottom: 40px;
  padding: 16px 0;
  border-top: 1px solid ${colors.steelBlue};
  border-bottom: 1px solid ${colors.steelBlue};
`

export const ArticleTagsContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-line-clamp: 1;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  max-height: 17px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${device.medium} {
    overflow: hidden;
    overflow-x: hidden;
    white-space: unset;
    font-size: 18px;
  }

  a {
    color: ${colors.darkGray2};
  }
`

export const Tag = styled(Link)<{ hoverColor: string }>`
  font-size: 16px;
  font-weight: 300;
  margin-right: 16px;

  ${device.medium} {
    margin-right: 48px;
    font-size: 18px;
  }

  &:hover {
    color: ${props => props.hoverColor};
  }
`
