import React, { useEffect, useState } from 'react'
import { usePage } from '../../contexts'
import { ITermHeadBlockFl } from '../../types'
import { Image } from '../Image/Image'
import { Player } from '../Player/Player'
import {
  Container,
  Content_FlContainer,
  PlayerImagContainer,
  SecondaryTitle,
  TextContainer,
  Thumbnail,
  Title,
} from './TermHeadBlockFl.styles'
import { ShareButtons } from '../ShareButtons/ShareButtons'

type Props = {
  termHead: ITermHeadBlockFl
}

export const TermHeadBlockFl = ({
  termHead: { imageObj, video, title, subTitle },
}: Props) => {
  const pageMeta = usePage()?.PageMeta
  const [showVideo, setShowVideo] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const providedPage = usePage()

  useEffect(() => {
    setShowVideo(false)
    setTimeout(() => setShowVideo(true), 250)
  }, [video])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!providedPage?.SEO) {
    return null
  }

  return (
    (video || imageObj || title) && (
      <Container>
        <PlayerImagContainer>
          {video && showVideo ? (
            pageMeta && (
              <Player pageMeta={pageMeta} video={video} img={video.poster} />
            )
          ) : (
            <Thumbnail>
              <Image image={imageObj} alt={title} />
            </Thumbnail>
          )}
        </PlayerImagContainer>
        <Content_FlContainer>
          {title && (
            <TextContainer>
              {isMobile ? (
                <>
                  <Title fontColor={pageMeta?.themed.font_color}>{title}</Title>
                  <ShareButtons
                    SEO={providedPage?.SEO}
                    isFaceLift={true}
                    size={'16px'}
                  />
                  <SecondaryTitle
                    dangerouslySetInnerHTML={{ __html: subTitle }}
                    fontColor={pageMeta?.themed.font_color}
                  />
                </>
              ) : (
                <>
                  <Title fontColor={pageMeta?.themed.font_color}>{title}</Title>
                  <SecondaryTitle
                    dangerouslySetInnerHTML={{ __html: subTitle }}
                    fontColor={pageMeta?.themed.font_color}
                  />
                  <ShareButtons
                    SEO={providedPage?.SEO}
                    isFaceLift={true}
                    size={'16px'}
                  />
                </>
              )}
            </TextContainer>
          )}
        </Content_FlContainer>
      </Container>
    )
  )
}
