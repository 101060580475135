import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { DesktopAdId, useHeader } from '../../contexts'
import { IPageSEO, ITimeLineElement } from '../../types'
import Ad from '../Ads/Ad'
import { parseHTML } from '../ContentParserFl/ContentParserFl'
import { EditMe } from '../EditMe'
import { ShareButtons } from '../ShareButtons/ShareButtons'
import { TimeBulletFL } from './TimeBulletFL'
import {
  Anchor,
  ItemContent,
  ItemSeparator,
  ItemTitle,
  MainItemWrapper,
  ShareButtonsMobileWrapper,
  ShareButtonsWrapper,
  StyledAdWrap,
  TimeLineItem,
} from './TimeLineFL.styles'
import { utils } from '../Player/Helpers/utils'

type TimeLineSubItemProps = {
  index: number
  adIndex: number
  isLast: boolean
  orderClassName: string
  SEO: IPageSEO
  el: [string, ITimeLineElement]
  data: {
    data: ITimeLineElement
    id: string
    key: string | number
  }
  color: string
}

export const TimeLineSubItemFL = ({
  orderClassName,
  adIndex,
  index,
  el,
  SEO,
  data: { data },
  color,
}: TimeLineSubItemProps) => {
  const anchorRef = useRef<HTMLDivElement>()
  const { headerHeight } = useHeader()
  const router = useRouter()
  useEffect(() => {
    const hash = router.asPath.split('#').pop()
    if (anchorRef.current && hash === data.id) {
      setTimeout(function () {
        if (anchorRef.current) {
          anchorRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          })
        }
      }, 1000)
    }
  }, [anchorRef, data.id, router])

  const cleanPageUrl = (url: string) => {
    const parsedUrl = new URL(url)
    parsedUrl.search = ''
    return parsedUrl.toString()
  }

  return (
    <React.Fragment key={'ti' + index}>
      <MainItemWrapper>
        <TimeLineItem theme={orderClassName}>
          <Anchor
            key={data.id}
            headerHeight={headerHeight}
            ref={anchorRef as React.RefObject<HTMLDivElement>}
            id={data.id}
          />
          <EditMe grid_id={el[1].id} anchorId={data.id}>
            <>
              <TimeBulletFL
                color={'#01DEFF'}
                order={orderClassName}
                date={data.createdOn}
              ></TimeBulletFL>
              <ItemTitle>{data.post_title}</ItemTitle>
              <ItemContent>
                {parseHTML({
                  html: data.post_content,
                  adMidArticle: false,
                  keyPrefix: 'tlparser',
                })}
              </ItemContent>
              {!utils.isMobile() ? (
                <ShareButtonsWrapper>
                  <ShareButtons
                    isFaceLift={true}
                    SEO={SEO}
                    size={'16px'}
                    anchor={'#' + data.id}
                  />
                </ShareButtonsWrapper>
              ) : (
                <ShareButtonsMobileWrapper
                  onClick={() => {
                    {
                      typeof window !== 'undefined' &&
                        navigator &&
                        navigator?.share &&
                        navigator
                          .share({
                            title: 'Title: ' + data.post_title,
                            url:
                              cleanPageUrl(window.location.href) +
                              '#' +
                              data.id,
                          })
                          .then(() => console.log('Successful share'))
                          .catch(() => console.log('Error sharing'))
                    }
                  }}
                >
                  <img
                    src='https://media.reshet.tv/image/upload/v1714889752/uploads/2024/904042569.png'
                    alt='שיתוף'
                  />
                  <div>שיתוף</div>
                </ShareButtonsMobileWrapper>
              )}
            </>
          </EditMe>
        </TimeLineItem>
        {utils.isMobile() && <ItemSeparator />}
      </MainItemWrapper>
      {adIndex == 1 && (
        <StyledAdWrap theme={{ color: color }}>
          <div className={'line'} />
          <Ad id={`timeline_box${1}` as DesktopAdId} collapse />
        </StyledAdWrap>
      )}
    </React.Fragment>
  )
}
