import styled from 'styled-components'
import { colors, device, hideScrollbar } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  margin: 0 20px 30px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* overflow-y: hidden; */

  ${device.medium} {
    margin: 0 0 30px;
  }

  ${hideScrollbar}
`

export const HorizontalOverlay = styled.div`
  position: absolute;
  content: '';
  top: 60px;
  left: 0;
  width: 160px;
  height: 310px;
  border-radius: inherit;
  background: linear-gradient(90deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
  z-index: 1;

  ${device.medium} {
    top: 107px;
    width: 194px;
    height: 361px;
    background: linear-gradient(
      88.17deg,
      #121212 28.6%,
      rgba(18, 18, 18, 0) 98.45%
    );
  }
`

export const ShowsContainer = styled.div`
  position: relative;
  display: flex;
  gap: 8px;

  ${device.medium} {
    gap: 16px;
  }
`

export const Show = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 175px;
  border-radius: 8px;
  position: relative;

  ${device.medium} {
    flex-basis: 203px;
  }
`

export const TodayAtReshet = styled.span`
  border-top-right-radius: inherit;
  background-color: ${colors.pink};
  color: ${colors.white};
  align-items: center;
  position: absolute;
  padding: 0 11px;
  display: flex;
  opacity: 0.85;
  height: 26px;
  z-index: 1;
  right: 0;
  top: 0;
`

export const PosterWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  position: relative;
  overflow-y: hidden;

  > img {
    border-radius: 8px;
    height: 310px;
    object-fit: cover;
    border-radius: inherit;

    ${device.medium} {
      height: 361px;
    }
  }

  :after {
    position: absolute;
    content: '';
    top: 0;
    /* for some reason, it renders in a weird way if it's bottom: 0; */
    bottom: -1px;
    left: 0;
    right: 0;
    border-radius: inherit;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 57%,
      rgba(0, 0, 0, 0.87) 79%,
      rgba(0, 0, 0, 0.95) 100%
    );
  }
`

export const TextContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 0 15px;
  top: 230px;
  width: 100%;

  ${device.medium} {
    top: 273px;
  }
`

export const Weekday = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.pink};
  margin-left: 8px;

  ${device.medium} {
    font-size: 23px;
  }
`

export const Hour = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.pink};

  ${device.medium} {
    font-size: 28px;
  }
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${colors.white};
  line-height: 1;
  max-height: 40px;
  overflow: hidden;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${device.medium} {
    font-size: 22px;
    max-height: 44px;
  }
`
