import dynamic from 'next/dynamic'
import { isNativeApp } from '../../client'
import { isHomepage } from '../../types'
import { usePage } from '../../contexts'
import { useEffect, useMemo, useState } from 'react'

export const sessionName = 'hide_bubble'
const BigBrotherBubbleHandler = () => {
  const [show, setShow] = useState(false)
  const BigBrotherBubble = useMemo(() => {
    return dynamic(() => import('./BigBrotherBubble'))
  }, [])
  const page = usePage()

  const { link, icon } = useMemo(() => {
    if (page?.SiteMeta.config?.features?.bb_bubble) {
      return page?.SiteMeta.config.features?.bb_bubble
    }
    return { link: '', icon: '' }
  }, [page])

  useEffect(() => {
    if (
      isNativeApp() &&
      page &&
      isHomepage(page) &&
      link &&
      icon &&
      !sessionStorage.getItem(sessionName)
    ) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [icon, link, page])

  if (show) {
    return (
      <BigBrotherBubble
        key='BigBrotherBubble'
        link={link}
        icon={icon}
        setShow={setShow}
      />
    )
  }

  return null
}
export default BigBrotherBubbleHandler
