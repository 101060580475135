import styled from 'styled-components'
import { colors, device, maxWidth } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 19px;

  ${device.medium} {
    margin-bottom: 28px;
    padding: 0;
    max-width: ${maxWidth.medium};
  }

  ${device.large} {
    max-width: ${maxWidth.large};
  }
`

export const PageContent = styled.div`
  display: flex;
  margin-top: 14px;
  justify-content: space-between;
  gap: 23px;
`

export const TaboolaContainer = styled.div`
  ${device.medium} {
    display: block;
  }
`

export const NewsflashContainer = styled.div`
  height: fit-content;
  flex: 1;
  ${device.medium} {
    margin-left: 35px;
    min-width: calc(100% - 370px);
  }

  ${device.large} {
    margin-left: 70px;
  }
`

export const TimelineWrapper = styled.div`
  display: flex;
  flex: 1;
  height: fit-content;
`

export const TextContainer = styled.div<{ hasLink: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 32px;

  p,
  a {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    color: ${colors.black};

    :hover {
      ${({ hasLink }) => hasLink && `color: ${colors.pink};`}
    }

    :after {
      ${({ hasLink }) => (hasLink ? 'content: " >>";' : 'content: "";')}
      color: ${colors.pink};
      display: inline;
    }
  }

  :after {
    ${device.medium} {
      padding-top: 32px;
      content: '';
      width: 100%;
      border-bottom: 1px solid ${colors.separatorGray};
    }
  }
`

export const Flash = styled(Link)`
  max-height: calc(1.2 * 3 * 20px);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.black};

  :hover {
    color: ${colors.pink};
    > span {
      color: ${colors.pink};
    }
  }

  ${device.medium} {
    -webkit-line-clamp: 2;
    max-height: calc(1.2 * 2 * 20px);
    width: 782px;
  }
`

export const LinkArrows = styled.span`
  color: ${colors.pink};

  ${device.medium} {
    color: ${colors.black};
  }
`

export const NewsFlashItem = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 60px;
  padding-right: 15px;
  width: 100%;
  row-gap: 4px;

  :last-child {
    ${TextContainer}:after {
      display: none;
    }
  }

  ${device.medium} {
    flex-direction: row;
  }
`

export const NewsFlashSpecialWidget = styled(NewsFlashItem)`
  position: relative;
  ${device.small} {
    display: flex;
    flex-direction: column;
  }

  :before {
    position: absolute;
    content: '•';
    top: 1.5px;
    letter-spacing: -5px;
    line-height: 22px;
    color: ${colors.darkBlue};
    right: -10px;
    font-size: 65px;
    color: #00104a;
    text-align: center;
  }

  :after {
    padding-top: 25px;
    content: '';
    width: 100%;
    ${device.medium} {
      border-bottom: 1px solid ${colors.separatorGray};
    }
  }
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  gap: 7px;

  span {
    line-height: 1;
    padding-top: 6px;
    padding-left: 9px;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.mediumGray};
  }

  :before {
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    content: '';
    height: 7px;
    width: 7px;
    right: -19px;
    top: 8px;
    background: ${colors.pink};

    ${device.medium} {
      height: 11px;
      width: 11px;
      right: -21px;
      top: 6px;
    }
  }

  ${device.medium} {
    gap: 0 9px;
    flex-direction: column;
    align-items: unset;
    flex-basis: 55px;
  }
`

export const Timeline = styled.div`
  content: '';
  border-right: 1px dashed ${colors.pink};
  margin-bottom: 65px;
`

export const FlashText = styled.div``

export const MivzakimLeftRail = styled.div`
  ${device.small} {
    max-width: 300px;
  }
`
