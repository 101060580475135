import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  margin-top: 20px;
  width: 100%;

  ${device.medium} {
    gap: 16px;
  }
`
