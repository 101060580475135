import { INewsflash } from '../types'
import { clientConfig } from './clientConfig'

export const clientApi = {
  getNewsflashes: async (): Promise<INewsflash[]> => {
    try {
      const response = await fetch(
        clientConfig.newsFlashQuerySource +
          '/wp-content/plugins/push-messages/pushMessages.json',
        clientConfig.newsFlashQuerySource !== ''
          ? {
              method: 'GET',
              headers: { 'Content-type': 'application/json' },
            }
          : {}
      )
      return response.json()
    } catch (e) {
      console.error(e)
    }
    return []
  },
  getNewsFlashesSpeed: async (): Promise<{
    newsflashHorizontalSpeed: number
    newsflashVerticalSpeed: number
  }> => {
    const response = await fetch('https://13tv.co.il/api_cache/config.json')
    const { newsflashHorizontalSpeed, newsflashVerticalSpeed } =
      await response.json()
    return {
      newsflashHorizontalSpeed: parseInt(newsflashHorizontalSpeed, 10),
      newsflashVerticalSpeed: parseInt(newsflashVerticalSpeed, 10),
    }
  },
}
