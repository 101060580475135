import styled from 'styled-components'
import { device } from '../../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h1`
  flex-grow: 0;
  font-size: 30px;
  line-height: 1.1;
  font-weight: bold;
  margin-bottom: 8px;

  ${device.medium} {
    font-size: 46px;
    line-height: 55px;
    margin-bottom: 20px;
  }
`

export const SubTitle = styled.h2`
  flex-grow: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 12px;

  ${device.medium} {
    font-size: 24px;
    font-weight: normal;
    line-height: 29px;
    margin-bottom: 20px;
  }
`
