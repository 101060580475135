import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

export const FaceLiftBlockSeparatorDiv = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.steelBlue};
  margin: 16px 0;

  ${device.medium} {
    margin: 40px 0;
  }
`
