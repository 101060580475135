import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

export const Text = styled.p`
  font-weight: bold;
  color: ${colors.darkBlue};
  font-size: 14px;
  line-height: 17px;
  ${device.medium} {
    font-size: 15px;
    line-height: 18px;
  }
`

export const Image = styled.img`
  width: 26px;
  margin-right: 5px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
`
