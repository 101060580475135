import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid
    ${props =>
      props.theme.isDarkTheme ? colors.darkBlueGray : colors.steelBlue};
  ${device.medium} {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  .swiper-slide {
    background: #fff;
  }
`

export const DesktopContainer = styled.div`
  display: none;
  ${device.medium} {
    display: block;
  }
`
export const MobileContainer = styled.div`
  display: block;
  ${device.medium} {
    display: none;
  }
`

export const PostsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  ${device.medium} {
    gap: 16px;
  }
`

export const PostContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 8px;
  gap: 8px;

  & img {
    &:active {
      filter: brightness(80%);
    }
  }

  ${device.medium} {
    display: flex;
    width: 308px;
    flex-direction: column;
    padding: 16px;
    height: 256px;
    gap: 8px;

    & img {
      width: 276px;
      height: 142px;
      &:hover {
        filter: brightness(80%);
      }
    }
  }
`

export const PostImage = styled.img`
  width: 100%;
  height: 88px;
`

export const PostTitle = styled.div`
  color: #000;
  text-align: right;
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;

  ${device.medium} {
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
