import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${device.medium} {
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;
    position: relative;
    z-index: 1;
  }
`
export const PlayerImagContainer = styled.div`
  ${device.medium} {
    width: 740px;
    height: 416px;
  }
`
export const Content_FlContainer = styled.div`
  ${device.medium} {
    width: 416px;
  }
`
export const Thumbnail = styled.div`
  display: flex;
  max-height: 211px;

  ${device.medium} {
    max-height: 354px;
  }

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${device.medium} {
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 16px;
  }
`
export const Title = styled.h1<{ fontColor?: string }>`
  font-size: 32px;
  font-weight: 400;
  line-height: 56px;
  color: ${props => props.fontColor ?? '#000'};
  font-family: Leon;
  position: relative;

  ${device.medium} {
    font-size: 56px;
  }

  @media only screen and (max-width: 768px) {
    border-bottom: 1px solid ${props => props.fontColor ?? '#000'};
  }
`

export const SecondaryTitle = styled.div<{ fontColor?: string }>`
  color: ${props => props.fontColor ?? '#000'};
  font-weight: 300;
  line-height: 20px;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.32px;

  ${device.medium} {
    width: 416px;
    font-size: 22px;
    line-height: 27px;
  }
`
