import { useFL } from '../../hooks/useFL'
import { IPaginationFl } from '../../types'
import { ArrowFl } from './ArrowFl'
import { Container, Dots, PagerButton, PagesWrap } from './PagerFl.styles'

type Props = {
  pager: IPaginationFl
}

const pageUrl = (page: number | string, base: string) =>
  base?.replace('%#%', page.toString())

export const PagerFl = ({ pager }: Props) => {
  const { isDarkTheme } = useFL()
  if (!pager) {
    return null
  }

  const { base, max_page } = pager
  const current_page: number | string =
    pager.current_page < 1 ? 1 : pager.current_page

  const pagerValues = getPagerValues(current_page, max_page)
  const pagerElements = pagerValues.map(page => {
    if (page === '...') {
      return (
        <Dots theme={{ isDarkTheme: isDarkTheme }} key={'p' + page}>
          {page}
        </Dots>
      )
    } else {
      return (
        <PagerButton
          theme={{ isDarkTheme: isDarkTheme }}
          key={'p' + page}
          href={pageUrl(page, base)}
          active={page === current_page}
        >
          {page}
        </PagerButton>
      )
    }
  })

  return (
    <Container backgroundColor={isDarkTheme ? '#000721' : '#FFF'}>
      <ArrowFl
        direction='left'
        active={current_page > 1}
        isDarkTheme={isDarkTheme}
        link={pageUrl(current_page - 1 > 1 ? current_page - 1 : 1, base)}
      />
      <PagesWrap>{pagerElements}</PagesWrap>
      <ArrowFl
        direction='right'
        active={current_page < max_page}
        isDarkTheme={isDarkTheme}
        link={pageUrl(
          current_page + 1 < max_page ? current_page + 1 : max_page,
          base
        )}
      />
    </Container>
  )
}

const getPagerValues = (current_page: number | string, max_page: number) => {
  const currentPageNumber = Number(current_page)
  const pagerValues: (number | string)[] = [currentPageNumber]
  const pagesToLeft = currentPageNumber > 1 ? 1 : currentPageNumber - 1
  const pagesToRight =
    currentPageNumber < max_page
      ? currentPageNumber == 1
        ? 3
        : currentPageNumber == 2
        ? 2
        : 1
      : max_page - currentPageNumber

  for (let i = 1; i <= pagesToRight && currentPageNumber + i <= max_page; i++) {
    pagerValues.push(currentPageNumber + i)
  }

  for (let i = 1; i <= pagesToLeft && currentPageNumber - i >= 1; i++) {
    pagerValues.unshift(currentPageNumber - i)
  }

  if (currentPageNumber > 2) {
    pagerValues.unshift('...')
    pagerValues.unshift(1)
  }

  if (currentPageNumber < max_page - 1) {
    pagerValues.push('...')
    pagerValues.push(max_page)
  }

  return pagerValues
}

export default PagerFl
