import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #9592a5;

  ${device.medium} {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
`

export const SeasonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-row: repeat(2, 190px);
  gap: 8px;

  ${device.small} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-row: repeat(2, 190x);
    gap: 8px;
  }

  ${device.regular} {
    grid-template-columns: repeat(5, 1fr);
    grid-template-row: repeat(2, 190px);
    gap: 12px;
  }

  ${device.medium} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-row: repeat(2, 356px);
    gap: 16px;
  }
`

export const SeasonImage = styled(Link)`
  width: 100%;
  height: auto;
  min-height: 190px;

  ${device.medium} {
    width: 200px;
    height: 356px;
  }

  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.76) 0%,
      rgba(0, 0, 0, 0) 32%
    ),
    url(${props => props.theme.img});

  &:hover {
    background-image: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.76) 0%,
        rgba(0, 0, 0, 0) 32%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      url(${props => props.theme.img});
    cursor: pointer;
  }

  &:active {
    background-image: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.76) 0%,
        rgba(0, 0, 0, 0) 32%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      url(${props => props.theme.img});
    cursor: pointer;
  }
`

export const SeasonText = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px 8px;
`

export const Title = styled.span`
  color: white;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 16px;
  max-height: calc(1.2 * 2 * 16px);
  text-overflow: ellipsis;
  text-align: center;

  ${device.medium} {
    font-size: 18px;
    max-height: calc(1.2 * 2 * 18px);
    line-height: 20px;
  }
`
