import React, { useState, useEffect } from 'react'
import {
  CounterContainer,
  CounterItem,
  CounterItemDigit,
  CounterItemText,
} from '../ImageCounter.styles'
import { he } from '../../../locales/he'

type date = {
  targetDate: string
}
export const CounterDown = ({ targetDate }: date) => {
  const calculateTimeLeft = () => {
    const now = new Date().getTime()
    const targetTime = new Date(targetDate).getTime()
    const timeDifference = targetTime - now
    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      )
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)

      return { days, hours, minutes, seconds }
    }

    return { days: 0, hours: 0, minutes: 0, seconds: 0 }
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer)
  })

  return (
    <CounterContainer>
      <CounterItem>
        <CounterItemDigit>{timeLeft.days}</CounterItemDigit>
        <CounterItemText>{he.days}</CounterItemText>
      </CounterItem>
      <CounterItem>
        <CounterItemDigit>{timeLeft.hours}</CounterItemDigit>
        <CounterItemText>{he.hours}</CounterItemText>
      </CounterItem>
      <CounterItem>
        <CounterItemDigit>{timeLeft.minutes}</CounterItemDigit>
        <CounterItemText>{he.minutes}</CounterItemText>
      </CounterItem>
      <CounterItem>
        <CounterItemDigit>{timeLeft.seconds}</CounterItemDigit>
        <CounterItemText>{he.seconds}</CounterItemText>
      </CounterItem>
    </CounterContainer>
  )
}
