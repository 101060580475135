import { ISpecialRef } from '../../types'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { Image } from '../Image/Image'
import { Container, ImageLink } from './SpecialRef.styles'
import { usePage } from '../../contexts'
import { addUrlParams } from '../../utils'

type Props = {
  specialRef: ISpecialRef
}

export const SpecialRef = ({
  specialRef: { link, imageObj, grid_title, grid_id },
}: Props) => {
  const page = usePage()
  if (link) {
    link = addUrlParams(link, [
      { key: 'cid', val: grid_id },
      { key: 'pid', val: page?.PageMeta.id + '' || '' },
    ])
  }
  return (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <ImageLink href={link ?? undefined}>
        <Image image={imageObj} />
      </ImageLink>
    </Container>
  )
}
