import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid
    ${props =>
      props.theme.isDarkTheme ? colors.darkBlueGray : colors.steelBlue};

  ${device.medium} {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
`

export const Carousel = styled.div`
  position: relative;

  ${device.medium} {
    display: none;
  }

  .swiper {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .swiper-slide {
    min-height: 102px;
    min-width: 102px;
  }
`

export const PodcastContainer = styled.div`
  display: none;

  ${device.medium} {
    display: grid;
    grid-template-columns: repeat(6, 200px);
    grid-template-row: repeat(2, 200px);
    gap: 16px;
  }
`
