import cx from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { DesktopAdId, useHeader } from '../../contexts'
import { IPageSEO, ITimeLineElement } from '../../types'
import Ad from '../Ads/Ad'
import { parseHTML } from '../ContentParser/ContentParser'
import { EditMe } from '../EditMe'
import { ShareButtons } from '../ShareButtons/ShareButtons'
import { TimeBullet } from './TimeBullet'
import {
  Anchor,
  InnerTimeWrapper,
  ItemContent,
  ItemTitle,
  MainItemWrapper,
  ShareButtonsWrapper,
  StyledAdWrap,
  TimeLineItem,
  TimeWrapper,
} from './TimeLine.styles'

type TimeLineSubItemProps = {
  index: number
  adIndex: number
  isLast: boolean
  orderClassName: string
  SEO: IPageSEO
  el: [string, ITimeLineElement]
  data: {
    data: ITimeLineElement
    id: string
    key: string | number
  }
  color: string
}

export const TimeLineSubItem = ({
  orderClassName,
  adIndex,
  isLast,
  index,
  el,
  SEO,
  data: { data },
  color,
}: TimeLineSubItemProps) => {
  const anchorRef = useRef<HTMLDivElement>()
  const { headerHeight } = useHeader()

  const router = useRouter()
  useEffect(() => {
    const hash = router.asPath.split('#').pop()
    if (anchorRef.current && hash === data.id) {
      setTimeout(function () {
        if (anchorRef.current) {
          anchorRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          })
        }
      }, 1000)
    }
  }, [anchorRef, data.id, router])
  return (
    <React.Fragment key={'ti' + index}>
      <MainItemWrapper>
        <TimeWrapper className={cx('rightSide')}>
          <InnerTimeWrapper
            className={cx(orderClassName)}
            theme={{ isLast: isLast, innerTimeWrapperColor: color }}
          >
            <TimeBullet
              color={color}
              order={orderClassName}
              date={data.createdOn}
            ></TimeBullet>
          </InnerTimeWrapper>
        </TimeWrapper>
        <TimeLineItem theme={orderClassName}>
          <Anchor
            key={data.id}
            headerHeight={headerHeight}
            ref={anchorRef as React.RefObject<HTMLDivElement>}
            id={data.id}
          />
          <EditMe grid_id={el[1].id} anchorId={data.id}>
            <>
              <ItemTitle>{data.post_title}</ItemTitle>
              <ItemContent>
                {parseHTML({
                  html: data.post_content,
                  adMidArticle: false,
                  keyPrefix: 'tlparser',
                })}
              </ItemContent>
              <ShareButtonsWrapper>
                <ShareButtons SEO={SEO} size={'25px'} anchor={'#' + data.id} />
              </ShareButtonsWrapper>
            </>
          </EditMe>
        </TimeLineItem>
      </MainItemWrapper>
      {adIndex == 1 && (
        <StyledAdWrap theme={{ color: color }}>
          <div className={'line'} />
          <Ad id={`timeline_box${1}` as DesktopAdId} collapse />
        </StyledAdWrap>
      )}
    </React.Fragment>
  )
}
