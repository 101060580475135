import styled from 'styled-components'
import { colors, fonts } from '../../../styles/GlobalVariables'

export const Container = styled.div`
  min-width: 308px;
  max-width: 308px;
  margin-bottom: 72px;
`

export const Title = styled.div<{
  fontColor: string
}>`
  margin: 0 0 16px;
  flex-grow: 0;
  font-family: ${fonts.narkis};
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  color: ${props => props.fontColor};
`

export const PostContainer = styled.div`
  font-family: ${fonts.narkis};
`

export const PostTitle = styled.a<{
  fontColor: string
}>`
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid
    ${props => (props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2)};
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  color: ${props => props.fontColor};
  text-decoration: none;

  &:hover {
    color: ${props =>
      props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2};
  }
`
