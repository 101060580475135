import styled, { css, keyframes } from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const HorizontalMarqueeContainer = styled.div`
  display: flex;
  padding-right: 10px;

  ${device.medium} {
    display: none;
  }
`

export const StyledLinkHorizontalMarqueeIcon = styled(Link)<{
  newsFlashColor?: string
  isDarkTheme?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 1px;
  right: 0;

  background: ${props =>
    props.isDarkTheme ? colors.tvGuideDark : colors.white};
  padding-left: 8px;
  padding-right: 1px;

  svg {
    font-size: 26px;

    rect {
      fill: ${props =>
        props.isDarkTheme
          ? colors.liveRed
          : props.newsFlashColor || colors.headerBlue};
    }
  }
`

export const VerticalMarqueeContainer = styled.div`
  display: none;

  ${device.medium} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

const marqueHeaderBg = css`
  background: linear-gradient(0deg, #011d6b, #011d6b),
    linear-gradient(90.57deg, #00104a -0.11%, #002e97 99.89%);
`

export const StyledLinkVerticalMarqueeHeader = styled(Link)<{
  backgroundColor?: string
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 23px;
  padding: 0 6.5px;
  z-index: 5;
  cursor: pointer;

  ${props =>
    props.backgroundColor
      ? props.backgroundColor.toLowerCase() === colors.darkBlue.toLowerCase()
        ? marqueHeaderBg
        : `background: ${props.backgroundColor}`
      : marqueHeaderBg};

  ${themeClassName.vod} & {
    background: ${colors.white};
  }
`

export const CurrentTime = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: ${colors.white};

  ${themeClassName.vod} & {
    color: ${colors.tvGuideDark};
  }
`

export const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: ${colors.white};

  ${themeClassName.vod} & {
    color: ${colors.tvGuideDark};
  }
`

export const VerticalMarqueeAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
`

export const VerticalMarquee = styled.div`
  height: calc(100% - 23px - 14px);
  display: flex;
  flex-direction: column;
  animation: ${VerticalMarqueeAnimation} 27.5s linear infinite;
  overflow: hidden;
`

export const NewsFlashSeparator = styled.div`
  ${device.medium} {
    content: '';
    width: calc(100% - 4px);
    border-bottom: 1px solid ${colors.separatorGray};
    margin: 0 auto;

    ${themeClassName.vod} & {
      background-color: ${colors.darkGray2};
    }
  }
`

export const StyledLinkNewsflashesItem = styled(Link)<{
  newsFlashColor?: string
}>`
  display: block;
  padding: 0 8px;
  border-right: 2px solid ${props => props.newsFlashColor || colors.headerBlue};
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  color: ${colors.termHeadBlockGray};

  ${themeClassName.vod} & {
    color: ${colors.white};
  }

  > time {
    margin-left: 9px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: ${props => props.newsFlashColor || colors.headerBlue};

    ${themeClassName.vod} & {
      color: ${colors.white};
    }

    ${device.medium} {
      margin: 0;
      font-size: 13px;
      line-height: 13px;
    }
  }

  ${device.medium} {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 15px 12px 12px 29px;
    border-right: none;
    font-size: 14px;
    line-height: 1.2;

    ${themeClassName.vod} & {
      padding: 16px 7px 6px 29px;
    }
  }
`

export const StyledLinkNewsflashes = styled(Link)<{ newsFlashColor?: string }>`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 700;
  z-index: 2;
  background: ${colors.white};
  color: ${props =>
    props.newsFlashColor ? props.newsFlashColor : colors.headerBlue};

  ${themeClassName.vod} & {
    background: ${colors.tvGuideDark};
    color: ${colors.white};
  }

  svg {
    margin-right: 5px;
    font-size: 8px;

    path {
      fill: ${props =>
        props.newsFlashColor ? props.newsFlashColor : colors.headerBlue};
    }

    ${themeClassName.vod} & path {
      fill: ${colors.white};
    }
  }
`

export const Container = styled.div<{ hideOnMobile?: boolean; width?: number }>`
  ${({ hideOnMobile }) => hideOnMobile && 'display: none;'};

  margin-right: 2px;
  position: relative;
  background: ${colors.white};
  flex: 1;

  ${themeClassName.vod} & {
    background: ${colors.tvGuideDark};
  }

  &:hover * {
    animation-play-state: paused;
  }

  ${device.medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 0;
    padding: 0;
    max-width: ${props => (props.width ? `${props.width}px` : '230px')};
  }

  > svg {
    position: absolute;
    top: calc(8px);
    right: 0;
    padding-left: 10px;

    background: ${colors.white};
    font-size: 8px;
  }
`
