import { useEffect } from 'react'
import styled from 'styled-components'
import { useRootStore } from '../store'
import { colors, device } from '../styles/GlobalVariables'

export const TestButtons = () => {
  const showButtons = useRootStore(state => state.showButtons)
  const toggleShowButtons = useRootStore(state => state.toggleShowButtons)

  useEffect(() => {
    document.addEventListener('keypress', e => {
      if (e.key.toLowerCase() === 'd' && e.shiftKey === true) {
        toggleShowButtons()
      }
    })
    return document.removeEventListener('keypress', e => {
      if (e.key.toLowerCase() === 'd' && e.shiftKey === true) {
        toggleShowButtons()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      {showButtons && <TheTestButton>This is a test button</TheTestButton>}
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 9999999999;
  pointer-events: none;

  bottom: 50px;
  left: 20px;

  ${device.medium} {
    bottom: 100px;
    left: 50px;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TheTestButton = styled.button`
  pointer-events: auto;
  direction: ltr;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px 20px;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  border: none;
  filter: drop-shadow(0px 0px 10px ${colors.darkBlue});
`
