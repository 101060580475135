import Head from 'next/head'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isDesktop, isNativeApp } from '../../client'
import {
  useHeader,
  useHero,
  useOverlay,
  usePage,
  useTheme,
} from '../../contexts'
import {
  isGridVodPage,
  isHomepage,
  isVodMain,
  isVodPage,
  isVodPlaylist,
} from '../../types'
import { reloadPageSetPageViewed } from '../../utils'
import { EditMe } from '../EditMe'
import { SubMenu } from '../Header/SubMenu'
import { HeadTags } from '../HeadTags/HeadTags'
import { Image } from '../Image/Image'
import { NotFound } from '../NotFound/NotFound'
import { dfpUtils, utils } from '../Player/Helpers'
import { PlayerContext } from '../Player/Store/PlayerContext'
import { TestButtons } from '../TestButtons'
import {
  BackgroundLogo,
  Banners,
  Container,
  FooterSpacer,
  LogoWrapper,
  StyledHeader,
} from './PageLayout.styles'
import { renderPage } from './renderPage'
import { CounterHandler } from '../Counter/CounterHandler'
import dynamic from 'next/dynamic'
import { useRootStore } from '../../store'
import { Footerstrip, Ozen, TopBanner } from '../Ads'
import { useFL } from '../../hooks/useFL'

type Props = {
  notFound: boolean
  adTargetEnv?: string
  kalturaReady: boolean
  kantarReady?: boolean
}

export const PageLayout = ({
  notFound,
  adTargetEnv,
  kalturaReady,
  kantarReady,
}: Props) => {
  const Footer = dynamic(() => import('../Footer/Footer'), { ssr: true })
  const WarTimeLine = useMemo(
    () =>
      dynamic(() => import('../../components/WarTimeLine/WarTimeLine'), {
        ssr: true,
      }),
    []
  )
  const [firstPageView, setFirstPageView, firstPagePath, setFirstPagePath] =
    useRootStore(state => [
      state.firstPageView,
      state.setFirstPageView,
      state.firstPagePath,
      state.setFirstPagePath,
    ])

  const { setFeedInView } = useOverlay()

  const providedPage = usePage()
  const { backgroundColor } = useFL()
  const theme = useTheme()
  const router = useRouter()
  const { headerHeight } = useHeader()
  const { imageHeight: heroHeight } = useHero()
  const [spacerHeight, setSpacerHeight] = useState(0)
  const [showHeader, setShowHeader] = useState(true)
  const { videoPlaying, setKalturaScriptReady, setKantarScriptReady } =
    useContext(PlayerContext)

  useEffect(() => {
    if (firstPagePath === null) {
      setFirstPagePath(router.asPath)
    }
  }, [firstPagePath, router.asPath, setFirstPagePath])

  useEffect(() => {
    if (
      firstPageView === null ||
      firstPagePath === null ||
      firstPagePath === router.asPath
    ) {
      setFirstPageView(true)
    } else {
      setFirstPageView(false)
    }
  }, [firstPagePath, firstPageView, router.asPath, setFirstPageView])

  useEffect(() => setShowHeader(!isNativeApp()), [])

  const levels = useMemo(
    () => providedPage?.PageMeta?.levels || {},
    [providedPage]
  )

  const pageType = useMemo(
    () => providedPage?.PageMeta?.pageType || '',
    [providedPage]
  )

  const layout = useMemo(
    () => providedPage && renderPage(providedPage),
    [providedPage]
  )

  const CounterComp = useRef<JSX.Element | null>(null)
  useEffect(() => {
    if (providedPage && !CounterComp.current) {
      CounterComp.current = <CounterHandler page={providedPage} />
    }
  }, [providedPage])

  const FooterStripComp = useRef<JSX.Element | null>(null)
  useEffect(() => {
    if (
      !isDesktop() &&
      providedPage &&
      FooterStripComp.current === null &&
      !providedPage.PageMeta.war_comp
    ) {
      if (
        !isNativeApp() ||
        (isNativeApp() &&
          providedPage.SiteMeta?.config?.features?.app_footer_strip === 'on')
      ) {
        FooterStripComp.current = (
          <Footerstrip onAdRender={onFooterstripRender} />
        )
      }
    }
  }, [providedPage, providedPage?.PageMeta.war_comp])

  const WarTimelineComp = useRef<JSX.Element | null>(null)
  useEffect(() => {
    if (providedPage?.PageMeta.war_comp && !WarTimelineComp.current) {
      WarTimelineComp.current = (
        <WarTimeLine
          data={providedPage.PageMeta.war_comp}
          key={'WarTimeLine'}
        />
      )
    }
  }, [WarTimeLine, providedPage?.PageMeta.war_comp])

  useEffect(() => {
    setKalturaScriptReady(kalturaReady)
  }, [setKalturaScriptReady, kalturaReady])

  useEffect(() => {
    setKantarScriptReady(kantarReady || false)
  }, [setKantarScriptReady, kantarReady])

  useEffect(() => {
    dfpUtils.setAdTargeting(
      pageType,
      levels,
      router.asPath,
      providedPage?.Content?.Item?.tags,
      adTargetEnv
    )

    reloadPageSetPageViewed(
      pageType,
      videoPlaying,
      router,
      notFound,
      providedPage?.PageMeta.is_auto_refresh == true
    )
  }, [
    pageType,
    videoPlaying,
    router,
    notFound,
    adTargetEnv,
    levels,
    providedPage,
  ])

  useEffect(() => {
    const handleScroll = () => {
      const feed = document.querySelector(
        '#taboola-below-vod-thumbnails,#taboola-below-portal-thumbnails'
      ) as HTMLElement
      if (feed) {
        setFeedInView(utils.isTaboolaElementInView(feed))
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setFeedInView])

  if (!providedPage) {
    return null
  }
  const { Header: header, Footer: footer, SiteMeta, PageMeta } = providedPage

  const HeaderStrip = showHeader ? (
    <StyledHeader
      header={header}
      pageType={PageMeta.pageType}
      ogData={providedPage?.SEO.og}
      displaySubmenu={!notFound && !!header?.subMenu?.length}
      bbButton={SiteMeta.config?.features?.bb_button}
    />
  ) : null

  const NotFoundWrapper = (
    <>
      <Head>
        <title>עמוד 404 | רשת 13</title>
        <meta name='canRecommend' content={'false'} />
        <meta name='robots' content={'noindex,follow'} />
      </Head>
      <Container
        className={theme}
        subMenu={!!header?.subMenu?.length}
        hasHero={false}
        showingHeader={showHeader}
      >
        {HeaderStrip}
        <NotFound />
      </Container>
    </>
  )

  if (notFound) {
    return NotFoundWrapper
  }

  const renderBanner = () => {
    if (providedPage.PageMeta.pageType === 'hpvod') {
      return null
    }
    const hasVodLeft =
      isGridVodPage(providedPage) &&
      providedPage.Content.PageGrid.reduce(
        (previousValue, currentValue) =>
          previousValue || currentValue.grid_type === 'VodLeft',
        false
      )
    return (
      <Banners key={router.asPath + ' banners'}>
        <TopBanner key={'TopBanner'} />
        {!hasVodLeft &&
          !providedPage?.PageMeta.war_comp &&
          !providedPage?.PageMeta.isFaceLift && <Ozen side='left' />}
        <Ozen side='right' />
      </Banners>
    )
  }

  const getBackgroundLogo = () => {
    if (
      !isGridVodPage(providedPage) ||
      !isVodPlaylist(providedPage.Content.PageGrid[0])
    ) {
      return undefined
    }
    if (
      !providedPage.Content.PageGrid[0].background.imageObj.d ||
      !providedPage.Content.PageGrid[0].background.imageObj.m
    ) {
      return undefined
    }

    return providedPage.Content.PageGrid[0].background.imageObj
  }

  const backgroundLogo = getBackgroundLogo()

  const onFooterstripRender = (height: number) => {
    setSpacerHeight(height)
  }
  const hasHero =
    isVodPage(providedPage) && isVodMain(providedPage.Content.PageGrid[0])
  const taboolaTheme = backgroundColor == '#fff' ? 'light' : 'dark'

  return (
    <>
      <TestButtons />
      <HeadTags page={providedPage} />
      <EditMe grid_id={providedPage.PageMeta.id} type={'admin-bar'}>
        <Container
          key={'main-page-layout'}
          className={theme + ` taboola_theme_${taboolaTheme}`}
          subMenu={!!header?.subMenu?.length}
          hasHero={hasHero}
          heroHeight={heroHeight}
          headerHeight={headerHeight}
          showingHeader={showHeader}
          isFaceLift={PageMeta.isFaceLift}
          backgroundColor={backgroundColor}
        >
          {backgroundLogo && (
            <LogoWrapper>
              <BackgroundLogo>
                <Image image={backgroundLogo} />
              </BackgroundLogo>
            </LogoWrapper>
          )}
          {HeaderStrip}
          {header?.subMenu?.length ? (
            <SubMenu subMenu={header.subMenu} />
          ) : null}
          {WarTimelineComp?.current}
          {renderBanner()}
          {layout || NotFoundWrapper}
          {CounterComp.current}
          {FooterStripComp.current}
          {isHomepage(providedPage) && footer && (
            <>
              <Footer footer={footer} siteMeta={SiteMeta} />
              {!!spacerHeight && <FooterSpacer height={spacerHeight} />}
            </>
          )}
        </Container>
      </EditMe>
    </>
  )
}
