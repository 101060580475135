import { useFL } from '../../../hooks/useFL'
import { he } from '../../../locales/he'
import { colors } from '../../../styles/GlobalVariables'
import { ITag } from '../../../types'
import { ArticleTagsContainer, Container, Tag } from './ArticleTagsFl.styles'

const ArticleTagsFl = ({ tags }: { tags: ITag[] }) => {
  const { isDarkTheme } = useFL()
  if (tags.length === 0) {
    return null
  }
  const tag2 = [...tags, ...tags]
  return (
    <Container>
      <ArticleTagsContainer>
        {`${he.tags}: `}
        {tag2.map(({ name, link }, tagIndex) => (
          <Tag
            hoverColor={isDarkTheme ? colors.white : colors.black}
            key={`tag_${tagIndex}`}
            href={link}
            children={name}
          />
        ))}
      </ArticleTagsContainer>
    </Container>
  )
}

export default ArticleTagsFl
