import { useGridBlock, usePage } from '../../contexts'
import { IPost, isHomepage } from '../../types'
import Credits from '../DateAndWriter/Credits'
import { Image } from '../Image/Image'
import Link from '../Link/Link'
import { utils } from '../Player/Helpers/utils'
import { Shave } from '../Shave/Shave'
import dynamic from 'next/dynamic'
import {
  Container,
  PromoStrip,
  TextContainer,
  Thumbnail,
  Title,
} from './MatrixPost.styles'
import PlayButton from '/public/assets/images/svg/play-button.svg'
import { useEffect, useRef } from 'react'

type Props = {
  post: IPost
  rowSpan: number
  columnSpan: number
}

export const MatrixPost = ({ post, rowSpan, columnSpan }: Props) => {
  const {
    redStrip,
    isBlank,
    link: postLink,
    background,
    imageObj,
    title,
    promotedStripText,
    video,
  } = post
  const postContainerRef = useRef<HTMLDivElement | null>(null)
  const { gridId } = useGridBlock()
  const page = usePage()
  const linkTarget = isBlank ? '_blank' : undefined

  const isDarkTheme = page?.isDarkTheme || false
  const pageId = page?.PageMeta.id
  const queryParams = utils.addPageQueryParams(postLink, pageId, gridId)
  const link = postLink + queryParams
  const RedStrip = dynamic(() => import('../RedStrip/RedStrip'))
  const hide_date = !link.match(/^(\/item)/)
  useEffect(() => {
    if (isHomepage(page) && postContainerRef.current) {
      postContainerRef.current.dataset.tbRegionItem = ''
    }
  }, [page])
  return (
    <Container
      backgroundColor={background}
      rowSpan={rowSpan}
      columnSpan={columnSpan}
      ref={postContainerRef}
    >
      <Thumbnail
        href={link}
        target={linkTarget}
        rowSpan={rowSpan}
        columnSpan={columnSpan}
      >
        {redStrip && <RedStrip {...redStrip} />}
        <Image image={imageObj} alt={title} />
        {promotedStripText && <PromoStrip>{promotedStripText}</PromoStrip>}
        {video && <PlayButton />}
      </Thumbnail>
      <TextContainer backgroundColor={background}>
        <Link href={link} target={linkTarget}>
          <Shave maxLines={3}>
            <Title isDarkTheme={isDarkTheme}>{title}</Title>
          </Shave>
        </Link>
        <Credits
          credits={post}
          color={isDarkTheme ? '#999' : undefined}
          hide_date={hide_date}
        />
      </TextContainer>
    </Container>
  )
}
