import { usePage } from '../../contexts'
import { useFL } from '../../hooks/useFL'
import { IAdvertisers, IPublishers } from '../../types'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import {
  Container,
  DesktopContainer,
  MobileContainer,
} from './Advertisers.styles'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

type Props = {
  layout: IAdvertisers | IPublishers
}

export const Advertisers = ({ layout }: Props) => {
  const page = usePage()
  const { isDarkTheme } = useFL()
  const pid = page?.PageMeta.id.toString()
  const gid = layout.grid_id.toString()

  return (
    <Container theme={{ isDarkTheme: isDarkTheme }}>
      <ComponentTitleFL
        componentTitle={layout.grid_title}
        componentId={layout.grid_id}
      />
      <DesktopContainer>
        <Desktop posts={layout.posts} pid={pid} gid={gid} />
      </DesktopContainer>
      <MobileContainer>
        <Mobile posts={layout.posts} pid={pid} gid={gid} />
      </MobileContainer>
    </Container>
  )
}
