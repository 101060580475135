import dynamic from 'next/dynamic'
import { useAdminCookie } from '../../contexts'
import { ChildrenWrap } from './Style'

type Props = {
  grid_id?: number | string
  type?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: JSX.Element | null
  anchorId?: string
}

export const EditMe = ({ grid_id, type, children, anchorId }: Props) => {
  const admin_cookie = useAdminCookie()

  if (!(grid_id && admin_cookie)) {
    return children || null
  }

  const AdminBarComp = dynamic(() => import('./AdminBar'), { ssr: false })
  const EditBLockComp = dynamic(() => import('./EditBLock'), { ssr: false })
  if (type === 'admin-bar') {
    return (
      <>
        <AdminBarComp key={'admin_bar'} />
        {children}
      </>
    )
  } else {
    return (
      <>
        <EditBLockComp
          grid_id={grid_id}
          admin_cookie={admin_cookie}
          anchorId={anchorId}
        />
        <ChildrenWrap>{children}</ChildrenWrap>
      </>
    )
  }
}
