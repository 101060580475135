import { useHeader, useWindow } from '../../contexts'
import { IArticlePage } from '../../types'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import {
  Container,
  Content,
  HorizontalContainer,
  LR,
  Sidebar,
} from './ArticleTimeLineFL.styles'

import { TimeLineFL } from '../TimeLineFL/TimeLineFL'
import MostPopular from '../Article/MostPopular/MostPopular'
import React, { useEffect, useRef, useState } from 'react'
import { ShareButtonsWrapper } from './ArticleFl.styles'
import { isDesktop } from '../../client'

type Props = {
  page: IArticlePage
}

export const ArticleTimeLineFL = ({ page }: Props) => {
  const article = page.Content.Item
  const mostRead = page.Content.Item.most_read
  const contentRef = useRef<HTMLDivElement>(null)
  const win = useWindow()
  const { headerHeight } = useHeader()
  const shareContainerRef = useRef<HTMLDivElement>(null)
  const [stickyShare, setStickyShare] = useState<'top' | 'bottom' | false>(
    false
  )
  const { PageMeta } = page
  const shareSize = 38
  const disableTaboola = PageMeta.ads.block.taboola
  const shareButtonsRef = useRef<HTMLDivElement>(null)
  const headerShareGap = 20

  useEffect(() => {
    const onScroll = () => {
      if (
        !win ||
        !isDesktop() ||
        !shareContainerRef.current ||
        !shareButtonsRef.current ||
        !headerHeight
      ) {
        return
      }
      const container = shareContainerRef.current
      const buttons = shareButtonsRef.current
      const containerTop = container.offsetTop
      const containerBottom = containerTop + container.offsetHeight
      const buttonsBottom =
        win.scrollY + headerHeight + headerShareGap + buttons.offsetHeight

      const isHeaderOverShare =
        win.scrollY >= containerTop - headerHeight - headerShareGap
      const isButtonsOverContainer = buttonsBottom > containerBottom

      switch (true) {
        case isHeaderOverShare && !isButtonsOverContainer:
          setStickyShare('top')
          break

        case isHeaderOverShare && isButtonsOverContainer:
          setStickyShare('bottom')
          break

        default:
          setStickyShare(false)
          break
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [headerHeight, shareContainerRef, shareButtonsRef, win])

  if (!article) {
    return null
  }

  return (
    <>
      <Container>
        <HorizontalContainer>
          <Content ref={contentRef}>
            {article && win && <TimeLineFL data={page} />}
            {!disableTaboola && (
              <Taboola
                pageType={PocTaboolaPageType.timeLine}
                suffixId={PocTaboolaSuffixId.pageFooter}
              />
            )}
          </Content>
          <Sidebar stickyShare={stickyShare} headerHeight={headerHeight}>
            {contentRef.current &&
              contentRef.current.offsetHeight >= 190 && // size of taboola fith six element and most read
              mostRead.length > 0 && <MostPopular mostRead={mostRead} />}
            <LR>
              <Taboola
                pageType={PocTaboolaPageType.article_item_left_rail_thumbnails}
                suffixId={PocTaboolaSuffixId.null}
              />
            </LR>
            <ShareButtonsWrapper
              theme={{ size: shareSize }}
              ref={shareContainerRef}
            ></ShareButtonsWrapper>
          </Sidebar>
        </HorizontalContainer>
      </Container>
    </>
  )
}
