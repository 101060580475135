import { ITimelineItem } from '../../../types'
import { DateTime } from '../../../utils'
import { utils } from '../../Player/Helpers/utils'
import { Shave } from '../../Shave/Shave'
import {
  Container,
  ContentContainer,
  Image,
  SecondaryTitle,
  Time,
  TimeContainer,
  Title,
} from './TimeLineMobile.styles'
import { filterContent } from './utils'

type Props = {
  fontColor?: string
  gid?: number
  isDarkTheme: boolean
  pId?: number
  timeline: ITimelineItem
}

const TimeLineMobile = ({
  fontColor,
  gid,
  isDarkTheme,
  pId,
  timeline,
}: Props) => {
  const { timelineUrl, post_title, createdOn, image, post_content } = timeline

  const queryParams = utils.addPageQueryParams(timelineUrl, pId, gid)
  const link = timelineUrl + queryParams
  return (
    <Container>
      <TimeContainer isDarkTheme={isDarkTheme}>
        <Time>{DateTime.parse(createdOn)?.toTime()}</Time>
      </TimeContainer>
      <ContentContainer href={link}>
        <Image src={image} alt={post_title} />
        <Shave maxLines={2}>
          <Title fontColor={fontColor}>{post_title}</Title>
        </Shave>
        <Shave maxLines={10}>
          <SecondaryTitle fontColor={fontColor}>
            {filterContent(post_content)}
          </SecondaryTitle>
        </Shave>
      </ContentContainer>
    </Container>
  )
}

export default TimeLineMobile
