import { usePage } from '../../contexts'
import { useFL } from '../../hooks/useFL'
import { IPost } from '../../types'
import { Image } from '../Image/Image'
import { utils } from '../Player/Helpers/utils'
import { ContestantPost, ImageText } from './ContestantsFl.style'

type Props = {
  post: IPost
  gid?: number
}

const PostContestant = ({ post, gid }: Props) => {
  const { imageObj, title, isBlank, link: postLink } = post
  const linkTarget = isBlank ? '_blank' : undefined
  const page = usePage()
  const pageId = page?.PageMeta.id
  const { fontColor } = useFL()
  const queryParams = utils.addPageQueryParams(postLink, pageId, gid)
  const link = postLink + queryParams
  return (
    <ContestantPost href={link} target={linkTarget}>
      <Image image={imageObj} alt={title} />
      <ImageText fontColor={fontColor}>{title}</ImageText>
    </ContestantPost>
  )
}

export default PostContestant
