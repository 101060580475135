import { useState } from 'react'
import { usePage } from '../../contexts'
import { he } from '../../locales/he'
import { IImageWithCredits } from '../../types'
import { ZoomIn } from '../ZoomIn'
import { Container, Details, ImageWrapper } from './ImageWithCredits.styles'

type Props = {
  image: IImageWithCredits
  showCredits: boolean
  isInTimeline?: boolean
  isLazy?: boolean
}

export const ImageWithCredits = ({
  image,
  showCredits = true,
  isInTimeline,
  isLazy = true,
}: Props) => {
  const page = usePage()
  const webpOn = page?.SiteMeta.config?.features?.webp === 'on' || false
  const [src, setSrc] = useState(
    webpOn ? image.src.replace(/png|jpg|jpeg/g, 'webp') : image.src
  )

  return (
    image && (
      <Container>
        <ImageWrapper className='article-image'>
          <img
            loading={isLazy ? 'lazy' : 'eager'}
            src={src}
            alt={image.alt}
            onError={() => webpOn && setSrc(image.src)}
          />
          {!isInTimeline && <ZoomIn index={0} />}
        </ImageWrapper>
        {showCredits && image.description && image.credit && (
          <Details>{`${image.description} | ${he.photoBy}: ${image.credit}`}</Details>
        )}
      </Container>
    )
  )
}
