import styled, { css } from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { StyledLinkNewsflashesItem } from '../Newsflashes/Newsflashes.styles'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  //margin: 0 -20px 30px;
  margin: 0 20px 30px;
  margin-top: 20px;

  ${device.medium} {
    margin: 0 0 28px;
    padding-top: 28px;
  }
`

export const StyledComponentTitle = styled(ComponentTitle)`
  margin: 0 20px 20px;

  ${device.medium} {
    margin: 0 0 28px;
  }
`

export const PostContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${device.medium} {
    flex-direction: row;
    margin-bottom: 16px;

    ${StyledLinkNewsflashesItem} {
      padding: 15px 5px 12px 29px;
    }
  }
`

export const NowLiveText = styled.span`
  min-width: unset;
  color: ${colors.liveRed};

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }
`

export const NowLiveTitle = styled.p<{
  truncateText: boolean
  isDarkTheme: boolean
}>`
  min-width: unset;

  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @supports (-webkit-touch-callout: none) {
    ${props =>
      props.truncateText && {
        position: 'relative',
        maxHeight: '32px',
        '::after': {
          position: 'absolute',
          content: '"..."',
          bottom: 0,
          left: 0,
        },
      }}
  }

  ${device.medium} {
    -webkit-line-clamp: 1;
  }

  ${props => (props.isDarkTheme ? `color: ${colors.white};` : '')}
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  color: ${colors.black};
  margin-top: 16px;
  padding: 0 20px;

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    padding: 0;
    max-width: 100%;
    font-size: 30px;
  }

  ${device.large} {
    font-weight: 900;
  }
`

export const PlayerWrapper = styled.div`
  flex: 1;

  ${device.medium} {
    /* distance between player and vertical newsflash marquee */
    margin-left: 30px;
  }
`
export const PlayerPlaceHolder = styled.div`
  min-height: calc((9 / 16) * 100%);
  background-color: ${colors.tvGuideDark};

  ${props =>
    props.theme.bg
      ? css`
          background-image: url('${props.theme.bg}');
          background-size: cover;
          background-repeat: no-repeat;
        `
      : ''}
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 12px;

  ${device.medium} {
    flex-basis: 960px;
    gap: 22px;
    min-height: 540px;
    max-height: 540px;
  }
`

export const NewsflashesContainer = styled.div`
  ${device.medium} {
    display: flex;
    height: 540px;
  }
`
