import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 0 -23px 20px;
  position: relative;
  z-index: 1;
  background-color: ${props => props.theme.color};

  & > img {
    width: 335px;
    height: 189px;
  }

  ${device.medium} {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0 0 28px;
    padding: 45px 0;
  }

  ${device.large} {
    gap: 30px;
  }

  :after {
    content: '';
    position: absolute;
    background-image: url(${props => props.theme.mobile_background_image});
    background-color: ${props => props.theme.color};
    top: 0;
    left: 50%;
    margin-left: -50vw;
    bottom: 0;
    z-index: -1;
    width: 100vw;
    background-size: cover;

    ${device.medium} {
      background-image: url(${props => props.theme.desktop_background_image});
      content: '';
      overflow-x: hidden;
      top: 0;
      left: 50%;
      margin-left: -50vw;
      bottom: 0;
      z-index: -1;
      width: 100vw;
      position: absolute;
    }
  }
`

export const Thumbnail = styled.div`
  display: flex;

  ${device.medium} {
    max-height: 100%;
    max-width: 100%;
  }

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
    padding: 6% 6% 3% 6%;

    ${device.medium} {
      padding: 0;
    }
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 554px;
  padding: 0 20px 12px;
  justify-content: flex-start;

  ${device.medium} {
    left: 35px;
    position: relative;
  }
`

export const VideoWrapper = styled.div`
  width: 88%;
  margin-right: 6%;
  margin-top: 6%;

  ${device.medium} {
    margin: 0;
    width: 100%;
  }
`

export const Title = styled.div`
  width: 630px;
  height: 35px;
  flex-grow: 0;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
  margin-right: 2%;

  ${device.medium} {
    font-size: 46px;
    line-height: 1.25;
    height: 55px;

    :after {
      width: 65px;
    }
  }

  ${device.small} {
    margin-right: 4%;
    width: 94%;
  }
`

export const SecondaryTitle = styled.div`
  width: 98%;
  flex-grow: 0;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
  margin-bottom: 10px;
  bottom: 10px;
  position: relative;
  margin-right: 2%;

  ${device.medium} {
    width: 630px;
    flex-grow: 0;
    font-size: 21px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    margin-bottom: 10px;
    bottom: 10px;
    position: relative;
  }

  ${device.small} {
    margin-right: 4%;
    width: 93%;
  }
`
export const Button = styled.a`
  width: fit-content;
  color: ${props => props.theme.text_color};
  height: 36px;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  object-fit: contain;
  border-radius: 6px;
  background-color: #fff;
  margin: 0 auto 25px;

  ${device.medium} {
    margin: 0;
    margin-right: 4%;
  }
`
