import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  margin-bottom: 12px;

  ${device.medium} {
    margin-bottom: 20px;
  }
`

export const Thumbnail = styled.a`
  flex: 1 1 50%;
  display: flex;
  position: relative;
  min-height: 0;

  > span > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }

  > svg {
    position: absolute;
    font-size: 50px;
    left: 16px;
    bottom: -25px;

    ${device.medium} {
      font-size: 77px;
      left: 22px;
      bottom: -35px;
    }
  }
`

export const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 12px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const Details = styled.div`
  font-size: 16px;
  line-height: 186%;
  color: ${colors.gray};
  margin: 3px 20px 0;
  font-weight: 600;

  ${device.medium} {
    margin: 0;
    color: ${colors.commentsGray};
  }
`
