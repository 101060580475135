import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: space-between;
  direction: ltr;
  background-color: ${props => props.backgroundColor};
  margin-bottom: 16px;

  ${device.medium} {
    margin-bottom: 40px;
  }
`
export const PagerButton = styled(Link)<{
  active: boolean
}>`
  display: grid;
  align-content: center;
  justify-items: center;
  background-color: ${props =>
    props.active
      ? props.theme.isDarkTheme
        ? colors.darkBlueGray
        : colors.veryLightGray
      : 'unset'};
  border-bottom: 3px solid
    ${props =>
      props.active
        ? `${props.theme.isDarkTheme ? colors.white : colors.black}`
        : 'transparent'};
  font-weight: 400;
  color: ${props =>
    props.active
      ? props.theme.isDarkTheme
        ? colors.white
        : colors.black
      : props.theme.isDarkTheme
      ? colors.steelBlue
      : colors.darkGray2};
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  font-size: 18px;
  padding: 8px 1rem;

  &:hover,
  &:active {
    color: ${props => (props.theme.isDarkTheme ? 'white' : 'black')};
  }
`

export const Dots = styled.span`
  display: grid;
  align-content: center;
  justify-items: center;
  font-weight: 400;
  color: ${props =>
    props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2};
  font-size: 18px;
`

export const ArrowWrapper = styled.div<{ active: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.active &&
    `&:hover {
        background-color: ${props.theme.isDarkTheme ? '#4D5164' : '#34394D'};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        cursor: pointer;
        }`}

  & a {
    width: 16px;
    height: 16px;
  }

  svg {
    font-size: 16px;
  }
`

export const PagesWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
