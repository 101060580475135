import { Swiper, SwiperSlide } from 'swiper/react'
import { IPost } from '../../types'
import { addAnalyticsToUrl } from '../../utils'
import { Image } from '../Image/Image'
import Link from '../Link/Link'
import { Shave } from '../Shave/Shave'
import { PostContainer, PostTitle, PostsContainer } from './Advertisers.styles'

type Props = {
  posts: IPost[]
  pid?: string
  gid: string
}

export const Mobile = ({ posts, gid, pid }: Props) => {
  return (
    <PostsContainer>
      <Swiper
        allowTouchMove={true}
        slidesPerView={posts.length > 2 ? 2.5 : 2}
        spaceBetween={8}
      >
        {posts.length &&
          posts.slice(0, 12).map((post: IPost, idx) => (
            <SwiperSlide key={idx}>
              <Link href={addAnalyticsToUrl(post.link, pid || '#', gid)}>
                <PostContainer>
                  <Image image={post.imageObj} alt={post.title} />
                  <Shave maxLines={3}>
                    <PostTitle>{post.title}</PostTitle>
                  </Shave>
                </PostContainer>
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
    </PostsContainer>
  )
}
