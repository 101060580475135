import React from 'react'
import { AdId } from '../../contexts'
import { Episode } from '../../utils'
import Ad from '../Ads/Ad'
import { PlaylistEpisode } from './PlaylistEpisode'
import { EpisodesGridFl } from './VodPlaylist.styles'

type Props = {
  episodes: Episode[]
  episodesGroupCounter: number
  nowPlaying: Episode
  handleEpisodeChange: (episode: Episode) => void
}

export const PlaylistEpisodes = ({
  episodes,
  episodesGroupCounter,
  nowPlaying,
  handleEpisodeChange,
}: Props) => {
  return (
    <EpisodesGridFl>
      {episodes.map((episode, index) => {
        const adId = getAdId(index)
        const renderAd = !!adId
        return (
          showEpisode(index, episodesGroupCounter) && (
            <React.Fragment key={episode.id}>
              <PlaylistEpisode
                episode={episode}
                onClick={() =>
                  episode.id !== nowPlaying.id && handleEpisodeChange(episode)
                }
                nowPlaying={episode.id === nowPlaying.id}
              />
              {renderAd && (
                <Ad
                  desktopAdId={`VodPlaylist_box_${adId}` as AdId}
                  mobileAdId={`VodPlaylist_box_${adId}` as AdId}
                  key={`ad-${index}`}
                  collapse={false}
                />
              )}
            </React.Fragment>
          )
        )
      })}
    </EpisodesGridFl>
  )
}

const showEpisode = (index: number, episodesGroupCounter: number) => {
  if (episodesGroupCounter === 0) {
    return index < 7
  }

  if (episodesGroupCounter > 0) {
    return index < 7 + episodesGroupCounter * 15
  }

  return false
}

/**
 * getAdId helps us render the ads inside the episode playlist
 * according to the requirements:
 * - first ad is rendered after 3 episodes;
 * - the other ads are rendered every 13 episodes.
 */
const getAdId = (index: number) => adIds[index] ?? 0
const adIds: { [key: number]: number } = {
  2: 1,
  13: 2,
  24: 3,
  35: 4,
}
