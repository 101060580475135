import debounce from 'lodash/debounce'
import { useEffect, useRef, useState } from 'react'
import { IShow } from '../../types'
import { he } from '../../locales/he'
import { getShowProgress } from '../../utils'
import {
  Description,
  FirstArrow,
  OnNow,
  ProgressBar,
  SecondArrow,
  Separator,
  Show,
  Shows,
  ShowTime,
  StyledLink,
  TextContainer,
  Title,
} from './ScheduleShows.styles'

type Props = {
  visible: boolean
  shows: IShow[]
  firstShowIndex: number
}

export const ScheduleShows = ({ visible, shows, firstShowIndex }: Props) => {
  const [isLeftOverProgressBar, setIsLeftOverProgressBar] = useState(false)
  const [isRightOverProgressBar, setIsRightOverProgressBar] = useState(false)
  const firstArrowRef = useRef<HTMLSpanElement>(null)
  const secondArrowRef = useRef<HTMLSpanElement>(null)
  const progressBarRef = useRef<HTMLDivElement>(null)

  // logic for coloring the arrows for the currently playing show
  useEffect(() => {
    const setArrowsColors = () => {
      const firstArrowRect = firstArrowRef.current?.getBoundingClientRect()
      const secondArrowRect = secondArrowRef.current?.getBoundingClientRect()
      const progressBarRect = progressBarRef.current?.getBoundingClientRect()
      if (!firstArrowRect || !secondArrowRect || !progressBarRect) {
        return
      }
      // we only care about the second arrow width since the first one also contains a space
      const arrowWidth = secondArrowRect.width

      setIsLeftOverProgressBar(
        progressBarRect.left < firstArrowRect.left + arrowWidth
      )
      setIsRightOverProgressBar(
        progressBarRect.left < secondArrowRect.left + arrowWidth
      )
    }
    setArrowsColors()

    const onResize = debounce(() => setArrowsColors(), 250)
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [visible, firstShowIndex, firstArrowRef, secondArrowRef, progressBarRef])

  return (
    <Shows active={visible}>
      {shows.map((show, index) => {
        const { start_time, title, desc, link } = show
        const progress = getShowProgress(show, shows[index + 1])
        const isCurrentlyPlaying = progress > 0
        return (
          <Show
            key={`show-${index}`}
            visible={!visible || index >= firstShowIndex}
          >
            {isCurrentlyPlaying && (
              <ProgressBar progress={progress} ref={progressBarRef} />
            )}
            <TextContainer>
              <ShowTime isCurrentlyPlaying={isCurrentlyPlaying}>
                {isCurrentlyPlaying && <OnNow>{he.onNow}</OnNow>}
                {start_time}
              </ShowTime>
              <Separator />
              <StyledLink href={link}>
                <Title>
                  {title}
                  {link && (
                    <FirstArrow
                      isCurrentlyPlaying={isCurrentlyPlaying}
                      ref={isCurrentlyPlaying ? firstArrowRef : undefined}
                      isOverProgressBar={isLeftOverProgressBar}
                    >
                      {' >'}
                    </FirstArrow>
                  )}
                  {link && (
                    <SecondArrow
                      isCurrentlyPlaying={isCurrentlyPlaying}
                      ref={isCurrentlyPlaying ? secondArrowRef : undefined}
                      isOverProgressBar={isRightOverProgressBar}
                    >
                      {'>'}
                    </SecondArrow>
                  )}
                </Title>
                <Description>{desc}</Description>
              </StyledLink>
            </TextContainer>
          </Show>
        )
      })}
    </Shows>
  )
}
