/**
 * Created by PhpStorm.
 * User: shlomy
 * Date: 06/08/2022
 * Time: 11:49
 */
import styled from 'styled-components'
import { colors, headerZIndex } from '../../styles/GlobalVariables'

const imgPrefix =
  'https://media.reshet.tv/image/upload/q_80,f_auto/v1596091408/red-alert-assets/'
export const alertGif = `${imgPrefix}alert.gif`,
  pikudSoundImg = `${imgPrefix}pikud_sound.png`,
  closeImg = `${imgPrefix}x-xlose.png`,
  logoImg = `${imgPrefix}logo-pikud.png`

export const RedAlertPreload = styled.div`
  display: none;
  background-image: url(${closeImg}), url(${logoImg});
`
export const RedAlertWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${headerZIndex + 2};
  background: ${colors.alertsRed};
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  color: ${colors.white};
`
export const PikudLogoWrap = styled.div`
  margin: 20px;
  img {
    width: 60px;
  }
`
const closeSize = 30
export const CloseAlerts = styled.div`
  position: relative;
  cursor: pointer;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid white;
  width: ${closeSize}px;
  height: ${closeSize}px;
  min-width: ${closeSize}px;
  min-height: ${closeSize}px;
  background-image: url(${closeImg});
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
`
export const AlertsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`

export const AlertsTitle = styled.div`
  font-size: 1.2em;
  font-weight: 300;
  margin-bottom: 5px;
`
export const Alerts = styled.div`
  font-weight: 300;
  display: inline-flex;
  flex-wrap: wrap;
  div + div:before {
    content: '|';
    padding: 0 10px;
  }
`
export const AlertItem = styled.div``
