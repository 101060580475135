import { AdId } from '../../contexts'
import { IBanner } from '../../types'
import Ad from './Ad'
import { Container } from './Banner.styles'

export interface IProps {
  banner: IBanner
  collapse: boolean
}

export const Banner = ({ banner, collapse }: IProps) => {
  const id = banner.banner as AdId
  return (
    <Container>
      <Ad id={id} collapse={collapse} />
    </Container>
  )
}
