import { colors } from '../../styles/GlobalVariables'
import { IMainTextOver } from '../../types'
import Credits from '../DateAndWriter/Credits'
import { Image } from '../Image/Image'
import Newsflashes from '../Newsflashes/Newsflashes'
import {
  Container,
  ContentContainer,
  NewsflashesContainer,
  Overlay,
  PostContainer,
  StyledComponentTitle,
  StyledLinkImage,
  StyledLinkSubtitle,
  StyledLinkTitle,
  TextContainer,
} from './MainTextOver.styles'

type Props = {
  mainTextOver: IMainTextOver
}

export const MainTextOver = ({ mainTextOver }: Props) => {
  if (mainTextOver.posts === null) {
    return null
  }
  const { grid_title, newsFlash, newsFlashColor } = mainTextOver
  const post = mainTextOver.posts[0]
  const { link, imageObj, title, secondaryTitle } = post
  const linkTarget = post.isBlank ? '_blank' : undefined

  return imageObj ? (
    <Container>
      <StyledComponentTitle componentTitle={grid_title} />
      <PostContainer>
        <ContentContainer>
          <Overlay />
          <TextContainer>
            {title && (
              <StyledLinkTitle href={link} target={linkTarget}>
                {title}
              </StyledLinkTitle>
            )}
            {secondaryTitle && (
              <StyledLinkSubtitle href={link} target={linkTarget}>
                {secondaryTitle}
              </StyledLinkSubtitle>
            )}
            <Credits credits={post} color={colors.white} />
          </TextContainer>
          <StyledLinkImage href={link} target={linkTarget}>
            <Image image={imageObj} alt={title || secondaryTitle} />
          </StyledLinkImage>
        </ContentContainer>
        <NewsflashesContainer>
          <Newsflashes
            newsFlashColor={newsFlashColor}
            newsFlash={newsFlash.newsFlashArr}
            width={300}
          />
        </NewsflashesContainer>
      </PostContainer>
    </Container>
  ) : null
}
