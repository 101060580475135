import { useRef } from 'react'
import { HiddenInput, StyledBtn } from './Image.styles'

export const CopyImage = ({ src }: { src: string }) => {
  const InputRef = useRef<HTMLInputElement>(null)
  if (!src) {
    return null
  }
  const copyPushImage = (event: React.MouseEvent<HTMLDivElement>) => {
    if (InputRef.current) {
      event.stopPropagation()
      event.preventDefault()
      InputRef.current.select()
      navigator.clipboard.writeText(InputRef.current.value)
      alert('קישור לתמונה:\n' + InputRef.current.value)
    } else {
      alert('משהו השתבש :(')
    }
  }
  return (
    <>
      <StyledBtn onClick={copyPushImage} theme={{ icon: 'image' }} />
      <HiddenInput
        type={'hidden'}
        defaultValue={src.replace(/upload\/.*\/v/, 'upload/t_img-push/v') || ''}
        ref={InputRef}
      />
    </>
  )
}
export const CopyLink = ({ url }: { url: string | URL }) => {
  url = window.location.origin + url.toString().split('?')[0]
  const InputRef = useRef<HTMLInputElement>(null)
  if (!url) {
    return null
  }

  const copyPushImage = (event: React.MouseEvent<HTMLDivElement>) => {
    if (InputRef.current) {
      event.stopPropagation()
      event.preventDefault()
      InputRef.current.select()
      navigator.clipboard.writeText(InputRef.current.value)
      alert('קישור:\n' + InputRef.current.value)
    } else {
      alert('משהו השתבש :(')
    }
  }

  return (
    <>
      <StyledBtn onClick={copyPushImage} theme={{ icon: 'link' }} />
      <HiddenInput
        type={'hidden'}
        defaultValue={url as string}
        ref={InputRef}
      />
    </>
  )
}
