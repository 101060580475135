import { ICredits } from '../../../types'
import { DateTime } from '../../../utils'
import {
  Container,
  DateContainer,
  UpdateContainer,
  Writer,
} from './ArticleCredits.styles'

type Props = {
  credits: ICredits
}

export const ArticleCredits = ({ credits }: Props) => {
  const date = DateTime.parse(credits.publishDate)
  const lastUpdatedTime = date?.toPrettyFormat()

  const updateTimeObj = credits.updateTimeObj
  const writers = credits.writers?.filter(w => w.name) || []
  return (
    <Container>
      {writers.length > 0 ? (
        writers.map((writer, index) => (
          <Writer key={writer.name} href={writer.link}>
            {`${writer.name}${
              index === credits.writers.length - 1 ? ' | ' : ', '
            }`}
          </Writer>
        ))
      ) : (
        <span>{credits.writers[0].name}&nbsp;|&nbsp;</span>
      )}
      <DateContainer>{lastUpdatedTime}</DateContainer>
      {updateTimeObj?.isShowUpdateTime && (
        <UpdateContainer>
          {DateTime.parse(updateTimeObj.updateTime)?.toPrettyFormat()}
        </UpdateContainer>
      )}
    </Container>
  )
}
