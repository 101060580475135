import styled from 'styled-components'
import { colors } from '../../../styles/GlobalVariables'

export const ComponentContainer = styled.div`
  position: relative;
  width: 100%;

  .swiper-slide {
    min-width: 140px;
  }
`

export const BigPostContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid
    ${props => (props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2)};
  margin-bottom: 16px;
  padding-bottom: 16px;

  & img {
    height: 193px;
    width: 100%;
    &:active {
      filter: brightness(80%);
    }
  }
`

export const BigPostContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const BigPostTitle = styled.div`
  color: ${props => props.theme.fontColor};
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

export const BigPostCredit = styled.div`
  color: ${props =>
    props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2};
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`

export const Post = styled.div`
  display: flex;
  width: 140px;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;

  & img {
    height: 102px;
    width: 100%;
    &:active {
      filter: brightness(80%);
    }
  }
`

export const PostTitle = styled.div`
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.32px;
  color: ${props => props.theme.fontColor};
  max-height: 50px;
  overflow: hidden;
`

export const PostCredit = styled.div`
  color: ${props => props.theme.fontColor};
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`
