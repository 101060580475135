import styled, { css, keyframes } from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import { Align, Axis } from './Marquee'

const horizontalAnimOffset = '0px'
const verticalAnimOffset = '35px'

const getSlideAnimation = (
  offset: number,
  animationTime: number,
  axis?: Axis,
  directionReverse?: boolean
) => {
  const animation = translateAnimation(offset, axis, directionReverse)
  return css`
    animation: ${animation} ${animationTime}s linear infinite;
  `
}

const translateAnimation = (
  offset: number,
  axis?: Axis,
  directionReverse?: boolean
) => keyframes`
    from {
        transform: translate${axis || 'X'}(${100 * offset}%);
    }
    to {
        transform: translate${axis || 'X'}(${
  (directionReverse ? -100 : 100) + 100 * offset
}%);
    }
`

export const Slide = styled.div<{
  axis?: Axis
  offset: number
  directionReverse?: boolean
  animationTime: number
}>`
  white-space: nowrap;
  position: absolute;
  ${props =>
    getSlideAnimation(
      props.offset,
      props.animationTime,
      props.axis,
      props.directionReverse
    )};
  top: unset;
  right: ${verticalAnimOffset};

  ${device.medium} {
    ${props => (props.axis === 'X' ? 'height: 100%;' : 'width: 100%')}
    top: ${horizontalAnimOffset};
    right: unset;
  }
`

export const Container = styled.div<{
  width?: string
  height?: string
  pauseOnHover?: boolean
  childrenMounted: boolean
}>`
  display: flex;
  overflow: hidden;
  align-items: center;
  position: relative;
  height: ${props => props.height ?? '5rem'};
  width: ${props => props.width ?? '100%'};
  cursor: pointer;

  ${Slide} {
    animation-play-state: ${props =>
      props.childrenMounted ? 'running' : 'paused'};
  }

  :hover {
    ${Slide} {
      animation-play-state: ${props => props.pauseOnHover && 'paused'};
    }
  }
`

export const ItemContainer = styled.div<{
  axis?: Axis
  contentReverse?: boolean
  align?: Align
}>`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  flex-direction: ${props =>
    props.axis === 'X'
      ? props.contentReverse
        ? 'row-reverse'
        : 'row'
      : props.axis === 'Y'
      ? props.contentReverse
        ? 'column-reverse'
        : 'column'
      : 'row'};
  align-items: ${props =>
    props.align === 'center' ? 'center' : `flex-${props.align}`};

  ${device.medium} {
    white-space: normal;
  }
`
