import styled from 'styled-components'
import { device, layout } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
`

export const CustomIframe = styled.iframe<{
  css?: string
  theme?: { height: string | boolean }
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: ${layout.gridBlockGap};
  }

  ${({ css }) => css}
  height: ${({ theme }) => (theme.height ? theme.height : '100')}px;
`
