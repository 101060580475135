import Link from 'next/link'
import styled from 'styled-components'
import { colors, device } from '../../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  white-space: pre;
  flex-wrap: wrap;
  &,
  > a {
    font-size: 14px;
    line-height: 1.2;
    color: ${props => props.color || colors.commentsGray};

    ${device.medium} {
      font-size: 15px;
      font-weight: bold;
    }
  }
`

export const DateContainer = styled.span`
  display: flex;
  &:before {
    content: 'פורסם: ';
  }
`

export const UpdateContainer = styled(DateContainer)`
  &:before {
    content: ', עודכן: ';
  }
`

export const Writer = styled(Link)`
  max-width: 100%;
  ${device.medium} {
    max-width: 70%;
  }
`
