import React, { useEffect, useState } from 'react'
import {
  CounterContainer,
  CounterItem,
  CounterItemDigit,
  CounterItemText,
} from '../ImageCounter.styles'
import { he } from '../../../locales/he'

type date = {
  startDate: Date
}

export const CounterUp = ({ startDate }: date) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const difference = now.getTime() - startDate.getTime()

      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)

      setTime({
        days,
        hours,
        minutes,
        seconds,
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [startDate])

  return (
    <CounterContainer>
      <CounterItem>
        <CounterItemDigit>{time.days}</CounterItemDigit>
        <CounterItemText>{he.days}</CounterItemText>
      </CounterItem>
      <CounterItem>
        <CounterItemDigit>{time.hours}</CounterItemDigit>
        <CounterItemText>{he.hours}</CounterItemText>
      </CounterItem>
      <CounterItem>
        <CounterItemDigit>{time.minutes}</CounterItemDigit>
        <CounterItemText>{he.minutes}</CounterItemText>
      </CounterItem>
      <CounterItem>
        <CounterItemDigit>{time.seconds}</CounterItemDigit>
        <CounterItemText>{he.seconds}</CounterItemText>
      </CounterItem>
    </CounterContainer>
  )
}
