import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import { Title } from '../ComponentTitle/ComponentTitle.styles'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-bottom: 28px;

  ${device.medium} {
    gap: 27px;
  }

  ${Title} {
    width: unset;
  }
`

export const PostsContainer = styled.div`
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 19px 30px;

  ${device.medium} {
    display: flex;
    grid-gap: 28px 14px;
  }

  ${device.large} {
    grid-gap: 28px 20.5px;
  }
`

export const PublisherPost = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  :last-child:nth-child(2n - 1) {
    grid-column: span 2;
  }

  ${device.medium} {
    flex-basis: 197.9px;
  }

  > img {
    max-width: 197.9px;
    min-height: 105.7px;
    max-height: 105.7px;

    object-fit: contain;
  }
`
