import styled from 'styled-components'
import { colors, device } from '../../../styles/GlobalVariables'
import Link from '../../Link/Link'

export const ArticleTagsContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;

  line-height: 1;
  font-size: 17px;
  max-height: 17px;
  margin-bottom: 15px;

  ${device.medium} {
    font-size: 20px;
    color: ${colors.anotherGrey};
    margin-bottom: 40px;
  }

  a + a {
    &:before {
      content: ', ';
    }
  }
`

export const Tag = styled(Link)`
  text-align: center;
  color: ${colors.anotherLightBlue};

  ${device.medium} {
    font-size: 20px;
  }
`
