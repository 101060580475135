import { useEffect, useState } from 'react'
import { IBroadcastWeek } from '../../types'
import { getShowProgress } from '../../utils'
import { Container } from './BroadcastWeekSchedule.styles'
import { Dates } from './Dates'
import { ScheduleShows } from './ScheduleShows'

type Props = {
  broadcastWeek: IBroadcastWeek[]
}

export const BroadcastWeekSchedule = ({ broadcastWeek }: Props) => {
  const [selectedDay, setSelectedDay] = useState(0)
  const [firstShowIndex, setFirstShowIndex] = useState(0)

  useEffect(() => {
    const shows = broadcastWeek[selectedDay].shows
    const currentlyPlayingShow = shows.find(
      (show, index) => getShowProgress(show, shows[index + 1]) > 0
    )

    if (!currentlyPlayingShow) {
      setFirstShowIndex(0)
      return
    }

    const currentlyPlayingShowIndex = shows.indexOf(currentlyPlayingShow)
    const firstShowIdx =
      currentlyPlayingShowIndex < 2 ? 0 : currentlyPlayingShowIndex - 2
    setFirstShowIndex(firstShowIdx)
  }, [broadcastWeek, selectedDay])

  return (
    <Container>
      <Dates
        broadcastWeek={broadcastWeek}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />
      {broadcastWeek.map(({ shows }, index) => (
        <ScheduleShows
          visible={index === selectedDay}
          shows={shows}
          firstShowIndex={firstShowIndex}
          key={`broadcastSchedule-${index}`}
        />
      ))}
    </Container>
  )
}
