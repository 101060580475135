import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { colors } from '../../styles/GlobalVariables'
import { IHeaderMenu } from '../../types'
import { urlsEqual } from '../../utils'
import Link from '../Link/Link'
import {
  ArrowWrapper,
  Container,
  SubMenuItem,
  SubMenuItemDot,
  SubMenuItems,
} from './SubMenu.styles'
import ArrowLeft from '/public/assets/images/svg/submenu-left-arrow.svg'
import ArrowRight from '/public/assets/images/svg/submenu-right-arrow.svg'

type Props = {
  subMenu: IHeaderMenu[]
  isFaceLift?: boolean
}

export const SubMenu = ({ subMenu, isFaceLift }: Props) => {
  const { asPath } = useRouter()
  const itemsRef = useRef<HTMLDivElement>(null)
  const lastItemRef = useRef<HTMLDivElement>(null)
  const highlightedItemRef = useRef<HTMLDivElement>(null)

  const [menuOverlay, setMenuOverlay] = useState(true)
  const [showButton, setShowButton] = useState({
    rightButton: false,
    leftButton: false,
  })

  const {
    rightButton: subMenuScrollRightButton,
    leftButton: subMenuScrollLeftButton,
  } = showButton

  useEffect(() => {
    const items = itemsRef?.current
    if (!items || subMenuScrollRightButton || subMenuScrollLeftButton) {
      return
    }

    if (items.scrollWidth > items.offsetWidth) {
      setShowButton({
        rightButton: subMenuScrollRightButton,
        leftButton: true,
      })
    }

    if (highlightedItemRef?.current?.clientWidth) {
      highlightedItemRef?.current?.scrollIntoView()
      scroll()
    }
  }, [itemsRef, subMenuScrollRightButton, subMenuScrollLeftButton])

  const scroll = (scrollDirection?: string) => {
    const subMenuItemsScrollCurrent = itemsRef?.current
    if (!subMenuItemsScrollCurrent) {
      return
    }

    let scrollOffsetWidth = 0

    if (scrollDirection) {
      scrollOffsetWidth =
        scrollDirection === 'left'
          ? -subMenuItemsScrollCurrent.offsetWidth
          : subMenuItemsScrollCurrent.offsetWidth

      subMenuItemsScrollCurrent.scrollLeft += scrollOffsetWidth
    }

    const isScrolledRight = subMenuItemsScrollCurrent.scrollLeft < 0
    const isScrolledLeft =
      Math.floor(
        subMenuItemsScrollCurrent.scrollWidth +
          subMenuItemsScrollCurrent.scrollLeft -
          1
      ) > subMenuItemsScrollCurrent.offsetWidth

    setShowButton({
      rightButton: isScrolledRight,
      leftButton: isScrolledLeft,
    })
  }

  const handleOnScroll = () => {
    if (!itemsRef.current || !lastItemRef.current) {
      return
    }

    const scrolled = itemsRef.current.scrollLeft * -1
    const scrollableArea =
      itemsRef.current.scrollWidth - itemsRef.current.offsetWidth
    const lastItemWidth = lastItemRef.current.clientWidth

    setMenuOverlay(scrolled < scrollableArea - lastItemWidth)
  }

  const subMenuItems = useMemo(
    () =>
      subMenu.map(({ title, link, target }, index) => {
        const active = urlsEqual(link, asPath)
        const ref =
          index === subMenu.length - 1
            ? lastItemRef
            : active
            ? highlightedItemRef
            : undefined

        return (
          <SubMenuItem
            key={`subMenuItem-${index}`}
            as={active && !isFaceLift ? 'h1' : 'div'}
            active={active}
            color={isFaceLift ? colors.midnightBlue : colors.headerBlue}
            ref={ref}
          >
            <Link href={link} target={target}>
              <p
                style={{
                  borderBottom: active ? `solid 2px ${colors.pink}` : 'unset',
                }}
              >
                {title}
              </p>
              <SubMenuItemDot />
            </Link>
          </SubMenuItem>
        )
      }),
    [asPath, subMenu, isFaceLift]
  )

  return (
    <Container>
      {subMenuScrollRightButton && (
        <ArrowWrapper
          direction='right'
          tabIndex={0}
          onClick={() => scroll('right')}
        >
          <ArrowRight />
        </ArrowWrapper>
      )}

      <SubMenuItems
        ref={itemsRef}
        onScroll={handleOnScroll}
        menuOverlay={menuOverlay}
      >
        {subMenuItems}
      </SubMenuItems>

      {subMenuScrollLeftButton && (
        <ArrowWrapper
          direction='left'
          tabIndex={0}
          onClick={() => scroll('left')}
        >
          <ArrowLeft />
        </ArrowWrapper>
      )}
    </Container>
  )
}
