import {
  Container,
  Description,
  Logo,
  RedirectSection,
  StyledLink,
  TextSection,
  Title,
} from './NotFound.styles'

import styled from 'styled-components'
import { he } from '../../locales/he'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'

const TaboolaWrap = styled.div`
  max-width: 1290px;
  margin: auto;
  margin-top: 150px;
`

export const NotFound = () => {
  return (
    <Container>
      <RedirectSection>
        <Logo
          loading='lazy'
          mobile={'/assets/images/logo-404-desktop.png'}
          desktop={'/assets/images/logo-404-desktop.png'}
        />
        <TextSection>
          <Title>{he.notFoundTitle}</Title>
          <Description>{he.notFoundDescription}</Description>
          <StyledLink href='/'>{he.notFoundButton}</StyledLink>
        </TextSection>
      </RedirectSection>
      <TaboolaWrap>
        <Taboola
          pageType={PocTaboolaPageType.notFound}
          suffixId={PocTaboolaSuffixId.pageFooter}
        />
      </TaboolaWrap>
    </Container>
  )
}
