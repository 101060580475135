import { useRouter } from 'next/router'
import React from 'react'
import { IBreadcrumb } from '../../types'
import { urlsEqual } from '../../utils'
import { Container, StyledLink, StyledTitle } from './BreadcrumbsFl.styles'
import BreadcrumbLeftArrow from '../../../public/assets/images/svg/breadcrumb-left_fl-arrow .svg'
import { colors } from '../../styles/GlobalVariables'
import { useFL } from '../../hooks/useFL'

type Props = {
  breadcrumbs: IBreadcrumb[]
}

export const BreadcrumbsFl = ({ breadcrumbs }: Props) => {
  const { isDarkTheme } = useFL()
  const { asPath } = useRouter()

  return (
    <Container theme={{ isDarkTheme: isDarkTheme }}>
      {breadcrumbs?.map(({ link, title }, index) => (
        <React.Fragment key={'crumb' + index}>
          {index !== 0 && <BreadcrumbLeftArrow />}
          <StyledLink href={!urlsEqual(link, asPath) ? link : undefined}>
            <StyledTitle
              hoverColor={isDarkTheme ? colors.white : colors.black}
              isLast={index === breadcrumbs.length - 1}
              theme={{ isDarkTheme: isDarkTheme }}
              fontWeight={index === breadcrumbs.length - 1 ? '500' : '300'}
            >
              {title}
            </StyledTitle>
          </StyledLink>
        </React.Fragment>
      ))}
    </Container>
  )
}
