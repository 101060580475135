import { useEffect, useState } from 'react'
import { usePage } from '../../contexts'
import { ITermHeadBlock } from '../../types'
import { Image } from '../Image/Image'
import { Player } from '../Player/Player'
import { Shave } from '../Shave/Shave'
import {
  Container,
  SecondaryTitle,
  TextContainer,
  Thumbnail,
  Title,
} from './TermHeadBlock.styles'

type Props = {
  termHead: ITermHeadBlock
}

export const TermHeadBlock = ({
  termHead: { imageObj, video, title, subTitle },
}: Props) => {
  const pageMeta = usePage()?.PageMeta
  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    setShowVideo(false)
    setTimeout(() => setShowVideo(true), 250)
  }, [video])

  return (
    (video || imageObj || title) && (
      <Container>
        {video && showVideo ? (
          pageMeta && (
            <Player pageMeta={pageMeta} video={video} img={video.poster} />
          )
        ) : (
          <Thumbnail>
            <Image image={imageObj} alt={title} />
          </Thumbnail>
        )}
        {title && (
          <TextContainer>
            <Title as={pageMeta?.isFaceLift ? 'h1' : 'div'}>{title}</Title>
            <Shave maxLines={10}>
              <SecondaryTitle dangerouslySetInnerHTML={{ __html: subTitle }} />
            </Shave>
          </TextContainer>
        )}
      </Container>
    )
  )
}
