import { SwiperButton } from '../Calendar/Calendar.styles'
import { Swiper as SwiperClass } from 'swiper/types'
import React from 'react'

export const NavButton = React.forwardRef<
  HTMLDivElement,
  {
    role: 'next' | 'prev'
    swiperRef: SwiperClass | undefined
  }
>((props, ref) => {
  const { role, swiperRef } = props
  const handleClick = () => {
    switch (role) {
      case 'next':
        swiperRef?.slidePrev()
        break
      case 'prev':
        swiperRef?.slideNext()
        break
    }
  }
  return <SwiperButton ref={ref} onClick={handleClick} theme={{ role: role }} />
})
