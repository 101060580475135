import { IHeaderItem } from '../../types'
import Link from '../Link/Link'
import { isInternalUrl } from '../../utils'
import { Image } from '../Image/Image'

type Props = {
  headerItem?: IHeaderItem
}

export const ImageClickable = ({ headerItem }: Props) => {
  if (!headerItem) {
    return <></>
  }

  return (
    <Link
      target={isInternalUrl(headerItem.link) ? '' : '_blank'}
      href={headerItem.link || '#'}
    >
      <Image image={headerItem.imageObj}></Image>
    </Link>
  )
}
