import { ICredits } from '../../types'
import { DateTime } from '../../utils'
import {
  Container,
  Date,
  FirstWriter,
  PromotionalText,
  SecondWriter,
  SecondWriterComma,
  Writers,
} from './Credits.styles'

type Props = {
  credits: ICredits
  color?: string
  hide_date?: boolean
}

const Credits = ({ credits, color, hide_date }: Props) => {
  if (credits.isPromotionalItem) {
    return (
      <PromotionalText
        href={credits.writers?.[0].link || undefined}
        color={color}
      >
        {credits.writers?.[0].name}
      </PromotionalText>
    )
  }

  const { name: writerName, link: writerLink } = credits.writers?.[0] ?? {}
  const { name: secondWriterName, link: secondWriterLink } =
    credits.writers?.[1] ?? {}
  const date = DateTime.parse(credits.updateDate ?? credits.publishDate)
  const lastUpdatedTime = date?.toPrettyFormat()

  return (
    <Container color={color}>
      <Writers>
        {writerName && (
          <FirstWriter href={writerLink}>{writerName}</FirstWriter>
        )}
        {secondWriterName && (
          <>
            <SecondWriterComma>,&nbsp;</SecondWriterComma>
            <SecondWriter href={secondWriterLink}>
              {secondWriterName}
            </SecondWriter>
          </>
        )}
      </Writers>
      {!hide_date && (
        <Date>
          {writerName && lastUpdatedTime && <>&nbsp;|&nbsp;</>}
          {lastUpdatedTime && <span>{lastUpdatedTime}</span>}
        </Date>
      )}
    </Container>
  )
}

export default Credits
