import { AdId, useGridBlock } from '../../../contexts'
import { ILayout7 } from '../../../types'
import Ad from '../../Ads/Ad'
import ComponentTitle from '../../ComponentTitle/ComponentTitle'
import { Post } from '../../Post/Post'
import { Banner, Container, PostsContainer } from './Layout7.styles'

type Props = {
  layout: ILayout7
}

export const Layout7 = ({ layout }: Props) => {
  const posts = layout.posts.filter(post => post.image != '')
  const posts1 = posts.slice(0, 3)
  const posts2 = posts.slice(3)

  const { grid_title, layout_mobile, isIframe } = layout
  const { gridId } = useGridBlock()

  return posts ? (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <PostsContainer>
        {posts1.map(post => (
          <Post
            key={post.id}
            post={post}
            layoutMobile={layout_mobile}
            height='263px'
            hideSecondaryTitle
          />
        ))}
        {!isIframe && (
          <Banner key={gridId}>
            <Ad id={layout.adUnit as AdId} />
          </Banner>
        )}
        {posts2.map(post => (
          <Post
            key={post.id}
            post={post}
            layoutMobile={layout_mobile}
            hideSecondaryTitle
          />
        ))}
      </PostsContainer>
    </Container>
  ) : null
}
