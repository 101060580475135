import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Mousewheel } from 'swiper'
import orderBy from 'lodash/orderBy'

import { IGenre, IVodGenreBlock } from '../../types'
import {
  Container,
  Genre,
  Text,
  NavigationOverlay,
  SwiperSection,
} from './VodGenresCarousel.styles'
import { colors, size } from '../../styles/GlobalVariables'
import ComponentTitle from '../ComponentTitle/ComponentTitle'

import 'swiper/css'
import 'swiper/css/navigation'

type Props = {
  item: IVodGenreBlock
}

type IColoredGenre = IGenre & {
  color: string
}

const genreColors = [colors.pink, colors.green, colors.yellow]

const generateGenreColors = (list: IGenre[] | undefined) => {
  if (!list) {
    return
  }
  const genreListColor: IColoredGenre[] = orderBy(
    list
      .filter(item => item.link.length > 0)
      .map(_item => _item as IColoredGenre),
    item => parseInt(item.position, 10)
  )
  for (let i = 0; i < genreListColor.length; i++) {
    const color = genreColors[i % genreColors.length]
    genreListColor[i].color = color
    if (i === 0) {
      continue
    }
    const j = genreListColor.length - i
    if (j <= i) {
      return genreListColor
    }
    genreListColor[j].color = color
  }

  return genreListColor
}

export const VodGenresCarousel = ({ item: vodGreenBlock }: Props) => {
  const genreColors = generateGenreColors(vodGreenBlock?.genres)

  const breakpoints = {
    [size.small]: {
      slidesPerView: 2,
    },
    [size.medium]: {
      slidesPerView: 'auto' as const,
      slidesOffsetAfter: 20,
      slidesOffsetBefore: 20,
    },
  }

  return (
    <Container>
      <ComponentTitle componentTitle={vodGreenBlock.grid_title} />
      <SwiperSection>
        <Swiper
          breakpoints={breakpoints}
          slidesPerView={'auto'}
          navigation={true}
          loop={true}
          centeredSlides={true}
          modules={[Navigation, Mousewheel]}
          mousewheel={{
            forceToAxis: true,
            invert: true,
          }}
          className='vodGenresCarousel'
        >
          {genreColors?.map((genre, index) => (
            <SwiperSlide key={index}>
              <Genre href={genre?.link} backgroundColor={genre.color}>
                <Text>{genre.name}</Text>
              </Genre>
            </SwiperSlide>
          ))}
        </Swiper>
        <NavigationOverlay position='left' />
        <NavigationOverlay position='right' />
      </SwiperSection>
    </Container>
  )
}
