import styled from 'styled-components'
import { colors, device, maxWidth } from '../../styles/GlobalVariables'
import { Container as Newsflashes } from '../Newsflashes/Newsflashes.styles'

export const Container = styled.div`
  background: ${colors.tvGuideDark};
  position: relative;

  ${device.medium} {
    max-width: ${maxWidth.medium};
    padding: 0;
    margin: 0 auto;
  }

  ${device.large} {
    max-width: ${maxWidth.large};
  }
`

export const PageTitleWrapper = styled.div`
  padding: 0 20px;

  ${device.medium} {
    padding: 0;
  }
`

export const ScrollToPlayer = styled.div`
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

export const ToSchedule = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;

  height: 90px;
  min-width: 90px;
  max-width: 90px;
  background-color: ${colors.pink};
  border-radius: 100%;

  position: absolute;
  left: 20px;
  top: 175px;

  ${device.medium} {
    top: 200px;
    left: 8px;
    height: 114px;
    min-width: 114px;
  }

  > span {
    text-align: center;
    max-width: 60px;
    line-height: 1.1;
    font-size: 14px;
    font-weight: 900;
    color: ${colors.white};
    margin-top: 14px;
    margin-bottom: 3px;

    ${device.medium} {
      line-height: 1;
      max-width: 81px;
      font-size: 20px;
      font-weight: 800;
      margin-top: 19px;
    }
  }

  > svg {
    font-size: 14px;

    ${device.medium} {
      font-size: 18px;
      margin-bottom: 12px;
    }
  }
`

export const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: url('https://media.reshet.tv/image/upload/v1657096625/uploads/2022/tv-guide-bg.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  margin-bottom: 20px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  gap: 30px;
`

const leftColumnWidth = 300
export const RightColumn = styled.div`
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;

  [class^='Playerstyles__WrapStyledPlayerComp'] {
    width: 100%;
  }

  ${device.medium} {
    flex: 1 0 75%;
    margin-bottom: 30px;

    [class^='Playerstyles__WrapStyledPlayerComp'] {
      width: 100%;
      max-height: 540px;
    }

    img {
      max-width: 960px;
    }
  }
`

export const PlayerWrapper = styled.div`
  display: block;
  max-width: 100%;

  ${device.medium} {
    display: flex;
    flex: 1;
    margin-bottom: 30px;
  }
`

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex: 0 0 ${leftColumnWidth}px;

  [id^='taboola'] {
    margin-top: 20px;
    max-width: ${leftColumnWidth}px;
  }

  ${device.medium} {
    ${Newsflashes} {
      max-height: 540px;
      margin-bottom: 30px;
    }
  }
`
