import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const PostsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 19px 15px;

  ${device.medium} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }

  ${device.large} {
    gap: 30px;
  }
`
