import {
  createRef,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { usePage } from '../../contexts'
import { he } from '../../locales/he'
import { ILiveBroadcast, IPost } from '../../types'
import Newsflashes from '../Newsflashes/Newsflashes'
import { Player } from '../Player/Player'
import {
  Container,
  NewsflashesContainer,
  NowLiveText,
  NowLiveTitle,
  PlayerPlaceHolder,
  PlayerWrapper,
  PostContainer,
  StyledComponentTitle,
  Title,
} from './LiveBroadcast.styles'
import { PageTitle } from '../PageTitle/PageTitle'

type Props = {
  liveBroadcast: ILiveBroadcast
  isFirst: boolean
}

export const LiveBroadcast = ({
  liveBroadcast: { posts, newsFlash, newsFlashColor, grid_title },
  isFirst,
}: Props) => {
  const page = usePage()
  const isDarkTheme = page?.isDarkTheme || false
  const pageMeta = usePage()?.PageMeta
  const SEO = usePage()?.SEO
  const nowLiveTitleRef: RefObject<HTMLDivElement> = createRef()
  const [truncateText, setTruncateText] = useState(false)

  const PlayerComp = useRef<JSX.Element | null>(null)

  useMemo(() => {
    if (!PlayerComp.current && pageMeta) {
      const post: IPost = posts[0]
      const { image, video } = post
      PlayerComp.current = (
        <Player
          img={image}
          video={video}
          pageMeta={pageMeta}
          key={video.name}
        />
      )
    }
  }, [pageMeta, posts])
  useEffect(() => {
    const nowLiveTitleCurrent = nowLiveTitleRef?.current
    if (!nowLiveTitleCurrent) {
      return
    }

    setTruncateText(
      nowLiveTitleRef?.current?.scrollHeight >
        nowLiveTitleRef?.current?.clientHeight + 2
    )
  }, [nowLiveTitleRef])

  if (posts === null) {
    return null
  }

  const post: IPost = posts[0]
  const { image, title } = post

  return (
    <Container>
      <PostContainer>
        <PlayerWrapper>
          <PlayerPlaceHolder theme={{ bg: image }}>
            {PlayerComp.current}
          </PlayerPlaceHolder>
          {title && (
            <Title>
              <NowLiveTitle
                ref={nowLiveTitleRef}
                truncateText={truncateText}
                isDarkTheme={isDarkTheme}
              >
                <NowLiveText>{he.nowLive}</NowLiveText>
                {title}
              </NowLiveTitle>
            </Title>
          )}
        </PlayerWrapper>
        <NewsflashesContainer>
          <Newsflashes
            newsFlashColor={newsFlashColor}
            newsFlash={newsFlash.newsFlashArr}
            width={300}
          />
        </NewsflashesContainer>
      </PostContainer>
      <StyledComponentTitle componentTitle={grid_title} />
      {isFirst && pageMeta && SEO && (
        <PageTitle SEO={SEO} pageMeta={pageMeta} />
      )}
    </Container>
  )
}
