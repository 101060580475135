import styled from 'styled-components'
import Link from '../../Link/Link'

export const Container = styled(Link)`
  display: flex;
  width: 308px;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
  cursor: pointer;
`

export const Time = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #000721;
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 11px;
  overflow: hidden;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TimeContainer = styled.div`
  position: absolute;
  background-color: #01deff;
  width: 58px;
  height: 28px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  width: 100%;
  height: 168px;
  object-fit: cover;
`

export const Title = styled.p<{ fontColor?: string }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: ${props => props.fontColor || '#000'};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SecondaryTitle = styled.p<{ fontColor?: string }>`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: ${props => props.fontColor || '#000'};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
