import styled from 'styled-components'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 30px;
`

export const ImageLink = styled(Link)`
  position: relative;
  flex: 1;
  flex-direction: column;
  display: flex;
`
