import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Shows = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
  gap: 4px;

  ${device.medium} {
    gap: 16px;
  }
`

export const Show = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  padding: 20px 0 20px 20px;
  background: ${colors.mediumGrayBg};
  position: relative;

  ${device.medium} {
    padding: 12px 0 24px 32px;
    gap: 47px;
  }
`

export const ProgressBar = styled.div<{ progress?: number }>`
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ progress }) => (progress ? `calc(100% - ${progress}%)` : '100%')};
  right: 0;
  border-radius: inherit;
  background: ${colors.pink};
`

export const TextContainer = styled.div`
  flex: 1;
  display: flex;

  > span,
  > a {
    color: ${colors.menuOptionGray};
  }

  z-index: 5;
`

export const ShowTime = styled.span<{ isCurrentlyPlaying: boolean }>`
  display: flex;
  flex: 0 0 100px;
  font-size: 17px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  min-height: 40px;

  ${device.medium} {
    flex: 0 0 125px;
    font-size: 24px;
    font-weight: 500;
    min-height: 53px;
  }
`

export const OnNow = styled.span`
  font-size: 13px;
  font-weight: bold;
  color: ${colors.menuOptionGray};

  ${device.medium} {
    font-size: 18px;
    font-weight: 900;
  }
`

export const Separator = styled.div`
  height: 100%;
  min-width: 2px;
  align-self: center;
  margin-left: 20px;
  background: ${colors.menuOptionGray};
`

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;

  ${device.medium} {
    gap: 5px;
  }
`

export const Title = styled.span`
  font-size: 17px;
  font-weight: 500;

  ${device.medium} {
    font-size: 24px;
  }
`

export const FirstArrow = styled.span<{
  isCurrentlyPlaying: boolean
  isOverProgressBar: boolean
}>`
  color: ${({ isOverProgressBar, isCurrentlyPlaying }) =>
    isCurrentlyPlaying && isOverProgressBar
      ? colors.menuOptionGray
      : colors.pink};
`

export const SecondArrow = styled.span<{
  isCurrentlyPlaying: boolean
  isOverProgressBar: boolean
}>`
  color: ${({ isOverProgressBar, isCurrentlyPlaying }) =>
    isCurrentlyPlaying && isOverProgressBar
      ? colors.menuOptionGray
      : colors.pink};
`

export const Description = styled.span`
  font-size: 14px;

  ${device.medium} {
    font-size: 16px;
  }
`
