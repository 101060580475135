import styled from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'
import { getRainbowBorderImage } from '../../utils'

export const Title = styled.h2<{ isDarkTheme: boolean }>`
  font-size: ${props => (props.isDarkTheme ? 18 : 28)}px;
  line-height: 1;
  font-weight: bold;
  color: ${props => (props.isDarkTheme ? colors.white : colors.black)};
  max-height: calc(1 * 28px);
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${device.medium} {
    width: 100%;
    font-size: ${props => (props.isDarkTheme ? 24 : 36)}px;
    line-height: 1.39;
    max-height: 50px;
  }

  ${themeClassName.vodMain} & {
    color: ${colors.white};
    font-size: 18px;
    font-weight: 500;
    line-height: normal;

    ${device.medium} {
      font-size: 24px;
      line-height: 1.25;
    }
  }
`

export const LeftText = styled.a<{ color: string; isDarkTheme: boolean }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5px 6px 1.5px 4px;
  margin-right: 10px;
  position: relative;
  ${props => (props.isDarkTheme ? '' : `background-color: ${props.color};`)}
  gap: 6px;

  // fixes bug where on mobile there is a distance between this and the parent's border
  margin-bottom: -0.25px;

  :after {
    ${props =>
      !props.isDarkTheme &&
      props.color.toLowerCase() === colors.darkBlue.toLocaleLowerCase() && {
        bottom: '-3px',
        borderBottom: `3px solid ${props.color}`,
      }}
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    transform-origin: left bottom;
    transform: skewX(-20deg);
    ${props => (props.isDarkTheme ? '' : `background-color: ${props.color};`)}
  }

  ${device.medium} {
    font-weight: bold;
    padding: 2px 6px 2px 8px;
    gap: 4px;
  }

  > a {
    z-index: 1;
  }

  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    transform: translate(0%, 10%);

    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: ${colors.white};

    ${device.medium} {
      font-size: 16px;
    }
  }

  > svg {
    z-index: 1;
    font-size: 10.7px;

    path {
      fill: ${colors.white};
    }
  }

  ${themeClassName.vodMain} & {
    background: none;
    padding: 2px 0;

    :after {
      display: none;
    }
  }
`

export const Logo = styled.div`
  display: flex;

  > img {
    flex: 1 !important;
    flex-shrink: 0 !important;
    max-height: 50px !important;

    ${device.medium} {
      max-height: 66px;
    }
  }
`

export const Rainbow = styled.div<{
  leftTextWidth: number | null
  renderRainbow: boolean
  lineColor: string
}>`
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 3px;

  ${props =>
    props.renderRainbow
      ? getRainbowBorderImage(props.leftTextWidth, true)
      : `background: 3px solid ${props.lineColor};`}
  ${device.medium} {
    ${props =>
      props.renderRainbow
        ? getRainbowBorderImage(props.leftTextWidth, false)
        : `background: 3px solid ${props.lineColor};`}
  }
`

export const Container = styled.div<{
  lineColor: string
  columnSpan?: number
  containerWidth: number | null
  leftTextWidth: number | null
  renderRainbow: boolean
  isDarkTheme: boolean
}>`
  grid-column: span ${props => props.columnSpan || 1};
  display: flex;
  ${props =>
    props.isDarkTheme ? '' : `border-bottom: 3px solid ${props.lineColor};`}
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 20px;
  position: relative;
  border-top: 0;
  border-left: 0;
  border-right: 0;

  ${device.medium} {
    margin: 0 0 28px;
  }

  ${themeClassName.vodMain} & {
    border: unset;

    ${Rainbow} {
      display: none;
    }
  }
`
