import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #9592a5;
  margin-bottom: 16px;
  padding-bottom: 16px;
  ${device.medium} {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
`

export const PostsContainer = styled.div`
  display: flex;
  position: relative;

  ${device.medium} {
    display: flex;
    column-gap: 20px;
    position: relative;
  }

  ${device.large} {
    column-gap: 29px;
  }

  .swiper-slide {
    min-height: 145px;
    min-width: 143px;

    ${device.medium} {
      min-height: 234px;
      min-width: 308px;
    }
  }
`
