import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const ClickableDiv = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  color: #fff;
  border: 1px solid #4d5164;
  width: 160px;

  ${device.medium} {
    display: flex;
    width: 156px;
    height: 40px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border: 1px solid #4d5164;
    color: #fff;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      border: 1px solid #9592a5;
    }
  }
`

export const TagsDiv = styled.div`
  ${device.medium} {
    background: #fff;
    position: absolute;
    z-index: 33;
    top: 42px;
    right: 0;
    width: 170px;
  }
`
export const MobileTagsDivTopContentWrapper = styled.div`
  height: 100vh;
  position: absolute;
  visibility: hidden;
  z-index: 32;
  background: #000;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  top: -20px;
  transition: visibility 0.05s ease;

  &.wrapper_visible {
    visibility: visible;
  }
  &.wrapper_hidden {
    visibility: hidden;
  }
`

export const MobileTagsDiv = styled.div`
  width: 100%;
  position: fixed;
  border-radius: 16px 16px 0 0;
  background: #4d5164;
  z-index: 33;
  bottom: -30px;
  height: 50%;
  left: 0;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 24px;
  align-items: center;
  transition: height 0.2s ease;

  &.expanded {
    height: 50vh;
  }
  &.collapsed {
    height: 0;
  }

  & .timeLineMobileTag {
    display: flex;
    padding: 11px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.32px;
  }
`
export const MobileTagsDivSeparator = styled.div`
  width: 35px;
  height: 2px;
  border-radius: 16px;
  background: #f0f0f0;
`
export const MobileTagsDivTitle = styled.div`
  display: flex;
  padding: 8px 8px 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.32px;
  border-bottom: 1px solid #9592a5;
`

export const Tag = styled.div`
  ${device.medium} {
    color: #5b5b5b;
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 120% */
    letter-spacing: 0.3px;

    &.active {
      font-weight: 500;
    }
  }

  color: #5b5b5b;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;

  &.active {
    font-weight: 500;
  }
`

export const TagsWrapper = styled.div`
  font-size: 18px;
  margin-top: 20px;
  width: 165px;
  position: relative;

  div.timeLineTag {
    padding: 5px 12px;
    width: 200px;
    cursor: pointer;
    margin-right: 10px;
    color: #5b5b5b;
  }

  div.all {
    color: #000;
  }

  div.active {
    background-color: ${props => props.theme.color};
    color: #000;
  }

  margin-bottom: 20px;
  ${device.small} {
    margin-bottom: unset;
  }
`

export const MainItemWrapper = styled.div`
  ${device.medium} {
    display: flex;
    //    padding-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
`

export const ShareButtonsWrapper = styled.div`
  ${device.medium} {
    margin-bottom: 40px !important;
  }
`

export const ShareButtonsMobileWrapper = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.32px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;

  img {
    width: 24px;
    height: 24px;
    padding: 4px 6px 3px 6px;
  }
`

export const TimeLineItem = styled.div`
  ${device.medium} {
    display: flex;
    width: 100%;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-bottom: 1px solid #4d5164;
    margin-bottom: 32px;

    ${ShareButtonsWrapper} {
      margin-bottom: 40px;

      & svg path {
        fill: #fff;
      }

      & button:hover {
        & svg path {
          fill: #9592a5;
        }
      }
    }
  }

  display: flex;
  width: 100vw;
  padding: 16px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  ${device.small} {
    margin-bottom: 32px;
    border-radius: 0;
    border-bottom: solid 1px #4d5164;
    &:not(.last):after {
      content: unset;
    }
  }
`

export const ItemSeparator = styled.div`
  border-bottom: 1px solid #4d5164;
  width: 92vw;
  position: relative;
  right: 16px;
`

export const ItemTitle = styled.div`
  ${device.medium} {
    color: #fff;
    text-align: right;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

export const ItemContent = styled.div`
  p {
    margin-bottom: 20px;
  }

  figure {
    margin-top: 0;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      height: 34%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.75)
      );
    }
  }

  figcaption {
    color: #fff !important;
    position: absolute;
    top: 85%;
    right: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    height: 27px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    z-index: 3;
  }

  overflow-y: hidden;
  display: inline;
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
  }

  a:hover {
    color: #9592a5;
  }

  img {
    width: 100%;
    flex-shrink: 0;
    padding: 0;
  }

  ${device.medium} {
    overflow-y: hidden;
    display: inline;
    color: #fff;

    a {
      color: #fff;
      text-decoration: underline;
    }

    a:hover {
      color: #9592a5;
    }

    figure {
      margin-top: 0;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 5px;
        width: 100%;
        height: 34%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.75)
        );
      }
    }

    figcaption {
      color: #fff !important;
      position: absolute;
      top: 91%;
      right: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      height: 27px;
      overflow: hidden;
      z-index: 44;
    }

    img {
      width: 100%;
      height: 446px;
      flex-shrink: 0;
      padding: 0;
    }
  }
`

export const Anchor = styled.div<{ headerHeight: number }>`
  position: absolute;
  top: -${props => props.headerHeight}px;
  display: block;
`

export const WrapperFlex = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  gap: 13px;
  padding: 0 16px 0 16px;
  margin-top: 10px;

  ${device.medium} {
    display: flex;
    padding: 0;
    width: 737px;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 40px;
    margin-top: 10px;
  }
`

export const SortToggleContainer = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: space-between;
  color: #fff;
  border: 1px solid #4d5164;
  align-items: center;
  width: 160px;

  ${device.medium} {
    display: flex;
    height: 40px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #4d5164;
    cursor: pointer;
    width: 160px;

    &:hover {
      border: 1px solid #9592a5;
    }
  }
`
export const ToggleContainerText = styled.div`
  color: #fff;
`

export const ToggleContainerIcon = styled.div`
  color: #fff;
`

export const TimeWrapper = styled.div`
  display: flex;
  align-self: stretch;

  ${device.small} {
    max-width: 13.75%;
    width: 13.75%;
    padding-right: 30px;

    &.first {
      padding-top: 12px;
    }
  }
`

export const InnerTimeWrapper = styled.div`
  &.last {
    border: none;
  }

  ${props =>
    props.theme.isLast
      ? ''
      : `
            ${device.small}{
                &:before {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    background-color: ${props.theme.innerTimeWrapperColor};
                    top: 12px;
                }
            }
    `}
`

const TimeH = 28
export const Time = styled.div`
  ${device.medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: #000;
    width: 64px;
    height: ${TimeH}px;
    line-height: 0.9;
    flex-grow: 0;
    z-index: 1;
    background-color: ${props => props.theme.color};
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  width: 64px;
  height: ${TimeH}px;
  line-height: 0.9;
  flex-grow: 0;
  z-index: 1;
  background-color: ${props => props.theme.color};

  &::after {
    content: '';
  }
`

export const StyledAdWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  margin-bottom: 30px;
  position: relative;

  .line {
    display: none;
  }

  ${device.small} {
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: space-between;
    border-right: ${props => props.theme.color} .line {
      display: inline-flex;
      content: '';
      height: 298px;
      background: ${props => props.theme.color};
      width: 1px;
      margin-right: 30px;
      margin-bottom: -48px;
    }

    [id^='timeline_box'] {
      text-align: center;
      width: 100%;
    }
  }
`

export const CalendarContainer = styled.div`
  &.sticky {
    position: fixed;
    top: 76px;
    z-index: 1000;
  }

  width: 100%;
  height: 70px;
  padding-left: 3px;
  gap: 48px;
  border-bottom: 1px solid #4d5164;
  background: #000721;
  z-index: 5;

  ${device.medium} {
    width: 739px;
    height: 75px;
    padding-left: 3px;
    gap: 48px;
    border-bottom: 1px solid #4d5164;
    background: #000721;
    z-index: 5;
    padding-top: 5px;

    &.sticky {
      position: fixed;
      top: 93px;
      z-index: 1000;
    }
  }
`

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  background: #000721;
`
export const StyledRow = styled.div`
  ${device.medium} {
    background: #000721;
    display: flex;
    width: 739px;
    left: 0;
    margin-bottom: 48px;
  }

  background: #000721;
  display: flex;
  width: 100vw;
  position: relative;
  left: 20px;
`
