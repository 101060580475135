import styled from 'styled-components'
import { device, WarOffset } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;

  ${device.medium} {
    padding: 0;
    max-width: 1280px;
  }

  ${props => WarOffset(props.theme.haseWarComp)}
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 16px;
  z-index: 1;

  ${device.medium} {
    margin-bottom: 40px;
  }
`

export const Title = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-width: 223px;
  color: ${props => props.theme.color};
  font-family: Leon;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  ${device.medium} {
    max-width: 90%;
    font-family: Leon;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 1px;
    color: ${props => props.theme.color};
    -webkit-line-clamp: 1;
  }
`

export const TaboolaWrapper = styled.div`
  margin-bottom: 15px;
`
