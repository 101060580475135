import React from 'react'
import { he } from '../../../locales/he'
import { ITag } from '../../../types'
import { ArticleTagsContainer, Tag } from './ArticleTags.styles'

const ArticleTags = ({ tags }: { tags: ITag[] }) => {
  if (tags.length === 0) {
    return null
  }
  return (
    <ArticleTagsContainer>
      {`${he.tags}: `}
      {tags.map(({ name, link }, tagIndex) => (
        <Tag key={`tag_${tagIndex}`} href={link} children={name} />
      ))}
    </ArticleTagsContainer>
  )
}

export default ArticleTags
