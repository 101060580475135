import { IPublishers } from '../../types'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { Image } from '../Image/Image'
import { Container, PostsContainer, PublisherPost } from './Publishers.styles'

type Props = {
  publishers: IPublishers
}

export const Publishers = ({ publishers }: Props) => {
  const { grid_title, posts } = publishers

  return (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <PostsContainer>
        {posts.map(post => (
          <PublisherPost
            href={post?.link}
            target={post?.isBlank ? '_blank' : undefined}
            key={post.id}
          >
            {post?.imageObj && <Image image={post.imageObj} />}
          </PublisherPost>
        ))}
      </PostsContainer>
    </Container>
  )
}
