import { useFL } from '../../../hooks/useFL'
import { he } from '../../../locales/he'
import { IMostRead } from '../../../types'
import {
  Container,
  PostContainer,
  PostTitle,
  Title,
} from './MostPopular.styles'

type Props = {
  mostRead: IMostRead[]
}

function MostPopular({ mostRead }: Props) {
  const { fontColor, isDarkTheme } = useFL()
  return (
    <Container>
      <Title fontColor={fontColor}>{he.mostPopular}</Title>
      <PostContainer>
        {mostRead.slice(0, 8).map(item => (
          <PostTitle
            fontColor={fontColor}
            key={item.id}
            href={item.link}
            theme={{ isDarkTheme: isDarkTheme }}
          >
            {item.title}
          </PostTitle>
        ))}
      </PostContainer>
    </Container>
  )
}

export default MostPopular
