import styled from 'styled-components'
import { device } from '../../../styles/GlobalVariables'
import { TextContainer } from '../../Post/Post.styles'

export const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;

  ${TextContainer} {
    margin-top: 11px;
  }

  ${device.medium} {
    ${TextContainer} {
      margin-top: 18px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }

  ${device.large} {
    gap: 30px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const CustomIframe = styled.iframe`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 190px;

  border: unset;
  ${device.large} {
    height: 170px;
  }
`

export const Banner = styled.div`
  flex-grow: 0;
  width: 300px;
  height: 250px;
  display: none;
  ${device.medium} {
    display: inline-block;
  }
`
