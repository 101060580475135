import { ISpecialRefFl } from '../../types'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { FaceLiftBlockSeparator } from '../FaceLiftBlockSeperator/FaceLiftBlockSeparator'
import { Image } from '../Image/Image'
import { Container, ImageLink } from './SpecialRefFl.styles'

type Props = {
  specialRef: ISpecialRefFl
}

export const SpecialRefFl = ({
  specialRef: { link, imageObj, grid_id, grid_title, withSeparator },
}: Props) => {
  return (
    <>
      <Container theme={{ withSeparator: withSeparator }}>
        <ComponentTitleFL
          componentTitle={grid_title}
          componentId={Number(grid_id)}
        />
        <ImageLink href={link ?? undefined}>
          <Image image={imageObj} />
        </ImageLink>
      </Container>
      {withSeparator && <FaceLiftBlockSeparator />}
    </>
  )
}
