import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 102px;
  flex-basis: 102px;
  gap: 10px;

  ${device.medium} {
    &:nth-child(n + 13) {
      display: none;
    }
  }
`

export const Thumbnail = styled(Link)`
  display: flex;
  transition: filter 0.3s ease;

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
    min-height: 102px;

    ${device.medium} {
      min-height: 200px;
      max-height: 200px;
    }
  }

  &:hover,
  &:active {
    filter: brightness(80%);
  }

  ${device.medium} {
    min-height: 200px;
    max-height: 200px;
  }
`

export const Title = styled.div<{ fontColor?: string }>`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  color: ${({ fontColor }) => fontColor ?? '#000'};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${device.medium} {
    font-size: 18px;
    font-weight: 700;
  }
`
