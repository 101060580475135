import { useState } from 'react'
import { usePage } from '../../contexts'
import { he } from '../../locales/he'
import { IPlaylistEpisode } from '../../types'
import { Player } from '../Player/Player'
import {
  AirDate,
  Container,
  ExpandButton,
  PlayerWrapper,
  PortalLink,
  SecondaryTitle,
  Title,
  Wrapper,
} from './NowPlaying.styles'

type Props = {
  nowPlaying: IPlaylistEpisode
  portalLink: string
}

export const NowPlaying = ({
  nowPlaying: { title, secondaryTitle, video, air_date: airDate },
  portalLink,
}: Props) => {
  const pageMeta = usePage()?.PageMeta
  const [collapsed, setCollapsed] = useState(true)

  if (!video) {
    return null
  }

  return (
    <Container>
      <PlayerWrapper>
        {pageMeta && (
          <Player pageMeta={pageMeta} video={video} img={video.poster} />
        )}
      </PlayerWrapper>
      <Title>{title}</Title>
      <Wrapper collapsed={collapsed}>
        <SecondaryTitle>{secondaryTitle}</SecondaryTitle>
        <PortalLink href={portalLink}>
          {he.toProgramPage}
          {' >'}
        </PortalLink>
      </Wrapper>
      <AirDate>
        {he.airDate}: {airDate}
      </AirDate>
      <ExpandButton
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? he.showMore + ' +' : he.showLess + ' -'}
      </ExpandButton>
    </Container>
  )
}
