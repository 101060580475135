import styled from 'styled-components'
import { device, hideOnMobile } from '../../styles/GlobalVariables'

export const Container = styled.div`
  ${hideOnMobile};

  justify-content: center;
  margin-bottom: 28px;

  ${device.medium} {
    display: flex;
  }
`
