import { useEffect, useRef, useState } from 'react'
import { IVodMain } from '../../types'
import { Image } from '../Image/Image'

import { isDesktop } from '../../client/getAdDeviceType'
import { useHeader, useHero } from '../../contexts'
import { he } from '../../locales/he'
import { animationDelayMs } from '../../utils'
import { utils } from '../Player/Helpers/utils'
import {
  Button,
  Container,
  HorizontalOverlay,
  ShowName,
  StyledLink,
  SubTitle,
  SwiperContainer,
  SwiperImage,
  SwiperLayout,
  TextContainer,
  Title,
} from './Hero.styles'

// Swiper
import {
  Autoplay,
  Controller,
  EffectFade,
  Mousewheel,
  Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

type Props = {
  item: IVodMain
}

export const Hero = ({ item: vodMain }: Props) => {
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [fadeOut, setFadeOut] = useState(false)
  const [opacity, setOpacity] = useState(1)
  const imageRef = useRef<HTMLImageElement>(null)
  const { imageHeight, setImageHeight } = useHero()
  const { headerHeight } = useHeader()

  useEffect(() => {
    const handleScroll = () => {
      if (!isDesktop()) {
        setFadeOut(utils.getViewOutsideWindow())
        return
      }

      if (!imageHeight) {
        return
      }
      setOpacity((imageHeight / window.scrollY / 100) * 50)
      setFadeOut(window.scrollY >= 0.45 * imageHeight * 0.77)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [fadeOut, imageHeight])

  useEffect(() => {
    const onResize = () => {
      // if (!isDesktop()) return
      if (!imageRef.current) {
        return
      }
      setImageHeight(imageRef.current.clientHeight)
    }

    onResize()
    window.addEventListener('resize', onResize)
    // fixes issue when resize is not triggered on window change
    setInterval(onResize, 250)

    return () => window.removeEventListener('resize', onResize)
  }, [imageRef, setImageHeight])

  return (
    <Container imageHeight={imageHeight} headerHeight={headerHeight}>
      <SwiperImage
        fadeOut={fadeOut}
        opacity={opacity}
        imageHeight={imageHeight}
      >
        <Swiper
          dir='rtl'
          loop={true}
          effect={'fade'}
          onSwiper={setSwiper}
          modules={[EffectFade, Controller]}
          className='swiper-image'
        >
          {vodMain?.episodes?.slice(0, 5)?.map((episode, index) => {
            return (
              <SwiperSlide key={index}>
                <SwiperContainer>
                  <HorizontalOverlay />
                  <Image
                    eager={index === 0}
                    image={episode.imageObj}
                    refDesktop={imageRef}
                  />
                </SwiperContainer>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </SwiperImage>
      <SwiperLayout>
        <Swiper
          controller={{
            control: swiper,
          }}
          dir='rtl'
          loop={true}
          effect={'fade'}
          mousewheel={{
            forceToAxis: true,
            invert: true,
          }}
          autoplay={{
            delay: animationDelayMs.sevenSeconds,
            disableOnInteraction: true,
          }}
          pagination={{ clickable: true }}
          modules={[EffectFade, Pagination, Autoplay, Controller, Mousewheel]}
          className='swiper-text'
        >
          {vodMain?.episodes
            ?.slice(0, 5)
            ?.map(
              ({ show_name, title, air_date, link, portal_link }, index) => {
                return (
                  <SwiperSlide key={index}>
                    <SwiperContainer>
                      <TextContainer>
                        {show_name && <ShowName>{show_name}</ShowName>}
                        {title && <Title>{title}</Title>}
                        {air_date && (
                          <SubTitle>
                            {he.broadcastDate}: {air_date}
                          </SubTitle>
                        )}
                        {link && (
                          <Button href={link}>{he.vodMainButton}</Button>
                        )}
                        {portal_link && (
                          <StyledLink href={portal_link}>
                            {he.vodMainLink} {'> '}
                          </StyledLink>
                        )}
                      </TextContainer>
                    </SwiperContainer>
                  </SwiperSlide>
                )
              }
            )}
        </Swiper>
      </SwiperLayout>
    </Container>
  )
}
