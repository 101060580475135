import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import {
  Container as PostContainer,
  SecondaryTitle,
  TextContainer,
  Thumbnail,
} from '../Post/Post.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const SubContainer = styled.div<{ lifestyle?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.lifestyle ? '20px' : '32px')};

  ${device.medium} {
    min-height: 599px;
    max-height: 599px;
    flex-direction: row;
    grid-template-rows: unset;
    gap: 30px 20px;
  }

  ${device.large} {
    gap: 30px 30px;
  }
`

export const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px 0;

  ${device.medium} {
    gap: 16px 0;
  }
`

export const RightColumn = styled.div`
  flex: 1;
  margin: 0 -20px;

  ${device.medium} {
    margin: 0;

    ${PostContainer} {
      &:first-child {
        ${Thumbnail} {
          min-height: 417px;
          max-height: 417px;
        }

        ${SecondaryTitle} {
          display: inline;
        }
      }
    }
  }

  ${PostContainer} {
    &:first-child {
      gap: 12px;

      ${TextContainer} {
        gap: 4px;
        margin-right: 22px;
        margin-left: 20px;

        ${device.medium} {
          margin-right: 0;
          margin-left: 0;
        }
      }

      ${SecondaryTitle} {
        display: inline;
      }

      ${device.medium} {
        gap: 0px;
      }
    }
  }
`

export const FourGrid = styled.div`
  display: grid;
  grid-row: span 2;
  grid-template-columns: 1fr 1fr;
  gap: 19px 15px;

  ${device.medium} {
    gap: 16px 10px;
  }

  ${device.large} {
    gap: 16px 30px;
  }
`
