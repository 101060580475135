import styled from 'styled-components'
import { colors } from '../../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  width: '100%';
  flex-direction: column;
`
export const PostsContainer = styled.div`
  display: flex;
  height: 400px;
  justify-content: space-between;
`
export const BigPostWrapper = styled.div`
  display: flex;
  width: 740px;
  height: 308px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
`
export const BigPost = styled.div`
  display: flex;
  width: 740px;
  height: 308px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  cursor: pointer;

  & img {
    width: 308px;
    height: 308px;
    object-fit: cover;
    flex-shrink: 0;
  }
`
export const BigPostContent = styled.div`
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  flex: 1 0 0;

  > span {
    font-size: 18px;
    font-weight: 300;
  }
`
export const BigPostTitle = styled.div`
  text-align: right;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: ${props => props.theme.fontColor};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const BigPostSecondaryTitle = styled.div`
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  color: ${props => props.theme.fontColor};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const BigPostCredit = styled.div`
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  color: ${props =>
    props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2};
`

export const SmallPostsWrapper = styled.div`
  display: flex;
  width: 415px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`
export const SmallPost = styled.div`
  display: flex;
  flex-direction: row;
  width: 415px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  cursor: pointer;

  & img {
    width: 200px;
    height: 120px;
    flex-shrink: 0;
  }
`

export const SmallPostContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`

export const SmallPostTitle = styled.div`
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: ${props => props.theme.fontColor};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SmallPostCredit = styled.div`
  color: ${props => (props.theme.isDarkTheme ? '#9592A5' : '#5B5B5B')};
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
`
