import { usePage } from '../../contexts'
import { useFL } from '../../hooks/useFL'
import { IPost } from '../../types'
import Credits from '../DateAndWriter/Credits'
import { ImageFl } from '../ImageFl/ImageFl'
import { utils } from '../Player/Helpers/utils'
import { Container, TextContainer, Thumbnail, Title } from './Post.styles'

type Props = {
  post: IPost
  gid?: number
}

const Post = ({ post, gid }: Props) => {
  const { title, imageObj, isBlank, link: postLink } = post
  const page = usePage()
  const pageId = page?.PageMeta.id
  const linkTarget = isBlank ? '_blank' : undefined
  const { fontColor, isDarkTheme } = useFL()
  const queryParams = utils.addPageQueryParams(postLink, pageId, gid)
  const link = postLink + queryParams

  return (
    <Container>
      <Thumbnail href={link} target={linkTarget}>
        <ImageFl
          image={imageObj}
          alt={imageObj ? title : title}
          playButton={true}
          isBigPost={false}
          playButtonName={'episodes'}
        />
      </Thumbnail>
      <TextContainer>
        <Title fontColor={fontColor}>{title}</Title>
        <Credits credits={post} color={isDarkTheme ? '#9592A5' : '#5B5B5B'} />
      </TextContainer>
    </Container>
  )
}

export default Post
