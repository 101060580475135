import { IPageMeta, IVideo } from '../../types'
import { he } from '../../locales/he'
import { secondsFormatted } from '../../utils'
import { Player } from '../Player/Player'
import { Container, Details, PlayerWrapper } from './ArticleVideo.styles'

type Props = {
  pageMeta: IPageMeta
  video: IVideo
  hideDetails: boolean
}

export const ArticleVideo = ({
  pageMeta,
  video,
  hideDetails = false,
}: Props) => {
  return (
    <Container>
      <PlayerWrapper>
        <Player pageMeta={pageMeta} video={video} img={video.poster} />
        {!hideDetails && (
          <Details>{`${he.watchingTime}: ${secondsFormatted(
            video.duration
          )}`}</Details>
        )}
      </PlayerWrapper>
    </Container>
  )
}
