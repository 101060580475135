import React from 'react'
import { useWindow } from '../../contexts'
import { IBase } from '../../types'
import { Post } from '../Post/Post'
import { PocTaboolaSuffixId, Taboola } from '../Taboola'
import { Container, TaboolaBlock } from './Base.styles'

type Props = {
  base: IBase
  pageType: string
  blockTaboola?: boolean
}

export const Base = ({ base, pageType, blockTaboola }: Props) => {
  const { posts, layout_mobile } = base

  const win = useWindow()
  let taboolaCount = 1
  const postsComponents = posts.slice(0, 40).map((post, index) => {
    if (
      win &&
      !blockTaboola &&
      pageType &&
      ['tag', 'writer', 'category'].includes(pageType)
    ) {
      if ([8, 4 * 6].includes(index)) {
        return (
          <React.Fragment key={'t' + taboolaCount}>
            <TaboolaBlock>
              <Taboola
                pageType={pageType}
                suffixId={PocTaboolaSuffixId.widget}
                index={taboolaCount++}
              />
            </TaboolaBlock>
            <Post post={post} layoutMobile={layout_mobile} hideSecondaryTitle />
          </React.Fragment>
        )
      }
    }
    return (
      <Post
        key={post.id}
        post={post}
        layoutMobile={layout_mobile}
        hideSecondaryTitle
      />
    )
  })

  return <Container children={postsComponents} />
}
