import React, { useState } from 'react'
import {
  SelectContainer,
  Select,
  SelectValue,
  SelectDropBox,
  Option,
  ArrowRightIcon,
} from './TimeLine.styles'

type SortByDropDownProps = {
  sortElementsBysoDate: (value: string) => void
}

export const SortByDropDown: React.FC<SortByDropDownProps> = props => {
  const [showDropDown, setShowDropDown] = useState(false)

  const [selectedText, setSelectedText] = useState('מיין לפי')

  const showDropHandler = () => setShowDropDown(!showDropDown)

  const selectedOptionHandler = (arg: Array<string>) => {
    setSelectedText(arg[1])
    props.sortElementsBysoDate(arg[0])
    showDropHandler()
  }

  const custStyle = {}

  return (
    <SelectContainer className={'sortSelect'}>
      <Select className='Selects' theme={{ ...custStyle }}>
        <SelectValue onClick={showDropHandler}>
          <div>{selectedText}</div>
          <ArrowRightIcon className={showDropDown ? 'up' : 'down'} />
        </SelectValue>
        <SelectDropBox theme={{ showDrop: showDropDown }}>
          <Option
            onClick={() => selectedOptionHandler(['newFirst', 'מהחדש לישן'])}
          >
            מהחדש לישן
          </Option>
          <Option
            onClick={() => selectedOptionHandler(['oldFirst', 'מהישן לחדש'])}
          >
            מהישן לחדש
          </Option>
        </SelectDropBox>
      </Select>
    </SelectContainer>
  )
}
