import { IPost, IReelsFl } from '../../types'
import { Container, ReelsContainer } from './ReelsFl.styles'
import { useEffect, useState } from 'react'
import { usePage } from '../../contexts'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { ReelsPostsFl } from '../Post/ReelsPostFl'
import { FaceLiftBlockSeparator } from '../FaceLiftBlockSeperator/FaceLiftBlockSeparator'

type Props = {
  layout: IReelsFl
}

export const ReelsFl = ({ layout }: Props) => {
  const [isMobile, setIsMobile] = useState(false)
  const { grid_title, grid_id, posts } = layout
  const page = usePage()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      {page?.PageMeta.themed.background_color && (
        <>
          <Container>
            <ComponentTitleFL
              componentTitle={grid_title}
              componentId={grid_id}
            />
            <ReelsContainer>
              {posts
                ?.slice(0, isMobile ? 4 : 3)
                .map(
                  (post: IPost) =>
                    post.imageObj && <ReelsPostsFl key={post.id} post={post} />
                )}
            </ReelsContainer>
          </Container>
          <FaceLiftBlockSeparator />
        </>
      )}
    </>
  )
}
