import { useEffect, useState } from 'react'
import { usePage } from '../../contexts'
import { IGridVideo } from '../../types'
import { Image } from '../Image/Image'
import { Player } from '../Player/Player'
import {
  Button,
  SecondaryTitle,
  TextContainer,
  Thumbnail,
  Title,
  VideoWrapper,
  Wrapper,
} from './VideoGrid.styles'

type Props = {
  video: IGridVideo
}

export const GridVideo = ({
  video: {
    imageObj,
    video,
    title,
    sub_title,
    color,
    link,
    button_text,
    video_desktop_background_image,
    video_mobile_background_image,
    video_button_text_color,
    autoplay,
  },
}: Props) => {
  const pageMeta = usePage()?.PageMeta
  const [showVideo, setShowVideo] = useState(false)
  const themeProps = {
    color: color,
    desktop_background_image: video_desktop_background_image,
    mobile_background_image: video_mobile_background_image,
  }

  useEffect(() => {
    if (video) {
      setTimeout(() => setShowVideo(true), 250)
    }
  }, [video])

  return (
    (video || imageObj || title) && (
      <Wrapper theme={themeProps}>
        {video && showVideo ? (
          pageMeta &&
          video && (
            <VideoWrapper>
              <Player
                pageMeta={pageMeta}
                video={{ ...video, autoPlay: autoplay === 1 }}
                img={video.poster}
              />
            </VideoWrapper>
          )
        ) : (
          <Thumbnail>
            <Image image={imageObj} alt={title} />
          </Thumbnail>
        )}
        {title && (
          <TextContainer>
            <Title>{title}</Title>
            <SecondaryTitle
              dangerouslySetInnerHTML={{
                __html: sub_title,
              }}
            />
            <Button
              theme={{ color: color, text_color: video_button_text_color }}
              href={link}
            >
              {button_text}
            </Button>
          </TextContainer>
        )}
      </Wrapper>
    )
  )
}
