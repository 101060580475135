import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'
import {
  colors,
  device,
  playButtonFLStyle,
  postThumbnailHeight,
  themeClassName,
} from '../../styles/GlobalVariables'
import { Container as CreditsContainer } from '../DateAndWriter/Credits.styles'
import Link from '../Link/Link'

export const Container = styled(SwiperSlide)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 145px;
  min-width: 143px;
  gap: 8px;

  ${device.medium} {
    min-height: 234px;
    min-width: 308px;
  }
`

export const Thumbnail = styled(Link)`
  display: flex;
  position: relative;
  min-height: ${postThumbnailHeight.mobilesmall};

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 35%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  &:hover::after {
    opacity: 1;
  }

  ${device.medium} {
    min-height: ${postThumbnailHeight.midmedium};
    max-height: ${postThumbnailHeight.midmedium};
  }

  ${playButtonFLStyle}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${device.medium} {
    flex: 1;
  }

  ${CreditsContainer} {
    font-size: 16px;
    ${device.medium} {
      font-size: 18px;
    }
  }
`

export const Title = styled.h3<{
  fontColor?: string
}>`
  font-size: 16px;
  color: ${props => props.fontColor};
  font-weight: 500;
  text-align: right;
  line-height: 1.2;
  overflow: hidden;
  width: 100%;
  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
  }
`

export const PromoStrip = styled.div<{ background?: string }>`
  color: ${colors.white};
  position: absolute;
  bottom: 0;
  right: 0;

  width: 42px;
  height: 17px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 6px;
  border-radius: 1px;
  background-color: ${colors.whiteRed};
  flex-grow: 0;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.5;
  letter-spacing: normal;
  text-align: right;

  ${device.medium} {
    width: 74px;
    height: 32px;
    font-size: 16px;
    line-height: 1;
    background-color: ${colors.whiteRed};
  }
`
