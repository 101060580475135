import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { ITimeLineElement } from '../../types'
import {
  ClickableDiv,
  TagsWrapper,
  Tag,
  TagsDiv,
  MobileTagsDiv,
  MobileTagsDivSeparator,
  MobileTagsDivTitle,
  MobileTagsDivTopContentWrapper,
} from './TimeLineFL.styles'
import Image from 'next/image'
import { utils } from '../Player/Helpers/utils'

interface TimeLineTagsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeline: any
  tagFilterHandler: (id: string) => void
  activeTag: string
}

type Tag = {
  counter: string
  id: string
  name: String
}

export const TimeLineTagsFL: React.FC<TimeLineTagsProps> = props => {
  const [tags, setTags] = useState<Array<Tag>>([])
  const { timeline, tagFilterHandler, activeTag } = props
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isVisibleMobile, setIsVisibleMobile] = useState<boolean>(false)
  const [isContentWrapperVisible, setIsContentWrapperVisible] =
    useState<boolean>(false)
  const tagsDivRef = useRef(null)
  const [currentTag, setCurrentTag] = useState<string>('')

  useEffect(() => {
    const elements: ITimeLineElement[] = timeline.elements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let tagsArray: any[] = [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tagsSortArray: any[] = []

    if (timeline && elements) {
      tagsSortArray = []
      tagsArray = []
      const posts_ids: (number | string)[] = []
      elements.forEach(el => {
        posts_ids.push(el.id)
        tagsSortArray = tagsSortArray.concat(el.tags)
      })

      //sort count of tags in use
      tagsSortArray.forEach(el => {
        const key = el.term_id
        const count = tagsArray[key]
          ? tagsArray[key].counter
            ? tagsArray[key].counter
            : 0
          : 0
        tagsArray[key] = {
          name: el.name,
          counter: count + 1,
          id: el.term_id,
        }
      })
      tagsArray.sort((a, b) => (a.counter < b.counter ? 1 : -1))
      setTags(tagsArray)
    }
  }, [timeline])

  const toggleVisibility = () => setIsVisible(!isVisible)
  const toggleVisibilityMobile = () => setIsVisibleMobile(!isVisibleMobile)

  return (
    <>
      <TagsWrapper
        theme={{
          color: timeline.header.favcolor,
          activeTag: activeTag,
        }}
      >
        <ClickableDiv
          onClick={() => {
            !utils.isMobile() ? toggleVisibility() : toggleVisibilityMobile()
            setIsContentWrapperVisible(true)
          }}
        >
          <div>סינון</div>
          <Image
            width={24}
            height={24}
            src={
              'https://media.reshet.tv/image/upload/v1713682639/uploads/2024/904028534.png'
            }
          />
        </ClickableDiv>
        {isVisible && (
          <TagsDiv
            id={utils.isMobile() ? 'mobileTagsDiv' : ''}
            ref={tagsDivRef}
          >
            <Tag
              className={cx([
                'timeLineTag',
                'all',
                'all' == currentTag ? 'active' : '',
              ])}
              onClick={() => {
                tagFilterHandler('All')
                setIsVisible(false)
                setCurrentTag('all')
              }}
              children={'הכל'}
            />
            {tags.map((el, index) => {
              return (
                <Tag
                  key={index}
                  id={el.id}
                  className={cx([
                    'timeLineTag',
                    el.id == currentTag ? 'active' : '',
                  ])}
                  onClick={() => {
                    tagFilterHandler(el.id)
                    setIsVisible(false)
                    setCurrentTag(el.id)
                  }}
                  children={el.name}
                />
              )
            })}
          </TagsDiv>
        )}
      </TagsWrapper>
      {utils.isMobile() && (
        <>
          <MobileTagsDivTopContentWrapper
            className={
              isContentWrapperVisible ? 'wrapper_visible' : 'wrapper_hidden'
            }
            onClick={() => {
              setIsVisibleMobile(false)
              setIsContentWrapperVisible(false)
            }}
          />
          <MobileTagsDiv
            className={isVisibleMobile ? 'expanded' : 'collapsed'}
            id={'mobileTagsDiv'}
          >
            <MobileTagsDivSeparator />
            <MobileTagsDivTitle>סינון</MobileTagsDivTitle>
            <Tag
              className={cx([
                'timeLineMobileTag',
                'all',
                'all' == currentTag ? 'active' : '',
              ])}
              onClick={() => {
                tagFilterHandler('All')
                setIsVisibleMobile(false)
                setIsContentWrapperVisible(false)
                setCurrentTag('all')
              }}
              children={'הכל'}
            />
            {tags.map((el, index) => {
              return (
                <Tag
                  key={index}
                  id={el.id}
                  className={cx([
                    'timeLineMobileTag',
                    el.id == currentTag ? 'active' : '',
                  ])}
                  onClick={() => {
                    tagFilterHandler(el.id)
                    setIsVisibleMobile(false)
                    setIsContentWrapperVisible(false)
                    setCurrentTag(el.id)
                  }}
                  children={el.name}
                />
              )
            })}
          </MobileTagsDiv>
        </>
      )}
    </>
  )
}
