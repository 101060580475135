import { IEpisodesGridFl, IPost } from '../../types'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { FaceLiftBlockSeparator } from '../FaceLiftBlockSeperator/FaceLiftBlockSeparator'
import BigPost from './BigPost'
import {
  Container,
  EpisodesContainer,
  FirsPostsContainer,
  PostsList,
} from './EpisodesGridFl.styles'
import Post from './Post'

type Props = {
  layout: IEpisodesGridFl
}

export const EpisodesGridFl = ({ layout }: Props) => {
  const { posts, layout_mobile, grid_title, grid_id } = layout

  if (!posts || posts.length === 0) {
    return null
  }

  return (
    <>
      <Container>
        <ComponentTitleFL componentTitle={grid_title} componentId={grid_id} />
        <EpisodesContainer>
          <FirsPostsContainer layoutMobile={layout_mobile}>
            <BigPost post={posts[0]} gid={grid_id} />
          </FirsPostsContainer>
          <PostsList>
            {posts
              .slice(1, 5)
              .map(
                (post: IPost) =>
                  post.imageObj && (
                    <Post key={post.id} post={post} gid={grid_id} />
                  )
              )}
          </PostsList>
        </EpisodesContainer>
      </Container>
      <FaceLiftBlockSeparator />
    </>
  )
}
