import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { useFL } from '../../hooks/useFL'
import { IContestants, IPodcastFL } from '../../types'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { Carousel, Container, PodcastContainer } from './PodcastFL.styles'
import { Post } from './Post'

interface IAppProps {
  podcast: IPodcastFL | IContestants
}

/**
 * PodcastFL component
 *
 * Maybe there is a better way to handle the mapping of the posts(maybe callback function)
 *
 * @param {IPodcastFL} podcast - The podcast object
 */
export const PodcastFL = ({ podcast }: IAppProps) => {
  const { grid_title, posts, grid_id } = podcast
  const { isDarkTheme, fontColor } = useFL()

  const swiperProps: SwiperProps = {
    slidesPerView: 5,
    dir: 'rtl',
    spaceBetween: 8,
    resistance: true,
    breakpoints: {
      320: {
        slidesPerView: 3.2,
        spaceBetween: 8,
      },
      768: {
        slidesPerView: 4.2,
        spaceBetween: 12,
      },
      1024: {
        slidesPerView: 4.5,
        spaceBetween: 16,
      },
    },
  }

  return (
    <Container theme={{ isDarkTheme: isDarkTheme }}>
      <ComponentTitleFL componentTitle={grid_title} componentId={grid_id} />
      <Carousel>
        <Swiper {...swiperProps}>
          {posts.map(post => {
            if (!post.imageObj) {
              return null
            }
            return (
              <>
                <SwiperSlide key={post.id}>
                  <Post post={post} grid_id={grid_id} fontColor={fontColor} />
                </SwiperSlide>
              </>
            )
          })}
        </Swiper>
      </Carousel>
      <PodcastContainer>
        {posts.map(post => {
          if (!post.imageObj) {
            return null
          }
          return (
            <Post
              key={post.id}
              post={post}
              fontColor={fontColor}
              grid_id={grid_id}
            />
          )
        })}
      </PodcastContainer>
    </Container>
  )
}
