import styled from 'styled-components'
import {
  colors,
  device,
  playButtonStyle,
  postThumbnailHeight,
  themeClassName,
} from '../../styles/GlobalVariables'
import { LayoutMobile } from '../../types'
import Link from '../Link/Link'

export const Thumbnail = styled(Link)<{ isDarkTheme: boolean }>`
  flex: 1 1 50%;
  display: flex;
  position: relative;
  min-height: ${postThumbnailHeight.mobile};
  max-height: ${postThumbnailHeight.mobile};
  border-radius: 1px;

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }

  ${device.medium} {
    min-height: ${postThumbnailHeight.medium};
    max-height: ${postThumbnailHeight.medium};
  }

  ${playButtonStyle()}
`

export const TextContainer = styled.div<{
  leftPadded?: boolean
  layout: LayoutMobile
  hideSecondaryTitle?: boolean
  backgroundColor: false | string
}>`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  margin-right: 19px;
  padding-left: ${props => (props.leftPadded ? '15px' : '0')};
  gap: ${props => (props.hideSecondaryTitle ? '8px' : '4px')};
  margin-top: ${props => (props.hideSecondaryTitle ? '11px' : '4px')};

  > span {
    font-weight: 300;
  }

  ${device.medium} {
    flex: 1;
    gap: 6px;
    margin-right: 0;
    margin-top: 18px;
    padding-left: unset;
    padding-right: ${props => (props.backgroundColor ? '4px' : '0')};
  }
`

export const Title = styled.h3<{ isDarkTheme?: boolean }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  color: ${props => (props.isDarkTheme ? colors.white : colors.black)};

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    font-weight: bold;
    font-size: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: calc(1.2 * 3 * 20px);
    -webkit-line-clamp: 3;
  }
`

export const SecondaryTitle = styled(Link)<{ isDarkTheme?: boolean }>`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: ${props => (props.isDarkTheme ? colors.white : colors.black)};

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 16px;
    max-height: calc(1.2 * 2 * 16px);
  }
`

export const PromotionalText = styled.div`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: ${colors.black};

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }
`

export const PromoStrip = styled.div<{ background?: string }>`
  color: ${colors.white};
  position: absolute;
  bottom: 0;
  right: 0;

  width: 42px;
  height: 17px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 6px;
  border-radius: 1px;
  background-color: ${colors.whiteRed};
  flex-grow: 0;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.5;
  letter-spacing: normal;
  text-align: right;
  color: #fff;

  ${device.medium} {
    width: 74px;
    height: 32px;
    font-size: 16px;
    line-height: 1;
    background-color: ${colors.whiteRed};
  }
`

export const SecondWriter = styled.span`
  display: none;

  ${device.large} {
    display: inline;
  }
`

export const Container = styled.div<{
  layout: LayoutMobile
  height: string
  backgroundColor: false | string
  hideSecondaryTitle?: boolean
}>`
  display: flex;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : null};
  flex-direction: row;

  &:first-child {
    flex-direction: ${props =>
      props.layout === 'first_big' ? 'column' : 'row'};

    ${TextContainer} {
      ${({ layout }) =>
        layout === 'first_big'
          ? {
              flex: 'unset',
              margin: '14px 0 0',
              gap: '2px',
            }
          : ''}
      ${device.medium} {
        margin-top: 18px;
      }
    }

    ${Thumbnail} {
      ${props =>
        props.layout === 'first_big'
          ? {
              minHeight: postThumbnailHeight.vertical,
              maxHeight: postThumbnailHeight.vertical,
            }
          : ''}
      ${device.medium} {
        min-height: ${postThumbnailHeight.medium};
        max-height: ${postThumbnailHeight.medium};
      }
    }

    ${Title} {
      -webkit-line-clamp: ${props =>
        props.layout === 'first_big' ? '2' : '4'};
      max-height: ${props =>
        props.layout === 'first_big'
          ? 'calc(1.2 * 2 * 16px)'
          : 'calc(1.2 * 4 * 16px)'};
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      ${device.medium} {
        -webkit-line-clamp: 3;
        max-height: calc(1.2 * 3 * 20px);
      }
    }

    ${SecondaryTitle} {
      display: ${props => (props.layout === 'first_big' ? 'none' : 'block')};
      padding-right: ${props => (props.layout === 'first_big' ? '2px' : '0')};
    }
  }

  ${device.medium} {
    min-height: ${props => props.height};

    &,
    &:first-child {
      flex-direction: column;
    }
  }
`
