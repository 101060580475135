import { usePage } from '../../../contexts'
import { useFL } from '../../../hooks/useFL'
import { colors } from '../../../styles/GlobalVariables'
import { IArticlesGrid, IPost } from '../../../types'
import { addAnalyticsToUrl } from '../../../utils'
import Credits from '../../DateAndWriter/Credits'
import { Image } from '../../Image/Image'
import Link from '../../Link/Link'
import {
  Container,
  Post,
  PostTitle,
  PostsContainer,
  PostsWrapper,
} from './DesktopAllSmall.styles'

type Props = {
  layout: IArticlesGrid
}

export const DesktopAllSmall = ({ layout }: Props) => {
  const { posts } = layout
  const page = usePage()
  const { fontColor, isDarkTheme } = useFL()

  if (!page) {
    return null
  }

  return (
    <Container theme={{ isDarkTheme: isDarkTheme }}>
      <PostsContainer>
        <PostsWrapper>
          {posts.length &&
            posts.slice(0, 4).map((post: IPost, i: number) => (
              <Link
                key={i}
                href={addAnalyticsToUrl(
                  post.link,
                  page.PageMeta.id.toString(),
                  layout.grid_id.toString()
                )}
              >
                <Post>
                  <Image image={post.imageObj} />
                  <PostTitle theme={{ fontColor: fontColor }}>
                    {post.title}
                  </PostTitle>
                  <Credits
                    credits={post}
                    color={isDarkTheme ? colors.steelBlue : colors.darkGray2}
                  />
                </Post>
              </Link>
            ))}
        </PostsWrapper>
      </PostsContainer>
    </Container>
  )
}
