import { IOpeningBannerFl } from '../../types'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { Image } from '../Image/Image'
import { Container, ImageLink } from './OpeningBannerFl.styles'

type Props = {
  openingBanner: IOpeningBannerFl
}

export const OpeningBannerFl = ({
  openingBanner: { link, imageObj, grid_id, grid_title },
}: Props) => {
  return (
    <>
      <Container>
        <ComponentTitleFL
          componentTitle={grid_title}
          componentId={Number(grid_id)}
        />
        <ImageLink href={link ?? undefined}>
          <Image image={imageObj} />
        </ImageLink>
      </Container>
    </>
  )
}
