import { usePage } from '../../contexts'
import { IVodPost } from '../../types'
import { Image } from '../Image/Image'
import { utils } from '../Player/Helpers/utils'
import { Shave } from '../Shave/Shave'
import {
  AirDate,
  Container,
  Overlay,
  TextContainer,
  Thumbnail,
  Title,
} from './VodEpisodePost.styles'
import PlayButton from '/public/assets/images/svg/play-button.svg'

type Props = {
  post: IVodPost
}

export const VodEpisodePost = ({ post }: Props) => {
  const {
    isBlank,
    id,
    link: postLink,
    background,
    imageObj,
    title,
    video,
    air_date,
    airDate: postAirDate,
  } = post
  const linkTarget = isBlank ? '_blank' : undefined
  const pageId = usePage()?.PageMeta.id

  const queryParams = utils.addPageQueryParams(postLink, pageId, id)
  const link = postLink + queryParams
  const airDate = (air_date || postAirDate)?.split('/').join('.')

  return (
    <Container backgroundColor={background}>
      <Thumbnail href={link} target={linkTarget}>
        <Image image={imageObj} alt={title} />
        <Overlay />
        {video && <PlayButton />}
      </Thumbnail>
      <TextContainer href={link} target={linkTarget}>
        <Shave maxLines={3}>
          <Title>{title}</Title>
        </Shave>
        <AirDate>{airDate}</AirDate>
      </TextContainer>
    </Container>
  )
}
