import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  gap: 19px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  ${device.medium} {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-columns: repeat(4, 1fr);
    gap: 28px 20px;
    margin-bottom: 28px;
  }

  ${device.large} {
    margin-bottom: 30px;
    gap: 30px;
  }
`

export const TaboolaBlock = styled.div`
  grid-column: 1/5;
`
