/**
 * Created by PhpStorm.
 * User: shlomy
 * Date: 19/07/2023
 * Time: 13:03
 */

const blockquote_pattern = /<p>\[blockquote_h text='(.*)'\s\]<\/p>/g

export const cleanHtmlString = (str: string) =>
  str
    ?.replace(
      blockquote_pattern,
      '<div class="blockquote"><blockquote>$1</blockquote></div>'
    ) // TODO: Remove blockquote workaround based on input from Reshet
    ?.replace(/role=\\"\\"/g, '')
    ?.replace(/&amp;/g, '&') //TODO: HACK: double encoding fix on qdata
export const getNodesFromHtmlText = (html: string) => {
  const div = document.createElement('div')

  div.innerHTML = cleanHtmlString(html)
  return (Array.from(div.childNodes) as HTMLElement[]).filter(
    t => t.nodeType !== Node.TEXT_NODE
  )
}
