import styled from 'styled-components'
import { colors, device } from '../../../styles/GlobalVariables'

export const LeftContainer = styled.div`
  display: flex;
  gap: 8px;
  font-size: 23px;

  > span {
    color: ${colors.commentsGray};
  }
`

export const RightContainer = styled.div`
  display: flex;
  align-items: baseline;
`

export const Separator = styled.span`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  color: ${colors.transparentGray};
  margin: 0 4px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 12px;

  ${device.medium} {
    flex-direction: row;
    margin-bottom: 20px;
  }
`

export const VerticalDivider = styled.div`
  content: '';
  height: 1px;
  width: 254px;
  margin-top: 8px;
  margin-bottom: 6px;
  background-color: ${colors.solidGray};
  ${device.medium} {
    display: none;
  }
`
