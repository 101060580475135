import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 12px;
  margin: 0 -20px 20px;
  position: relative;
  z-index: 1;

  ${device.medium} {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0 0 28px;
    padding: 45px 0;
  }

  ${device.large} {
    gap: 30px;
  }

  :after {
    content: '';
    position: absolute;
    background: ${colors.termHeadBlockBG};
    top: 0;
    left: 50%;
    margin-left: -50vw;
    bottom: 0;
    z-index: -1;
    width: 100vw;
    overflow-x: hidden;
  }
`

export const Thumbnail = styled.div`
  display: flex;
  max-height: 211px;

  ${device.medium} {
    max-height: 354px;
  }

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 354px;
  padding: 0 20px 12px;

  ${device.medium} {
    margin: 0;
  }
`

export const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 1.31;
  color: ${colors.darkBlue};
  position: relative;

  :after {
    content: '';
    position: absolute;
    width: 45px;
    border-bottom: 2px solid ${colors.pink};
    right: 0;
    bottom: 0;
  }

  ${device.medium} {
    font-size: 36px;
    line-height: 1.25;

    :after {
      width: 65px;
    }
  }
`

export const SecondaryTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.28;

  > a {
    color: ${colors.darkBlue};
  }

  ${device.medium} {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    color: ${colors.termHeadBlockGray};

    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
