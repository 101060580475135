import { useRef } from 'react'
import { useWindow } from '../../contexts'
import { ITVGuidePage } from '../../types'
import { he } from '../../locales/he'
import { mapBroadcastWeek } from '../../utils'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { BroadcastWeekSchedule } from '../BroadcastWeekSchedule/BroadcastWeekSchedule'
import { FeaturedShows } from '../FeaturedShows/FeaturedShows'
import Newsflashes from '../Newsflashes/Newsflashes'
import { PageTitle } from '../PageTitle/PageTitle'
import { Player } from '../Player/Player'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import {
  BackgroundWrapper,
  Container,
  LeftColumn,
  PageTitleWrapper,
  PlayerWrapper,
  RightColumn,
  ScrollToPlayer,
  ToSchedule,
  Wrapper,
} from './TVGuide.styles'
import DownArrowWhiteIcon from '/public/assets/images/svg/down-arrow-white.svg'

type Props = {
  page: ITVGuidePage
}

export const TVGuide = ({ page }: Props) => {
  const { SEO, Header, PageMeta } = page
  const newsFlashArr = page.Content.PageGrid[0].newsFlash.newsFlashArr
  const playerRef = useRef<HTMLDivElement>(null)
  const disableTaboola = PageMeta.ads.block.taboola
  const win = useWindow()

  const { broadcastWeek: initialBroadcastWeek, video } =
    page.Content.PageGrid[0]
  const broadcastWeek = mapBroadcastWeek(initialBroadcastWeek)

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle SEO={SEO} pageMeta={PageMeta} />
      </PageTitleWrapper>
      <Breadcrumbs breadcrumbs={Header.breadCrumbs} />
      <FeaturedShows featuredShows={broadcastWeek} />

      <ToSchedule
        onClick={() =>
          playerRef?.current?.scrollIntoView({ behavior: 'smooth' })
        }
      >
        <span>{he.fullProgramsSchedule}</span>
        <DownArrowWhiteIcon />
      </ToSchedule>

      <Wrapper>
        <RightColumn>
          <BackgroundWrapper>
            <PlayerWrapper>
              <Player pageMeta={PageMeta} video={video} img={video.poster} />
              <ScrollToPlayer ref={playerRef} />
            </PlayerWrapper>
            <BroadcastWeekSchedule broadcastWeek={broadcastWeek} />
          </BackgroundWrapper>
        </RightColumn>
        <LeftColumn>
          <Newsflashes hideOnMobile width={300} newsFlash={newsFlashArr} />
          {!disableTaboola && win && win.innerWidth > 768 && (
            <Taboola
              pageType={PocTaboolaPageType.epg}
              suffixId={PocTaboolaSuffixId.epgLeftRail}
            />
          )}
        </LeftColumn>
      </Wrapper>
      {win && !disableTaboola && (
        <Taboola
          pageType={PocTaboolaPageType.epg}
          suffixId={PocTaboolaSuffixId.pageFooter}
        />
      )}
    </Container>
  )
}
