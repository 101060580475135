import styled, { css, keyframes } from 'styled-components'
import { colors, device, themeClassName } from '../../../styles/GlobalVariables'
import Link from '../../Link/Link'

export const HorizontalMarqueeContainer = styled.div`
  display: none;

  ${device.medium} {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
`

const marqueHeaderBg = css`
  background: linear-gradient(0deg, #011d6b, #011d6b),
    linear-gradient(90.57deg, #00104a -0.11%, #002e97 99.89%);
`

export const StyledLinkHorizontalMarqueeHeader = styled(Link)<{
  backgroundColor?: string
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 76px;
  z-index: 5;
  padding: 26px 0px;
  cursor: pointer;
  border-left: 2px solid ${colors.grey};
  margin-left: 10px;

  ${props =>
    props.backgroundColor
      ? props.backgroundColor.toLowerCase() === colors.darkBlue.toLowerCase()
        ? marqueHeaderBg
        : `background: ${props.backgroundColor}`
      : marqueHeaderBg};

  ${themeClassName.vod} & {
    background: ${colors.white};
    border-left: none;
    height: 100%;
  }
`

export const CurrentTime = styled.span`
  line-height: 14px;
  color: #555;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: normal;
  ${themeClassName.vod} & {
    color: ${colors.tvGuideDark};
  }
`
export const Title = styled(Link)<{
  color?: string
}>`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: ${props => (props.color ? props.color : colors.blue)};

  ${themeClassName.vod} & {
    color: ${colors.tvGuideDark};
  }
`

export const VerticalMarqueeAnimation = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
`

export const StyledLinkNewsflashesItem = styled(Link)<{
  newsFlashColor?: string
}>`
  display: block;
  padding: 0 8px;
  width: 210px;
  height: 68px;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  color: ${colors.termHeadBlockGray};

  ${themeClassName.vod} & {
    color: ${colors.white};
  }

  > time {
    margin-left: 9px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: ${props => props.newsFlashColor || colors.headerBlue};

    ${themeClassName.vod} & {
      color: ${colors.white};
    }

    ${device.medium} {
      margin: 0;
      font-size: 13px;
      line-height: 13px;
    }
  }

  ${device.medium} {
    border-right: 1px solid ${colors.grey};
    font-size: 14px;
    line-height: 1.2;
    max-height: 5em;
    overflow: hidden;
    text-overflow: ellipsis;

    ${themeClassName.vod} & {
    }
  }
`

export const StyledLinkNewsflashesText = styled.div`
  font-size: 14px;
  line-height: 1.2;
`

export const StyledLinkNewsflashes = styled(Link)<{ newsFlashColor?: string }>`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 700;
  z-index: 2;
  background: ${colors.white};
  color: ${props =>
    props.newsFlashColor ? props.newsFlashColor : colors.headerBlue};

  ${themeClassName.vod} & {
    background: ${colors.tvGuideDark};
    color: ${colors.white};
  }

  svg {
    margin-right: 5px;
    font-size: 8px;
    path {
      fill: ${props =>
        props.newsFlashColor ? props.newsFlashColor : colors.headerBlue};
    }

    ${themeClassName.vod} & path {
      fill: ${colors.white};
    }
  }
`

export const Container = styled.div<{ hideOnMobile?: boolean; width?: number }>`
  ${({ hideOnMobile }) => hideOnMobile && 'display: none;'};

  position: relative;
  background: ${colors.lightGrey};
  flex: 1;

  ${themeClassName.vod} & {
    background: ${colors.tvGuideDark};
  }

  &:hover * {
    animation-play-state: paused;
  }

  ${device.medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 0;
    padding: 0;
    max-width: ${props => (props.width ? `${props.width}px` : '230px')};
    border-bottom: solid 2px #555;
  }

  > svg {
    position: absolute;
    top: calc(8px);
    right: 0;
    padding-left: 10px;

    background: ${colors.white};
    font-size: 8px;
  }
`
