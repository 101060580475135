import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid
    ${props =>
      props.theme.isDarkTheme ? colors.darkBlueGray : colors.steelBlue};
  margin-bottom: 16px;
  padding-bottom: 16px;
  ${device.medium} {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
`
export const Desktop = styled.div`
  display: none;
  ${device.medium} {
    display: block;
  }
`
export const Mobile = styled.div`
  display: block;
  ${device.medium} {
    display: none;
  }
`
