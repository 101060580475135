import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

export const EpisodesHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;

  margin-bottom: 20px;

  ${device.medium} {
    gap: 28px;
    margin-bottom: 28px;
  }
`

export const LastEpisodes = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.white};

  ${device.medium} {
    font-size: 24px;
  }
`

export const SeasonSelector = styled.div`
  display: flex;
  position: relative;
`

export const SelectedSeason = styled.span<{ open: boolean }>`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.darkGray2};
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 9px;
    transform: rotate(${props => (props.open ? '180deg' : 0)});
  }

  ${device.medium} {
    font-size: 24px;
  }
`

export const SeasonsList = styled.ul<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  padding: 4px 8px 4px 10px;
  background: ${colors.seasonSelector};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px rgba(227, 227, 227, 0.1);
  position: absolute;
  width: 133px;
  top: 22px;
  right: 0;
  z-index: 2;

  ${device.medium} {
    top: 29px;
    width: 283px;
    padding: 12px;
    border: none;
  }
`

export const SeasonTitle = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 500;
  color: ${props => (props.selected ? colors.pink : colors.white)};
  border-bottom: 1px solid rgba(243, 243, 243, 0.2);
  cursor: pointer;

  :hover {
    color: ${colors.pink};
  }

  :first-child {
    ${device.medium} {
      padding-top: 0;
    }
  }

  :last-child {
    border-bottom: none;

    ${device.medium} {
      padding-bottom: 0;
    }
  }

  ${device.medium} {
    font-size: 20px;
  }
`

export const EpisodesGridFl = styled.div`
  display: grid;
  gap: 19px;

  ${device.medium} {
    grid-template-columns: repeat(4, 1fr);
    gap: 28px 20px;
  }
  ${device.large} {
    gap: 28px 30px;
  }
`

export const ShowMore = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.white};
  align-self: center;
  cursor: pointer;
  margin-top: 28px;

  svg {
    height: 9px;
    margin-right: 4px;
  }
`
