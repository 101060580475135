import styled from 'styled-components'
import PlayButton from '../../../public/assets/images/svg/play-logo-episodes.svg'
import { colors, device, fonts } from '../../styles/GlobalVariables'

export const RelativeDiv = styled.div`
  ${props =>
    props.theme.isAdmin
      ? `
  position: relative;
      &:hover{
        [class*='StyledBtn']{
          opacity: .5;
          &:hover{
            opacity: 1;
          }
        }
    }
  `
      : ''}
`

export const HiddenInput = styled.input``

export const ControlsWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  flex-direction: row-reverse;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
`

export const MobileImage = styled.img`
  ${device.medium} {
    display: none;
  }
`

export const DesktopImage = styled.img`
  display: none;

  ${device.medium} {
    display: inherit;
    min-height: inherit;
    max-height: inherit;
  }
`
export const StyledPlayButtonDesktop = styled(PlayButton)<{
  playWidth: string
  playHeight: string
  playTop: string
  playLeft: string
  playBottom: string
  playRight: string
  transformFirst: string
  transformSecond: string
  playPosition: string
}>`
  display: none;

  ${device.medium} {
    position: ${props => props.playPosition};
    display: block;
    top: ${props => props.playTop}%;
    right: ${props => props.playRight}px;
    left: ${props => props.playLeft}%;
    bottom: ${props => props.playBottom}px;
    transform: translate(
      ${props => props.transformFirst}%,
      ${props => props.transformSecond}%
    );
    width: ${props => props.playWidth}px;
    height: ${props => props.playHeight}px;
    backdrop-filter: blur(18px);
    border-radius: 50%;
  }
`
export const StyledPlayButtonMobile = styled(PlayButton)<{
  playWidth: string
  playHeight: string
  playTop: string
  playLeft: string
  playBottom: string
  playRight: string
  transformFirst: string
  transformSecond: string
}>`
  ${device.medium} {
    display: none;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    display: block;
    top: ${props => props.playTop}%;
    right: ${props => props.playRight}px;
    left: ${props => props.playLeft}%;
    bottom: ${props => props.playBottom}px;
    transform: translate(
      ${props => props.transformFirst}%,
      ${props => props.transformSecond}%
    );
    width: ${props => props.playWidth}px;
    height: ${props => props.playHeight}px;
    backdrop-filter: blur(18px);
    border-radius: 50%;
  }
`
export const PlayTextContainerDesktop = styled.div`
  display: none;

  ${device.medium} {
    display: flex;
    width: 336px;
    color: ${colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${fonts.narkis};
    font-size: 18px;
    line-height: 1.2em;
    font-style: normal;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    align-items: flex-end;
    padding-left: 8px;
  }
`
export const PlayTextContainerMobile = styled.div`
  display: flex;
  width: 311px;
  color: ${colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${fonts.narkis};
  font-size: 18px;
  line-height: 1.2em;
  font-style: normal;
  font-weight: 500;
  align-items: flex-end;
  height: 2.6em;
`

export const PlayContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;

  ${device.medium} {
    display: flex;
    flex-direction: row;
    padding: 0 8px;
    gap: 8px;
  }
`
export const ProfileTitle = styled.div<{ fontColor: string }>`
  position: absolute;
  margin-top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 16px;
  color: ${props => props.fontColor};
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  max-height: 40px;
  width: 90%;

  ${device.medium} {
    font-size: 18px;
  }
`
