import { Swiper, SwiperSlide } from 'swiper/react'
import { usePage } from '../../../contexts'
import { useFL } from '../../../hooks/useFL'
import { colors } from '../../../styles/GlobalVariables'
import { IArticlesGrid, IPost } from '../../../types'
import { addAnalyticsToUrl } from '../../../utils'
import Credits from '../../DateAndWriter/Credits'
import { Image } from '../../Image/Image'
import Link from '../../Link/Link'
import { Shave } from '../../Shave/Shave'
import { ComponentContainer, Post, PostTitle } from './MobileSwiperSmall.styles'

type Props = {
  layout: IArticlesGrid
}

export const MobileSwiperSmall = ({ layout }: Props) => {
  const { posts } = layout
  const page = usePage()
  const { fontColor, isDarkTheme } = useFL()

  if (!page || !posts.length) {
    return null
  }

  return (
    <ComponentContainer>
      <Swiper allowTouchMove={true} slidesPerView={2.5} spaceBetween={8}>
        {posts.slice(0, 12).map((post: IPost, idx) => (
          <SwiperSlide key={idx}>
            <Link
              href={addAnalyticsToUrl(
                post.link,
                page.PageMeta.id.toString(),
                layout.grid_id.toString()
              )}
            >
              <Post>
                <Image image={post.imageObj} />
                <Shave maxLines={4}>
                  <PostTitle theme={{ fontColor: fontColor }}>
                    {post.title}
                  </PostTitle>
                </Shave>
                <Credits
                  credits={post}
                  color={isDarkTheme ? colors.steelBlue : colors.darkGray2}
                />
              </Post>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </ComponentContainer>
  )
}
