import { useCallback } from 'react'
import { usePage } from '../../contexts'
import { ISeasonFL } from '../../types'
import { addAnalyticsToUrl } from '../../utils'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { utils } from '../Player/Helpers/utils'
import {
  Container,
  SeasonContainer,
  SeasonImage,
  SeasonText,
  Title,
} from './SeasonFL.styles'

type Props = {
  season: ISeasonFL
}

/**
 * SeasonFL component
 */
export const SeasonFL = ({ season }: Props) => {
  const { grid_title, grid_id, posts } = season
  const page = usePage()

  const addAnalytics = useCallback(
    (link: string) => {
      return (
        link &&
        addAnalyticsToUrl(
          link || '',
          page?.PageMeta.id?.toString() || '',
          grid_id?.toString() || ''
        )
      )
    },
    [page?.PageMeta.id, grid_id]
  )

  return (
    <Container>
      <ComponentTitleFL componentTitle={grid_title} componentId={grid_id} />
      <SeasonContainer>
        {posts.slice(0, 12).map(post => {
          const { id, imageObj, title, link, isBlank } = post
          if (!imageObj) {
            return null
          }

          return (
            <SeasonImage
              href={addAnalytics(link)}
              target={isBlank ? '_blank' : '_self'}
              theme={{ img: utils.isMobile() ? imageObj.m : imageObj.d }}
              key={id}
            >
              <SeasonText>
                <Title>{title}</Title>
              </SeasonText>
            </SeasonImage>
          )
        })}
      </SeasonContainer>
    </Container>
  )
}

export default SeasonFL
