import styled from 'styled-components'
import { colors, device, fonts, maxWidth } from '../../styles/GlobalVariables'
import { Container as BreadcrumbsContainer } from '../Breadcrumbs/Breadcrumbs.styles'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${device.medium} {
    margin: 0 auto;
    max-width: ${maxWidth.medium};
    width: 100%;
    flex-direction: row;
  }

  ${device.large} {
    max-width: ${maxWidth.large};
  }
  [class^='Breadcrumbsstyles__Container'] {
    width: 100%;
    margin-right: 0;
  }
`

export const HorizontalContainer = styled.div`
  display: flex;
`
export const LR = styled.div`
  width: 100%;
  min-width: 300px;
  max-width: 300px;
`

export const FullWidth = styled.div`
  position: relative;
  ${device.small} {
    margin-bottom: 28px;
  }

  [class^='ImageWithCreditsstyles__Container'] {
    margin-bottom: 0;
    [class^='ImageWithCreditsstyles__Image'] {
      margin-bottom: 0;
    }
  }

  .playkit-pre-playback-play-overlay .playkit-pre-playback-play-button {
    border: 8px solid rgba(136, 172, 255, 0.28);
    background: ${colors.darkBlue};
    &:hover {
      border: 8px solid rgba(55, 112, 245, 0.28);
    }
  }

  [class^='ArticleTitlestyles__Container'],
  [class^='ArticleMetastyles__Container'] {
    padding: 0 20px;

    ${device.small} {
      padding: 0;
    }
  }
`

export const ArticleOnImage = styled.div`
  ${device.small} {
    position: absolute;
    top: 0;
    right: 0;
    justify-content: flex-end;
    background: linear-gradient(
      180deg,
      ${colors.black}00 32.56%,
      ${colors.black} 92.95%
    );

    [class^='ArticleTitlestyles'],
    [class^='ArticleMetastyles'] {
      width: 100%;
      display: block;
      color: ${colors.white};
      flex-grow: 0;
      font-size: 38px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 0 28px;
    }

    [class^='ArticleTitlestyles__SubTitle'] {
      height: 44px;
      flex-grow: 0;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${colors.white};
    }
    [class^='ArticleCreditsstyles'] {
      color: ${colors.commentsGray};
    }
    height: 100%;
    margin-bottom: 0;
  }
  margin-bottom: 18px;
  width: 100%;
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-left: 20px;
    margin-right: 20px;

    ${device.medium} {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  > ${BreadcrumbsContainer} {
    margin-bottom: 16px;
  }

  > ${FullWidth} {
    margin-left: 0px;
    margin-right: 0px;
  }

  ${device.medium} {
    flex-basis: 960px;
    flex-shrink: 0;
  }
`

export const TextContainer = styled.div`
  p {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 16px;

    ${device.medium} {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  blockquote {
    display: flex;
    align-items: center;
    padding-right: 20px;
    border-right: 4px solid ${colors.darkBlue};
    margin: 30px 0;

    &,
    p {
      letter-spacing: 0.02em;
      color: ${colors.darkBlue};
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;

      ${device.medium} {
        font-size: 31px;
        line-height: 37px;
        margin: 40px 0;
      }
    }

    ${device.medium} {
      margin: 40px 0;
    }
  }

  h3 {
    font-weight: bold;
    color: ${colors.darkBlue};
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px;
    margin-bottom: 4px;

    ${device.medium} {
      font-size: 24px;
      line-height: 29px;
      margin-top: 40px;
      margin-bottom: 11px;
    }
  }

  ul {
    margin-bottom: 30px;

    ${device.medium} {
      margin-bottom: 40px;
    }
  }

  ul li {
    font-size: 18px;
    line-height: 22px;
    list-style-type: '•';
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    :before {
      content: '•';
      position: relative;
      top: 2px;
      font-size: 54px;
      line-height: 22px;
      letter-spacing: -6px;
      color: ${colors.darkBlue};
    }

    ${device.medium} {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 10px;
    }
  }

  ul li a {
    font-weight: bold;
    color: ${colors.darkBlue};
  }
`

export const AdWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 20px;

  margin-bottom: 16px;

  ${device.medium} {
    flex-direction: row-reverse;
    margin-bottom: 20px;
  }

  > div {
    min-width: unset;
  }

  > p {
    margin-bottom: 0;
  }
`

export const InShort = styled.div`
  min-width: 300px;
  max-width: 300px;

  ${props =>
    props.theme.show === 'desktop'
      ? `
        display: none;
        ${device.small}{
            display: block;
        }
    `
      : `
        ${device.small}{
            display: none;
        }
    `}

  .main-title {
    margin: 0 0 16px;
    padding: 1px 5.5px 1px 220.5px;
    background-color: ${props => props.theme.backgroundColor};
    flex-grow: 0;
    font-family: ${fonts.narkis};
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    color: ${colors.white};
  }

  .short-timeline-items {
    font-family: ${fonts.narkis};

    .time-line-wrap {
      margin: 6px 0;
      padding-top: 6px;
      & + div {
        border-top: 1px solid #0000004a;
      }
      .date {
        flex-grow: 0;
        font-size: 16px;
        font-weight: 600;
        color: #00a8f5;
        margin-bottom: 4px;
      }
      .title {
        flex-grow: 0;
        font-size: 18px;
        font-weight: normal;
        line-height: normal;
        color: ${colors.black};
      }
    }
  }
`
