import { useMemo } from 'react'
import styled from 'styled-components'
import { IGridVodItem, IGridVodPage, IVodPlaylist } from '../../types'
import { midBoxPositions, taboolaPositionsGridVOD } from '../../utils'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { EditMe } from '../EditMe'
import {
  commonItemToComponent,
  gridVodItemToComponent,
} from '../itemToComponent'
import { PageTitle } from '../PageTitle/PageTitle'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import { WordPressWrapper } from '../WordPressWrapper'
import { Container } from './GridVod.styles'
import { MidBox } from '../Ads'
import { GridBlockProvider } from '../../contexts'

type Props = {
  page: IGridVodPage
}

export const GridVodPage = ({ page }: Props) => {
  const disableTaboola = page.PageMeta.ads.block.taboola
  const disableDFP = page.PageMeta.ads.block.dfp

  let taboolaCount = 1
  const pageGridItems = useMemo(() => {
    let adsToInject = midBoxPositions.length
    return page?.Content?.PageGrid?.map((item, index, x) => {
      const renderTaboola =
        !disableTaboola && x.length > 1 && taboolaPositionsGridVOD(index + 1)
      return (
        <WordPressWrapper item={item} key={index}>
          <EditMe grid_id={item.grid_id}>
            <GridBlockProvider gridId={item.grid_id}>
              {gridVodItemToComponent({
                item: item as IGridVodItem,
                i: index,
              }) ?? commonItemToComponent({ item, i: index })}
            </GridBlockProvider>
          </EditMe>
          {renderTaboola && (
            <TaboolaWrapper>
              <Taboola
                pageType={PocTaboolaPageType.vod}
                suffixId={PocTaboolaSuffixId.widget}
                index={taboolaCount++}
              />
            </TaboolaWrapper>
          )}
          {!disableDFP && midBoxPositions.includes(index + 1) && (
            <MidBox
              index={1 + midBoxPositions.length - adsToInject--}
              collapse={true}
              key={'midBox' + index}
            />
          )}
        </WordPressWrapper>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?.Content?.PageGrid])

  const { SEO, PageMeta, Header } = page
  const vodTitle = (page?.Content?.PageGrid[0] as IVodPlaylist).vodShowName

  return (
    <Container key={PageMeta.id}>
      <PageTitle SEO={SEO} pageMeta={PageMeta} forceTitle={vodTitle} />
      <Breadcrumbs breadcrumbs={Header.breadCrumbs} />
      {pageGridItems}
      {!disableTaboola && (
        <TaboolaWrapper>
          <Taboola
            key={'GridVodFeed'}
            pageType={PocTaboolaPageType.vod}
            suffixId={PocTaboolaSuffixId.pageFooter}
          />
        </TaboolaWrapper>
      )}
    </Container>
  )
}

const TaboolaWrapper = styled.div`
  margin-bottom: 30px;
`
