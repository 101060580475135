import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { IVodPrograms } from '../../types'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { Image } from '../Image/Image'
import {
  Carousel,
  Container,
  ProgramContainer,
  ProgramsGrid,
  Thumbnail,
} from './VodPrograms.styles'

type Props = {
  item: IVodPrograms
}

export const VodPrograms = ({ item }: Props) => {
  const { grid_title: title, shows } = item
  if (!shows) {
    return null
  }

  const swiperProps: SwiperProps = {
    slidesPerView: 2,
    dir: 'rtl',
    spaceBetween: 11,
    breakpoints: {
      542: {
        slidesPerView: 3,
      },
      713: {
        slidesPerView: 4,
      },
      883: {
        slidesPerView: 5,
      },
      1055: {
        slidesPerView: 6,
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 22.5,
      },
      1300: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    },
  }

  return (
    <Container>
      <ComponentTitle componentTitle={title} />
      <Carousel>
        <Swiper {...swiperProps}>
          {shows.map(({ url, imageObj }, index) => (
            <SwiperSlide key={index}>
              <ProgramContainer>
                <Thumbnail href={url} ariaLabel={`${index}`}>
                  <Image image={imageObj} />
                </Thumbnail>
              </ProgramContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </Carousel>
      <ProgramsGrid>
        {shows.map(({ url, imageObj }, index) => (
          <ProgramContainer key={index}>
            <Thumbnail href={url} ariaLabel={`${index}`}>
              <Image image={imageObj} />
            </Thumbnail>
          </ProgramContainer>
        ))}
      </ProgramsGrid>
    </Container>
  )
}
