import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
  position: relative;

  ${device.medium} {
    margin-bottom: 28px;
  }
`

export const PlayerWrapper = styled.div`
  flex: 1;
  margin-bottom: 20px;

  ${device.medium} {
    order: 4;
    margin-bottom: 8px;
  }
`

export const Title = styled.h2`
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
  color: ${colors.white};
  margin-bottom: 4px;

  ${device.medium} {
    font-size: 34px;
    order: 1;
    margin-bottom: 0;
  }
`

export const Wrapper = styled.div<{ collapsed: boolean }>`
  display: ${props => (props.collapsed ? 'none' : 'flex')};
  flex-direction: column;

  ${device.medium} {
    order: 2;
    margin-top: 12px;
  }
`

export const SecondaryTitle = styled.h3`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: ${colors.white};
  margin-bottom: 8px;

  ${device.medium} {
    font-size: 18px;
    margin-bottom: 12px;
  }
`

export const PortalLink = styled(Link)`
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2;
  color: ${colors.pink};
  margin-bottom: 12px;
`

export const AirDate = styled.span`
  font-size: 14px;
  line-height: 1.2;
  color: ${colors.transparentWhite};
  margin-bottom: 22px;

  ${device.medium} {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.whitish};
    margin-bottom: 0;
    order: 5;
  }
`

export const ExpandButton = styled.span<{ collapsed: boolean }>`
  font-size: 17px;
  font-weight: 500;
  color: ${props => (props.collapsed ? colors.pink : colors.collapsedButton)};
  border-bottom: 1px solid
    ${props => (props.collapsed ? colors.pink : colors.collapsedButton)};
  cursor: pointer;
  max-width: fit-content;

  ${device.medium} {
    order: 3;
    margin-top: 13px;
    margin-bottom: 24px;
  }
`
