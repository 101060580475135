import styled from 'styled-components'
import {
  colors,
  device,
  playButtonStyle,
  postThumbnailHeight,
} from '../../styles/GlobalVariables'

export const Container = styled.div<{ nowPlaying?: boolean }>`
  display: flex;
  gap: 12px;
  cursor: ${props => (props.nowPlaying ? 'default' : 'pointer')};

  ${device.medium} {
    flex-direction: column;
    gap: 18px;
  }
`

export const Thumbnail = styled.div<{ nowPlaying?: boolean }>`
  flex: 1 1 50%;
  display: flex;
  position: relative;
  min-height: ${postThumbnailHeight.mobile};
  max-height: ${postThumbnailHeight.mobile};
  border-radius: 1px;

  > img {
    flex: 1;
    object-fit: cover;
    min-width: 0;
  }

  ${device.medium} {
    min-height: ${postThumbnailHeight.medium};
    max-height: ${postThumbnailHeight.medium};
  }

  ${playButtonStyle()}
`

export const CurrentlyPlayingOverlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;

  background-color: ${colors.darkOverlay};
  z-index: 1;

  ${device.medium} {
    font-size: 24px;
    font-weight: 500;
  }
`

export const TextContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 11px;

  ${device.medium} {
    gap: 6px;
    padding-top: 0;
  }
`

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: ${colors.white};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: calc(1.2 * 4 * 16px);

  ${device.medium} {
    font-size: 20px;
    font-weight: bold;
    -webkit-line-clamp: 3;
    max-height: calc(1.2 * 3 * 20px);
  }
`

export const AirDate = styled.span`
  font-size: 14px;
  line-height: 1.2;
  color: ${colors.transparentWhite};

  ${device.medium} {
    font-size: 15px;
    line-height: 1.77;
  }
`
