import styled from 'styled-components'
import {
  colors,
  device,
  playButtonStyle,
  themeClassName,
} from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div<{
  backgroundColor: false | string
  rowSpan: number
  columnSpan: number
  span?: number
}>`
  grid-area: span ${props => props.rowSpan} / span ${props => props.columnSpan};
  display: flex;
  flex-direction: column;
  gap: 14px;
  ${props =>
    props.backgroundColor ? `background: ${props.backgroundColor}` : null};

  ${device.medium} {
    gap: 18px;
    max-width: unset;
  }
`

export const Thumbnail = styled(Link)<{
  rowSpan: number
  columnSpan: number
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  min-height: ${({ rowSpan }) => (rowSpan === 1 ? '95' : '280')}px;
  max-height: ${({ rowSpan }) => (rowSpan === 1 ? '95' : '280')}px;

  ${device.medium} {
    /* prevents overflow;
        explanation: https://stackoverflow.com/q/36247140/3597276 */
    min-width: 0;
    min-height: ${({ rowSpan }) => (rowSpan === 1 ? '170px' : '471px')};
    max-height: ${({ rowSpan }) => (rowSpan === 1 ? '170px' : '471px')};
  }

  img {
    flex: 1;
    object-fit: cover;
    min-height: 0; // explanation above
  }

  ${playButtonStyle()}
`

export const PromoStrip = styled.div`
  background: ${colors.whiteRed};
  color: ${colors.white};
  position: absolute;
  padding: 4px 6px;
  bottom: 0;
  right: 0;
  font-size: 12px;
  font-weight: normal;

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
  }
`

export const TextContainer = styled.div<{
  backgroundColor: false | string
}>`
  ${props =>
    props.backgroundColor === colors.lightGray && {
      paddingRight: '2px',
    }}
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${device.medium} {
    ${props =>
      props.backgroundColor === colors.lightGray && {
        paddingRight: '4px',
      }}
    gap: 6px;
  }
`

export const Title = styled.h3<{ isDarkTheme?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: ${props => (props.isDarkTheme ? colors.white : colors.black)};

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    font-size: 20px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: calc(1.2 * 3 * 20px);
  }
`
