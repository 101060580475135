import { he } from '../../locales/he'
import { IPlaylistEpisode } from '../../types'
import { Image } from '../Image/Image'
import {
  AirDate,
  Container,
  CurrentlyPlayingOverlay,
  TextContainer,
  Thumbnail,
  Title,
} from './PlaylistEpisode.styles'
import CurrentlyPlayingLogo from '/public/assets/images/svg/currently-playing-logo.svg'
import PlayButton from '/public/assets/images/svg/play-button.svg'

type Props = {
  episode: IPlaylistEpisode
  onClick?: () => void
  nowPlaying?: boolean
}

export const PlaylistEpisode = ({ episode, onClick, nowPlaying }: Props) => {
  const { imageObj, title, air_date: airDate } = episode

  return (
    <Container onClick={onClick} nowPlaying={nowPlaying}>
      <Thumbnail nowPlaying={nowPlaying}>
        {nowPlaying && (
          <CurrentlyPlayingOverlay>
            <CurrentlyPlayingLogo />
            {he.nowPlaying}
          </CurrentlyPlayingOverlay>
        )}
        <Image image={imageObj} alt={imageObj ? title : title} />
        <PlayButton />
      </Thumbnail>
      <TextContainer>
        <Title>{title}</Title>
        <AirDate>{airDate}</AirDate>
      </TextContainer>
    </Container>
  )
}
