import styled from 'styled-components'
import { colors, device, fonts, maxWidthFl } from '../../styles/GlobalVariables'
import { Container as BreadcrumbsContainer } from '../Breadcrumbs/Breadcrumbs.styles'
import {
  figureStyle,
  StyledIframeWrapper as IframeWrapper,
} from '../ContentParserFl/ContentParserFl.style'
import { Container as ShareButtons } from '../ShareButtons/ShareButtons.styles'

export const Container = styled.div<{
  backgroundColor: string
  fontColor: string
}>`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background-color: ${props => props.backgroundColor};

  a {
    color: #000;
  }

  ${device.medium} {
    margin: 8px auto 0 auto;
    max-width: ${maxWidthFl.large};
  }
`
export const ContainerArticleCredits = styled.div`
  display: flex;
  white-space: pre;
  gap: 16px;
  padding: 16px 0;
  flex-wrap: wrap;

  &,
  > a {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.32px;

    ${device.medium} {
      font-size: 18px;
      font-weight: bold;
    }
  }
`

export const HorizontalContainer = styled.div`
  display: flex;
`
export const ArticleMetaContainer = styled.div<{ border: string }>`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--Seperation-Line, ${props => props.border});
  border-bottom: 1px solid var(--Seperation-Line, ${props => props.border});

  @media all and (max-width: 768px) {
    margin-bottom: 24px;
  }
`
export const ArticleContentWraper = styled.div`
  ${device.medium} {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
  }
`

export const Sidebar = styled.div<{
  stickyShare: 'top' | 'bottom' | false
  headerHeight: number
}>`
  display: none;
  margin-right: 124px;

  ${device.medium} {
    display: flex;
  }

  ${device.large} {
    flex-direction: column;
  }

  ${ShareButtons} {
    ${({ stickyShare, headerHeight }) =>
      stickyShare === 'top' && {
        position: 'fixed',
        top: `${headerHeight + 20}px`,
      }};

    ${({ stickyShare }) =>
      stickyShare === 'bottom' && { position: 'absolute', bottom: 0 }};
  }
`

export const ShareButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 16px;
  font-size: 38px;
  min-width: ${props => props.theme.size || 40}px;
  position: relative;

  svg {
    display: block;
  }

  ${ShareButtons} {
    flex-direction: column;
  }
`

export const FullWidth = styled.div``

export const Content = styled.article`
  display: flex;
  flex-direction: column;

  > * {
    margin-left: 20px;
    margin-right: 20px;

    ${device.medium} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  > ${BreadcrumbsContainer} {
    margin-bottom: 16px;
  }

  > ${FullWidth} {
    margin-left: 0;
    margin-right: 0;
  }

  & iframe[title='YouTube video player'] {
    height: 460px;
  }

  ${device.medium} {
    flex-basis: 848px;
    flex-shrink: 0;
  }
`
export const TextContainer = styled.div`
  margin-bottom: 15px;

  ${device.medium} {
    margin-bottom: 40px;
  }

  > *:last-child {
    > * {
      margin-bottom: 0;
    }

    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
    font-weight: normal;

    &:hover {
      color: ${colors.steelBlue};
    }
  }

  p {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 16px;

    ${device.medium} {
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 20px;
    }

    iframe,
    ${IframeWrapper} {
      margin-top: 30px;
      margin-bottom: 14px;

      ${device.medium} {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }

  p > iframe,
  ${IframeWrapper} {
    margin-right: -20px;
    margin-left: -20px;
    max-width: 100vw;

    ${device.medium} {
      margin-right: unset;
      margin-left: unset;
      max-width: unset;
    }
  }

  figure {
    ${figureStyle};
  }

  blockquote:not(.twitter-tweet, .instagram-media) {
    &,
    p {
      padding: 16px 8px;
      text-align: center;
      justify-content: center;
      font-family: 'Leon';
      font-size: 22px;
      font-weight: 400;
      line-height: 24px;

      ${device.medium} {
        padding: 32px 82px;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 1px;
      }
    }

    ${device.medium} {
      margin: 40px 0;
    }
  }

  .twitter-tweet,
  .instagram-media {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px;
    margin-bottom: 4px;

    ${device.medium} {
      font-size: 28px;
      line-height: 29px;
      margin-top: 40px;
      margin-bottom: 16px;
    }
  }

  ul,
  ol {
    margin-bottom: 30px;

    ${device.medium} {
      margin-bottom: 40px;
    }

    li {
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      :before {
        position: relative;
        top: 2px;
        font-size: 54px;
        line-height: 22px;
        letter-spacing: -6px;
      }

      ${device.medium} {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 10px;
      }

      a {
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  ol {
    li {
      display: list-item;
      margin-right: 22px;

      ::marker {
        color: #00104a;
      }
    }
  }

  iframe {
    max-width: 100%;
  }

  > iframe,
  ${IframeWrapper}, .twitter-tweet {
    margin: 0 auto 12px !important;
  }
`

export const TaboolaLR = styled.div`
  max-width: 300px;
  min-width: 300px;
`

export const TaboolaWrapper = styled.div`
  margin: 0 20px;

  ${device.medium} {
    margin: unset;
  }
`
export const MostPopular = styled.div<{
  fontColor: string
  border: string
  hoverFontColor: string
}>`
  min-width: 308px;
  max-width: 308px;
  margin-bottom: 72px;
  margin-top: 10px;

  .main-title {
    margin: 0 0 16px;
    flex-grow: 0;
    font-family: ${fonts.narkis};
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    color: ${props => props.fontColor};
  }

  .short-timeline-items {
    font-family: ${fonts.narkis};
  }

  .title {
    display: flex;
    flex-direction: column;
    padding: 24px 21px;
    gap: 8px;
    align-self: stretch;
    border-top: 1px solid var(--Seperation-Line, ${props => props.border});
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    color: ${props => props.fontColor};
    text-decoration: none;

    &:hover {
      color: ${props => props.hoverFontColor};
    }
  }
`
