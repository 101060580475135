import Link from '../Link/Link'
import { ArrowWrapper } from './PagerFl.styles'
import ArrowLeftActiveIcon from '/public/assets/images/svg/arrow-left_fl-active.svg'
import ArrowRightActiveIcon from '/public/assets/images/svg/arrow-right_fl-active.svg'
import ArrowLeftWhiteActiveIcon from '/public/assets/images/svg/arrow-white_left-active.svg'
import ArrowRightWhiteActiveIcon from '/public/assets/images/svg/arrow-white_right-active.svg'

type Props = {
  direction: 'left' | 'right'
  active: boolean
  link: string
  isDarkTheme: boolean
  onClick?: () => void
}

export const ArrowFl = ({
  direction,
  active,
  link,
  isDarkTheme,
  onClick,
}: Props) => {
  const icon =
    direction === 'left' ? (
      active ? (
        isDarkTheme ? (
          <ArrowLeftWhiteActiveIcon />
        ) : (
          <ArrowLeftActiveIcon />
        )
      ) : null
    ) : direction === 'right' ? (
      active ? (
        isDarkTheme ? (
          <ArrowRightWhiteActiveIcon />
        ) : (
          <ArrowRightActiveIcon />
        )
      ) : null
    ) : null

  return (
    <ArrowWrapper
      onClick={onClick}
      theme={{ isDarkTheme: isDarkTheme }}
      active={active}
    >
      {link ? <Link href={active ? link : undefined}>{icon}</Link> : icon}
    </ArrowWrapper>
  )
}
