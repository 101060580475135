import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
export const ReelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  ${device.medium} {
    flex-direction: row;
    gap: 16px;
  }
`
