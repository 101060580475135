import styled from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import Link from '../Link/Link'
import { StyledLinkNewsflashesItem } from '../Newsflashes/Newsflashes.styles'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 -20px 30px;

  ${device.medium} {
    margin: 0 0 28px;
  }
`

export const StyledComponentTitle = styled(ComponentTitle)`
  margin: 0 20px 20px;

  ${device.medium} {
    margin: 0 0 28px;
  }
`

export const PostContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${device.medium} {
    flex-direction: row;

    ${StyledLinkNewsflashesItem} {
      padding: 15px 5px 12px 29px;
    }
  }
`

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  min-height: 390px;

  ${device.medium} {
    flex-direction: row;
    margin-left: 20px;
    max-height: unset;
  }

  ${device.large} {
    /* distance between player and vertical newsflash marquee */
    margin-left: 30px;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #000000 1.02%, rgba(0, 0, 0, 0) 71.06%);

    ${device.medium} {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 49.91%,
        rgba(0, 0, 0, 0.81) 96.38%
      );
    }
  }
`

export const StyledLinkImage = styled(Link)`
  flex: 1;
  display: flex;

  ${device.medium} {
    min-height: 540px;
    max-height: 540px;
  }

  img {
    flex: 1;
    min-width: 0;
    border-radius: 1px;
    object-fit: cover;

    ${device.medium} {
      min-height: 540px;
      max-height: 540px;
    }
  }
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.81) 96%
  );
`

export const TextContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  z-index: 5;

  ${device.medium} {
    gap: 0;
    padding: 0 28px 28px 28px;
    max-width: 746px;
  }

  ${device.large} {
    flex: 1;
  }
`

export const StyledLinkTitle = styled(Link)`
  color: ${colors.white};
  font-size: 28px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 4px;
  padding-left: 18px;

  max-height: calc(2 * 1.2 * 28px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    padding: 0;
    font-size: 38px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 8px;

    max-height: calc(1 * 1.2 * 38px);
    -webkit-line-clamp: 1;
  }
`

export const StyledLinkSubtitle = styled(Link)`
  color: ${colors.white};
  font-size: 16px;
  line-height: 1.35;
  font-weight: 400;
  margin-bottom: 4px;

  max-height: calc(2 * 1.35 * 16px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 12px;

    max-height: calc(2 * 1.2 * 18px);
    -webkit-line-clamp: 2;
  }
`

export const NewsflashesContainer = styled.div`
  ${device.medium} {
    display: flex;
    height: 540px;
  }
`
