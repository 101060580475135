import styled from 'styled-components'
import { colors, device, fonts } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  flex-direction: column;

  ${device.medium} {
    gap: 16px;
    max-width: 738px;
    margin-bottom: 0px;
  }
`

export const Thumbnail = styled(Link)`
  & img {
    width: 100%;
    height: auto;
    transition: filter 0.3s ease;

    &:hover,
    &:active {
      filter: brightness(80%);
    }
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  ${device.medium} {
    justify-content: flex-start;
  }
`

export const Title = styled.div<{
  fontColor?: string
}>`
  color: ${({ fontColor }) => fontColor ?? '#000'};
  text-align: right;
  font-family: ${fonts.narkis};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${device.medium} {
    -webkit-line-clamp: 3;
    font-size: 28px;
  }
`

export const DateContainer = styled.div`
  color: ${props =>
    props.theme.isDarkTheme ? colors.steelBlue : colors.darkGray2};
  text-align: right;
  font-family: ${fonts.narkis};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  ${device.medium} {
    font-size: 18px;
    font-weight: 300;
  }
`

export const SecondaryTitle = styled.div<{ fontColor?: string }>`
  display: none;

  ${device.medium} {
    display: block;
    color: ${({ fontColor }) => fontColor ?? '#000'};
    text-align: right;
    font-family: ${fonts.narkis};
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.4;
  }
`
