import axios from 'axios'
import { useEffect, useState } from 'react'
import { Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { he } from '../../locales/he'
import { IOpinion, IOpinionsAndPosts } from '../../types'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { StyledPost } from '../Layout/Layout6/Layout6.styles'
import {
  Container,
  Content,
  Header,
  Layout,
  LogoContainer,
  No,
  Opinion,
  OpinionDetails,
  OpinionsContainer,
  Owner,
  OwnersContainer,
  Percentage,
  PostsContainer,
  Subtitle,
  Title,
  Voting,
  Yes,
} from './Opinions.styles'
import ThumbsDown from '/public/assets/images/svg/thumbs-down.svg'
import ThumbsUp from '/public/assets/images/svg/thumbs-up.svg'
import { useGridBlock } from '../../contexts'

type IVotes = {
  post_id: number
  user_like_sum: number
  user_unlike_sum: number
}

export type VoteType = 'like' | 'unlike'

type Votes = {
  [key: number]: VoteType
}

type IOpinionAndVotes =
  | IOpinion & {
      likes: number
      unlikes: number
    }

type Props = {
  opinionsAndPosts: IOpinionsAndPosts
}

export const Opinions = ({
  opinionsAndPosts: { grid_title, posts, layout_mobile, opinions },
}: Props) => {
  const { gridId } = useGridBlock()

  const [opinionsAndVotes, setOpinionsAndVotes] = useState<
    IOpinionAndVotes[] | IOpinion[]
  >(opinions)
  const [opinion, setOpinion] = useState(opinionsAndVotes[0])
  const [storeVotes, setStoreVotes] = useState<Votes>({})

  useEffect(() => {
    const getOpinionsAndVotes = async () => {
      const opinionsAndVotes = await mapVotesToOpinions(opinions)
      const cleanOpinionsAndVotes = [] as (IOpinionAndVotes | IOpinion)[]
      opinionsAndVotes.forEach(
        opinion => opinion !== undefined && cleanOpinionsAndVotes.push(opinion)
      )

      setOpinionsAndVotes(cleanOpinionsAndVotes)
      setOpinion(cleanOpinionsAndVotes[0])
    }
    getOpinionsAndVotes()

    const votes = getVotesFromStore()
    setStoreVotes(votes)
  }, [opinions])

  const {
    //owner: { name },
    show_voting,
    voting_title,
    title,
  } = opinion
  const { likes, unlikes } = hasVotes(opinion)
    ? opinion
    : { likes: 0, unlikes: 0 }

  const total = likes + unlikes
  const percentOfLikes = Number(total > 0 ? (likes / total) * 100 : 0).toFixed()
  const percentOfDislikes = total > 0 ? 100 - Number(percentOfLikes) : 0

  const vote = async (voteType: VoteType, id: number) => {
    if (!hasVotes(opinion)) {
      return
    }

    const response = await axios.get(
      `https://marketing.reshet.tv/opinion_votes/opinion_votes_controller.php?action=setVote&postid=${id}&user${voteType}=1`
    )
    if (response.status !== 200) {
      return
    }

    const newOpinion = opinion
    voteType === 'like' && newOpinion.likes++
    voteType === 'unlike' && opinion.unlikes++

    const newOpinions = [] as (IOpinionAndVotes | IOpinion)[]
    opinionsAndVotes.map(
      opinion =>
        !!opinion && newOpinions.push(opinion.id === id ? newOpinion : opinion)
    )

    setOpinionsAndVotes(newOpinions)
    setOpinion(newOpinion)

    const newVotes = { ...storeVotes, [id]: voteType }
    setStoreVotes(newVotes)
    setVotesToStore(newVotes)
  }

  // eslint-disable-next-line no-prototype-builtins
  const voted = storeVotes.hasOwnProperty(opinion.id)
  const voteType = voted ? storeVotes[opinion.id] : undefined

  const hasImage = opinion.content.includes('<img')

  return (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <Layout>
        <PostsContainer>
          {posts.map(post => (
            <StyledPost
              key={post.id}
              layoutMobile={layout_mobile}
              post={post}
              height='289px'
              maxLines={3}
            />
          ))}
        </PostsContainer>
        {opinion && (
          <OpinionsContainer>
            <div
              id={`opinions-anchors-${gridId}`}
              style={{ display: 'none' }}
            />
            <Header>
              <Title>
                {he.opinionsBold}
                <Subtitle>{he.opinionsRegular}</Subtitle>
              </Title>
              <LogoContainer />
            </Header>
            <OwnersContainer>
              <Swiper
                slidesPerView={'auto'}
                slidesPerGroupAuto={true}
                navigation={true}
                modules={[Navigation, Mousewheel]}
              >
                {opinionsAndVotes
                  .map(op => {
                    if (!op) {
                      return null
                    }
                    const {
                      id,
                      owner: { img, name },
                    } = op

                    return (
                      <SwiperSlide key={id}>
                        <Owner
                          selected={opinion.id === id}
                          onClick={() => opinion.id !== id && setOpinion(op)}
                        >
                          {img ? (
                            <img
                              loading='lazy'
                              src={img}
                              alt={name || undefined}
                            />
                          ) : (
                            <img />
                          )}
                          {name && <h3>{name}</h3>}
                        </Owner>
                      </SwiperSlide>
                    )
                  })
                  .slice(0, 15)}
              </Swiper>
            </OwnersContainer>
            <Opinion>
              <OpinionDetails>
                <h3>{title}</h3>
                {/*<span>{DateTime.parse(date)?.toPrettyFormat()}</span>*/}
              </OpinionDetails>
              {opinion && (
                <Content
                  hasImage={hasImage}
                  dangerouslySetInnerHTML={{ __html: opinion.content }}
                ></Content>
              )}
              {show_voting && hasVotes(opinion) && (
                <>
                  <h3>{voting_title}</h3>
                  <Voting voted={voted} vote={voteType}>
                    <Yes onClick={() => !voted && vote('like', opinion.id)}>
                      <ThumbsUp />
                      {total > 0 && (
                        <Percentage isThumbsUp>{percentOfLikes}%</Percentage>
                      )}
                    </Yes>
                    <No onClick={() => !voted && vote('unlike', opinion.id)}>
                      <ThumbsDown />
                      {total > 0 && (
                        <Percentage>{percentOfDislikes}%</Percentage>
                      )}
                    </No>
                  </Voting>
                </>
              )}
            </Opinion>
          </OpinionsContainer>
        )}
      </Layout>
    </Container>
  )
}

const mapVotesToOpinions = async (opinions: IOpinion[]) => {
  return await Promise.all(
    opinions.map(async opinion => {
      try {
        const response = await axios.get(
          `https://13tv.co.il/Services/opinion_votes/opinion_votes_controller.php?action=getVote&postid=${opinion.id}`
        )
        const votes = response.data as IVotes
        if (!votes) {
          return
        }
        const { user_like_sum: likes, user_unlike_sum: unlikes } = votes

        if (!likes && !unlikes) {
          return { ...opinion, likes: 0, unlikes: 0 }
        }

        return { ...opinion, likes, unlikes }
      } catch (error) {
        console.log("Couldn't get votes for opinions. Error:\n", { error })
        return opinion
      }
    })
  )
}

const getVotesFromStore = () => {
  const votes = localStorage.getItem('votes')
  if (!votes) {
    return []
  }
  return JSON.parse(votes) as Votes
}

const setVotesToStore = (votes: Votes) => {
  localStorage.setItem('votes', JSON.stringify(votes))
}

const hasVotes = (
  opinion: IOpinion | IOpinionAndVotes
): opinion is IOpinionAndVotes =>
  !!opinion && 'likes' in opinion && 'unlikes' in opinion
