import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div<{ backgroundUrl: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${device.medium} {
    position: relative;
    margin-bottom: 28px;

    padding-top: ${props => (props.backgroundUrl ? '62px' : '0')};
    padding-bottom: ${props => (props.backgroundUrl ? '83px' : '0')};

    // Background
    :before {
      display: ${props => (props.backgroundUrl ? 'block' : 'none')};
      position: absolute;
      content: '';
      z-index: auto;
      background-image: url(${props => props.backgroundUrl});
      object-fit: cover;
      overflow-x: hidden;

      width: 100vw;
      margin-left: -50vw;
      top: 0;
      left: 50%;
      height: 100%;
    }
  }
`

export const DesktopContainer = styled.div`
  display: none;

  ${device.medium} {
    display: flex;
    column-gap: 20px;
    position: relative;
  }

  ${device.large} {
    column-gap: 29px;
  }
`

export const ProgramsContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 205px 205px 205px;

  ${device.medium} {
    column-gap: 7px;
    max-height: 365px;
  }

  ${device.large} {
    column-gap: 8px;
  }
`

export const PostsContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 300px 300px;
  grid-template-rows: 170px 170px;

  ${device.medium} {
    gap: 25px 20px;
  }

  ${device.large} {
    gap: 25px 30px;
  }
`

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin: 0 -20px;

  ${device.medium} {
    display: none;
  }

  // Swiper centered slides breakpoint fix
  @media (min-width: 1000px) {
    margin: 0;
  }
`

export const CarouselContainer = styled.div``

export const SlideContainer = styled.div`
  max-height: 185px;
`

export const ProgramContainer = styled(Link)`
  display: flex;
  text-align: center;

  > img {
    object-fit: cover;
    height: 288px;

    ${device.medium} {
      height: 365px;
    }
  }
`
