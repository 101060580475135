import Link from 'next/link'
import styled from 'styled-components'
import { colors, device } from '../../../styles/GlobalVariables'

export const Container = styled.div`
  display: flex;
  white-space: pre;
  gap: 16px;
  padding: 16px 0;
  flex-wrap: wrap;
  &,
  > a {
    font-size: 14px;
    line-height: 1.2;
    color: ${props => props.color || colors.black};

    ${device.medium} {
      font-size: 18px;
      font-weight: bold;
    }
  }
`

export const DateContainer = styled.span`
  ${device.medium} {
    font-size: 18px;
    font-weight: 300;
  }

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  &:after {
    content: '';
  }
`

export const Writer = styled(Link)`
  max-width: 100%;
  ${device.medium} {
    max-width: 70%;
  }
`
export const TimeDateWrapper = styled.div`
  color: ${colors.black};
  font-weight: 300;
  ${device.medium} {
    font-size: 18px;
  }
`
