import 'swiper/css'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { IEpisodesRowFL } from '../../types'
import ComponentTitleFL from '../ComponentTitle/ComponentTitleFL'
import { Container, PostsContainer } from './EpisodesRowFL.styles'
import { Post } from './Post'

type Props = {
  layout: IEpisodesRowFL
}

export const EpisodesRowFL = ({ layout }: Props) => {
  const { posts, grid_title, grid_id } = layout

  const swiperProps: SwiperProps = {
    slidesPerView: 2.25,
    dir: 'rtl',
    spaceBetween: 8,
    centerInsufficientSlides: true,
    resistanceRatio: 0.4,
    resistance: true,
    breakpoints: {
      768: {
        slidesPerView: 2.45,
        spaceBetween: 12,
      },
      1024: {
        slidesPerView: 2.45,
        spaceBetween: 16,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  }

  return (
    <Container>
      <ComponentTitleFL componentTitle={grid_title} componentId={grid_id} />
      <PostsContainer>
        <Swiper {...swiperProps}>
          {posts.map(
            post =>
              post.imageObj && (
                <SwiperSlide key={post.id}>
                  <Post
                    key={post.id}
                    post={post}
                    maxLines={4}
                    grid_id={grid_id}
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </PostsContainer>
    </Container>
  )
}
