import dynamic from 'next/dynamic'
import { usePage } from '../../contexts'
import { colors, postHeight } from '../../styles/GlobalVariables'
import { IPost, LayoutMobile } from '../../types'
import Credits from '../DateAndWriter/Credits'
import { Image } from '../Image/Image'
import Link from '../Link/Link'
import { utils } from '../Player/Helpers/utils'
import { Shave } from '../Shave/Shave'
import {
  Container,
  PromoStrip,
  SecondaryTitle,
  TextContainer,
  Thumbnail,
  Title,
} from './PostFl.styles'
import { useFL } from '../../hooks/useFL'

type Props = {
  post: IPost
  layoutMobile: LayoutMobile
  hideSecondaryTitle?: boolean
  height?: string
  className?: string
  leftPadded?: boolean
  maxLines?: number
  grid_id?: number
}

export const PostFl = ({
  post,
  layoutMobile,
  hideSecondaryTitle,
  height = postHeight,
  className,
  leftPadded,
  maxLines = 3,
  grid_id,
}: Props) => {
  const {
    redStrip,
    imageObj,
    title,
    secondaryTitle,
    isBlank,
    background,
    promotedStripText,
    // video,
    link: postLink,
  } = post

  const linkTarget = isBlank ? '_blank' : undefined
  const page = usePage()
  const { isDarkTheme } = useFL()
  const pageId = page?.PageMeta.id

  const queryParams = utils.addPageQueryParams(postLink, pageId, grid_id)
  const link = postLink + queryParams
  const hide_date = !postLink.match(/^(\/item)/)
  const RedStrip = dynamic(() => import('../RedStrip/RedStrip'))
  return (
    <Container
      layout={layoutMobile}
      backgroundColor={background}
      height={height}
      className={className}
      hideSecondaryTitle={hideSecondaryTitle}
    >
      <Thumbnail href={link} target={linkTarget} isDarkTheme={isDarkTheme}>
        {redStrip && <RedStrip {...redStrip} />}
        <Image image={imageObj} alt={imageObj ? title : title} />
        {promotedStripText && <PromoStrip>{promotedStripText}</PromoStrip>}
      </Thumbnail>
      <TextContainer
        leftPadded={leftPadded}
        layout={layoutMobile}
        hideSecondaryTitle={hideSecondaryTitle}
        backgroundColor={background}
      >
        <Link href={link} target={linkTarget}>
          <Shave maxLines={maxLines}>
            <Title isDarkTheme={isDarkTheme}>{title}</Title>
          </Shave>
        </Link>
        {!hideSecondaryTitle && secondaryTitle && (
          <SecondaryTitle
            href={link}
            target={linkTarget}
            isDarkTheme={isDarkTheme}
          >
            {secondaryTitle}
          </SecondaryTitle>
        )}
        <Credits
          credits={post}
          color={isDarkTheme ? colors.steelBlue : colors.darkGray2}
          hide_date={hide_date}
        />
      </TextContainer>
    </Container>
  )
}
