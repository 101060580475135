import styled from 'styled-components'
import { colors, device, themeClassName } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.span<{ color?: string }>`
  display: flex;
  height: 17px;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  color: ${props => props.color || colors.transparentGray};

  ${themeClassName.vodMain} & {
    color: ${colors.transparentWhite};
  }

  ${device.medium} {
    height: 27px;
    font-size: 15px;
    line-height: 1.77;
  }
`

export const Writers = styled.span`
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FirstWriter = styled(Link)`
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
  color: inherit;
  flex-shrink: 0;
  order: -1;
`

export const SecondWriterComma = styled.span`
  display: none;

  ${device.medium} {
    display: inline;
  }
`

export const SecondWriter = styled(Link)`
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
  color: inherit;
  display: none;

  ${device.medium} {
    display: inline;
  }
`

export const Date = styled.span`
  display: flex;
  flex-shrink: 0;
`

export const PromotionalText = styled(Link)<{ color?: string }>`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: ${props => props.color || colors.black};

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${themeClassName.vodMain} & {
    color: ${colors.transparentGray};
  }
`
