import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'

export const ImageWrapper = styled.div`
  margin-bottom: 3px;
  display: flex;
  max-height: 243px;
  position: relative;

  > img {
    flex: 1;
    object-fit: cover;
  }

  ${device.medium} {
    margin-bottom: 8px;
    max-height: 460px;

    > img {
      min-height: 460px;
    }
  }
`

export const Details = styled.div`
  font-size: 15px;
  color: ${colors.gray};
  font-weight: 600;
  margin: 0 20px;

  ${device.medium} {
    margin: 0;
    color: ${colors.commentsGray};
  }
`

export const Container = styled.div`
  margin-bottom: 12px;

  ${device.medium} {
    margin-bottom: 40px;
  }
`
