import styled from 'styled-components'
import {
  colors,
  device,
  hideOnMobile,
  themeClassName,
} from '../../styles/GlobalVariables'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { Container as VodPost } from '../Post/VodPost.styles'
import ArrowLeft from '/public/assets/images/svg/arrow-left-grey.svg'
import ArrowRight from '/public/assets/images/svg/arrow-right-grey.svg'

export const Container = styled.div`
  margin-bottom: 30px;

  ${VodPost} {
    margin-bottom: 0;
  }
`

export const StyledComponentTitle = styled(ComponentTitle)`
  ${device.medium} {
    h2 {
      height: unset;
      max-height: unset;
    }
  }
`

export const NavigationOverlay = styled.div<{ position: 'left' | 'right' }>`
  background-image: ${props =>
    props.position === 'left'
      ? 'linear-gradient(89deg, #121212 3%, rgba(18, 18, 18, 0) 100%);'
      : 'linear-gradient(271deg, #121212 3%, rgba(18, 18, 18, 0) 100%);'};
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
  ${props => (props.position === 'left' ? 'left: 20px;' : 'right: 20px;')}

  ${device.medium} {
    ${props => (props.position === 'left' ? 'left: 0;' : 'right: 0;')}
  }

  ${hideOnMobile}
`

export const TouchArea = styled.div<{ position: 'left' | 'right' }>`
  display: flex;
  position: absolute;
  pointer-events: auto;
  cursor: pointer;
  padding: 20px 10px;
  ${props => (props.position === 'left' ? 'left: 0;' : 'right: 0;')}
  top: calc(74px - 20px);
  user-select: none;
`

export const ArrowLeftIcon = styled(ArrowLeft)`
  font-size: 22px;
  height: 22px;
  width: 12px;
  z-index: 1;
`

export const ArrowRightIcon = styled(ArrowRight)`
  font-size: 22px;
  height: 22px;
  width: 12px;
  z-index: 1;
  pointer-events: auto;
`

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: normal;

  ${device.medium} {
    font-size: 24px;
    line-height: 1.25;
  }

  ${themeClassName.vod} & {
    color: ${colors.white};
  }
`

export const CarouselWrapper = styled.div`
  position: relative;
  margin: 0 -20px;

  ${device.medium} {
    margin: 0;
  }

  .swiper {
    overflow-x: hidden;
    overflow-y: auto;

    .swiper-slide {
      width: 166px;

      ${device.medium} {
        width: 300px;
      }
    }
  }

  .swiper-button-disabled {
    display: none;
  }
`
